import React, { useEffect, useState } from 'react'
import CenterModal from '../../../components/Modals/center-modal';
import { ReactComponent as BackICon } from '../../../assets/icons/back.svg';
import InputSelect from '../../../components/InputGroup/InputSelect';
import { ALL_REGEX } from '../../../config/static';
import MobileInput from '../../../components/InputGroup/MobileInput';
import InputText from '../../../components/InputGroup/InputText';
import { toast } from 'react-toastify';
import { statusUpdate } from '../../../services/private/borker.service';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { svTypes } from '../../../utils/constants';
import Dropdown from '../../../components/InputGroup/dropdown';
import style from '../../../modules/Settings/Projects/style.module.css'
import DateTimePicker from '../../../components/InputGroup/DateTime';
import moment from 'moment';
import BasicModal from '../../../components/Modals/BasicModal';
import { checkIsSomeItemAvailableInList } from '../../../helpers/helpers';
import { getLeadAccess, getLeadStatusAssignedToConfigByStatusId, getLeadStatusShareConfigByStatusId, getUsersForSettings } from '../../../services/private/company.service';
import { validityUnit } from '../../../helpers/enums';
import { ReactComponent as PlusPrimaryIcon } from '../../../assets/icons/PlusPrimary.svg';
import InputTextarea from '../../../components/InputGroup/InputTextarea';
import { markCompletedByUUID } from '../../New Dashboard/services/dashboard.service';

const durationUnit = [
    {
        label: 'Minutes',
        value: 'mins'
    },
    {
        label: 'Hours',
        value: 'hrs'
    }
]
const MarkAsCompleteModal = ({ show,
    closeModal,
    selectedLead,
    getEvents,
    intentStatuses,
    userProfile,
    allowedPermissions,
    leadId,
    statusId,
    reassindedUserUuid
}) => {

    const [detail, setDetail] = useState({
        name: '',
        email: '',
        address: '',
        company: '',
        profession: '',
        designation: '',
        panNo: '',
        aadhar: '',
    })
    const [mobile, setMobile] = useState('');
    const [altMobile, setAltMobile] = useState('');
    const [aboutProject, setAboutProject] = useState('');
    const [preference, setPrefrence] = useState('2 BHK');
    const [investement, setInvestment] = useState('45L-50L');
    const [purpose, setPurpose] = useState('end use');
    const [possessionPreference, setPossessionPreference] = useState('Dec 25')
    const [others, setOthers] = useState('')
    const [referralName, setReferralName] = useState('')
    const [referralMobile, setReferralMobile] = useState('');
    const [society, setSociety] = useState('');
    const [flatNo, setFlatNo] = useState('');
    const [error, setError] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [mobileError, setMobileError] = useState(false);
    const [intent, setIntent] = useState('');
    const [followupScheduleDate, setFollowupScheduleDate] = useState('');
    const [duration, setDuration] = useState('');
    const [duration_unit, setDuration_unit] = useState('');
    const [scheduleDate, setScheduleDate] = useState('')
    const [svTypes, setSvTypes] = useState('offline');
    const [preSaleUserId, setPreSaleUserId] = useState('');
    const [closingUserId, setClosingUserId] = useState('');
    const [usersForAssigned, setUsersForAssigned] = useState([]);
    const [statusShareUsers, setStatusShareUsers] = useState([]);
    const [leadsShareUsers, setLeadsShareUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [remarks, setRemarks] = useState('')
    const [users, setUsers] = useState([{
        access: 'edit',
        validity: 30,
        validity_unit: 'days'
    }]);
    const [referenceMobileError, setReferenceMobileError] = useState(false);

    console.log(selectedLead, 'selectedLead')
    useEffect(() => {
        if (Object.keys(selectedLead?.length > 0)) {
            setDetail({
                ...detail,
                name: selectedLead?.customer_name || selectedLead?.name,
            })
            setMobile(selectedLead?.customer_mobile)
            setIntent(selectedLead?.intent_id);
            setClosingUserId(selectedLead?.closing_user_id)
            setPreSaleUserId(selectedLead?.pre_sale_user_id)
            if (!selectedLead?.pre_sale_user_id) {
                getShareLeadAccessAssignedToByStatus();
            }
        }
    }, [selectedLead])

    const handleChangeDetail = (e) => {
        setDetail({ ...detail, [e.target.name]: e.target.value })
    }

    const getAllUsers = () => {
        getUsersForSettings().then(res => {
            if (res.status === 200) {
                const userData = res.data.data.map((data) => {
                    return {
                        label: data.name + " - " + data.email,
                        // label: data.name,
                        value: data.id
                    }
                })
                const usersForAssign = res.data.data.map((data) => {
                    return {
                        label: data.name + " - " + data.email,
                        // label: data.name,
                        value: data.id,
                        uuid: data.uuid
                    }
                });
                setUsersForAssigned(usersForAssign);
                setAllUsers(userData)
            }
        })
    }

    const getShareLeadAccess = async () => {
        const res = await getLeadAccess(selectedLead?.lead_uuid);
        if (res.data.status === 200) {
            if (res.data.data.length > 0) {
                const mapped = res.data.data.map(item => ({
                    user_id: item.user_id,
                    validity: item.validity,
                    validity_unit: item?.validity_unit,
                    access: userProfile?.sv_completed_read_access ? 'view' : item.access
                }))
                // const results = allUsers.filter((item) => !mapped.some((mapVal) => item.value === mapVal.user_id));
                // setAllUsers(results)
                setLeadsShareUsers(mapped);
            }
        }
    }

    const getShareLeadAccessAssignedToByStatus = async () => {
        const res = await getLeadStatusAssignedToConfigByStatusId({ status_id: statusId });
        if (res.data.status === 200) {
            if (res.data.data?.user_uuid) {
                const user = usersForAssigned.find(i => i.uuid === res.data.data?.user_uuid)
                setPreSaleUserId(user?.value)
            } else {
                const user = usersForAssigned.find(i => i.uuid === reassindedUserUuid)
                setPreSaleUserId(user?.value)
            }
        }
    }

    const getShareLeadAccessUsingStatusId = async () => {
        const res = await getLeadStatusShareConfigByStatusId({ status_id: statusId });
        if (res.data.status === 200) {
            if (res.data.data.length > 0) {
                const mapped = res.data.data.map(item => ({
                    user_id: item.user_id,
                    validity: item.validity,
                    validity_unit: item?.validity_unit,
                    access: item.access
                }))
                // const results = allUsers.filter((item) => !mapped.some((mapVal) => item.value === mapVal.user_id));
                // setAllUsers(results)
                setStatusShareUsers(mapped);
            }
        }
    }

    useEffect(() => {
        if (statusShareUsers.length > 0 && (leadsShareUsers.length === 0 || !userProfile?.maintain_lead_access)) {
            setUsers(statusShareUsers);
        } else if (leadsShareUsers.length > 0 && statusShareUsers.length === 0) {
            setUsers(leadsShareUsers);
        } else if (statusShareUsers.length > 0 && leadsShareUsers.length > 0 && userProfile?.maintain_lead_access) {
            // merge two array uniquely
            var ids = new Set(statusShareUsers.map(d => d.user_id));
            var merged = [...statusShareUsers, ...leadsShareUsers.filter(d => !ids.has(d.ID))];
            setUsers(merged);
        }
    }, [statusShareUsers, leadsShareUsers])

    useEffect(() => {
        if (leadId) {
            if (userProfile?.allowed_modules?.includes('lead_sharing')) {
                getShareLeadAccess();
            }
        }
        if (statusId && userProfile?.allowed_modules?.includes('lead_sharing')) {
            getShareLeadAccessUsingStatusId();
        }
        getAllUsers();
    }, [leadId, statusId]);

    const handleSubmit = async () => {
        // All fields Error check
        if (!mobile || !detail.name || !detail.email || !intent || !scheduleDate || !followupScheduleDate) {
            setError(true);
            document.getElementById('customer-form').scrollIntoView({
                behavior: "smooth"
            });

            return
        } else {
            setError(false)
        }

        // Email Error check
        if (!ALL_REGEX.EMAIL.test(detail.email)) {
            setEmailError(true);
            return;
        } else {
            setEmailError(false)
        }
        // // Mobile Error check
        if (selectedLead.project_id !== 610 && mobile.length < 12) {
            setMobileError(true);
            return;
        } else {
            setMobileError(false)
        }
        // Error check for source field referral option
        // if ((aboutProject === 'Reference' && referralMobile) && referralMobile.length < 12) {
        //     document.getElementById('source').scrollIntoView({
        //         behavior: 'smooth', block: "end", inline: "nearest"
        //     });
        //     setReferenceMobileError(true);
        //     return;
        // } else {
        //     setMobileError(false)
        // }
        const payload = {
            customer_name: detail.name || null,
            customer_mobile: mobile || null,
            project_id: selectedLead?.project_id,
            email: detail.email || null,
            address: detail.address || null,
            company: detail.company || null,
            profession: detail.profession || null,
            designation: detail.designation || null,
            preference: preference || null,
            investement: investement || null,
            purpose: purpose || null,
            alt_mobile: altMobile.slice(-10) || null,
            alt_mobile_country_code: altMobile.slice(0, 2),
            // about_project: 'Channel Partner',
            possession_preference: possessionPreference || null,
            // reference_name: referralName || null,
            // reference_mobile: referralMobile || null,
            // reference_flat_no: flatNo || null,
            // reference_society: society || null,
            // other_source: others || null,
            intentId: intent,
            completed_time: scheduleDate,
            followupScheduleDate: followupScheduleDate,
            sv_duration: duration || null,
            sv_duration_unit: duration_unit || null,
            remarks: remarks,
            preSaleUserId: preSaleUserId || null,
            sourcingUserId: null,
            closingUserId: closingUserId,
            lead_Access_users: leadsShareUsers.map(item => ({
                ...item,
                validity: item.validity || '30',
                access: item.access || 'edit',
                validity_unit: item.validity_unit || 'days',
                expires_at: new Date(moment().add(parseInt(item.validity), item.validity_unit)).toISOString()
            })),
        }
        const res = await markCompletedByUUID(selectedLead?.site_visit_uuid, payload);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getEvents();
            closeModal();
        }

    }

    return (
        <BasicModal show={show} closeModal={closeModal} modalClass='lg:!max-w-[760px] !max-w-[95%] md:!mx-auto !mx-4'>
            <div className='p-4 flex items-center justify-between'>
                {/* <button onClick={closeModal} className='mr-2'>
                    <BackICon />
                </button> */}
                <h3 className='text-xl font-semibold m-0'> Mark SV Complete</h3>
                <button className='border-0 bg-tr ' onClick={closeModal}><CloseIcon /></button>
            </div>
            <div className='px-4 h-[450px] !mb-3 overflow-y-auto'>
                <div id='customer-form'>
                    <div className='text-lg font-semibold !mb-4 text-gray800'>Basic Details</div>
                    <div className='grid grid-cols-2 gap-6'>
                        <div className={` font-medium position-relative flex flex-col`}>
                            {/* <label className='sm:!text-lg text-base !mb-3 '>Mobile No.<sup className='text-red'>*</sup></label> */}

                            <MobileInput
                                label={'Mobile No.'}
                                inputClass={'border !w-full focus:outline-none focus:border-primary rounded-xl !text-gray800 !font-medium text-input !h-[38px] sm:!h-[54px] text-sm sm:!text-base sm:placeholder:!text-base placeholder:!text-sm  !py-2 sm:!py-3'}
                                // containerClass={classname}
                                country={'in'}
                                value={mobile}
                                countryCodeEditable={false}
                                enableSearch={true}
                                disableSearchIcon={true}
                                onChange={phone => {
                                    setMobile(phone)
                                }}
                                disabled={selectedLead.project_id === 610 ? false : true}
                            />
                            {/* <div className=' flex items-center gap-2 sm:!text-sm text-xs text[#314056] !mt-4'>
                                <span><InfoIcon /></span> Make sure this is your registered mobile no. on whatsapp.
                            </div> */}
                            {error && !mobile && (
                                <div className="text-red sm:!text-sm text-xs !mt-4">Please Enter Customer Mobile No.</div>
                            )}
                            {
                                mobileError && mobile && <div className="text-red sm:!text-sm text-xs !mt-4">Please Enter Valid Mobile No.</div>
                            }
                        </div>

                        <div className={`flex flex-col font-medium relative w-full `}>
                            {/* <label className='sm:!text-lg text-base !mb-3 '>Name<sup className='text-red'>*</sup></label> */}
                            <InputText
                                label={'Name'}
                                type={"text"}
                                name='name'
                                value={detail.name}
                                onChange={handleChangeDetail}
                                placeholder={'Enter Name'}
                                required={true}
                                disable={true}
                            />
                            {error && !detail.name && (
                                <div className="text-red sm:!text-sm text-xs !mt-4">Please Enter Customer Name</div>

                            )}
                        </div>

                        <div className={`flex flex-col font-medium relative w-full `}>
                            <InputText
                                label={'Email'}
                                type={"email"}
                                name='email'
                                value={detail.email}
                                onChange={handleChangeDetail}
                                placeholder={'Enter E-Mail'}
                                required={true}
                            />
                            {error && !detail.email && (
                                <div className="text-red sm:!text-sm text-xs !mt-4">Please Enter Customer Email</div>
                            )}
                            {emailError && detail.email && (
                                <div className="text-red sm:!text-sm text-xs !mt-4">Please Enter Valid Email</div>
                            )}

                        </div>
                        <DateTimePicker
                            label={'Select Site Visit Done Date & Time'}
                            onValueChange={(e) => {
                                setScheduleDate(e)
                            }}
                            name="scheduleDate"
                            handelBlur={() => { }}
                            value={scheduleDate}
                            error={error && !scheduleDate ? 'Please Select SV Complete date' : ''}
                            completed={true}
                        />
                        <InputSelect
                            index={4}
                            name="intent"
                            label={'Intent'}
                            inputclass={'!mt-0'}
                            error={error && !intent ? 'Please Select Intent' : ''}
                            touched={''}
                            options={intentStatuses}
                            remarks
                            placeholder="Select Intent"
                            value={intent ? intentStatuses.find(i => i.value === intent) : ''}
                            onChange={(e) => {
                                setInvestment(e.value)
                            }}
                            required={true}
                        />
                        <div className='form-group !mt-0'>
                            <label>Type of Site Visit</label>
                            <div>
                                <label className='mr-4'>
                                    <input
                                        type='radio'
                                        name='sitevisit'
                                        value='offline'
                                        checked={svTypes === 'offline'}
                                        onChange={() => {
                                            setSvTypes('offline');
                                        }}
                                    /> Onsite
                                </label>
                                <label>
                                    <input
                                        type='radio'
                                        name='sitevisit'
                                        value='online'
                                        checked={svTypes === 'online'}
                                        onChange={() => {
                                            setSvTypes('online')
                                        }}
                                    /> Video Call
                                </label>
                            </div>
                        </div>
                        {svTypes === 'online' && <div className='form-group position-relative mt-3'>
                            <label >Duration</label>
                            <Dropdown
                                list={durationUnit}
                                onChange={(value) => {
                                    setDuration_unit(value.value)
                                }}
                                value={duration_unit ? durationUnit.find(item => item.value === duration_unit) : ''}
                                style={style}
                            />
                            <input
                                type='text'
                                className='form-control text-input'
                                // style={{ borderColor: (error && !milestones.duration) ? '#FC5A5A' : '' }}
                                placeholder='e.g. 3'
                                value={duration}
                                onChange={(e) => {
                                    let val = e.target.value
                                        .replace(/^0+/g, '')
                                        .replace(/[^0-9]/g, '')

                                    setDuration(val)
                                }}
                            />
                            {/* {(error && !milestones.duration) && (
                                                    <div className="input-feedback mt-8">
                                                        Please Enter Duration
                                                    </div>
                                                )} */}
                        </div>}
                        <div>

                            <label className='mt-3'>Next Followup Date & Time</label>
                            <DateTimePicker
                                onValueChange={(e) => {
                                    setFollowupScheduleDate(e)
                                }}
                                name="followupScheduleDate"
                                handelBlur={() => { }}
                                value={followupScheduleDate}
                                error={error && !followupScheduleDate ? 'Please Select Followup date and time' : ''}
                                required={true}
                            />
                            {followupScheduleDate ?
                                <div className="w-100 text-center mt-2 px-24" >
                                    <p className='text-center px-16 fz14 mt-16 black-dark-700 mb-0'>We will remind you <span className='black fw-po-medium'>15 mins</span> prior to <span className='black fw-po-medium'>{moment(followupScheduleDate).format("hh:mm a, Do MMM YYYY")}</span></p>
                                    {/* <p className='text-center px-16 fz14 mt-16 black-dark-700 mb-0'>You will be reminded at <span className='black fw-po-medium'>{moment(values.followupScheduleDate).subtract(15, 'minutes').format("hh:mm a, Do MMM YYYY")}</span></p> */}
                                </div>
                                : " "}
                        </div>

                        <div className='!border !border-dashed col-span-2 '></div>

                        <div className={`flex flex-col font-medium relative w-full `}>
                            {/* <label className='sm:!text-lg text-base !mb-3 '>Alternate No.</label> */}
                            <MobileInput
                                label={'Alternate No.'}
                                inputClass={'border !w-full focus:outline-none focus:border-primary !text-gray800 !font-medium rounded-xl text-input !h-[38px] sm:!h-[54px] text-sm sm:!text-base sm:placeholder:!text-base placeholder:!text-sm  !py-2 sm:!py-3'}
                                // containerClass={classname}
                                country={'in'}
                                value={altMobile}
                                countryCodeEditable={false}
                                enableSearch={true}
                                disableSearchIcon={true}
                                onChange={(e) => {
                                    setAltMobile(e.phone)
                                }}
                            />
                        </div>

                        <div className={`flex flex-col font-medium relative w-full `}>
                            <InputText
                                label={'Address'}
                                type={"text"}
                                placeholder={'Enter Address '}
                                name='address'
                                value={detail.address}
                                onChange={handleChangeDetail}
                            />

                            {/* {error && !detail.address && (
                                <div className="text-red sm:!text-sm text-xs !mt-4">Please Enter Customer Address</div>

                            )} */}
                        </div>


                        <div className={`flex flex-col font-medium relative w-full `}>
                            {/* <label className='sm:!text-lg text-base !mb-3 '>Company</label> */}
                            <InputText
                                label={'Company'}
                                type={"text"}
                                placeholder={'Enter Company'}
                                name='company'
                                value={detail.company}
                                onChange={handleChangeDetail}
                            />

                        </div>
                        <div className={`flex flex-col font-medium relative w-full `}>
                            {/* <label className='sm:!text-lg text-base !mb-3 '>Profession  </label> */}
                            <InputText
                                label={'Profession'}
                                type={"text"}
                                placeholder={'Enter Profession'}
                                name='profession'
                                value={detail.profession}
                                onChange={handleChangeDetail}
                            />
                            {/* <input
                                className={` border rounded-xl focus:outline-none focus:!border-primary  text-input sm:!text-lg text-sm placeholder:sm:!text-lg placeholder:!text-sm !py-2 !px-2 sm:!py-3 sm:!px-4`}
                                type={"text"}
                                placeholder={'Enter Profession'}
                                name='profession'
                                value={detail.profession}
                                onChange={handleChangeDetail}
                            /> */}
                        </div>

                        <div className={`flex flex-col font-medium relative w-full `}>
                            {/* <label className='sm:!text-lg text-base !mb-3 '>Designation</label> */}
                            <InputText
                                label={'Designation'}
                                type={"text"}
                                placeholder={'Enter Designation'}
                                name='designation'
                                value={detail.designation}
                                onChange={handleChangeDetail}
                            />

                        </div>
                        <div className='col-span-2'>
                            <InputTextarea
                                label={'Comments'}
                                placeholder={'Enter Comments'}
                                value={remarks}
                                onChange={(e) => setRemarks(e.target.value)}
                            />
                        </div>
                        {/* <div className='w-full flex items-center' id='source'>
                            <div className={` flex flex-col font-medium w-full`}>                               
                                <InputSelect
                                    label={'How did you find out about Krystal one Project?'}
                                    options={InfoList}
                                    placeholder={'Select option'}
                                    value={aboutProject ? InfoList.find(i => i.value === aboutProject) : ''}
                                    onChange={(value) => setAboutProject(value.value)}
                                    required={true}
                                />
                                {error && !aboutProject && (
                                    <div className="text-red sm:!text-sm text-xs !mt-4">Please Select Project Source</div>

                                )}
                            </div>
                        </div> */}
                        {/* {aboutProject === 'Other' && <div className={`flex flex-col font-medium relative w-full`}>
                           
                            <InputText
                                label={'Other Source'}

                                type={"text"}
                                value={others}
                                onChange={(e) => setOthers(e.target.value)}
                                placeholder={'Enter Other Source'}                           
                            />                            

                        </div>}
                        {
                            aboutProject === 'Reference' && <>

                                <div className={`flex flex-col font-medium relative w-full `}>                                   
                                    <InputText
                                        label={'Reference Name'}
                                        placeholder={'Enter Reference Name '}
                                        name='address'
                                        value={referralName}
                                        onChange={(e) => setReferralName(e.target.value)}
                                        required={true}
                                    />                                   
                                    {error && !referralName && (
                                        <div className="text-red sm:!text-sm text-xs !mt-4">Please Enter Reference Name</div>

                                    )}
                                </div>
                                <div className={`flex flex-col font-medium relative w-full `}>                                    
                                    <MobileInput
                                        label={'Reference Mobile'}
                                        inputClass={'border !w-full focus:outline-none focus:border-primary !text-gray800 !font-medium rounded-xl text-input !h-[38px] sm:!h-[54px] text-sm sm:!text-base sm:placeholder:!text-base placeholder:!text-sm  !py-2 sm:!py-3'}                                        
                                        country={'in'}
                                        value={referralMobile}
                                        countryCodeEditable={false}
                                        enableSearch={true}
                                        disableSearchIcon={true}
                                        onChange={(e) => {
                                            setReferralMobile(e.phone)
                                        }}
                                    />
                                    {error && !referralMobile && (
                                        <div className="text-red sm:!text-sm text-xs !mt-4">Please Enter Reference Mobile No</div>

                                    )}
                                    {
                                        referenceMobileError && referralMobile && <div className="text-red sm:!text-sm text-xs !mt-4">Please Enter Valid Mobile No.</div>
                                    }
                                </div>


                                <div className={`flex flex-col font-medium relative w-full `}>                                   
                                    <InputText
                                        label={'Society'}
                                        placeholder={'Enter Society'}
                                        name='address'
                                        value={society}
                                        onChange={(e) => setSociety(e.target.value)}
                                    />                                    
                                </div>
                                <div className={`flex flex-col font-medium relative w-full `}>                                    
                                    <InputText
                                        label={'Flat No'}
                                        placeholder={'Enter Flat No'}
                                        name='address'
                                        value={flatNo}
                                        onChange={(e) => setFlatNo(e.target.value)}
                                    />                                    
                                </div> 


                            </>
                        }*/}
                        <div className='!border !border-dashed col-span-2 '></div>
                        {userProfile?.allowed_modules?.includes('lead_sharing') && checkIsSomeItemAvailableInList(allowedPermissions, ['view_lead_share_modal', 'edit_lead_sharing']) &&
                            <>
                                <div className=''>
                                    <InputSelect
                                        label={'Pre-sale'}
                                        placeholder={'Select User'}
                                        inputclass={'!mt-0'}
                                        options={usersForAssigned}
                                        value={preSaleUserId ? usersForAssigned.find(user => user.value === preSaleUserId) : ''}
                                        onChange={(value) => {
                                            if (value) {
                                                setPreSaleUserId(value.value);
                                            } else {
                                                setPreSaleUserId(value);
                                            }
                                        }}
                                        isClearable={true}
                                    />
                                </div>
                                <div className=''>
                                    <InputSelect
                                        label={'Closing'}
                                        placeholder={'Select User'}
                                        inputclass={'!mt-0'}
                                        options={usersForAssigned}
                                        value={closingUserId ? usersForAssigned.find(user => user.value === closingUserId) : ''}
                                        onChange={(value) => {
                                            if (value) {
                                                setClosingUserId(value.value);
                                            } else {
                                                setClosingUserId(value);
                                            }
                                        }}
                                        isClearable={true}
                                    />
                                </div>
                                <div className='col-span-2'>
                                    <label className='!mt-4'>Shared With</label>
                                    {
                                        users.map((item, index) => (


                                            <div className={`${index > 0 ? 'mt-3' : ''}`} key={`${index}`}>
                                                <div className='flex justify-between items-center'>
                                                    <h3 className='text-base font-semibold'>User {index + 1}</h3>
                                                    {allowedPermissions.includes('edit_lead_sharing') && <button
                                                        type='button'
                                                        className='text-primary text-base font-medium'
                                                        onClick={() => {
                                                            let newUsers = [...users];
                                                            newUsers.splice(index, 1);
                                                            if (users.length === 1) {
                                                                setUsers([{}])
                                                            } else {
                                                                setUsers([...newUsers])
                                                            }

                                                        }}
                                                    >
                                                        Remove
                                                    </button>}
                                                </div>
                                                <div>
                                                    <InputSelect
                                                        placeholder={'Select User'}
                                                        options={allUsers}
                                                        inputclass={'!mt-0'}
                                                        value={item.user_id ? allUsers.find(user => user.value === item.user_id) : ''}
                                                        onChange={(value) => {
                                                            let newUsers = [...users];
                                                            newUsers[index].user_id = value.value;
                                                            // let filteredUser = allUsers.filter(aluser => aluser.value !== value.value)
                                                            // setAllUsers(filteredUser)
                                                            setUsers([...newUsers]);
                                                        }}
                                                    />
                                                    <div className='mt-3'>
                                                        <label htmlFor={`edit_${index}`} className='mr-4'>
                                                            <input
                                                                type="radio"
                                                                className='mr-2'
                                                                name={`permission_${index}`}
                                                                id={`edit_${index}`}
                                                                value='edit'
                                                                checked={item.access === 'edit'}
                                                                onChange={() => {
                                                                    let newUsers = [...users];
                                                                    newUsers[index].access = 'edit'
                                                                    setUsers(newUsers);
                                                                }}
                                                                disabled={!allowedPermissions.includes('edit_lead_sharing')}
                                                            />
                                                            <span>Edit Access</span>
                                                        </label>
                                                        <label htmlFor={`view_${index}`}>
                                                            <input
                                                                type="radio"
                                                                className='mr-2'
                                                                name={`permission_${index}`}
                                                                id={`view_${index}`}
                                                                value='view'
                                                                checked={item.access === 'view'}
                                                                onChange={() => {
                                                                    let newUsers = [...users];
                                                                    newUsers[index].access = 'view'
                                                                    setUsers(newUsers);
                                                                }}
                                                                disabled={!allowedPermissions.includes('edit_lead_sharing')}
                                                            />
                                                            <span>View Only</span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="">Validity</label>
                                                        <div className='grid grid-cols-2 gap-2'>
                                                            <InputText
                                                                placeholder={'e.g. 30'}
                                                                value={item.validity}
                                                                onChange={(e) => {
                                                                    let newUsers = [...users];
                                                                    newUsers[index].validity = e.target.value
                                                                    setUsers(newUsers);
                                                                }}
                                                            />
                                                            <InputSelect
                                                                inputclass={'m-0'}
                                                                placeholder={'Select Time'}
                                                                value={item.validity_unit ? validityUnit.find(validity => validity.value === item.validity_unit) : ''}
                                                                options={validityUnit}
                                                                onChange={(value) => {
                                                                    let newUsers = [...users];
                                                                    newUsers[index].validity_unit = value.value;
                                                                    setUsers(newUsers);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        ))
                                    }
                                </div>
                                <button
                                    type='button'
                                    className='inline-flex !mt-3 items-center gap-x-2 !text-primary inter text-sm font-semibold disabled:bg-transparent disabled:hover:bg-transparent'
                                    onClick={() => setUsers([...users, {
                                        access: 'edit',
                                        validity: 30,
                                        validity_unit: 'days'
                                    }])}
                                >
                                    <PlusPrimaryIcon />Add another
                                </button>
                            </>}
                        <div className='!border !border-dashed col-span-2'></div>
                        <div className='text-lg font-semibold col-span-2 text-gray800'>Inventory Preference</div>
                        <div className={` flex flex-col font-medium form-group position-relative col-span-2`}>
                            <label className='!mb-3'>Possession Preference</label>
                            <div className='grid grid-cols-2 sm:flex gap-6'>
                                <div
                                    className={`w-max rounded-full flex items-center text-lg border ${possessionPreference === 'Dec 25' ? '!text-white bg-gradient-to-r from-[#684EFB] to-[#3B37F5] ' : 'bg-[#F2F3F6] text-[#666677]'}  !p-2`}
                                    onClick={() => setPossessionPreference('Dec 25')}
                                >
                                    <input
                                        type="radio"
                                        name="possession"
                                        id=""
                                        value='Dec 25'
                                        className='accent-white'
                                        checked={possessionPreference === 'Dec 25'}
                                    />
                                    <label className={`ml-1.5 font-semibold mb-0 ${possessionPreference === 'Dec 25' ? '!text-white' : ''}`}>Dec 25</label>
                                </div>
                                <div
                                    className={`w-max rounded-full flex items-center text-lg border ${possessionPreference === 'Dec 26' ? '!text-white bg-gradient-to-r from-[#684EFB] to-[#3B37F5] ' : ' bg-[#F2F3F6] text-[#666677]'}  !p-2`}
                                    onClick={() => setPossessionPreference('Dec 26')}
                                >
                                    <input
                                        type="radio"
                                        name="possession"
                                        id=""
                                        className='accent-white'
                                        value={'Dec 26'}
                                        checked={possessionPreference === 'Dec 26'}
                                    />
                                    <label className={`ml-1.5 text-sm font-semibold mb-0 ${possessionPreference === 'Dec 26' ? '!text-white' : ''}`}>Dec 26</label>
                                </div>
                                <div
                                    className={`w-max rounded-full flex items-center text-lg border ${possessionPreference === 'Dec 27' ? '!text-white bg-gradient-to-r from-[#684EFB] to-[#3B37F5] ' : ' bg-[#F2F3F6] text-[#666677]'}  !p-2`}
                                    onClick={() => setPossessionPreference('Dec 27')}
                                >
                                    <input
                                        type="radio"
                                        name="possession"
                                        id=""
                                        className='accent-white'
                                        value={'Dec 27'}
                                        checked={possessionPreference === 'Dec 27'}
                                    />
                                    <label className={`ml-1.5 text-sm font-semibold mb-0 ${possessionPreference === 'Dec 27' ? '!text-white' : ''}`}>Dec 27</label>
                                </div>
                            </div>
                        </div>
                        <div className={` flex flex-col font-medium form-group position-relative col-span-2`}>
                            <label className='!mb-3'>Preferred Configuration</label>
                            <div className=' grid grid-cols-2 md:flex gap-6'>
                                <div
                                    className={`w-max rounded-full flex items-center text-lg border ${preference === '2 BHK' ? '!text-white bg-gradient-to-r from-[#684EFB] to-[#3B37F5] ' : ' bg-[#F2F3F6] text-[#666677]'} !p-2`}
                                    onClick={() => setPrefrence('2 BHK')}
                                >
                                    <input
                                        type="radio"
                                        name="Preferred"
                                        id=""
                                        value={'2 BHK'}
                                        className='accent-white'
                                        checked={preference === '2 BHK'}
                                    />
                                    <label className={`ml-1.5 text-sm font-semibold mb-0 ${preference === '2 BHK' ? '!text-white' : ''}`}>2 BHK</label>
                                </div>
                            </div>
                        </div>

                        <div className={` flex flex-col form-group font-medium position-relative col-span-2`}>
                            <label className='!mb-3'>How much you would like to invest?</label>
                            <div className='grid grid-cols-2 sm:flex gap-6'>
                                <div
                                    className={`w-max rounded-full flex items-center text-lg border ${investement === '45L-50L' ? '!text-white bg-gradient-to-r from-[#684EFB] to-[#3B37F5] ' : ' bg-[#F2F3F6] text-[#666677]'}  !p-2`}
                                    onClick={() => setInvestment('45L-50L')}
                                >
                                    <input
                                        type="radio"
                                        name="invest"
                                        id=""
                                        className='accent-white'
                                        value={'45L-50L'}
                                        checked={investement === '45L-50L'}
                                    />
                                    <label className={`ml-1.5 text-sm font-semibold mb-0 ${investement === '45L-50L' ? '!text-white' : ''}`}>45L-50L</label>
                                </div>
                                <div
                                    className={`w-max rounded-full flex items-center text-lg border ${investement === '50L-55L' ? '!text-white bg-gradient-to-r from-[#684EFB] to-[#3B37F5] ' : ' bg-[#F2F3F6] text-[#666677]'}  !p-2`}
                                    onClick={() => setInvestment('50L-55L')}
                                >
                                    <input
                                        type="radio"
                                        name="invest"
                                        id=""
                                        className='accent-white'
                                        value={'50L-55L'}
                                        checked={investement === '50L-55L'}
                                    />
                                    <label className={`ml-1.5 text-sm font-semibold mb-0 ${investement === '50L-55L' ? '!text-white' : ''}`}>50L-55L</label>
                                </div>
                                <div
                                    className={`w-max rounded-full flex items-center text-lg border ${investement === '60L-75L' ? '!text-white bg-gradient-to-r from-[#684EFB] to-[#3B37F5] ' : ' bg-[#F2F3F6] text-[#666677]'}  !p-2`}
                                    onClick={() => setInvestment('60L-75L')}
                                >
                                    <input
                                        type="radio"
                                        name="invest"
                                        id=""
                                        className='accent-white'
                                        value={'60L-75L'}
                                        checked={investement === '60L-75L'}
                                    />
                                    <label className={`ml-1.5 text-sm font-semibold mb-0 ${investement === '60L-75L' ? '!text-white' : ''}`}>60L-75L</label>
                                </div>
                            </div>
                        </div>

                        <div className={` flex flex-col font-medium form-group position-relative col-span-2`}>
                            <label className='!mb-3 '>Purpose of Purchase?</label>
                            <div className='flex gap-6'>
                                <div
                                    className={`w-max rounded-full flex items-center text-lg border ${purpose === 'end use' ? '!text-white bg-gradient-to-r from-[#684EFB] to-[#3B37F5] ' : ' bg-[#F2F3F6] text-[#666677]'}  !p-2`}
                                    onClick={() => setPurpose('end use')}
                                >
                                    <input
                                        type="radio"
                                        name="Purpose"
                                        value={'end use'}
                                        id=""
                                        className='accent-white'
                                        checked={purpose === 'end use'}

                                    />
                                    <label className={`ml-1.5 text-sm font-semibold mb-0 ${purpose === 'end use' ? '!text-white' : ''}`}>End Use</label>
                                </div>
                                <div
                                    className={`w-max rounded-full flex items-center text-lg border ${purpose === 'investment' ? '!text-white bg-gradient-to-r from-[#684EFB] to-[#3B37F5]' : ' bg-[#F2F3F6] text-[#666677]'}  !p-2`}
                                    onClick={() => setPurpose('investment')}
                                >
                                    <input type="radio"
                                        name="Purpose"
                                        value={'investment'}
                                        id=""
                                        className='accent-white'
                                        checked={purpose === 'investment'}
                                    />
                                    <label className={`ml-1.5 text-sm font-semibold mb-0 ${purpose === 'investment' ? '!text-white' : ''}`}>Investment</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='px-4 py-4 w-full shadow-topshadow'>
                <div className='flex justify-end w-full'>
                    <button onClick={closeModal} className="text-base font-medium inter leading-6 border mr-4 rounded-lg !text-black700 sm:w-[130px] sm:basis-[130px] sm:grow-0 sm:shrink-0 w-full basis-full h-[44px] text-center inter">Cancel</button>
                    <button className="text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary sm:w-[130px] sm:basis-[130px] sm:grow-0 sm:shrink-0 w-full basis-full h-[44px] text-center inter" onClick={handleSubmit} >Save</button>
                </div>
            </div>
        </BasicModal>
    )
}

export default MarkAsCompleteModal