import moment from 'moment'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { sendWhatsappInvitation, statusUpdate } from '../../services/private/borker.service'
import { toast } from 'react-toastify'
import { ReactComponent as ThreedotsIcon } from '../../assets/icons/threedots.svg';
import TooltipText from '../../components/Tooltip';
import useDocumentTitle from '../../custom hooks/useDocumentTitile';
import Consumer from '../../helpers/context'
import { Oval } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import MarkAsCompleteModal from './Modal/MarkAsCompleteModal';
import { useHistory } from 'react-router-dom';
import RenderTableData from './RenderSvTableData';
import SVColumnCustomisation from './Modal/svColumnCustomisation';
import { ReactComponent as TableIcon } from '../../assets/new/table.svg';
import { markCancelledByUUID } from '../New Dashboard/services/dashboard.service';
import MarkDeadModal from '../New Dashboard/Components/Modals/MarkDeadModal';
import SortIconDisplay from '../../components/Custom Table/sortIconDisplay';
import UpdateFollowupModal from '../New Dashboard/Components/Modals/UpdateFollowupModal';
import SVScheduleModal from '../New Dashboard/Components/Modals/SVScheduleModal';
import SVCancelledModal from '../New Dashboard/Components/Modals/SVCancelledModal';

const getClassName = (exist) => {
    switch (exist) {
        case 'yes':
            return 'hot-btn'
        case 'no':
            return 'dead-btn'
    }
}

const tableHeight = (filterLength) => {
    if (filterLength) {
        return `max-h-[calc(100vh-300px)] min-h-[calc(100vh-300px)]`
    } else {
        return 'max-h-[calc(100vh-245px)] min-h-[calc(100vh-245px)]'
    }
}

const SiteVisitLeads = ({
    leads,
    count,
    searchText,
    getEvents,
    selectedLead,
    setShowLightModal,
    setSelectedImg,
    setShowDeleteModal,
    setSelectedLead,
    isLoading,
    scheduleColumn,
    updateColumnList,
    handleChangeSelection,
    saveCurrentUserColumnPrefrence,
    dashboardColumns,
    setShowCustomisedColumnModal,
    showCustomisedColumnModal,
    redirectURL,
    redirectBactTo,
    statuses,
    intentStatuses,
    onSuccess,
    agencyList,
    sourceStatuses,
    selectedInternalTab,
    selectedTab,
    allUsers,
    getStats,
    col,
    sort,
    filters,
    setShowQualificationModal
}) => {
    const [showMarkLeadCompleteModal, setShowMarkLeadCompleteModal] = useState(false);
    const [tableColumn, setTableColumn] = useState([]);
    const [showCustomColumn, setShowCustomColumn] = useState(false);
    const [markDead, setMarkDead] = useState(false)
    const [showSVModal, setShowSVModal] = useState(false);
    const [showSvCancelled, setShowSvCancelled] = useState(false)
    useDocumentTitle('Site Visit Tracker (Scheduled)');

    let { allowedPermissions, userProfile } = useContext(Consumer);
    const history = useHistory();

    useEffect(() => {
        const sortedList = scheduleColumn.sort((a, b) => a.placement - b.placement)
        setTableColumn(sortedList);
    }, [scheduleColumn]);

    const handleStatusChange = async (uuid) => {
        const res = await markCancelledByUUID(uuid);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getEvents();
            getStats();
        }
    }

    const handleSendInvite = async (lead) => {
        const payload = {
            owner_mobile: lead.company_owner_mobile,
            owner_name: lead.company_owner_name
        }
        const res = await sendWhatsappInvitation(payload)
        if (res.data.status) {
            toast.success(res.data.message);
        }

    }

    return (
        // <div className="user-table ml-24">
        <div className={`relative ${(isLoading || leads?.length === 0) ? '' : 'overflow-auto'} !w-100 !border !rounded-lg ${tableHeight(filters?.length)} h-100`}>
            <table className='table'>
                <thead className='!z-[99]'>
                    <tr>
                        <th>S.No.</th>
                        {
                            tableColumn?.map((item, index) => {
                                return (
                                    <th
                                        key={`${item.db_value}_${index}`}
                                        className={`${item.label === 'Name' ? 'sticky-th' : ''}`}
                                    >
                                        <span className='flex items-center gap-1 w-max'>
                                            {item.label}
                                            <SortIconDisplay sortCol={col?.label} sort={sort} colName={item?.label} />
                                        </span>
                                    </th>
                                )
                            })
                        }
                        {/* <th className='sticky-th'>Name</th>
                            <th>Mobile No.</th>
                            <th>Schedule Date</th>
                            <th>CP User</th>
                            <th>CP User Mobile</th>
                            <th>CP Firm</th>
                            <th>CP Owner Mobile</th>
                            <th>CP User Registered</th>
                            <th>Lead Exist</th>
                            <th>Project</th>
                            <th>Sourcing Manager</th>
                            <th>Email</th>
                            <th>SV Type </th>
                            <th>Remarks</th> */}
                        {/* <th>Status</th> */}
                        <th className="!pl-3 !py-2 !sticky -right-3">
                            <TooltipText id="button-tooltip" title='Customise Columns' className='leadmanager-tooltip'>
                                <button className='!w-[50px] flex items-center justify-center' onClick={() => setShowCustomisedColumnModal(true)}>
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <line x1="5.81445" y1="10.1094" x2="14.4082" y2="10.1094" stroke="#44444F" strokeWidth="1.71875" strokeLinecap="round" />
                                        <line x1="10.1113" y1="5.8125" x2="10.1113" y2="14.4062" stroke="#44444F" strokeWidth="1.71875" strokeLinecap="round" />
                                    </svg> */}
                                    <TableIcon />
                                </button>
                            </TooltipText>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (leads.length > 0 && !isLoading) && leads.map((lead, index) => (
                            <tr key={lead.uuid}>
                                <td>{index + 1}</td>
                                {
                                    tableColumn?.map((item, tableIndex) => {
                                        return (
                                            <RenderTableData
                                                item={item}
                                                lead={lead}
                                                index={index}
                                                handleSendInvite={handleSendInvite}
                                                redirectURL={redirectURL}
                                                redirectBactTo={redirectBactTo}
                                                statuses={statuses}
                                                intentStatuses={intentStatuses}
                                                onSuccess={onSuccess}
                                                agencyList={agencyList}
                                                sourceStatuses={sourceStatuses}
                                                tab={selectedTab?.name}
                                                subTab={selectedInternalTab?.name}
                                                allUsers={allUsers}
                                            />
                                        )
                                    })

                                }
                                {/* <td>{lead.sourcing_manager || '-'}</td> */}
                                <td className='dropdown view-more !sticky -right-3'>
                                    <button className="!w-[50px] hover:bg-black100 flex items-center justify-center !h-7 rounded-lg" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <ThreedotsIcon />
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right !p-2 -!top-0 !w-[220px]" aria-labelledby="dropdownMenuButton">
                                        {!lead?.is_cancelled && <a className="dropdown-item text-capitalize"
                                            onClick={() => {
                                                // handleStatusChange(lead.uuid, 'completed')
                                                setSelectedLead(lead)
                                                setShowMarkLeadCompleteModal(true)
                                            }}
                                        >
                                            Mark SV Completed
                                        </a>}
                                        <a className="dropdown-item"
                                            onClick={() => {
                                                setSelectedLead(lead);
                                                setShowQualificationModal(true);
                                            }}
                                        >
                                            Change Qualification Status
                                        </a>
                                        {!lead?.is_cancelled && <a className="dropdown-item text-capitalize"
                                            onClick={() => {
                                                // handleStatusChange(lead?.site_visit_uuid)
                                                setSelectedLead(lead)
                                                setShowSvCancelled(true)
                                            }}
                                        >
                                            SV Cancelled
                                        </a>}

                                        {!lead?.broker_company_id && !lead?.is_cancelled && !lead?.is_completed && <a className="dropdown-item text-capitalize"
                                            onClick={() => {
                                                setSelectedLead(lead)
                                                setShowSVModal(true)
                                            }}
                                        >
                                            Edit SV Scheduled
                                        </a>}
                                        <a className="dropdown-item"
                                            onClick={() => {
                                                window.open(`/presale/opportunity-details/${lead?.lead_uuid}`)
                                            }}
                                        >
                                            View Timeline
                                        </a>

                                        <a className="dropdown-item"
                                            onClick={() => {
                                                window.open(`/presale/opportunity-details/${lead?.lead_uuid}`)
                                            }}
                                        >
                                            View Details
                                        </a>
                                        <a className="dropdown-item"
                                            onClick={() => {
                                                window.open(`/dashboard/details/${lead?.customer_uuid}`)
                                            }}
                                        >
                                            View Customer
                                        </a>
                                        {/* {lead.lead_uuid && <a className="dropdown-item text-capitalize"
                                                onClick={() => {
                                                    window.open(`/leads/${lead.lead_uuid}`);
                                                }}
                                            >
                                                View Lead
                                            </a>} */}
                                        {lead?.image && <a className="dropdown-item text-capitalize"
                                            onClick={() => {
                                                setSelectedImg(lead);
                                                setShowLightModal(true)
                                            }}
                                        >
                                            View Image
                                        </a>}
                                        {allowedPermissions?.includes('delete_sv_event') && !lead.lead_uuid && <a className="dropdown-item text-capitalize del-menu"
                                            onClick={() => {
                                                setSelectedLead(lead);
                                                setShowDeleteModal(true)
                                            }}
                                        >
                                            Delete SV
                                        </a>}
                                        <a className="dropdown-item text-capitalize del-menu"
                                            onClick={() => {
                                                setSelectedLead(lead);
                                                setMarkDead(true)
                                            }}
                                        >
                                            Mark as Dead
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            {leads?.length === 0 && !isLoading && <div className='d-flex justify-content-center flex-column items-center !max-h-[50vh] !min-h-[50vh]'>
                <figure><svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none"><circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF"></circle><path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF"></path><circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974"></circle><circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE"></circle><circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC"></circle><path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974"></path><path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE"></path></svg></figure>
                {leads?.length == 0 && (searchText) &&
                    <>
                        <h2 className="po black fz16 fw-po-medium mb-8">Sorry, we couldn’t find any results</h2>
                        <p className='text-center black-dark-600 fz14 mb-0'> Please try again</p>
                    </>
                }
                {
                    leads?.length == 0 && !searchText &&
                    <>
                        <h2 className="po black fz16 fw-po-medium mb-8"> No Leads Found</h2>
                        {/* <p className='text-center black-dark-600 fz14 mb-0'>Please add new leads to view</p>                                */}
                    </>
                }
            </div>}

            {isLoading && <div className='d-flex w-full justify-content-center flex-column items-center !max-h-[calc(100vh-245px)] !min-h-[calc(100vh-245px)]'>
                <Oval
                    height={50}
                    width={50}
                    color="#0062FF"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#0062FF"
                    strokeWidth={4}
                    strokeWidthSecondary={4}
                />
            </div>}
            {
                showMarkLeadCompleteModal &&
                <MarkAsCompleteModal
                    show={showMarkLeadCompleteModal}
                    closeModal={() => setShowMarkLeadCompleteModal(false)}
                    selectedLead={selectedLead}
                    getEvents={() => {
                        getEvents();
                        getStats();
                    }}
                    intentStatuses={intentStatuses}
                    userProfile={userProfile}
                    allowedPermissions={allowedPermissions}
                    leadId={selectedLead?.site_visit_uuid}
                    statusId={selectedLead?.status_id}
                    reassindedUserUuid={selectedLead?.pre_sale_user_id}
                />
            }
            {showCustomisedColumnModal &&
                <SVColumnCustomisation
                    dashboardColumns={dashboardColumns}
                    activeColumnTab='Scheduled'
                    show={showCustomisedColumnModal}
                    setShow={setShowCustomisedColumnModal}
                    updateColumnList={updateColumnList}
                    handleChangeSelection={handleChangeSelection}
                    saveCurrentUserColumnPrefrence={saveCurrentUserColumnPrefrence}
                />
            }
            {markDead && <MarkDeadModal
                show={markDead}
                setShow={setMarkDead}
                uuid={selectedLead?.lead_uuid}
                updateData={() => { }}
                selectedOption={{}}
                data={selectedLead}
                statusId={statuses.find(i => i.label === 'Dead Lead').value}
                reassindedUserUuid={selectedLead?.assigned_user_uuid}
                onSuccess={() => {
                    getEvents();
                    getStats();
                }}
            />}
            {showSVModal && <SVScheduleModal
                show={showSVModal}
                setShow={() => {
                    setSelectedLead({})
                    setShowSVModal(false);
                }}
                leadName={selectedLead?.customer_name || selectedLead?.name}
                leadId={selectedLead?.lead_uuid}
                onSuccess={() => {
                    setSelectedLead({})
                    getEvents();
                    getStats();
                }}
                intentOptions={intentStatuses}
                selectedIntent={selectedLead?.intent}
                statusId={statuses.find(i => i.label === 'SV Scheduled').value}
                reassindedUserUuid={selectedLead?.presales_manager}
                isEdit={true}
                svUUID={selectedLead?.site_visit_uuid}
                isNew={false}
            />}
            {/* {showMarkLeadCompleteModal && <SVCompletedModal
                show={showMarkLeadCompleteModal}
                setShow={setShowMarkLeadCompleteModal}
                leadId={selectedLead.lead_uuid}
                onSuccess={()=>{
                    getEvents();                    
                }}
                intentOptions={intentOptions}
                selectedIntent={data.intent}
                statusId={changeDataTemp.value}
                reassindedUserUuid={data?.assigned_user_uuid}
                data={data}
            />} */}
            {/* {
        showFolloupModal && <UpdateFollowupModal
          setShow={setShowFollowupModal}
          show={showFolloupModal}
          leadName={selectedLead?.name}
          leadId={selectedLead.id}
          onSuccess={() => {
            getEvents();
            getStats();
          }}
          intentOptions={intentStatuses}
          selectedIntent={selectedLead?.intent}
          selectedStatus={selectedLead.status}
        />
      } */}
            {showSvCancelled && <SVCancelledModal
                show={showSvCancelled}
                setShow={setShowSvCancelled}
                leadId={selectedLead.lead_uuid}
                onSuccess={() => {
                    getEvents();
                    getStats()
                }}
                intentOptions={intentStatuses}
                selectedIntent={selectedLead.intent}
                statusId={statuses.find(i => i.label === 'SV Cancelled').value}
                reassindedUserUuid={{}}
                data={selectedLead}
            />}
        </div>
        // </div>
    )
}

export default SiteVisitLeads