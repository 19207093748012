import React from 'react';
import { ReactComponent as BlueAlertIcon } from '../../../../assets/new/alert-blue.svg'

const BlueAlert = () => {
    return (
        // <div className='rounded-full bg-[#DCF9EC] h-14 w-14 flex items-center justify-center'>
        <div className='rounded-full bg-[#D6E5FF] h-12 w-12 flex items-center justify-center'>
            <BlueAlertIcon />
        </div>
        // </div>
    )
}

export default BlueAlert