import React from 'react';
import { ReactComponent as SuccessIcon } from '../../../../assets/new/success.svg'
import BasicModal from '../../../../components/Modals/BasicModal';

const SuccessBookingModal = ({ show, closeModal, status, count }) => {
    return (
        <BasicModal show={show} closeModal={closeModal} modalClass='!max-w-[420px] !min-w-[420px]'>
            <div className='!p-8'>
                <div className=''>
                    <SuccessIcon />
                </div>
                <div className='!my-8 '>
                    <h3 className='mb-1 text-lg font-medium !text-[#314155]'>Your {count > 1 ? 'all' : ''} {count} {status} are saved! </h3>
                    <p className='mb-0 text-[#647187] text-sm'>
                        You can edit the individual details of the opportunities from Opportunity Details.
                    </p>
                </div>
                <div className='w-full !gap-3 flex flex-col'>
                    <button
                        type='submit'
                        className='text-base font-medium inter leading-6 !rounded-lg !text-white !bg-primary  h-[44px] text-center inter'
                        onClick={() => {
                            closeModal();
                        }}
                    >Got it</button>
                </div>
            </div>
        </BasicModal>
    )
}

export default SuccessBookingModal;