
export const API_URLS_DASHBOARD = {
    baseurl: process.env.REACT_APP_DEV_API_URL_V2,
    baseurlV1: process.env.REACT_APP_DEV_API_URL,

    company: {
        config: '/companies/config',
        getColumnPreferenceForCustomerDashboard: '/companies/getColumnPreferenceForCustomerDashboard',
        saveColumnPreferenceForcustomerDashbaord: '/companies/saveColumnPreferenceForcustomerDashbaord',
        getColumnPreferenceForOpportunityDashboard: '/companies/getColumnPreferenceForOpportunityDashboard',
        saveColumnPreferenceForOpportunityDashbaord: '/companies/saveColumnPreferenceForOpportunityDashbaord',
        getColumnPreferenceForBookingDashboard: '/companies/getColumnPreferenceForBookingDashboard',
        saveColumnPreferenceForBookingDashbaord: '/companies/saveColumnPreferenceForBookingDashbaord'
    },
    dashboard: {
        customerDashboardStats: '/customer/customerDashboardStats',
        customerDashboard: '/customer/customerDashboard',
        getTimeline: '/customer/getTimeline',
        getCustomerOpportunities: '/customer/getCustomerOpportunities',
        getCustomerDetails: '/customer/getCustomerDetails',
        getCustomerEventCount: '/customer/getCustomerEventCount',
        updateSingleKeyValue: '/customer/updateByKeyValue',
        updateDoubleKeyValue: '/customer/updateByKeyValue2',
        findCustomerWithMobile: '/customer/findCustomerWithMobile',
        deleteCustomer: '/customer/deleteCustomer'
    },
    audience: {
        create: '/audience/create',
        getAudience: '/audience/getAudience',
        syncAudienceFilters: '/audience/syncAudienceFilters',
        getAllAudiences: '/audience/getAllAudiences',
        updateAudience: '/audience/updateAudience'
    },
    opportunity: {
        insertOpportunity: '/Opportunity/insertOpportunity',
        opportunityDashboard: '/Opportunity/opportunityDashboard',
        opportunityStats: '/Opportunity/opportunityStats',
        changeStatus: '/Opportunity/changeStatusV2',
        getTimeline: '/opportunity/getTimeline',
        getOpportunityBasicDetails: '/opportunity/getOpportunityBasicDetails',
        getOpportunityDetails: '/opportunity/getOpportunityDetails',
        getBookingAndEOIUnitsByOpportunityUUID: '/opportunity/getBookingAndEOIUnitsByOpportunityUUID',
        getOpportunityEventCount: '/opportunity/getOpportunityEventCount',
        opportunityUpdateDoubleKeyValue2: '/opportunity/updateByKeyValue2',
        opportunityUpdateSingleKeyValue: '/opportunity/updateByKeyValue',
        reassignSingleOpportunity: '/Opportunity/reassignSingleOpportunity',
        getTaggingConflictByUUID: '/opportunity/getTaggingConflictByUUID',
        assignClosingAndTagging: '/opportunity/assignClosingAndTagging',
        findOpportunityByMobileAndProject: '/opportunity/findOpportunityByMobileAndProject',
        getAllEventOpportunityDashboard: '/opportunity/getAllEventOpportunityDashboard',
        getAllEventOpportunityDashboardStats: '/opportunity/getAllEventOpportunityDashboardStats',
        bulkReassignOpportunity: '/opportunity/bulkReassignOpportunity',
        editBrokerCode: '/opportunity/editBrokerCode',
        insertBulkOpportunities: '/opportunity/insertBulkOpportunities',
        bulkStatusUpdate: '/Opportunity/bulkStatusUpdate',
        deleteOpportunityByUUID: '/opportunity/deleteOpportunityByUUID',
        changeQualifiedStatusByUUID: '/opportunity/changeQualifiedStatusByUUID'
    },
    booking: {
        bookingDashboard: '/bookingDone/bookingDashboard',
        bookingDashboardStats: '/bookingDone/bookingDashboardStats',
        setBookingOtherDetails: '/bookingDone/setBookingOtherDetails',
        setEOIOtherDetails: '/eoiDone/setBookingOtherDetails',
        updateEOIBasicDetails: '/eoiDone/updateBasicDetails',
        updateBookingBasicDetails: '/bookingDone/updateBasicDetails'
    },
    sitevisit: {
        getSvForSVTracker: '/siteVisit/getSvForSVTracker',
        getStatsForSvTracker: '/siteVisit/getStatsForSvTracker',
        getDeadEventsAfterSv: '/siteVisit/getDeadEventsAfterSv',
        rescheduleSiteVisit: '/siteVisit/rescheduleSiteVisit',
        getClosingManagerByProjectId: '/companies/getClosingManagerByProjectId',
        scheduleSiteVisit: '/siteVisit/scheduleSiteVisit',
        markCancelledByUUID: '/siteVisit/markCancelledByUUID',
        markCompletedByUUID: '/siteVisit/markCompletedByUUID'
    },
    followup: {
        rescheduleFollowup: '/followup/rescheduleFollowup',
    },
    note: {
        getAllByLeadUUID: '/notes/getAllByLeadUUID'
    },
    cp: {
        scheduleMeet: '/cpVisits/schedule',
        reschedule: '/cpVisits/reschedule',
        getByUUID: '/cpVisits/getByUUID',
        completeMeet: '/cpVisits/complete',
        deleteByUUID: '/cpVisits/deleteByUUID',
        getAll: '/cpVisits/getAll'
    }

}

// /