import React, { useEffect, useState } from 'react'
import TooltipText from '../../components/Tooltip'
import { createDatewithTime } from '../../helpers/date'
import RenderDashboardTableData from './Components/RenderDashboardTableData';
import { ReactComponent as TableIcon } from '../../assets/new/table.svg';
import DashboardColumnCustomisation from './Components/Modals/DashboardColumnCustomisation';
import { toast } from 'react-toastify';
import { deleteOpportunityByUUID, reassignSingleOpportunity, saveColumnPreferenceForOpportunityDashbaord, saveColumnPreferenceForcustomerDashbaord } from './services/dashboard.service';
import OpportunityColumnCustomisation from './Components/Modals/OpportunityColumnCustomisation';
import { ReactComponent as ThreedotsIcon } from '../../assets/icons/threedots.svg';
import { getKnowlarityIvrCallerIdByCompanyId, knowlarityClickToCall } from '../../services/private/company.service';
import { checkIsSomeItemAvailableInList, mergeUniqueByKey } from '../../helpers/helpers';
import LeadTaggingModal from '../../components/Modals/LeadTaggingModal';
import AdvanceCallModal from '../../components/Modals/AdvanceCallModal';
import ShareLeadAccessModal from '../../components/Modals/ShareLeadAccessModal';
import SortIconDisplay from '../../components/Custom Table/sortIconDisplay';
import ReassignedOpportunity from './Components/Modals/ReassignOpportunityModal';
import { useHistory } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import { getEOIDetails, updateEOIApprovalStatus } from '../../services/private/inventory.service';
import DetailsModal from '../Inventory/Modal/details';
import AreYouSureModal from '../../components/Modals/AreYouSureModal';
import CancelBooking from '../Inventory/Modal/cancelBooking';
import NewEOIModal from '../../components/Modals/NewEOIModal';
import NewBookingModal from '../../components/Modals/NewBookingModal';
import ConfirmDeletion from '../../components/Modals/ConfirmDeletion';
import ChangeQualificationModal from './Components/Modals/ChangeQualificationModal';

const tableHeight = (filterLength) => {
    if (filterLength) {
        return `max-h-[calc(100vh-284px)] min-h-[calc(100vh-284px)]`
    } else {
        return 'max-h-[calc(100vh-240px)] min-h-[calc(100vh-240px)]'
    }
}

const SV_LIST = ['All Site Visits', 'SV Scheduled', 'SV Completed', 'SV Cancelled'];

const Opportunities = ({
    opportunityData,
    getColumns,
    dashboardColumns,
    setDashboardColumns,
    selectedInternalTab,
    allLeadsColumn,
    newLeadsColumn,
    siteVisitColumn,
    notConnectedColumn,
    inProgressColumn,
    svCompleteColumn,
    bookingColumn,
    deadColumn,
    intentStatuses,
    sourceStatuses,
    statuses,
    userProfile,
    allowedPermissions,
    isRolesModule,
    getOpportunities,
    handleUserReassignLead,
    col,
    sort,
    selectedTableValues,
    setSelectedTableValues,
    setSelectedTableData,
    selectedTableData,
    setLeadName,
    showCheck,
    searchText,
    filters,
    isLoading,
    selectedTab,
    opportuniStats
}) => {
    const [showCustomisedColumnModal, setShowCustomisedColumnModal] = useState(false);
    const [tableColumn, setTableColumn] = useState([]);
    const [callerList, setCallerList] = useState([]);
    const [selectedItem, setSelectedItem] = useState({});
    const [showLeadTaggingModal, setShowLeadTaggingModal] = useState(false);
    const [tagTitle, setTagTitle] = useState('');
    const [showShareLeadAccessModal, setShowShareLeadAccessModal] = useState(false);
    const [showAdvanceCallModal, setShowAdvanceCallModal] = useState(false);
    const [ssCustomColumnAvailable, setIsCustomColumnAvailable] = useState(false);
    const [reAssignAvailable, setReAssignAvailable] = useState(false);
    const [deleteAvailable, setDeleteAvailable] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [showReassignModal, setShowReassignModal] = useState(false);
    const [bookingUUID, setBookingUUID] = useState('');
    const [showVerifyToken, setShowVerifyToken] = useState(false);
    const [leadStatus, setLeadStatus] = useState('');
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [showCancelBooking, setShowCancelBooking] = useState(false);
    const [bookingStatus, setBookingStatus] = useState('');
    const [selectedBooking, setSelectedBooking] = useState({});
    const [showEOIDoneModal, setShowEOIDoneModal] = useState(false);
    const [showBookingModal, setShowBookingModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteLeading, setDeleteLeading] = useState(false);
    const [showQualificationModal, setShowQualificationModal] = useState(false);

    const ROLE_NAME = localStorage.getItem('role');
    const history = useHistory();

    useEffect(() => {
        if (!isRolesModule) {
            if (ROLE_NAME === 'ADMIN') {
                setIsCustomColumnAvailable(true);
                setReAssignAvailable(true);
                setDeleteAvailable(true);
            }
            // if (reAssignRole?.includes('re_assign_leads')) {
            //     setReAssignAvailable(true);
            // }
        } else {
            if (allowedPermissions.includes('customise_column_self')) {
                setIsCustomColumnAvailable(true);
            }
            if (allowedPermissions.includes('re_assign_leads_single')) {
                setReAssignAvailable(true);
            }
            if (allowedPermissions.includes('delete_leads')) {
                setDeleteAvailable(true);
            }
        }
    }, [isRolesModule]);

    const onHandleSelectAllLeads = () => {
        if (!selectAll) {
            const selectedVal = opportunityData.map((lead) => lead.id)
            // setSelectedTableValues([...selectedTableValues, ...selectedVal]);
            setSelectedTableValues([...new Set([...selectedTableValues, ...selectedVal])]);
            const mergedLeads = mergeUniqueByKey(selectedTableData, opportunityData, 'id')
            // setSelectedTableData([...selectedTableData, ...opportunityData]);
            setSelectedTableData([...mergedLeads]);
        } else {
            if (selectedTableValues?.length > opportunityData?.length) {
                const leadIds = opportunityData.map(lead => lead.id);
                const filtered = selectedTableValues.filter(item => !leadIds.includes(item))
                const filteredData = selectedTableData.filter(item => !leadIds.includes(item.id))
                setSelectedTableValues(filtered)
                setSelectedTableData(filteredData)
            } else {

                setSelectedTableValues([])
                setSelectedTableData([]);
            }
        }
        setSelectAll(!selectAll)
    }

    useEffect(() => {
        if (selectedTableValues?.length >= opportunityData?.length) {
            const allSelected = opportunityData.every(lead => selectedTableValues.includes(lead.id))
            setSelectAll(allSelected && selectedTableValues?.length > 0)
        }
    }, [selectedTableValues, selectedTableData, opportunityData]);

    const handelChange = (e, data) => {
        if (e.target.checked) {
            setLeadName(data.name)
            setSelectedTableValues([...selectedTableValues, data.id])
            setSelectedTableData([...selectedTableData, data])
        }
        else {
            const filteredValues = selectedTableValues.filter((value) => value != data.id);
            const filteredData = selectedTableData.filter((value) => value.id != data.id);
            setSelectedTableValues(filteredValues);
            setSelectedTableData(filteredData);
            setLeadName('')
        }
    }
    /**
 * Updating re-arranged list items
 */
    const updateColumnList = (tab, list) => {
        let columnClone = [...dashboardColumns];
        const index = columnClone.findIndex(item => item.tab === tab)
        const itemColumn = columnClone[index].columns
        columnClone[index].columns = { ...itemColumn, selected: list }
        setDashboardColumns([...columnClone]);
    };

    const handleChangeSelection = (tab, selected, notSelected) => {
        let columnClone = [...dashboardColumns];
        const index = columnClone.findIndex(item => item.tab === tab)
        columnClone[index].columns = { selected, notSelected }
        setDashboardColumns([...columnClone]);
    };

    const saveCurrentUserColumnPrefrence = () => {
        const preference = dashboardColumns.map(items => {
            items.columns.selected.forEach((item, index) => {
                item.placement = index + 1;
            })
            return {
                tab: items.tab,
                columns: [...items.columns.selected, ...items.columns.notSelected]
            }
        })
        saveColumnPreferenceForOpportunityDashbaord({ preference }).then(res => {
            if (res.data.status === 200) {
                toast.success(res.data.message);
                getColumns();
                setShowCustomisedColumnModal(false);
                // getLeadsData();
            }
        }).catch(err => {
            console.log({ err })
        })
    }

    const getCallerIds = async () => {
        const res = await getKnowlarityIvrCallerIdByCompanyId();
        if (res.data.status === 200) {
            const formatedData = res.data.data.map(item => ({ label: item.caller_id, value: item.uuid }))
            setCallerList(formatedData)
        }
    }

    useEffect(() => {
        getCallerIds()
    }, []);

    useEffect(() => {
        const columns = dashboardColumns.find(item => {
            if (SV_LIST.includes(selectedInternalTab?.name) && item.tab === 'Site Visits') {
                return item;
            }
            return item.tab === selectedInternalTab?.name
        })
        setTableColumn(columns?.columns?.selected)
    }, [dashboardColumns, selectedInternalTab]);

    const handleAdvanceClickToCall = async (useAlternate, callerId) => {
        const payload = {
            use_alternate: useAlternate,
            caller_id: callerId
        }

        const res = await knowlarityClickToCall(selectedItem.id, payload);
        if (res.data.status === 200) {
            toast.success(res.data.message)
            setSelectedItem({})
            setShowAdvanceCallModal(false)
        }
    }

    const handleClickToCall = async (lead) => {
        const res = await knowlarityClickToCall((lead?.id || lead?.uuid), {});
        if (res.data.status === 200) {
            toast.success(res.data.message)
        }
    }

    const handleReassignModalSubmit = (presaleManager, closingManager, sourcingManager) => {
        const payload = {
            preSaleUserId: presaleManager?.value || null,
            sourcingUserId: sourcingManager?.value || null,
            closingUserId: closingManager?.value || null
        }
        reassignSingleOpportunity(selectedItem?.id, payload).then(res => {
            if (res.status === 200) {
                toast.success("Lead Reassigned");
                setShowReassignModal(false);
                getOpportunities();
                // if (ROLE_NAME === 'ADMIN') {
                //     window.location.reload();
                // } else {
                //     history.push('/')
                // }
                // handle();                
            }
        }).catch(e => {
            toast.error(e.response.data.message)
        })
    }

    const handleOpenVerifyPage = (data) => {
        setLeadStatus('eoi')
        setSelectedItem(data);
        // setShowDetailsModal(true)
        setShowVerifyToken(true)
    }

    const getEOILeadDetails = async (uuid) => {
        setBookingUUID(uuid)
        const res = await getEOIDetails(uuid);
        setDataLoading(false);
        if (res.data.status === 200) {
            if (res.data.data?.customer?.uuid) {
                setSelectedBooking(res.data.data);
                setShowDetailsModal(true);
                setShowVerifyToken(false);
            }
        }
    }

    const handleChangeBookingStatus = async (payload) => {
        const res = await updateEOIApprovalStatus(bookingUUID, payload);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getOpportunities();
            setShowDetailsModal(false)
        }
    }

    const handleUpdateBooking = async (payload) => {
        const res = await updateEOIApprovalStatus(bookingUUID, payload);
        if (res.data.status === 200) {
            getOpportunities();
            setShowCancelBooking(false);
            setSelectedBooking({})
        }
    }

    const handleDeleteOpportunity = (uuid) => {
        setDeleteLeading(true);
        deleteOpportunityByUUID(uuid).then(res => {
            if (res.status === 200) {
                toast.success(res.data.message);
                getOpportunities()
                opportuniStats();
                setDeleteLeading(false);
                setShowDeleteModal(false);
            }
        }).catch(e => {
            toast.error(e.response.data.message)
            setDeleteLeading(false);
        })
    }

    return (
        <>
            <div className={`relative ${isLoading ? '' : 'overflow-auto'} !w-100 !border !rounded-lg ${tableHeight(filters?.length)} !h-100`}>
                <table className="min-w-full text-sm text-left ">
                    <thead className="text-sm  text-[#344054]">
                        <tr className="border-b-2">
                            <th scope="col" className=" !sticky left-0 !min-w-[61px] !max-w-[65px] !px-3 !py-2 text-sm !text-black700 !font-normal inter">
                                {showCheck ?
                                    <>
                                        <input
                                            type="checkbox"
                                            onChange={onHandleSelectAllLeads}
                                            checked={selectAll}
                                            id='selectAll_leads'
                                            className='select-all-check resasign-input'
                                            title='Select all'

                                        />

                                    </>
                                    :
                                    'S.No.'
                                }
                            </th>
                            {
                                tableColumn?.length > 0 && tableColumn?.map((item, index) => {
                                    return (
                                        <th
                                            key={`${item.label}_${index}`}
                                            className={`${item.label === 'Name' ? '!sticky !left-14' : ''} !px-3 !py-2`}
                                            scope="col"
                                        >
                                            <span className='flex items-center gap-1 text-sm !text-black700 !font-normal !inter w-max' >
                                                {item.label}
                                                <SortIconDisplay sortCol={col} sort={sort} colName={item.label} />
                                            </span>
                                        </th>
                                    )
                                })
                            }
                            <th scope="col" class="!pl-3 !py-2 !sticky !right-0">
                                <TooltipText id="button-tooltip" title='Customise Columns' className='leadmanager-tooltip'>
                                    <button onClick={() => setShowCustomisedColumnModal(true)}>
                                        <TableIcon />
                                    </button>
                                </TooltipText>
                            </th>
                        </tr>
                    </thead>
                    <tbody >
                        {
                            !isLoading && opportunityData?.length > 0 && opportunityData.map((lead, index) => (
                                <tr class=" border-b !px-3">
                                    <td class="!pl-3 !py-2 text-[#44444F] !w-[61px] !sticky left-0 bg-white">
                                        {showCheck ?
                                            <input
                                                type="checkbox"
                                                onChange={(e) => handelChange(e, lead)}
                                                checked={selectedTableValues.includes(lead.id)}
                                            /> :
                                            index + 1
                                        }
                                    </td>
                                    {
                                        tableColumn?.length > 0 && tableColumn?.map((item, index) => {
                                            return (
                                                <RenderDashboardTableData
                                                    tHead={item}
                                                    tData={lead}
                                                    isRedirectOnNameClick={(userProfile?.allowed_modules?.includes('lead_sharing') && lead?.access === 'view') ? false : true}
                                                    intentStatuses={intentStatuses}
                                                    sourceStatuses={sourceStatuses}
                                                    statuses={statuses}
                                                    redirectURL={'presale/opportunity-details'}
                                                    redirectBactTo={'dashboard'}
                                                    tab={selectedTab?.name}
                                                    subTab={selectedInternalTab?.name}
                                                    handleClickToCall={handleClickToCall}
                                                    allowedPermissions={allowedPermissions}
                                                    handleOpenVerifyPage={handleOpenVerifyPage}
                                                    dataLoading={dataLoading}
                                                    setDataLoading={setDataLoading}
                                                    getEOILeadDetails={getEOILeadDetails}
                                                    setLeadStatus={setLeadStatus}
                                                    leadStatus={leadStatus}
                                                    setSelectedItem={setSelectedItem}
                                                    selectedItem={selectedItem}
                                                    setBookingStatus={setBookingStatus}
                                                    bookingStatus={bookingStatus}
                                                    setShowShareLeadAccessModal={setShowShareLeadAccessModal}
                                                    onSuccess={() => {
                                                        getOpportunities();
                                                        opportuniStats();
                                                    }}
                                                />
                                            )
                                        })}
                                    <td className='dropdown view-more bg-white !sticky right-0'>
                                        <button className="!w-[50px] hover:bg-black100 flex items-center justify-center !h-7 rounded-lg" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <ThreedotsIcon />
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-right p-2 !w-[220px]" aria-labelledby="dropdownMenuButton">

                                            {reAssignAvailable &&
                                                <a className="dropdown-item"
                                                    onClick={() => {
                                                        setSelectedItem(lead);
                                                        setShowReassignModal(true)
                                                    }}
                                                >
                                                    Re-assign Lead
                                                </a>}
                                            <a className="dropdown-item"
                                                onClick={() => {
                                                    setSelectedItem(lead);
                                                    setShowQualificationModal(true);
                                                }}
                                            >
                                                Change Qualification Status
                                            </a>
                                            <a className="dropdown-item"
                                                onClick={() => {
                                                    window.open(`/presale/opportunity-details/${lead.id || lead.uuid}`)
                                                }}
                                            >
                                                View Timeline
                                            </a>

                                            <a className="dropdown-item"
                                                onClick={() => {
                                                    window.open(`/presale/opportunity-details/${lead.id || lead.uuid}`)
                                                }}
                                            >
                                                View Details
                                            </a>
                                            {userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && allowedPermissions?.includes('edit_broker_tag') && <a className="dropdown-item" onClick={() => {
                                                setSelectedItem(lead)
                                                setShowLeadTaggingModal(true);
                                            }}>
                                                Edit Lead Tagging
                                            </a>}
                                            {userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && allowedPermissions?.includes('edit_broker_tag') && <a className="dropdown-item" onClick={() => {
                                                setSelectedItem(lead)
                                                setShowLeadTaggingModal(true);
                                            }}>
                                                Edit CP User
                                            </a>}
                                            {userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && allowedPermissions?.includes('edit_broker_tag') && <a className="dropdown-item" onClick={() => {
                                                setSelectedItem(lead)
                                                setShowLeadTaggingModal(true);
                                                setTagTitle('Remove Lead Title')
                                            }}>
                                                Remove Lead Tagging
                                            </a>}

                                            {userProfile?.allowed_modules?.includes('lead_sharing') &&
                                                checkIsSomeItemAvailableInList(allowedPermissions, ['can_share_leads']) &&
                                                <a className="dropdown-item" onClick={() => {
                                                    setSelectedItem(lead)
                                                    setShowShareLeadAccessModal(true)
                                                }}>

                                                    Share Lead Access
                                                </a>}
                                            {callerList.length > 0 &&
                                                (ROLE_NAME === 'Super Admin' ||
                                                    (userProfile?.allowed_modules?.includes('ivr_call') &&
                                                        allowedPermissions.includes('use_ivr_outbound_call'))) && <a
                                                            className="dropdown-item" onClick={() => handleClickToCall(lead)}
                                                        >
                                                    Quick Call
                                                </a>}
                                            {callerList.length > 0 &&
                                                (ROLE_NAME === 'Super Admin' ||
                                                    (userProfile?.allowed_modules?.includes('ivr_call') &&
                                                        allowedPermissions.includes('use_ivr_outbound_call'))) && <a
                                                            className="dropdown-item" onClick={() => {
                                                                setSelectedItem(lead);
                                                                setShowAdvanceCallModal(true);
                                                            }}>
                                                    Advanced Call
                                                </a>}
                                            <a className="dropdown-item del-menu-itme" onClick={() => {
                                                setSelectedItem(lead);
                                                setShowDeleteModal(true);
                                            }}>Delete Opportunity</a>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                {isLoading && <div className='d-flex w-full justify-content-center flex-column items-center !max-h-[calc(100vh-240px)] !min-h-[calc(100vh-240px)]'>
                    <Oval
                        height={50}
                        width={50}
                        color="#0062FF"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#0062FF"
                        strokeWidth={4}
                        strokeWidthSecondary={4}
                    />
                </div>}
                {!isLoading && opportunityData?.length === 0 && <div className='d-flex justify-content-center flex-column text-center !max-h-[calc(100vh-240px)] !min-h-[calc(100vh-240px)]'>
                    <div className='flex items-center flex-col'>

                        <figure><svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none"><circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF"></circle><path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF"></path><circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974"></circle><circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE"></circle><circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC"></circle><path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974"></path><path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE"></path></svg></figure>
                        {(filters?.length > 0 || searchText) &&
                            <>
                                <h2 className="po black fz16 fw-po-medium mb-8">Sorry, we couldn’t find any results</h2>
                                <p className='text-center black-dark-600 fz14 mb-0'> Please try again</p>
                            </>
                        }
                        {
                            !searchText && !filters?.length &&
                            <>
                                <h2 className="po black fz16 fw-po-medium mb-8"> No Opportunity Found</h2>
                            </>
                        }
                    </div>
                </div>}
            </div>
            {showCustomisedColumnModal &&
                <OpportunityColumnCustomisation
                    dashboardColumns={dashboardColumns}
                    activeColumnTab={selectedInternalTab.name}
                    show={showCustomisedColumnModal}
                    setShow={setShowCustomisedColumnModal}
                    updateColumnList={updateColumnList}
                    handleChangeSelection={handleChangeSelection}
                    saveCurrentUserColumnPrefrence={saveCurrentUserColumnPrefrence}
                />
            }
            {showShareLeadAccessModal &&
                <ShareLeadAccessModal
                    show={showShareLeadAccessModal}
                    selectedItem={selectedItem}
                    closeModal={() => {
                        setShowShareLeadAccessModal(false)
                        setSelectedItem({})
                    }}
                    onSuccess={() => {
                        getOpportunities();
                        // opportuniStats();
                    }}
                    allowedPermissions={allowedPermissions}
                />
            }
            {
                showAdvanceCallModal &&
                <AdvanceCallModal
                    show={showAdvanceCallModal}
                    closeModal={() => {
                        setSelectedItem({})
                        setShowAdvanceCallModal(false)
                    }}
                    handleSubmit={handleAdvanceClickToCall}
                    callerList={callerList}
                />
            }

            {
                showLeadTaggingModal &&
                <LeadTaggingModal
                    show={showLeadTaggingModal}
                    uuid={selectedItem.id}
                    selectedItem={selectedItem}
                    updateData={getOpportunities}
                    title={tagTitle}
                    closeModal={() => {
                        setSelectedItem({})
                        setShowLeadTaggingModal(false)
                    }}
                />
            }

            {showReassignModal && <ReassignedOpportunity
                setShow={setShowReassignModal}
                show={showReassignModal}
                selectedLeadName={selectedItem?.customer_name || selectedItem?.name}
                handleSubmit={handleReassignModalSubmit}
            />}
            {showCancelBooking &&
                <CancelBooking
                    show={showCancelBooking}
                    closeModal={() => {
                        setShowCancelBooking(false);
                    }}
                    handleSubmitSuccess={() => {
                        getOpportunities()
                        setSelectedItem({});
                    }}
                    data={selectedItem}
                    ModalType={'Token'}
                />
            }
            {showVerifyToken && <AreYouSureModal
                show={showVerifyToken}
                title='Verify Token before booking'
                subText={'Kindly verify the received token amount before marking the booking status for this lead.'}
                closeModal={() => {
                    setShowVerifyToken(false)
                }}
                confirmClose={() => {
                    setBookingStatus(selectedItem.approval_status);
                    getEOILeadDetails(selectedItem.eoi_uuid);
                }}
                buttonTitle={'Verify Token'}
            />}
            {showDetailsModal && <DetailsModal
                show={showDetailsModal}
                unit={{}}
                selectedBooking={selectedBooking}
                closeModal={() => setShowDetailsModal(false)}
                modalOpenFrom={'table'}
                bookingStatus={bookingStatus}
                ModalType={selectedInternalTab?.name === 'Token' ? 'Token' : 'Booking'}
                handleChangeApprovalStatus={handleChangeBookingStatus}
                handleSubmit={handleUpdateBooking}
                updateData={() => {
                    getOpportunities();
                }}
                editBtn={selectedInternalTab?.name === 'Token' ? 'Edit Token' : 'Edit Booking'}
                handleEditBtn={() => {
                    if (selectedInternalTab?.name === 'Token') {
                        setShowEOIDoneModal(true)
                    } else {
                        setShowBookingModal(true)
                    }
                    setShowDetailsModal(false)
                }}
            // operation={allowedPermissions.includes('verify_bookings_eoi') ? '' : 'view only'}
            />}
            {
                showEOIDoneModal &&
                <NewEOIModal
                    show={showEOIDoneModal}
                    closeModal={() => {
                        setShowEOIDoneModal(false);
                        setSelectedItem({});
                    }}
                    lead={selectedItem}
                    data={selectedItem}
                    onSuccess={() => {
                        setSelectedItem({});
                        setShowEOIDoneModal(false);
                        getOpportunities();
                        opportuniStats();
                    }}
                    isEdit={true}
                />
            }
            {
                showBookingModal &&
                <NewBookingModal
                    show={showBookingModal}
                    closeModal={() => setShowBookingModal(false)}
                    lead={selectedItem}
                    data={selectedItem}
                    onSuccess={() => {
                        setSelectedItem({});
                        setShowBookingModal(false);
                        getOpportunities();
                        opportuniStats();
                    }}
                    isEdit={true}
                />
            }
            {showDeleteModal && <ConfirmDeletion
                show={showDeleteModal}
                setShow={setShowDeleteModal}
                title={`Delete ${selectedItem?.name}?`}
                deleteText={'opportunity'}
                deleteHandler={() => handleDeleteOpportunity(selectedItem?.id)}
                deleteLeading={deleteLeading}
            />}

            {
                showQualificationModal && <ChangeQualificationModal
                    show={showQualificationModal}
                    closeModal={() => {
                        setSelectedItem({});
                        setShowQualificationModal(false)
                    }}
                    selectedLead={selectedItem}
                    getOpportunities={getOpportunities}
                />
            }
        </>
    )
}

export default Opportunities