import React, { useContext, useEffect, useState } from "react";
import { useMemo } from "react";
// import { getUsersForSettings } from "../../services/private/company.service";

import Consumer from '../../../../helpers/context';
import SliderModal from "../../../../components/Modals/SliderModal";
import InputSelect from "../../../../components/InputGroup/InputSelect";
import { getUsersForSettings } from "../../../../services/private/company.service";

export default function ReassignedOpportunity({
    setShow,
    show,
    handleSubmit,
    selectedLeadName,
    selectedLead
}) {

    const [allUser, setAllUsers] = useState([]);
    const [presaleManager, setPresaleManager] = useState();
    const [closingManager, setClosingManager] = useState();
    const [sourcingManager, setSourcingManager] = useState();
    const [error, setError] = useState();
    const [remarks, setRemarks] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { userProfile } = useContext(Consumer);

    useEffect(() => {
        if (selectedLead && Object.keys(selectedLead).length > 0) {
            setPresaleManager({
                label: selectedLead?.presales_manager,
                value: selectedLead?.presales_manager_id
            });
            setClosingManager({
                label: selectedLead?.closing_manager,
                value: selectedLead?.closing_manager_id
            });
            setSourcingManager({
                label: selectedLead?.sourcing_manager,
                value: selectedLead?.sourcing_manager_id
            });
        }
    }, [selectedLead]);

    const getAllUsers = () => {
        getUsersForSettings().then(res => {
            if (res.status === 200) {
                const userData = res.data.data.map((data) => {
                    return {
                        label: data.name + " - " + data.email,
                        value: data.id
                    }
                })
                setAllUsers(userData)
            }
        })
    }

    const handleSubmitUser = () => {
        // if (!presaleManager) {
        //     setError(true);
        //     return;
        // } else {
        //     setError(false)
        // }
        handleSubmit(presaleManager, closingManager, sourcingManager);
    }

    useEffect(() => {
        getAllUsers()
    }, []);

    return (
        <SliderModal
            setShow={setShow}
            show={show}
            title="Reassign Lead"
            body={<>
                <div className="h-full overflow-auto !pl-5 !pr-5">

                    <div className="form-group">
                        <label className=''>Lead Name</label>
                        <div className='text-capitalize fz16 fw-po-medium '>
                            {selectedLeadName ? selectedLeadName : '-'}
                        </div>
                    </div>

                    <InputSelect
                        index={4}
                        name="user"
                        menuPlacement="bottom"
                        value={presaleManager}
                        options={allUser}
                        onChange={setPresaleManager}
                        placeholder="Select User"
                        label="Pre-sale"
                        error={error && !presaleManager ? 'Please Select Pre-sale' : ''}
                        isMulti={false}
                        required={true}
                    />
                    <InputSelect
                        menuPlacement="bottom"
                        value={closingManager}
                        options={allUser}
                        onChange={setClosingManager}
                        placeholder="Select User"
                        label="Closing"
                        // error={error && !closingManager ? 'Please Select Closing Manager' : ''}
                        isMulti={false}
                    />
                    <InputSelect
                        menuPlacement="bottom"
                        value={sourcingManager}
                        options={allUser}
                        onChange={setSourcingManager}
                        placeholder="Select User"
                        label="Sourcing"
                        // error={error && !sourcingManager ? 'Please Select Sourcing Manager' : ''}
                        isMulti={false}
                    />

                </div>
                <div className="form-inline justify-content-between px-20 filter-ok-cancel">
                    <button
                        className="cancel outline-btn"
                        onClick={() => setShow(false)}
                    >
                        Cancel
                    </button>
                    <button
                        className="pr-btn"
                        onClick={handleSubmitUser}
                        disabled={!presaleManager?.value && !closingManager?.value && !sourcingManager?.value}
                    >
                        Reassign
                        {/* {count > 1 && <span className="ml-1 fz14">{`(${count})`}</span>} */}
                    </button>

                </div>
            </>}
        />
    )
}