import React, { useContext, useEffect, useState } from 'react'
import { getAllBrokerCompanyUsers, getAllBrokersSV } from '../../../services/private/borker.service';
import { useParams, useLocation, Link } from 'react-router-dom';
import { ReactComponent as PagebackIcon } from '../../../assets/icons/Pageback.svg';
import useDocumentTitle from '../../../custom hooks/useDocumentTitile';
import Tabs from '../../../components/HOC/Tabs';
import CompanyUsers from './CompanyUsers';
import { SVEvents } from '../../PropacityPlus/SVEvents';
import AddBrokerCompanyUser from './Modal/AddBrokerCompanyUser';
import { ReactComponent as PluswhiteIcon } from '../../../assets/icons/Pluswhite.svg';
import { getColumnPreferenceForOpportunityDashboard, opportunityDashboard, opportunityStats } from '../../New Dashboard/services/dashboard.service';
import Consumer from '../../../helpers/context';
import Opportunities from '../../New Dashboard/Opportunities';
import InternalTabs from '../../New Dashboard/Tabs/InternalTabs';
import CPOpportunities from './CPOpportunities';

const tabList = [
    { name: 'Users' },
    { name: 'Opportunities' }
]
// const svTabList = [
//     {
//         name: 'Scheduled',
//         value: 'scheduled'
//     },
//     {
//         name: 'Completed',
//         value: 'completed'
//     },
//     {
//         name: 'Cancelled',
//         value: 'cancelled'
//     }
// ]

const SV_LIST = ['SV Scheduled', 'SV Completed', 'SV Cancelled']
const svTabList = [
    {
        name: 'All',
        value: 'all_pre_sale',
        count: 0
    },
    {
        name: 'SV Scheduled',
        value: 'scheduled',
        count: 0,
    },
    {
        name: 'SV Completed',
        value: 'completed',
        count: 0
    },
    {
        name: 'SV Cancelled',
        value: 'cancelled',
        count: 0
    },
    {
        name: 'Token',
        value: 'eoi_only',
        count: 0
    },
    {
        name: 'Booking',
        value: 'booking_only',
        count: 0
    },
    {
        name: 'Dead',
        value: 'dead_leads',
        count: 0
    }
]

const BorkerCompanyUsers = () => {
    const [users, setUsers] = useState([]);
    const [name, setName] = useState('');
    const [selectedTab, setSelectedTab] = useState({
        name: 'Users'
    })
    const [tabRowCount, setTabRowCount] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [showingResults, setShowingResults] = useState(100);
    const [currentPage, setCurrentPage] = useState(1)
    const [leadsCount, setLeadsCount] = useState('');
    const [maxPage, setMaxPage] = useState(1);
    const [events, setEvents] = useState([]);
    const [internalTabRowCount, setInternalTabRowCount] = useState({});
    // const [selectedInternalTab, setSelectedInternalTab] = useState({
    //     name: 'Scheduled',
    //     value: 'scheduled'
    // })
    const [searchText, setSearchText] = useState('');
    const [id, setId] = useState('');
    const [showAddBrokerCompanyUser, setShowAddBrokerCompanyUser] = useState(false)
    const [selectedUser, setSelectedUser] = useState({});
    const [loading, setLoading] = useState(false);
    const [opportunityData, setOpportunityData] = useState([]);
    const [opportunityDashboardColumns, setOpportunityDashboardColumns] = useState([]);
    const [intentStatuses, setIntentStatuses] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [sourceStatuses, setSourceStatuses] = useState([]);
    const [selectedInternalTab, setSelectedInternalTab] = useState({
        name: 'All',
        value: 'all_pre_sale',
        count: 0
    })

    const { uuid } = useParams();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    let { companyConfig, allowedPermissions, isRolesModule, userProfile } = useContext(Consumer);

    useDocumentTitle(name);

    useEffect(() => {
        if (Object.keys(companyConfig).length > 0) {
            setIntentStatuses(companyConfig.Intent);
            setStatuses(companyConfig.Status);
            setSourceStatuses(companyConfig?.Source);
            // setProject(companyConfig.Project);
        }
    }, [companyConfig]);

    useEffect(() => {
        if (searchParams.get('company')) {
            setName(searchParams.get('company'))
        }
        if (searchParams.get('id')) {
            setId(searchParams.get('id'))
        }
        if (searchParams.get('tab')) {
            setSelectedTab({ name: searchParams.get('tab') })
        }
        if (searchParams.get('internalTab')) {
            const tab = svTabList.find(i => i.name === searchParams.get('internalTab'))
            setSelectedInternalTab(tab);
        }

    }, [searchParams]);

    const getEvents = async () => {
        setIsLoading(true)
        const payload = {
            "pageNumber": currentPage,
            "perPage": showingResults,
            "searchText": searchText,
            "tab": selectedInternalTab?.value,
            filters: {
                cpFirm: [
                    {
                        id: id,
                        uuid: uuid
                    }
                ]
            }
        }
        try {
            const res = await getAllBrokersSV('', payload);
            if (res.data.status === 200) {
                setIsLoading(false)
                setEvents(res.data.data?.entries);
                const totalPages = Math.ceil(
                    Number(res.data.data?.stats[selectedInternalTab.value]) / showingResults
                );
                setMaxPage(totalPages);
                setLeadsCount(res.data.data?.stats[selectedInternalTab.value]);
                let stats = {}
                svTabList.forEach(i => {
                    stats = { ...stats, [i.name]: `${res.data.data?.stats[i.value]}` }
                })
                setInternalTabRowCount(stats)
            }
        } catch (err) {
            if (err.response.data.message === 'No site visits found' || err.response.data.status === 404) {
                setEvents([]);
                setIsLoading(false)
                // setTabRowCount({})
            }
        }
    }

    const getOpportunityColumns = async () => {
        const res = await getColumnPreferenceForOpportunityDashboard();
        if (res.data.status === 200) {
            let dashboardColumns = [];
            res.data.data.forEach(items => {
                let columnsList = [];
                let selectedColumn = [];

                items.columns.forEach(column => {
                    if (column.is_selected) {
                        selectedColumn.push(column);
                    }

                    if (!column.is_selected) {
                        columnsList.push(column);
                    }
                })

                // adding column according to tab with sorting
                dashboardColumns.push({
                    tab: items.tab,
                    columns: {
                        selected: selectedColumn.sort((a, b) => a.placement - b.placement),
                        notSelected: columnsList
                    }
                })

                // data for table columns

            })
            setOpportunityDashboardColumns(dashboardColumns)
        }
    }

    const getOpportunities = async () => {
        // const combineFilters = [...filters, ...leadTopFilters]
        // const filterResult = combineFilters.reduce((acc, obj) => {
        //     // const value = Object.values(obj)[0]; // Extract the nested object
        //     return { ...acc, ...obj }; // Merge it into the accumulator
        // }, {})
        const payload = {
            limit: showingResults,
            pageNumber: 1,
            filters: {
                broker_company_id: [
                    {
                        id: id,
                        uuid: uuid
                    }
                ]
            },
            sort: '',
            col: '',
            text: '',
            filterType: SV_LIST.includes(selectedInternalTab?.name) ? 'site_visits' : selectedInternalTab?.value,
            site_visits_filter: SV_LIST.includes(selectedInternalTab?.name) ? selectedInternalTab?.value : '',
            followup_filter: selectedInternalTab?.name === 'SV Cancelled' ? 'cancelled' : ''
        }
        try {
            setIsLoading(true)
            const res = await opportunityDashboard(payload);
            setIsLoading(false)
            if (res.data.status === 200) {

                if (selectedInternalTab.name === 'All') {
                    const ids = res.data.data.allPreSale.map(l => l.id)
                    localStorage.setItem('leadIds', JSON.stringify(ids))
                    setOpportunityData(res.data.data?.allPreSale)
                } else if (selectedInternalTab?.name === 'Dead') { // Dead Lead
                    const ids = res.data.data.deadLeads.map(l => l.id)
                    localStorage.setItem('leadIds', JSON.stringify(ids))
                    setOpportunityData(res.data.data?.deadLeads)
                } else if (selectedInternalTab?.name === 'Token') { // Token Leads
                    const ids = res.data.data.eoiOnly.map(l => l.id)
                    localStorage.setItem('leadIds', JSON.stringify(ids))
                    setOpportunityData(res.data.data?.eoiOnly)
                } else if (selectedInternalTab?.name === 'Booking') { // Booking Leads
                    const ids = res.data.data.bookingOnly.map(l => l.id)
                    localStorage.setItem('leadIds', JSON.stringify(ids))
                    setOpportunityData(res.data.data?.bookingOnly)
                } else if (selectedInternalTab?.name === 'SV Scheduled ') {      // SV Scheduled            
                    const ids = res.data.data.sitevisits.map(l => l.id)
                    localStorage.setItem('leadIds', JSON.stringify(ids))
                    setOpportunityData(res.data.data?.sitevisits)
                } else if (selectedInternalTab?.name === 'SV Completed') {      // SV Completed           
                    const ids = res.data.data.sitevisits.map(l => l.id)
                    localStorage.setItem('leadIds', JSON.stringify(ids))
                    setOpportunityData(res.data.data?.sitevisits)
                } else if (selectedInternalTab?.name === 'SV Cancelled ') {      // SV Cancelled            
                    const ids = res.data.data.sitevisits.map(l => l.id)
                    localStorage.setItem('leadIds', JSON.stringify(ids))
                    setOpportunityData(res.data.data?.sitevisits)
                } else if (selectedInternalTab?.name === 'SV Scheduled Revisit') {
                    const ids = res.data.data.siteVisitsScheduled.map(l => l.id)
                    localStorage.setItem('leadIds', JSON.stringify(ids))
                    setOpportunityData(res.data.data?.siteVisitsScheduled)
                }
            }
        } catch (err) {
            setIsLoading(false)
        }
    }

    const opportuniStats = async () => {
        // const combineFilters = [...filters, ...leadTopFilters]
        // const filterResult = combineFilters.reduce((acc, obj) => {
        //     // const value = Object.values(obj)[0]; // Extract the nested object
        //     return { ...acc, ...obj }; // Merge it into the accumulator
        // }, {})
        const payload = {
            limit: showingResults,
            pageNumber: 1,
            filters: {
                broker_company_id: [
                    {
                        id: id,
                        uuid: uuid
                    }
                ]
            },
            sort: '',
            col: '',
            text: '',
            filterType: SV_LIST.includes(selectedInternalTab?.name) ? 'site_visits' : selectedInternalTab?.value,
            site_visits_filter: SV_LIST.includes(selectedInternalTab?.name) ? selectedInternalTab?.value : '',
        }
        const res = await opportunityStats(payload)
        if (res.data.status === 200) {
            const stats = res.data.data;
            svTabList.forEach(item => {
                if (item.name === 'All') {
                    const totalPages = Math.ceil(
                        Number(stats?.allLeadsCount) / showingResults
                    );
                    setMaxPage(totalPages);
                    item.count = stats.allLeadsCount
                    localStorage.setItem('leadCount', res.data.data?.allLeadsCount)
                    if (selectedInternalTab?.value === item?.value) {
                        setLeadsCount(stats?.allLeadsCount);
                    }
                }
                if (item.name === 'Token') {
                    const totalPages = Math.ceil(
                        Number(stats?.eoiOnlyCount) / showingResults
                    );
                    setMaxPage(totalPages);

                    item.count = stats.eoiOnlyCount
                    localStorage.setItem('leadCount', res.data.data?.eoiOnlyCount)
                    if (selectedInternalTab?.value === item?.value) {
                        setLeadsCount(stats?.eoiOnlyCount);
                    }
                }
                if (item.name === 'Booking') {
                    const totalPages = Math.ceil(
                        Number(stats?.bookingsOnlyCount) / showingResults
                    );
                    setMaxPage(totalPages);

                    item.count = stats.bookingsOnlyCount
                    localStorage.setItem('leadCount', res.data.data?.bookingsOnlyCount)
                    if (selectedInternalTab?.value === item?.value) {
                        setLeadsCount(stats?.bookingsOnlyCount);
                    }
                }
                if (item.name === 'Dead') {
                    const totalPages = Math.ceil(
                        Number(stats?.deadLeadsCount) / showingResults
                    );
                    setMaxPage(totalPages);

                    item.count = stats.deadLeadsCount
                    localStorage.setItem('leadCount', res.data.data?.deadLeadsCount)
                    if (selectedInternalTab?.value === item?.value) {
                        setLeadsCount(stats?.deadLeadsCount);
                    }
                }
                if (item.name === 'SV Scheduled') {
                    const totalPages = Math.ceil(
                        Number(stats?.siteVisitScheduledCount) / showingResults
                    );
                    setMaxPage(totalPages);

                    item.count = stats.siteVisitScheduledCount
                    localStorage.setItem('leadCount', res.data.data?.siteVisitScheduledCount)
                    if (selectedInternalTab?.value === item?.value) {
                        setLeadsCount(stats?.siteVisitScheduledCount);
                    }
                }
                if (item.name === 'SV Completed') {
                    const totalPages = Math.ceil(
                        Number(stats?.siteVisitCompletedCount) / showingResults
                    );
                    setMaxPage(totalPages);

                    item.count = stats.siteVisitCompletedCount
                    localStorage.setItem('leadCount', res.data.data?.siteVisitCompletedCount)
                    if (selectedInternalTab?.value === item?.value) {
                        setLeadsCount(stats?.siteVisitCompletedCount);
                    }
                }
                if (item.name === 'SV Cancelled') {
                    const totalPages = Math.ceil(
                        Number(stats?.siteVisitCancelledCount) / showingResults
                    );
                    setMaxPage(totalPages);

                    item.count = stats.siteVisitCancelledCount || 0
                    localStorage.setItem('leadCount', res.data.data?.siteVisitCancelledCount)
                    if (selectedInternalTab?.value === item?.value) {
                        setLeadsCount(stats?.siteVisitCancelledCount);
                    }
                }
            })
        }
    }
    const getUsers = async () => {
        setLoading(true);
        const res = await getAllBrokerCompanyUsers(uuid);
        if (res.data.status === 200) {
            setLoading(false);
            setUsers(res.data.data);
        }
    }

    useEffect(() => {
        if (uuid) {
            getUsers();

        }

    }, [uuid])

    useEffect(() => {
        if (uuid && id) {
            // getEvents();
            getOpportunities();
            opportuniStats()
        }
    }, [uuid, id, selectedInternalTab])
    useEffect(() => {
        getOpportunityColumns();
    }, [])

    return (

        <div className="main-section show-new-leads flex flex-col !pl-0 !pb-0 !pr-0">
            <div className="!px-5 !border-b !border-grayLight !pt-5">
                <div className="row d-flex d-flex flex-md-fill flex-wrap !mb-4">
                    <div className="col-md-7 col-6 d-flex flex-md-fill flex-column">
                        <h1 className="text-xl m-0 black fw-po-medium flex items-center ">
                            <Link to='/cp-manager' className='!mr-2'>
                                <PagebackIcon />
                            </Link>
                            {name}
                        </h1>
                        {/* <p className="fz16 black-dark-700 mb-0 d-md-block d-none">Manage all subscriptions for plus app users</p> */}
                    </div>
                    <div className="col-md-5 col-6 d-flex justify-content-end items-center d-flex flex-md-fill">
                        <div className='!mr-2'>
                            <button className='border rounded-lg p-2' onClick={() => {
                                const payload = []
                                payload.push({
                                    'broker_company_id': [{ "id": id, "name": name, "uuid": uuid }]
                                })
                                const filters =
                                    localStorage.setItem('filter', JSON.stringify(payload))
                                window.open('/')
                            }}> View Tagged Leads</button>
                        </div>
                        <button
                            className="pr-btn px-16 !py-2 border-0 lh-20 flex items-center"
                            onClick={() => setShowAddBrokerCompanyUser(true)}
                        >
                            <PluswhiteIcon />
                            Add User
                        </button>

                    </div>
                </div>
                <Tabs
                    tabList={tabList}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    tabName={selectedTab}
                    tabRowCount={tabRowCount}
                />
            </div>
            <div className='!mt-4 !px-6 h-100'>
                {selectedTab.name === 'Opportunities' &&
                    <InternalTabs
                        tabList={svTabList}
                        selectedTab={selectedInternalTab}
                        setSelectedTab={setSelectedInternalTab}
                    />
                }
                {selectedTab.name === 'Users' && <CompanyUsers
                    users={users}
                    setShowAddBrokerCompanyUser={setShowAddBrokerCompanyUser}
                    setSelectedUser={setSelectedUser}
                    isLoading={loading}
                />}
                {/* {selectedTab.name === 'Opportunities' && <SVEvents
                    events={events}
                    isLoading={isLoading}
                    searchText={searchText}
                    selectedTab={selectedInternalTab}
                    setSelectedTab={setSelectedInternalTab}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    setShowingResults={setShowingResults}
                    showingResults={showingResults}
                    leadsCount={leadsCount}
                    maxPage={maxPage}
                    tabRowCount={internalTabRowCount}
                    setTabRowCount={setInternalTabRowCount}
                    tabList={svTabList}
                />} */}
                {
                    selectedTab.name === 'Opportunities' && <CPOpportunities
                        opportunityData={opportunityData}
                        getColumns={getOpportunityColumns}
                        dashboardColumns={opportunityDashboardColumns}
                        setDashboardColumns={setOpportunityDashboardColumns}
                        selectedInternalTab={selectedInternalTab}
                        intentStatuses={intentStatuses}
                        sourceStatuses={sourceStatuses}
                        statuses={statuses}
                        userProfile={userProfile}
                        allowedPermissions={allowedPermissions}
                        isRolesModule={isRolesModule}
                        getOpportunities={getOpportunities}
                        handleUserReassignLead={(id) => {
                            // setSelectedTableValues([id])
                            // setShowReassignModal(true)
                        }}
                        selectedTableValues={[]}
                        setSelectedTableValues={() => { }}
                        setSelectedTableData={() => { }}
                        selectedTableData={[]}
                        setLeadName={() => { }}
                        showCheck={''}
                        col={''}
                        sort={''}
                        searchText={searchText}
                        filters={[]}
                        isLoading={isLoading}
                        selectedTab={''}
                    />
                }
            </div>
            {/* <UserLimitExeedModal show={false} /> */}
            {showAddBrokerCompanyUser && <AddBrokerCompanyUser
                show={showAddBrokerCompanyUser}
                closeModal={() => {
                    setSelectedUser({})
                    setShowAddBrokerCompanyUser(false)
                }}
                getUsers={getUsers}
                selectedUser={selectedUser}
                uuid={uuid}
            />}
        </div>
    )
}

export default BorkerCompanyUsers