import React, { useContext, useEffect, useRef, useState } from 'react'
import MobileInput from '../../../../../components/InputGroup/MobileInput'
import { getProjectsForFilter } from '../../../../../services/private/leads.service';
import { customerDashboard, findCustomerWithMobile, findOpportunityByMobileAndProject, insertOpportunity } from '../../../services/dashboard.service';
import { toast } from 'react-toastify';
import Consumer from '../../../../../helpers/context';
import InputSelect from '../../../../../components/InputGroup/InputSelect';
import { Oval } from 'react-loader-spinner';
import InputText from '../../../../../components/InputGroup/InputText';
import TooltipText from '../../../../../components/Tooltip';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close.svg';
import InputTextarea from '../../../../../components/InputGroup/InputTextarea';
import { getUsersForSettings } from '../../../../../services/private/company.service';
import OpportunityExist from './OpportunityExist';

const BasicDetails = ({
    name,
    setName,
    mobile,
    setMobile,
    projects,
    setProjects,
    remarks,
    setRemarks,
    source,
    setSource,
    closeModal,
    loading,
    selectedPresale,
    setSelectedClosing,
    selectedClosing,
    setSelectedPresale,
    handleSubmit,
    setSteps,
    setShowAlertModal,
    isDisable
}) => {

    const [usersForAssigned, setUsersForAssigned] = useState([])
    const [projectList, setProjectList] = useState([]);
    const [sourceList, setSourceList] = useState([]);
    const [showList, setShowList] = useState(false);
    const [usersList, setUsersList] = useState([]);
    const [currentActive, setCurrentActive] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState({})
    const [showOpportunityExistModal, setShowOpportunityExistModal] = useState(false);
    const [opportunityDetail, setOpportunityDetail] = useState({});

    const { companyConfig } = useContext(Consumer);

    const nameRef = useRef();
    const mobileRef = useRef();

    const handleMouseClick = (e) => {
        if (nameRef.current && !nameRef.current.contains(e.target)) {
            setShowList(false)
        }
        if (mobileRef.current && !mobileRef.current.contains(e.target)) {
            setShowList(false)
        }
    }

    useEffect(() => {
        if (companyConfig) {
            setSourceList(companyConfig?.Source)
            // setIntentStatuses(companyConfig?.Intent)
        }
    }, [companyConfig])

    const getProjects = async () => {
        const res = await getProjectsForFilter();
        if (res.data.status === 200) {
            const mapped = res.data.data.map((item) => ({ ...item, label: item.name, value: item.id }))
            setProjectList(mapped);
            // setAllProject(mapped)
        }
    }
    const getAllUsers = () => {
        getUsersForSettings().then(res => {
            if (res.status === 200) {
                // const userData = res.data.data.map((data) => {
                //     return {
                //         label: data.name + " - " + data.email,
                //         // label: data.name,
                //         value: data.id
                //     }
                // })
                const usersForAssign = res.data.data.map((data) => {
                    return {
                        label: data.name + " - " + data.email,
                        // label: data.name,
                        value: data.id
                    }
                });
                setUsersForAssigned(usersForAssign);
                // setAllUsers(userData)
            }
        })
    }

    useEffect(() => {
        getAllUsers();
        getProjects();
        document.addEventListener('mousedown', handleMouseClick)
        return (() => document.removeEventListener('mousedown', handleMouseClick))
    }, []);

    const loadOptions = async (value, setState, field) => {
        setState(value);
        setCurrentActive(field)

        const payload = {
            "pageNumber": 1,
            "perPage": 100,
            "searchText": field === 'mobile' ? value.slice(2, 12) : value,
            filters: {},
            tab: 'all'
        }
        const res = await customerDashboard(payload)
        if (res.data.status === 200) {
            setShowList(res.data.data.length > 0 ? true : false)
            setUsersList([...res.data.data])
        }
    }

    const handleCheckOpportunity = async (value) => {
        if (value && value?.length > 0) {
            const payload = {
                mobile: mobile.slice(-10),
                project_id: value[value.length - 1]?.id
            }
            try {
                const res = await findOpportunityByMobileAndProject(payload)
                if (res.data.status === 200) {
                    setOpportunityDetail(res.data.data)
                    setShowOpportunityExistModal(true)
                }
            } catch (err) {
                console.log(err.response.data.message)
                setProjects([...value])
            }
        } else {
            setProjects(value)
        }
    }


    const handleCheckCustomer = async () => {
        try {
            const res = await findCustomerWithMobile({ mobile: mobile.slice(-10) })
            if (res.data.status === 200) {
                setOpportunityDetail(res.data.data)
                setShowOpportunityExistModal(true)
            }
        } catch (err) {
            console.log(err.response.data.message)
        }
    }

    return (
        <>
            <div className="!py-5 !px-6 h-[422px] overflow-y-auto grid grid-cols-2 !gap-x-9 !gap-y-5">
                <div className='relative'>
                    <MobileInput
                        label={'Mobile'}
                        value={mobile}
                        // onChange={(e) => setMobile(e.phone)}
                        onChange={(e) => setMobile(e.phone)}
                        // handleBlur={() => handleCheckCustomer()}
                        required={true}
                        inputclass={'mt-0'}
                        disabled={isDisable}
                    />

                </div>
                <div className='relative'>
                    <InputText
                        label={'Name'}
                        placeholder={'Customer Name'}
                        inputclass={'mt-0'}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        // onChange={(e) => setName(e.target.value)}
                        required={true}
                        readOnly={isDisable}
                    />
                </div>
                <InputSelect
                    label={`Project ${!isDisable ? '(Optional)' : ''}`}
                    options={projectList}
                    isMulti={true}
                    value={projects}
                    onChange={(e) => handleCheckOpportunity(e)}
                    inputclass={'mt-0'}
                    required={isDisable}
                />
                <InputSelect
                    label={'Source'}
                    value={source}
                    onChange={(e) => setSource(e)}
                    options={sourceList}
                    required={true}
                    inputclass={'mt-0'}
                />
                {/* {projects?.length > 1 && <div className='flex justify-between items-center'>
    <label htmlFor="" className='mb-0 text-sm text-black-t font-medium'> Presale and Closing Manager same for all</label>
    <button
        type="button"
        className={`btn btn-lg btn-toggle ${sameTime ? 'active' : ''} mr-2`}
        value={sameTime}
        onClick={() => {
            setSameTime(!sameTime)
        }}
    >
        <div className="handle"></div>
        <span className={`${sameTime ? 'on' : 'off'}`}></span>
    </button>
</div>} */}
                {
                    // !sameTime && 
                    <>
                        {projects?.length > 0 && projects.map((item, index) => (
                            <div className={`col-span-2 !space-y-4 `}>
                                {projects?.length > 1 && <div className='border-t !mb-6 !mt-2'></div>}
                                {projects?.length > 1 && <p className='m-0 text-black-t text-base font-semibold flex items-center justify-between'>
                                    {/* { */}
                                    {/* // item.label.length > 15 ? <TooltipText title={item.label}>
                                        //     <span className='!max-w-[150px] !min-w-[50px] text-nowrap truncate'>{item.label}</span>
                                        // </TooltipText>
                                        //     : */}
                                    <span className='!max-w-[150px] !min-w-[50px] text-textblack text-nowrap'>For {item.label} Opportunity</span>
                                    {/* } */}
                                    {/* <span className='!border-t w-[75%] !border-black-t inline-block !ml-3'></span> */}
                                    <button className='border rounded-3xl text-xs !py-1 !pl-4 !pr-3 flex items-center hover:bg-black200'
                                        onClick={() => {
                                            let proj = [...projects];
                                            proj.splice(index, 1);
                                            setProjects([...proj])
                                        }}
                                    >
                                        Remove
                                        <CloseIcon className='h-4 w-4 !ml-1.5' />
                                    </button>
                                </p>}
                                <div className='grid grid-cols-2 !gap-x-9 !gap-y-5'>
                                    <InputSelect
                                        label={'Pre-sale'}
                                        placeholder={'Select User'}
                                        options={usersForAssigned}
                                        inputclass={'mt-0'}
                                        value={item?.preSaleUserId ? usersForAssigned.find(user => user.value === item?.preSaleUserId) : ''}
                                        onChange={(value) => {
                                            let proj = [...projects]
                                            proj[index]['preSaleUserId'] = value.value
                                            setProjects(proj)
                                        }}
                                    />
                                    <InputSelect
                                        label={'Closing'}
                                        placeholder={'Select User'}
                                        inputclass={'mt-0'}
                                        options={usersForAssigned}
                                        value={item?.closingUserId ? usersForAssigned.find(user => user.value === item?.closingUserId) : ''}
                                        onChange={(value) => {
                                            let proj = [...projects]
                                            proj[index]['closingUserId'] = value.value
                                            setProjects(proj)
                                        }}
                                    />
                                    <div className='col-span-2'>
                                        <InputTextarea
                                            label={`Opportunity Comments (Optional)`}
                                            value={item?.remarks}
                                            onChange={(e) => {
                                                let proj = [...projects]
                                                proj[index]['remarks'] = e.target.value
                                                setProjects(proj)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                }
                {/* {
    // sameTime && 
    projects?.length > 0 && <>
        <InputSelect
            label={'Pre-sale'}
            placeholder={'Select User'}
            options={usersForAssigned}
            value={selectedPresale ? usersForAssigned.find(user => user.value === selectedPresale) : ''}
            onChange={(value) => {
                setSelectedPresale(value.value)
            }}

            required={true}
        />
        <InputSelect
            label={'Closing'}
            placeholder={'Select User'}
            inputclass={'!mt-4'}
            options={usersForAssigned}
            value={selectedClosing ? usersForAssigned.find(user => user.value === selectedClosing) : ''}
            onChange={(value) => {
                setSelectedClosing(value.value);
            }}
        />
    </>
} */}
                {projects?.length === 0 && <>
                    <InputSelect
                        label={'Pre-sale'}
                        placeholder={'Select User'}
                        options={usersForAssigned}
                        inputclass={'mt-0'}
                        value={selectedPresale ? usersForAssigned.find(user => user.value === selectedPresale) : ''}
                        onChange={(value) => {
                            setSelectedPresale(value.value)
                        }}
                    />
                    <InputSelect
                        label={'Closing'}
                        placeholder={'Select User'}
                        options={usersForAssigned}
                        inputclass={'mt-0'}
                        value={selectedClosing ? usersForAssigned.find(user => user.value === selectedClosing) : ''}
                        onChange={(value) => {
                            setSelectedClosing(value.value)
                        }}
                    />
                    <div className='col-span-2'>
                        <InputTextarea
                            label={`Remarks`}
                            placeholder={'Enter Remarks'}
                            value={remarks}
                            onChange={(e) => setRemarks(e.target.value)}
                        />
                    </div>
                </>
                }

            </div>

            <div className="d-flex flex-row align-items-center justify-content-end !px-5 !py-4 righmodalfooter shadow-topshadow">

                <button onClick={() => setShowAlertModal(true)} className='border !rounded-lg py-2.5 w-[128px] shrink-0 !text-black-t font-medium text-base text-center'>Cancel</button>
                {!loading && <button onClick={() => {
                    if (source.label === 'Reference') {
                        setSteps('referral')
                    } else {
                        handleSubmit();
                    }
                }} className='border !rounded-lg w-[128px] !py-2.5 !ml-4 !bg-primary !border-primary text-white font-medium text-base text-center hover:opacity-90'>
                    {
                        source.label === 'Reference' ? 'Next' : 'Create'}
                </button>}

                {loading && <button className='border !rounded-lg w-[128px] py-2.5 !ml-3 !bg-primary !border-primary font-semibold text-16  flex items-center justify-center'>
                    <Oval
                        height={24}
                        width={24}
                        color="#FFFFFF"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#FFFFFF"
                        strokeWidth={4}
                        strokeWidthSecondary={4}
                    />
                </button>}

            </div >
            {showOpportunityExistModal && <OpportunityExist
                show={showOpportunityExistModal}
                closeModal={() => {
                    setShowOpportunityExistModal(false)
                    // setMobile('+91')
                }}
                leadExistResponse={opportunityDetail}
            />}
        </>
    )
}

export default BasicDetails