import React, { useEffect, useState } from 'react'
import ReassignLeadsManageModal from '../../../../components/Modals/ReassignLeadsManageModal';
import SliderModal from '../../../../components/Modals/SliderModal';
import InputSelect from '../../../../components/InputGroup/InputSelect';
import { Oval } from 'react-loader-spinner';
import DateTimePicker from '../../../../components/InputGroup/DateTime';
import { InProgessReasonsList, NotConnectedReasonsList } from '../../../../utils/constants';
import { toast } from 'react-toastify';
import { bulkStatusUpdate } from '../../services/dashboard.service';
import BulkStatusChangeModal from './BulkStatusChangeSuccessModal';
import RightModal from '../../../../components/Modals/RightModal';

const ChangeBulkStatusModal = ({ show,
    closeModal,
    selectedTableData,
    setSelectedTableData,
    selectedTableValues,
    setSelectedTableValues,
    statuses,
    intentStatuses,
    handleSuccess,
    resetDataMods
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [showMangeLeadModal, setShowMangeLeadModal] = useState(false);
    const [status, setStatus] = useState('');
    const [reason, setReason] = useState('');
    const [followupDate, setFollowupDate] = useState('');
    const [remarks, setRemarks] = useState();
    const [presale, setPresale] = useState('');
    const [closing, setClosing] = useState('');
    const [intent, setIntent] = useState('');
    const [statusList, setStatusList] = useState([])
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    useEffect(() => {
        const filterStatus = statuses.filter(i => (i.label === 'Not Connected' || i.label === 'In Progress'))
        setStatusList(filterStatus)
    }, [statuses])

    const handleSubmit = async () => {
        if (selectedTableValues?.length === 0) {
            toast.error(" Please select opportunity to change status.");
            return;
        }
        if (!status || !reason || !intent || !followupDate) {
            toast.error('Please fill required fields');
            return;
        }
        const payload = {
            followupScheduleDate: followupDate,
            remarks: remarks,
            intentId: intent?.value,
            info: reason.value,
            statusId: status?.value,
            lead_uuids: selectedTableValues
        }
        try {
            setIsLoading(true)
            const res = await bulkStatusUpdate(payload)
            setIsLoading(false)
            if (res.data.status === 200) {
                setShowSuccessModal(true);
                handleSuccess();
            }
        } catch (err) {
            setIsLoading(false)
        }
    }
    return (

        <RightModal show={show} closeModal={closeModal} modalClass={`configurationModal`}>
            <div className="modal-header  bg-white !px-5  !py-4 flex !items-center">
                <h1 className='text-black text-2xl inter m-0'>Bulk Status Change</h1>
                <button onClick={closeModal} className='modal-close'> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M13.4115 12.0003L18.6886 17.2822C19.079 17.6729 19.0787 18.306 18.688 18.6964C18.2973 19.0867 17.6641 19.0864 17.2738 18.6957L11.9963 13.4135L6.70658 18.6953C6.31577 19.0856 5.6826 19.0851 5.29237 18.6943C4.90213 18.3035 4.9026 17.6703 5.29342 17.2801L10.5827 11.9986L5.29565 6.70679C4.9053 6.31609 4.90559 5.68292 5.29629 5.29257C5.68699 4.90223 6.32016 4.90251 6.7105 5.29321L11.998 10.5855L17.2739 5.31734C17.6648 4.92711 18.2979 4.92758 18.6881 5.31839C19.0784 5.70921 19.0779 6.34237 18.6871 6.73261L13.4115 12.0003Z" fill="#171725" />
                </svg></button>
            </div>
            <div className="flex flex-col h-full overflow-hidden">
                <div className="h-full !pl-5 !pr-5 !pb-5 overflow-auto">
                    <InputSelect
                        index={4}
                        name="Status"
                        menuPlacement="bottom"
                        value={status}
                        options={statusList}
                        onChange={(e) => setStatus(e)}
                        placeholder="Select Status"
                        label="Status"
                        inputclass={'!mt-4'}
                        required={true}
                    // error={error && !selectedTeam ? 'Please Select Team' : ''}                            
                    // isMulti={userProfile?.mulit_user_assign && count > 1}
                    />
                    <InputSelect
                        index={4}
                        name="reason"
                        menuPlacement="bottom"
                        value={reason}
                        options={status?.label === 'Not Connected' ? NotConnectedReasonsList : InProgessReasonsList}
                        onChange={(e) => setReason(e)}
                        placeholder="Select Reason"
                        label="Reason"
                        inputclass={'!mt-4'}
                        required={true}
                    // error={error && !selectedTeam ? 'Please Select Team' : ''}                            
                    // isMulti={userProfile?.mulit_user_assign && count > 1}
                    />
                    <DateTimePicker
                        handleBlur={() => { }}
                        onValueChange={(e) => {
                            setFollowupDate(e)
                        }}
                        label="Select Followup Date & Time"
                        name="scheduleDate"
                        // error={errors['scheduleDate']}
                        value={followupDate}
                        inputclass={'!mt-4'}
                        required={true}
                    />
                    <InputSelect
                        index={4}
                        name="intent"
                        menuPlacement="bottom"
                        value={intent}
                        options={intentStatuses}
                        onChange={(e) => setIntent(e)}
                        placeholder="Select Intent"
                        label="Intent"
                        inputclass={'!mt-4'}
                        required={true}
                    // error={error && !selectedTeam ? 'Please Select Team' : ''}                            
                    // isMulti={userProfile?.mulit_user_assign && count > 1}
                    />

                    <label className='mt-3'>Comments (optional)</label>
                    <textarea
                        className="form-control mb-16"
                        name="remarks"
                        value={remarks}
                        placeholder="Enter Comments..."
                        onChange={(e) => setRemarks(e.target.value)}
                    >
                    </textarea>
                </div>
                <div className='flex items-center justify-between !border-b !border-t !px-5 !py-4'>
                    <div className='!whitespace-nowrap !text-sm font-semibold'>
                        Selected Customers ({selectedTableValues.length})
                    </div>
                    <button className='!text-sm !w-max font-medium text-[#647187]' onClick={() => setShowMangeLeadModal(true)}>
                        Edit Selection
                    </button>
                </div>
                <div className="flex items-center justify-content-between !px-5 !py-4 !w-100">
                    <button
                        className="cancel outline-btn !mr-3 !py-2.5 !px-4 !w-[112px]"
                        onClick={() => {
                            closeModal()
                            // if (selectedTableData.length === 0) {
                            //     resetDataMods();
                            // }
                        }}
                    >
                        Cancel
                    </button>
                    {!isLoading && <button
                        className="pr-btn !py-2.5 !px-4 !w-[calc(100%-112px)]"
                        onClick={handleSubmit}
                        disabled={isLoading}
                    >
                        Change Bulk Status
                        {selectedTableData.length > 0 && <span className="ml-1 fz14">{`(${selectedTableData.length})`}</span>}
                    </button>}
                    {isLoading && <button className="pr-btn !px-5 !w-[calc(100%-112px)] flex items-center justify-center" type='button'>
                        <Oval
                            height={24}
                            width={24}
                            color="#ffffff"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#ffffff"
                            strokeWidth={4}
                            strokeWidthSecondary={4}
                        />
                    </button>}

                </div>
            </div>
            {
                showMangeLeadModal && <ReassignLeadsManageModal
                    show={showMangeLeadModal}
                    setShow={setShowMangeLeadModal}
                    selectedTableData={selectedTableData}
                    setSelectedTableData={setSelectedTableData}
                    selectedTableValues={selectedTableValues}
                    setSelectedTableValues={setSelectedTableValues}
                />
            }
            {
                showSuccessModal && <BulkStatusChangeModal
                    show={showSuccessModal}
                    closeModal={() => {
                        resetDataMods();
                        setShowSuccessModal(false);
                        closeModal();
                    }}
                    status={status?.label}
                    count={selectedTableValues?.length}
                />
            }
        </RightModal>
    )

}


export default ChangeBulkStatusModal