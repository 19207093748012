import React, { useEffect } from 'react';
import { numberFormat } from '../../helpers/helpers';

const Tabs = ({ selectedTab, setSelectedTab, tabList, tabName, tabRowCount, isDisable }) => {
    return (
        <div className='tabs-bottom-active-border'>
            <div className="nav nav-tabs border-0 !mb-2 gap-x-6" id="nav-tab" role="tablist">
                <ul className="nav gap-x-6 nav-payment-milestone flex-nowrap" >
                    {tabList.map(item => (
                        <>
                            <li className="nav-item" key={item.name}>
                                <a
                                    className={`nav-link inter p-0 border-0 text-base text-textblack font-medium relative ${isDisable ? '!text-[#667085CC] cursor-not-allowed' : ''} hover:!text-primary ${selectedTab.name === item.name ? 'active' : ''}`}
                                    onClick={() => {
                                        if (!isDisable) {
                                            setSelectedTab(item)
                                        }
                                    }}
                                >
                                    {item.name + ` ${tabRowCount[item.name] >= 0 ? '(' + numberFormat(tabRowCount[item.name]) + ')' : ''}`}
                                </a>
                            </li>

                        </>
                    ))}
                </ul>
            </div>
        </div>
    )
}
export default Tabs;