import React, { useEffect, useState } from 'react'

const svList = [
    {
        label: 'Direct/CP',
        value: 'all'
    },
    {
        label: 'Direct',
        value: 'direct'
    },
    {
        label: 'Channel Partner',
        value: 'cp'
    },
    {
        label: 'Walk In',
        value: 'walk_in'
    },
]

const bookingList = [
    {
        label: 'All',
        value: 'all'
    },
    {
        label: 'Direct',
        value: 'direct'
    },
    {
        label: 'Channel Partner',
        value: 'cp'
    },
]
const PresaleDropdown = ({ scheduledBy, setScheduleBy, dropClass, listFor = 'booking' }) => {
    const [list, setList] = useState([])
    useEffect(() => {
        if (listFor === 'sv_tracker') {
            setList([...svList])
        } else {
            setList([...bookingList])
        }
    }, [listFor])
    return (
        <div className={`group dropdown view-more !px-3 !py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 !rounded-lg hover:bg-gray-50 flex items-center ${dropClass}`}>

            <button
                className="inline-flex items-center justify-center "
                type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            >
                {scheduledBy.label}
                <svg
                    className="w-4 h-4 ml-2 group-[.show]:rotate-180 transition-transform duration-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                >
                    <path
                        fillRule="evenodd"
                        d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 111.06 1.06l-4 4a.75.75 0 01-1.06 0l-4-4a.75.75 0 01.02-1.06z"
                        clipRule="evenodd"
                    />
                </svg>
            </button>
            <div className="dropdown-menu dropdown-menu-right !p-2" aria-labelledby="dropdownMenuButton">
                {
                    list.map((item, index) => (
                        <a className="dropdown-item" key={item.value} onClick={() => {
                            setScheduleBy(item)
                        }}>
                            {item.label}
                        </a>
                    ))
                }
            </div>
        </div>
    )
}

export default PresaleDropdown