import React, { useContext, useEffect, useState } from 'react'
// import RightModal from '../../../../components/Modals/RightModal'

import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { toast } from 'react-toastify';
import Consumer from '../../../../helpers/context';
import RightModal from '../../../../components/Modals/RightModal';
import { changeQualifiedStatusByUUID } from '../../services/dashboard.service';

const ChangeQualificationModal = ({ show, closeModal, selectedLead, getOpportunities }) => {

    const [qualifiedStatus, setQualifiedStatus] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState({});

    const { companyConfig } = useContext(Consumer);

    useEffect(() => {
        if (selectedLead?.qualified_status_id && selectedLead?.qualified_status_name) {
            setSelectedStatus({
                label: selectedLead?.qualified_status_name,
                value: selectedLead?.qualified_status_id
            })
        }
    }, [selectedLead]);

    useEffect(() => {
        if (Object.keys(companyConfig).length > 0) {
            setQualifiedStatus(companyConfig?.QualifiedStatus)
        }
    }, [companyConfig]);

    const handleSubmit = async () => {
        const payload = {
            qualified_status_id: selectedStatus?.value
        }
        const res = await changeQualifiedStatusByUUID(selectedLead?.id, payload);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getOpportunities();
            closeModal();
        }
    }

    console.log(selectedStatus, 'selectedStatus')
    return (
        <RightModal closeModal={closeModal} show={show} modalClass={'!w-[400px]'}>
            <div className="modal-header sticky top-0 left-0 bg-white !px-5  !py-4 flex">
                <h1 className='text-black text-2xl inter m-0'>Qualification Status</h1>
                <button onClick={closeModal} className='modal-close'>
                    <CloseIcon />
                </button>
            </div>
            <div className="modal-body !px-5 h-5/6 overflow-y-auto !space-y-4">
                <div className='form-group'>
                    <label htmlFor="">Name</label>
                    <p className='mb-0 text-base font-semibold text-black'> {selectedLead?.name} </p>
                </div>
                <div className='form-group mt-3'>
                    <label>Mark Qualified/Not Qualified</label>
                    <div>
                        {
                            qualifiedStatus?.map((item, index) => (
                                <label className='mr-4' key={`${item?.label}`}>
                                    <input
                                        type='radio'
                                        name='qualification'
                                        value={item?.value}
                                        checked={selectedStatus?.value === item?.value}
                                        onChange={() => {
                                            setSelectedStatus(item)
                                        }}
                                    /> {item.label}
                                </label>
                            ))}
                    </div>
                </div>
            </div>

            <div className="d-flex flex-row align-items-center justify-content-between !px-5 gap-x-4 !py-4 righmodalfooter shadow-topshadow">

                <button onClick={closeModal} className='border rounded-lg py-2.5 w-28 basis-28 grow-0 shrink-0 border-primary !text-primary font-semibold text-16 text-center'>Cancel</button>
                <button onClick={handleSubmit} className='border rounded-lg py-2.5 w-full ml-4 !bg-primary !border-primary text-white font-semibold text-16 text-center hover:opacity-90'> Confirm</button>

            </div >
        </RightModal>
    )
}

export default ChangeQualificationModal