import React, { useState, useEffect, useMemo, useContext } from 'react';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import { getAllCities } from '../../../../../services/public/public.service';
import { getMyUsers } from '../../../../../services/private/company.service';
import DynamicAccordion from '../../../../../components/Modals/FilterModal/Dynamic.accordion';
import SliderModal from '../../../../../components/Modals/SliderModal';
import { getLeadsFilter, getProjectsForFilter } from '../../../../../services/private/leads.service';
import CheckAccordion from '../../../../../components/Modals/FilterModal/Check.accordion';
import RadioCheckAccordion from '../../../../../components/Modals/FilterModal/RadioCheck.accordion';
// import DynamicAccordion from '../FilterModal/Dynamic.accordion';
// import { getAllCities } from '../../../services/public/public.service';
// import { getLeadsFilter } from '../../../services/private/leads.service';

const defaultStaticFilterList = [
    'Subscription',
    'Date Purchased',
    'Amount Paid',
    'Referral Code',
    'Call Date'
];

const searchFilterkey = [
    'Assigned To',
]

const sharedWithSearchKey = [
    'Assigned To'
]

const SUBSCRIPTION_TYPE = [
    {
        label: 'Premium',
        value: 'premium'
    },
    {
        label: 'Freemium',
        value: 'freemium'
    },
    // {
    //     label: 'Free',
    //     value: 'free'
    // },
]
const CALL_TYPE_LIST = [
    {
        label: 'Outgoing',
        value: 'Outgoing'
    },
    {
        label: 'Incoming',
        value: 'Incoming'
    },
    {
        label: 'Abandanded',
        value: 'abandanded'
    }
]
const STATUS_LIST = [
    {
        label: 'Answered',
        value: 'connected'
    },
    {
        label: 'Not Connected',
        value: 'not connected'
    },
    {
        label: 'Missed',
        value: 'missed'
    },
    {
        label: 'Off Hours',
        value: 'offhours'
    }
]

const OTHER_STATUS = [
    {
        label: 'Connected',
        value: true
    },
    {
        label: 'Not Connected',
        value: false
    }
]

export default function KnowlarityFilterModal({
    show,
    setShow,
    handleFilter,
    filterData,
    setFilterCount,
    filtersData,
    filterToaster,
    setCurrentPage,
}) {

    // Filter Values

    const [callDate, setCallDate] = useState({});
    const [filterSelected, setFilterSelected] = useState(false)
    const [filterDate, setFilterDate] = useState({});
    const [defaultFilterList, setDefaultFilterList] = useState(defaultStaticFilterList);
    const [city, setCity] = useState([]);
    const [assign, setAssign] = useState([]);
    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState([]);
    const [callStatus, setCallStatus] = useState([]);
    const [callType, setCallType] = useState([]);
    const [agentStatus, setAgentStatus] = useState([]);
    const [customerStatus, setCustomerStatus] = useState([])

    // console.log(filtersData, 'filtersData')

    const [inputValues, setInputValues] = useState({
        City: '',
        'Assigned To': ''
    });

    const [inputValuesNotFound, setInputValuesNotFound] = useState({
        City: '',
        'Assigned To': ''

    });

    const [searchResults, setSearchResults] = useState({
        City: [],
        'Assigned To': [],
        "Project": [],
    });

    const [searchDefultResults, setSearchDefaultResults] = useState({
        "Assigned To": [],
        "Assigned User": [],
        'Shared With': [],
        'Marked By': [],
        'Assigned To': []
    });

    const ROLE_NAME = localStorage.getItem('role');


    const handleJoinDateChange = (name, date) => {
        setFilterDate({ ...filterDate, [name]: date });
        setCallDate({ ...callDate, [name]: date });
    }
    const getFilterData = async (type, value) => {

        // const res = await getPlusAppFilterUsers({ filterType: type, value: value });
        const res = await getLeadsFilter({ filterType: type, value: value });
        if (res.status === 200) {
            return res.data.data.map(i => ({ ...i, id: i.user_id }))
        }
        else {
            return []
        }
    }

    const getProjects = async () => {
        const res = await getProjectsForFilter();
        if (res.data.status === 200) {
            const mapped = res.data.data.map(item => ({ label: item.name, value: item.id }))
            setProjects(mapped);
        }
    }

    const getAllCitiesByName = async (searchText) => {
        const res = await getAllCities(searchText)

        if (res.data.status === 200) {
            const cities = res.data?.data.map((city) => { return { label: city.name, value: city.name } });
            return cities
        } else {
            return []
        }
    }

    const getDefaultUser = async () => {
        const res = await getMyUsers();
        if (res.data.status === 200) {
            const defaultUser = res.data.data.map(u => ({ ...u, value: u.name, user_id: u.id }))
            setSearchResults((prevState) => ({
                ...prevState,
                'Assigned To': [...defaultUser]
            }));

            setSearchDefaultResults((prevState) => ({
                ...prevState,
                'Assigned To': [...defaultUser]
            }))
            // getBrokerCompany();
        }
    }

    const handleInputChange = async (e) => {
        const inputValuesData = { ...inputValues, [e.target.name]: e.target.value }
        setInputValues(inputValuesData);
        // setFilterSelected(true)
        //if user make input field blank using backspace       
        if (searchFilterkey.includes(e.target.name) && !e.target.value) {
            setSearchResults({ ...searchResults, [e.target.name]: searchDefultResults[e.target.name] });
            return false
        }

        const fieldName = e.target.name;
        if (inputValuesData[fieldName].length < 3) {
            setSearchResults({ ...searchResults, [fieldName]: [] });
            return false
        };

        let filterData = [];
        if (fieldName === 'City') {
            filterData = await getAllCitiesByName(inputValuesData[fieldName])
        } else {
            filterData = await getFilterData(searchFilterkey.includes(fieldName) ? 'Assign To' : sharedWithSearchKey.includes(fieldName) ? 'Shared With' : fieldName, inputValuesData[fieldName])
        }

        if (!filterData.length) {
            setInputValuesNotFound({ ...inputValuesNotFound, [fieldName]: 'No result found.' })
        } else {
            setInputValuesNotFound({ ...inputValuesNotFound, [fieldName]: '' })
        }
        setSearchResults({ ...searchResults, [fieldName]: filterData });
    }

    useEffect(() => {
        if (callDate?.length || assign?.length || project?.length || callType?.length || agentStatus?.length || customerStatus?.length || callStatus?.length) {
            setFilterSelected(true)
        } else {
            setFilterSelected(false)
        }
    }, [assign, project, callDate, callType, callStatus, agentStatus, customerStatus])

    useEffect(() => {
        getDefaultUser();
        getProjects();
    }, []);

    const sendFilterRequest = () => {
        let filterArr = [];

        if (city.length > 0) {
            filterArr.push({
                'city': city.map((c) => {
                    return c.value
                })
            })
        }

        if (assign.length > 0) {
            filterArr.push({
                assigned_to: assign
            })
        }
        if (project.length > 0) {
            filterArr.push({
                projects: project
            })
        }
        if (callStatus.length > 0) {
            filterArr.push({
                status: callStatus.map(i => i.value)
            })
        }
        if (callType.length > 0) {
            filterArr.push({
                call_type: callType[0].value
            })
        }
        if (agentStatus?.length > 0) {
            filterArr.push({
                agent_status: agentStatus[0].value
            })
        }
        if (customerStatus?.length > 0) {
            filterArr.push({
                customer_status: customerStatus[0].value
            })
        }
        if (Object.keys(callDate).length > 0) {
            let startDate = callDate.startDate;
            let endDate = callDate.endDate || callDate.startDate;
            filterArr.push({
                'call_date': {
                    'from': startDate,
                    'to': endDate
                }
            })
        }

        setFilterCount(filterArr.length)
        handleFilter(filterArr);
        filterData(filterArr);
        filterToaster();
        setShow(false)
    }

    const handelRemoveClick = () => {
        if (filterSelected || filtersData.length > 0 || Object.keys(callDate).length > 0 || project.length || assign.length || callStatus?.length || callType?.length || agentStatus?.length) {
            toast.success("All filters Removed");
            setFilterCount(0);
            handleFilter([]);
            filterData([]);
            setProject([])
            setCallDate({})
            setAssign([]);
            setCallType([]);
            setAgentStatus([]);
            setCustomerStatus([]);
            setCallStatus([]);
        }
    }

    /***
     * update filter value after refresh if filter applied
     */
    const updateFilterValaue = () => {
        let filters = filtersData;
        let filtersArr = {};
        for (const filterData of filters) {
            filtersArr = { ...filtersArr, ...filterData }
        }

        let filterKeys = Object.keys(filtersArr);

        if (filterKeys.includes('city')) {
            const filteredCity = filtersArr.city.map(i => ({
                label: i,
                value: i,
                checked: true
            }))

            setCity(filteredCity || [])
        }
        if (filterKeys.includes('assigned_to')) {
            setAssign(filtersArr['assigned_to']);
        }
        if (filterKeys.includes('projects')) {
            setProject(filtersArr['projects'])
        }
        if (filterKeys.includes('status')) {
            const status = STATUS_LIST.filter(i => filtersArr['status'].includes(i.value))
            setCallStatus(status)
        }
        if (filterKeys.includes('call_type')) {
            setCallType(CALL_TYPE_LIST?.filter(i => i.value === filtersArr['call_type']))
        }

        if (filterKeys.includes('agent_status')) {
            setAgentStatus(OTHER_STATUS?.filter(i => i.value === filtersArr['agent_status']))
        }
        if (filterKeys.includes('customer_status')) {
            setCustomerStatus(OTHER_STATUS?.filter(i => i.value === filtersArr['customer_status']))
        }

        if (filterKeys.includes("call_date")) {
            const { from, to } = filtersArr.call_date
            const date = {
                'startDate': from,
                'endDate': to,
            }
            setCallDate(date || {})
        }
    }

    useEffect(() => {
        updateFilterValaue();
    }, [
        filtersData,
        show,
    ])

    useEffect(() => {
        let filters = filtersData;
        const filtersArr = [];
        for (const filterData of filters) {
            filtersArr.push(Object.keys(filterData)[0])
        }

        if (!filtersArr.includes("call_date")) {
            setCallDate({})
        }
        if (!filtersArr.includes('assigned_to')) {
            setAssign([])
        }
        if (!filtersArr.includes('projects')) {
            setProject([])
        }
        if (!filtersArr.includes('call_type')) {
            setCallType([])
        }
        if (!filtersArr.includes('agent_status')) {
            setAgentStatus([])
        }
        if (!filtersArr.includes('customer_status')) {
            setCustomerStatus([])
        }
        if (!filtersArr.includes('status')) {
            setCallStatus([]);
        }
        setFilterCount(filtersArr.length);
    }, [filtersData])

    return (
        <SliderModal
            show={show}
            setShow={setShow}
            title={'Filters'}
            titleRight={<>
                {filterSelected || filtersData.length > 0 ?
                    <a className="fz14 h-font mr-24 black-dark-700 fw-po-medium pr-hover" onClick={handelRemoveClick}>Remove all</a>
                    : " "}
            </>
            }
            body={
                <>
                    <div className="filter">
                        <div className='w-full'></div>
                        <div className="all-fliters">
                            <div id="accordion">
                                <CheckAccordion
                                    value={project}
                                    setValue={setProject}
                                    selectionValues={projects}
                                    accordionName="Project"
                                    label="Project"
                                    showSearch={true}
                                />
                                <CheckAccordion
                                    value={callStatus}
                                    setValue={setCallStatus}
                                    selectionValues={STATUS_LIST}
                                    accordionName="status"
                                    label="Call Status"
                                    showSearch={false}
                                />
                                <RadioCheckAccordion
                                    value={callType}
                                    setValue={setCallType}
                                    selectionValues={CALL_TYPE_LIST}
                                    accordionName="call_type"
                                    label="Call Type"
                                    showSearch={false}
                                />
                                <RadioCheckAccordion
                                    value={agentStatus}
                                    setValue={setAgentStatus}
                                    selectionValues={OTHER_STATUS}
                                    accordionName="agent_status"
                                    label="Agent Status"
                                    showSearch={false}
                                />
                                <RadioCheckAccordion
                                    value={customerStatus}
                                    setValue={setCustomerStatus}
                                    selectionValues={OTHER_STATUS}
                                    accordionName="customer_status"
                                    label="Customer Status"
                                    showSearch={false}
                                />
                                {defaultFilterList.includes('Call Date') && <div className="filter-card cursor-pointer">
                                    <div className="filter-header collapsed" data-toggle="collapse" data-target="#collapsedatejoined" aria-expanded="false" aria-controls="collapsedatejoined" id="headingDateJoined">
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <h2 className="fz16 black mb-0 fw-regular">
                                                Call Date
                                                {Object.keys(callDate).length > 0 && <span>{Object.keys(callDate).length}</span>}
                                            </h2>

                                            <div className="icon mr-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#92929D" />
                                            </svg></div>
                                        </div>
                                    </div>
                                    <div id="collapsedatejoined" className="filter-body collapse" aria-labelledby="headingDateJoined" data-parent="#accordion">
                                        <button className={`border-0 bg-tr mx-3 clear-btn px-0 ${callDate.startDate ? "" : 'd-none'} `} onClick={() => {
                                            setCallDate({})
                                        }}
                                        >
                                            Clear All
                                        </button>
                                        <div className="d-flex justify-content-between align-center mx-3" >
                                            <div className='mr-8 mb-20 w-100'>
                                                <Form.Label>
                                                    Start Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={callDate.startDate ? new Date(callDate.startDate) : ''}
                                                    placeholderText={'Start Date'}
                                                    maxDate={new Date()}
                                                    onChange={(date) => handleJoinDateChange('startDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                            <div className='w-100'>
                                                <Form.Label>
                                                    End Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={callDate.endDate ? new Date(callDate.endDate) : ''}
                                                    placeholderText={'End Date'}
                                                    maxDate={new Date()}
                                                    minDate={callDate.startDate}
                                                    onChange={(date) => handleJoinDateChange('endDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                <DynamicAccordion
                                    label='Assigned To'
                                    accordionName="Assigned User"
                                    placeholder='Enter user name to search'
                                    value={assign}
                                    setValue={setAssign}
                                    searchResults={searchResults['Assigned To']}
                                    setSearchResults={(data) => setSearchResults({ ...searchResults, ["Assigned To"]: structuredClone(data) })}
                                    inputValues={inputValues["Assigned To"]}
                                    handleInputChange={handleInputChange}
                                    notFound={inputValuesNotFound["Assigned To"]}
                                    setInputValues={setInputValues}
                                    inputValuesNotFound={inputValuesNotFound}
                                    setInputValuesNotFound={setInputValuesNotFound}
                                    searchDefultResults={searchDefultResults}
                                />

                            </div>
                        </div>
                    </div>
                    <div className="form-inline justify-content-between px-20 filter-ok-cancel">
                        <button className="cancel outline-btn" onClick={() => setShow(false)}>Cancel</button>
                        <button className="pr-btn" onClick={sendFilterRequest}>Apply Filter</button>
                    </div>
                </>
            }
        />
    )
}