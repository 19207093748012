import React, { useContext, useEffect, useRef, useState } from 'react'
import { brokerProjectListing, deleteBrokerSV, getAllBrokersSV, getProjectByUserAccess, insertLead, leadCheckforBroker } from '../../services/private/borker.service';
import moment from 'moment';
import { ReactComponent as ExportIcon } from '../../assets/icons/ExportIcon.svg'
import Tabs from '../../components/HOC/Tabs';
import SiteVisitLeads from './SiteVisitLeads';
import SiteVisitCompletedLeads from './SiteVisitCompletedLeads';
import SitiVisitAttempts from './SitiVisitAttempts';
import LeadsFooterBar from '../LeadsManager/LeadsFooterBar';
import SiteVisitCancelled from './SiteVisitCancelled';
import CreateLeadModal from './Modal/CreateLeadModal';
import SearchBox from '../../components/SearchBox';
import LeadExistModal from './Modal/LeadExistModal';
import BrokerLeadsFilter from './Modal/BrokerLeadsFilter';
import useDocumentTitle from '../../custom hooks/useDocumentTitile';
import { getColumnPreferenceForSvTracker, getCompanyAgency, getMasterColumnPreferenceForSvTracker, getUniqueFacebookForms, getUsersForSettings, saveColumnPreferenceForSvTracker } from '../../services/private/company.service';
import { toast } from 'react-toastify';
import Consumer from '../../helpers/context';
import Lightbox from 'yet-another-react-lightbox';
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import ConfirmDeletionCustom from '../../components/Modals/ConfirmDeletionCustom';
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import "yet-another-react-lightbox/plugins/captions.css";

// import InternalTabs from '../../components/HOC/InternalTabs';
// import FiltersTag from './FiltersTag';
import InputSelect from '../../components/InputGroup/InputSelect';
import DeadLeads from './DeadLeads';
import { getClosingManagerByProjectId, getDeadEventsAfterSv, getStatsForSvTracker, getSvForSVTracker } from '../New Dashboard/services/dashboard.service';
import { ReactComponent as RefreshIcon } from "../../assets/icons/dashboard_refresh.svg";
import { useLocation } from 'react-router-dom';
import InternalTabs from '../New Dashboard/Tabs/InternalTabs';
import PresaleDropdown from '../New Dashboard/Components/PresaleDropdown';
import { ReactComponent as PluswhiteIcon } from "../../assets/icons/Pluswhite.svg";
import NewSitevisitModal from '../New Dashboard/Components/Modals/NewSitevisitModal';
import ProjectFilters from '../LeadsManager/LeadsTopBar/component/ProjectFilters';
import ProjectFilter from '../New Dashboard/Components/ProjectFilter';
import { getProjectsForFilter } from '../../services/private/leads.service';
import { Dropdown } from 'react-bootstrap';
import StatusDropDownDynamic from '../../components/Status Dropdown/StatusDropdownDynamic';
import FilterModal from '../../components/Modals/FilterModal/FilterModal';
import { getCustomerEnumsCall } from '../../services/private/customer.service';
import { ConvertFitersArrayToObject } from '../../helpers/helpers';
import SVTrackerFiltersModal from './Modal/SVTrackerFiltersModal';
import FiltersTag from '../New Dashboard/Components/FiltersTag';
import { getDeadReasons } from '../../services/public/public.service';
import TooltipText from '../../components/Tooltip';
import ChangeQualificationModal from '../New Dashboard/Components/Modals/ChangeQualificationModal';

const tabList = [
    {
        name: 'Scheduled',
        value: 'scheduled'
    },
    {
        name: 'Completed',
        value: 'completed'
    },
    // {
    //     name: 'Attempts',
    //     value: 'attempt'
    // },
    {
        name: 'Cancelled',
        value: 'cancelled'
    },
    {
        name: 'Dead',
        value: 'dead'
    }
]

const svTabList = [
    {
        name: 'All',
        value: 'all',
        count: 0
    },
    {
        name: 'First Time',
        value: 'first',
        count: 0
    },
    {
        name: 'Revisit',
        value: 'revisit',
        count: 0
    },

    {
        name: 'Un-Tagged',
        value: 'un_tagged',
        count: 0
    },
    {
        name: 'Tagged',
        value: 'tagged',
        count: 0
    },

]

const deadTabList = [
    {
        name: 'SV Scheduled',
        value: 'post_sv_schedule',
        count: 0
    },
    {
        name: 'SV Completed',
        value: 'post_sv_completed',
        count: 0
    },
    {
        name: 'Revisit Schedule',
        value: 'revisit_schedule',
        count: 0
    },
    {
        name: 'Revisit Completed',
        value: 'revisit_completed',
        count: 0
    },
]

const svScheduleTabList = [
    {
        name: 'All',
        value: 'all',
        count: 0
    },
    {
        name: 'First Time',
        value: 'first',
        count: 0
    },
    {
        name: 'Revisit',
        value: 'revisit',
        count: 0
    },
    {
        name: "Upcoming",
        value: "upcoming",
        count: 0
    },
    {
        name: "Overdue",
        value: "overdue",
        count: 0
    },
    {
        name: "Past",
        value: "past",
        count: 0
    }
]

const InnerTabList = ['Dead', 'Scheduled', 'Completed'];
const OPTIONS = [
    { value: "Date", label: "Created Date" },
    { value: "Mobile No.", label: "Mobile No." },
    { value: "Name", label: "Name" },
    { value: "Project", label: "Project" },
    { value: "Source", label: "Source" },
    { value: "Status", label: "Status" },
    { value: "Intent", label: "Intent" },

    { value: "Tag Expiry", label: "Tag Expiry" },
    { value: "Last Activity Date", label: "Last Activity Date" },
    { value: "Last Re-Engage Date", label: "Last Re-Engage Date" },
    { value: "Minimum Budget", label: "Minimum Budget" },
    { value: "Maximum Budget", label: "Maximum Budget" },
    { value: "Site Visit Date", label: "Site Visit Date" },
    { value: "Site Visit Followup Date", label: "Followup Date" },
    { value: "Site Visit Count", label: "Site Visit Count" },
]

const DEAD_TAB_SORTING_OPTION = [
    { value: "Date", label: "Created Date" },
    { value: "Mobile No.", label: "Mobile No." },
    { value: "Name", label: "Name" },
    { value: "Project", label: "Project" },
    { value: "Source", label: "Source" },
    { value: "Status", label: "Status" },
    { value: "Intent", label: "Intent" },

    { value: "Tag Expiry", label: "Tag Expiry" },
    { value: "Last Activity Date", label: "Last Activity Date" },
    { value: "Last Re-Engage Date", label: "Last Re-Engage Date" },
    { value: "Minimum Budget", label: "Minimum Budget" },
    { value: "Maximum Budget", label: "Maximum Budget" },
]

const columnEnum = {
    'Assigned To': 'Assigned User',
    'dead_lead_date': 'Marked Date'
}

const BorkerLeads = () => {
    const [events, setEvents] = useState([]);
    const [selectedTab, setSelectedTab] = useState(localStorage.getItem('tab') ? tabList.find(i => i.name === localStorage.getItem('tab')) : tabList[0])
    const [tabRowCount, setTabRowCount] = useState({});
    const [showingResults, setShowingResults] = useState(100);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [leadsCount, setLeadsCount] = useState();
    const [searchText, setSearchText] = useState('');
    const [showCreateLeadModal, setShowCreateLeadModal] = useState(false)
    const [selectedLead, setSelectedLead] = useState('');
    const [showLeadExistModal, setShowLeadExistModal] = useState(false);
    const [leadExistResponse, setLeadExistResponse] = useState({});
    const [refreshTime, setRefreshTime] = useState('');
    const [filters, setFilters] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [intentStatuses, setIntentStatuses] = useState([]);
    const [showLightModal, setShowLightModal] = useState(false);
    const [selectedImg, setSelectedImg] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [internalTabRowCount, setInternalTabRowCount] = useState({});
    const [scheduleColumn, setScheduleColumn] = useState([]);
    const [completedColumn, setCompletedColumn] = useState([]);
    const [cancelledColumn, setCancelledColumn] = useState([]);
    const [dashboardColumns, setDashboardColumns] = useState([]);
    const [showCustomisedColumnModal, setShowCustomisedColumnModal] = useState(false)
    const [assignDisable, setAssignDisable] = useState(false);
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState({});
    const [eventStatus, setEventStatus] = useState('all');
    const [leadTopFilters, setLeadTopFilters] = useState([])
    const [scheduledBy, setScheduleBy] = useState({
        label: 'Direct/CP',
        value: 'all'
    });
    const [showNewSiteVisitModal, setShowNewSiteVisitModal] = useState(false);
    const [statuses, setStatuses] = useState([]);
    const [agencyList, setAgencyList] = useState([]);
    const [sourceStatuses, setSourceStatuses] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [sort, setSort] = useState(localStorage.getItem('sort') || "");
    const [showSort, setShowSort] = useState(false);
    const [btnAsc, setBtnAsc] = useState(sort === 'asc' ? true : false);
    const [btnDes, setBtnDes] = useState(sort === 'desc' ? true : false);
    const [sortingOptions, setSortingOptions] = useState(OPTIONS);
    const [col, setCol] = useState('');
    const [fbFormList, setFbFormList] = useState([]);
    const [propertyEnums, setPropertyEnums] = useState([]);
    const [reasons, setReasons] = useState([]);
    const [showExportLeadModal, setShowExportLeadModal] = useState(false);
    const [exportLoading, setExportLoading] = useState(false)
    const [selectedInternalTab, setSelectedInternalTab] = useState({
        name: 'All',
        value: 'completed'
    },);
    const [showQualificationModal, setShowQualificationModal] = useState(false);

    useDocumentTitle('Site Visit Tracker');
    let { companyConfig, isRolesModule, allowedPermissions } = useContext(Consumer);

    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    const tab = localStorage.getItem('tab');
    const subTab = localStorage.getItem('subTab');

    const sortRef = useRef();

    const handleMouseClick = (e) => {
        if (sortRef.current && !sortRef.current.contains(e.target)) {
            setShowSort(false)
        }
    }

    const getAllUsers = () => {
        const payload = {
            project_uuids: leadTopFilters.length === 0 ? projectList?.map(i => i.uuid) : leadTopFilters.map(i => i.uuid)
        }
        getClosingManagerByProjectId(payload).then(res => {
            if (res.status === 200) {
                const userData = res.data.data.map((data) => {
                    return {
                        label: data.name,
                        // label: data.name,
                        value: data.id
                    }
                })
                setAllUsers(userData)
            }
        })
    }

    // const getAllProjects = async () => {
    //     await getProjectByUserAccess().then((res) => {
    //         if (res.status === 200) {
    //             const mapped = res.data.data.map(item => ({ ...item, label: item.name, value: item.id }))
    //             setProjects(mapped);
    //             if (localStorage.getItem('svProject')) {
    //                 const projectId = JSON.parse(localStorage.getItem('svProject'))
    //                 const selectedProj = mapped.find(i => i.value === projectId)
    //                 if (selectedProj) {
    //                     setSelectedProject(selectedProj)
    //                 } else {
    //                     setSelectedProject(mapped[0])
    //                     localStorage.setItem('svProject', JSON.stringify(mapped[0].value))
    //                 }
    //             } else {
    //                 setSelectedProject(mapped[0]);
    //                 localStorage.setItem('svProject', JSON.stringify(mapped[0].value))
    //             }
    //         }
    //     }).catch((err) => {
    //         console.log("Something went wrong", err);
    //     })
    // }

    const getAgencyData = async () => {
        await getCompanyAgency().then(res => {
            if (res.status === 200) {
                setAgencyList(res.data.data)
            }
        }).catch((err) => {
            console.log("Something went wrong", err);
        });
    }

    const getStats = async () => {
        const payload = {
            pageNumber: currentPage,
            perPage: showingResults,
            searchText: searchText,
            tab: selectedTab?.value,
            subTab: selectedInternalTab?.value,
            scheduledBy: scheduledBy.value,
            eventStatus: eventStatus,
            filters: ConvertFitersArrayToObject(filters),
            sortDirection: sort,
            sortColumn: col?.value,
            project_uuids: leadTopFilters.length > 0 ? leadTopFilters?.map(i => i.uuid) : []
        }
        const res = await getStatsForSvTracker(payload)
        if (res.data.status === 200) {
            const stats = res.data.data
            setTabRowCount({
                Scheduled: stats?.scheduled,
                Completed: stats?.completed,
                Cancelled: stats?.cancelled,
                Dead: stats?.dead
            })

            if (selectedTab.name === 'Scheduled') {
                const totalPages = Math.ceil(
                    Number(stats?.scheduled) / showingResults
                );
                setMaxPage(totalPages);
                setLeadsCount(stats?.scheduled);
                localStorage.setItem('leadCount', stats?.scheduled);
                svScheduleTabList.forEach(item => {
                    item.count = stats?.subTab[item.value]
                })
            }
            if (selectedTab?.name === 'Completed') {
                const totalPages = Math.ceil(
                    Number(stats?.completed) / showingResults
                );
                setMaxPage(totalPages);
                setLeadsCount(stats?.completed);
                localStorage.setItem('leadCount', stats?.completed);
                svTabList.forEach(item => {
                    item.count = stats?.subTab[item.value]
                })
            }
            if (selectedTab?.name === 'Dead') {
                const totalPages = Math.ceil(
                    Number(stats?.dead) / showingResults
                );
                setMaxPage(totalPages);
                setLeadsCount(stats?.dead);
                localStorage.setItem('leadCount', stats?.dead);
                deadTabList.forEach(item => {
                    item.count = stats?.subTab[item.value]
                })
            }
            if (selectedTab?.name === 'Cancelled') {
                localStorage.setItem('leadCount', stats?.cancelled);
            }
            if (selectedInternalTab) {
                setLeadsCount(selectedInternalTab?.count);
            }
        }
    }

    const getProjects = async () => {
        const res = await getProjectsForFilter();
        if (res.data.status === 200) {
            setProjectList(res.data.data);
        }
    }

    const exportEvents = async () => {
        const payload = {
            pageNumber: currentPage,
            perPage: showingResults,
            searchText: searchText,
            tab: selectedTab?.value,
            subTab: selectedInternalTab?.value,
            scheduledBy: scheduledBy.value,
            eventStatus: eventStatus,
            filters: ConvertFitersArrayToObject(filters),
            sortDirection: sort,
            sortColumn: col?.value || '',
            project_uuids: leadTopFilters.length > 0 ? leadTopFilters?.map((i => i.uuid)) : [],
            export: true
        }
        try {
            // const res = await getAllBrokersSV(selectedProject?.uuid || '', payload);
            setExportLoading(true)
            const res = await getSvForSVTracker(payload);
            setExportLoading(false);
            if (res.data.status === 200) {
                const link = document.createElement("a");
                link.href = res.data.data.url;
                link.download = `dashboard_exported`;
                link.click();
                setShowExportLeadModal(false);
            }
        } catch (err) {
            if (err.response.data.message === 'No site visits found' || err.response.data.status === 404) {
                setEvents([]);
                setIsLoading(false)
                // setTabRowCount({})
            }
        }
    }

    const exportDeadEvents = async () => {
        const payload = {
            pageNumber: currentPage,
            perPage: showingResults,
            searchText: searchText,
            subTab: selectedInternalTab?.value,
            scheduledBy: scheduledBy,
            eventStatus: eventStatus,
            filters: ConvertFitersArrayToObject(filters),
            sortDirection: sort,
            sortColumn: col?.value || '',
            project_uuids: leadTopFilters.length > 0 ? leadTopFilters?.map((i => i.uuid)) : [],
            export: true
        }
        try {
            // const res = await getAllBrokersSV(selectedProject?.uuid || '', payload);
            setExportLoading(true);
            const res = await getDeadEventsAfterSv(payload);
            setExportLoading(false);
            if (res.data.status === 200) {
                const link = document.createElement("a");
                link.href = res.data.data.url;
                link.download = `dashboard_exported`;
                link.click();
                setShowExportLeadModal(false);
            }
        } catch (err) {
            if (err.response.data.message === 'No site visits found' || err.response.data.status === 404) {
                setEvents([]);
                setIsLoading(false)
                // setTabRowCount({})
            }
        }
    }

    const getEvents = async () => {
        if (filters.length > 0) {
            localStorage.setItem('sv_filters', JSON.stringify(filters));
        }
        if (col) {
            localStorage.setItem('sv_col', JSON.stringify(col))
            localStorage.setItem('sv_sort', sort)
        }
        if (searchText) {
            localStorage.setItem('sv_search', searchText)
        } else {
            localStorage.removeItem('sv_search')
        }

        setIsLoading(true)
        const payload = {
            pageNumber: currentPage,
            perPage: showingResults,
            searchText: searchText,
            tab: selectedTab?.value,
            subTab: selectedInternalTab?.value,
            scheduledBy: scheduledBy.value,
            eventStatus: eventStatus,
            filters: ConvertFitersArrayToObject(filters),
            sortDirection: sort,
            sortColumn: col?.value || '',
            project_uuids: leadTopFilters.length > 0 ? leadTopFilters?.map((i => i.uuid)) : []
        }
        try {
            // const res = await getAllBrokersSV(selectedProject?.uuid || '', payload);
            const res = await getSvForSVTracker(payload);

            if (res.data.status === 200) {
                setIsLoading(false)
                setRefreshTime(Date.now())
                setEvents(res.data.data);
            }
        } catch (err) {
            if (err.response.data.message === 'No site visits found' || err.response.data.status === 404) {
                setEvents([]);
                setIsLoading(false)
                // setTabRowCount({})
            }
        }
    }

    const getDeadEvents = async () => {
        if (filters.length > 0) {
            localStorage.setItem('sv_filters', JSON.stringify(filters));
        }
        if (col) {
            localStorage.setItem('sv_col', JSON.stringify(col))
            localStorage.setItem('sv_sort', sort)
        }
        if (searchText) {
            localStorage.setItem('sv_search', searchText)
        } else {
            localStorage.removeItem('sv_search')
        }
        setIsLoading(true)
        const payload = {
            pageNumber: currentPage,
            perPage: showingResults,
            searchText: searchText,
            subTab: selectedInternalTab?.value,
            scheduledBy: scheduledBy,
            eventStatus: eventStatus,
            filters: ConvertFitersArrayToObject(filters),
            sortDirection: sort,
            sortColumn: col,
            project_uuids: leadTopFilters.length > 0 ? leadTopFilters?.map((i => i.uuid)) : []
        }
        try {
            // const res = await getAllBrokersSV(selectedProject?.uuid || '', payload);
            const res = await getDeadEventsAfterSv(payload);

            if (res.data.status === 200) {
                setIsLoading(false)
                setRefreshTime(Date.now())
                setEvents(res.data.data);
            }
        } catch (err) {
            if (err.response.data.message === 'No site visits found' || err.response.data.status === 404) {
                setEvents([]);
                setIsLoading(false)
                // setTabRowCount({})
            }
        }
    }

    const getColumns = async () => {
        const res = await getColumnPreferenceForSvTracker();
        if (res.data.status === 200) {
            let dashboardColumns = []
            res.data.data.forEach(items => {
                let columnsList = [];
                let selectedColumn = [];

                items.columns.forEach(column => {
                    if (column.is_selected) {
                        selectedColumn.push(column);
                    }

                    if (!column.is_selected) {
                        columnsList.push(column);
                    }
                })

                // adding column according to tab with sorting
                dashboardColumns.push({
                    tab: items.tab,
                    columns: {
                        selected: selectedColumn.sort((a, b) => a.placement - b.placement),
                        notSelected: columnsList
                    }
                })

                // data for table columns
                if (items.tab === 'Scheduled') {
                    setScheduleColumn(selectedColumn)
                }
                if (items.tab === 'Completed') {
                    setCompletedColumn(selectedColumn)
                }
                if (items.tab === 'Cancelled') {
                    setCancelledColumn(selectedColumn)
                }
            })
            setDashboardColumns(dashboardColumns)
        }
    }

    const handleSortChange = e => {
        if (col && Object.keys(col).length > 0) {
            if (e == "asc") {
                setBtnAsc(true);
                setBtnDes(false);
            } else {
                setBtnAsc(false);
                setBtnDes(true);
            }
            // localStorage.setItem('sort', e);
            setSort(e);
            // sortToaster();
            // setCurrentPage(1)
        }
    }

    const handleSortColChange = (e) => {
        // const value = e.value === 'Marked Date' ? 'dead_lead_date' : e.value
        setCol(e)
        // localStorage.setItem('col', value)
        // localStorage.removeItem('currentPage')
        // sortToaster();
        // setCurrentPage(1);
        if (!sort) {
            // localStorage.setItem('sort', 'asc')
            setSort("asc");
            setBtnAsc(true);
        }
    }

    /**
    * Updating re-arranged list items
    */
    const updateColumnList = (tab, list) => {
        let columnClone = [...dashboardColumns];
        const index = columnClone.findIndex(item => item.tab === tab)
        const itemColumn = columnClone[index].columns
        columnClone[index].columns = { ...itemColumn, selected: list }
        setDashboardColumns([...columnClone]);
    };

    const handleChangeSelection = (tab, selected, notSelected) => {
        let columnClone = [...dashboardColumns];
        const index = columnClone.findIndex(item => item.tab === tab)
        columnClone[index].columns = { selected, notSelected }
        setDashboardColumns([...columnClone]);
    };

    const saveCurrentUserColumnPrefrence = () => {
        const preference = dashboardColumns.map(items => {
            items.columns.selected.forEach((item, index) => {
                item.placement = index + 1;
            })
            return {
                tab: items.tab,
                columns: [...items.columns.selected, ...items.columns.notSelected]
            }
        })
        saveColumnPreferenceForSvTracker({ preference }).then(res => {
            if (res.data.status === 200) {
                toast.success(res.data.message);
                getColumns();
                setShowCustomisedColumnModal(false);
                // getLeadsData();
            }
        }).catch(err => {
            console.log({ err })
        })
    }

    const handleCheckLeadExist = async (lead, checkFrom, assignTo) => {
        try {
            setAssignDisable(true)
            const res = await leadCheckforBroker(lead.uuid || lead?.site_visit_uuid)
            if (res.data.status === 200) {
                setSelectedLead(lead)
                setLeadExistResponse(res.data.data);
                setShowLeadExistModal(true);
                setAssignDisable(false)
            }
        } catch (err) {
            if (err.response.data.status === 404) {
                setSelectedLead(lead)
                if (checkFrom === 'table') {
                    handleUpdateAssignedTO(lead.uuid || lead?.site_visit_uuid, assignTo)
                } else {
                    setShowCreateLeadModal(true)
                    setAssignDisable(false)
                }
            }
        }
    }

    const handleDeleteSV = async () => {
        const res = await deleteBrokerSV(selectedLead.uuid);
        if (res.data.status) {
            toast.success(res.data.message)
            setShowDeleteModal(false);
            getEvents();
        }
    }

    const handleUpdateAssignedTO = async (uuid, assignTo) => {
        const payload = {
            assigend_to: assignTo,
            followupScheduleDate: moment().add('days', 1),
            intentId: intentStatuses.find(i => i.label === 'Hot Lead').value,
            tagging_validity: 30,
            tagging_validity_unit: 'days'
        }
        try {
            await insertLead(uuid, payload).then(res => {
                if (res.status === 200) {
                    toast.success(res.data.message);
                    getEvents()
                    setAssignDisable(false);
                }
            })
        } catch (err) {
            toast.error(err.response.data.message);
            setAssignDisable(false)
        }
    }

    const getFbFormData = async () => {
        const res = await getUniqueFacebookForms();
        if (res.data.status === 200) {
            const list = res.data.data.map((item) => ({
                ...item,
                label: item.form_name,
                value: item.form_id,
            }));
            setFbFormList(list);
        }
    };

    useEffect(() => {
        getFbFormData()
        if (tab) {
            const seleted = tabList.find(i => i.name === tab)
            setSelectedTab(seleted);
        }
        const load = async () => {
            const res = await getCustomerEnumsCall();
            setPropertyEnums(res.data.data);
        };
        load();

        document.addEventListener('mousedown', handleMouseClick)
        return (() => document.removeEventListener('mousedown', handleMouseClick))
    }, []);

    useEffect(() => {
        setShowingResults(100);
        setCurrentPage(1);
        if (selectedTab?.name === tab && subTab) {
            if (tab == 'Scheduled') {
                const seleted = svScheduleTabList.find(i => i.name == subTab);
                setSelectedInternalTab(seleted);
            }
            if (tab == 'Completed') {
                const seleted = svTabList.find(i => i.name == subTab)
                setSelectedInternalTab(seleted);
            }
            //   if (tab == 'Cancelled') {
            //     const seleted = InterTabList.find(i => i.name == subTab)
            //     setSelectedInternalTab(seleted);
            //   }
            if (tab == 'Dead') {
                const seleted = deadTabList.find(i => i.name == subTab)
                setSelectedInternalTab(seleted);
            }

        } else {
            if (selectedTab?.name == 'Scheduled') {
                setSelectedInternalTab(svScheduleTabList[0]);
            }
            if (selectedTab?.name == 'Completed') {
                setSelectedInternalTab(svTabList[0]);
            }
            //   if (selectedTab?.name == 'Cancelled') {
            //     setSelectedInternalTab(InterTabList[0]);
            //   }
            if (selectedTab?.name == 'Dead') {
                setSelectedInternalTab(deadTabList[0]);
            }
        }

        // Sorting options according to tab
        if (selectedTab?.name === 'Dead') {
            setSortingOptions(DEAD_TAB_SORTING_OPTION)
        }
        if (selectedTab?.name === 'Completed') {
            setSortingOptions([...OPTIONS, { value: "Site Visit Completed Date", label: "Completed Time" },])
        }
        if (selectedTab?.name === 'Cancelled' || selectedTab?.name === 'Scheduled') {
            setSortingOptions([...OPTIONS])
        }

        // For apply already saved filter search and  sort options
        if (localStorage.getItem('sv_filters') || localStorage.getItem('sv_col')) {
            setFilters(JSON.parse(localStorage.getItem('sv_filters')) || [])
            setCol(JSON.parse(localStorage.getItem('sv_col')))
            setSort(localStorage.getItem('sv_sort'))
            setBtnAsc(localStorage.getItem('sv_sort') == 'asc' ? true : false);
            setBtnDes(localStorage.getItem('sv_sort') == 'desc' ? true : false);
        }
        if (localStorage.getItem('sv_search')) {
            setSearchText(localStorage.getItem('sv_search'))
        }
    }, [selectedTab]);

    useEffect(() => {
        if (selectedInternalTab?.name === subTab) {
            localStorage.removeItem('tab');
            localStorage.removeItem('subTab');
        }
    }, [selectedInternalTab])

    useEffect(() => {
        if (Object.keys(companyConfig).length > 0) {
            setIntentStatuses(companyConfig.Intent);
            setStatuses(companyConfig?.Status)
            setSourceStatuses(companyConfig?.Source)
        }
    }, [companyConfig]);

    const getDeadReasonsStatus = () => {
        getDeadReasons().then(res => {
            if (res.status === 200) {
                const reasonsData = res.data.data.map((data) => {
                    return {
                        label: data.reason,
                        value: data.uuid
                    }
                })
                setReasons(reasonsData);
            }
        })
    }

    useEffect(() => {
        getProjects();
        // getAllProjects();
        getColumns();
        getAgencyData();
        getDeadReasonsStatus();
    }, []);

    // const handleSelectProject = () => {
    //     const projectId = JSON.parse(localStorage.getItem('svProject'))
    //     const selectedProj = projects.find(i => i.value === projectId)
    //     setSelectedProject(selectedProj)
    // }
    // useEffect(() => {
    //     if (localStorage.getItem('svProject') && projects.length > 0) {
    //         handleSelectProject();
    //     }
    // }, [projects]);

    const handleRefresh = () => {
        if (selectedTab.name === 'Dead') {
            getDeadEvents();
            getStats();
        } else {
            getEvents();
            getStats();
        }
    }

    useEffect(() => {
        handleRefresh();
    }, [
        selectedTab,
        showingResults,
        currentPage,
        searchText,
        filters,
        selectedInternalTab,
        leadTopFilters,
        eventStatus,
        scheduledBy,
        col,
        sort
    ]);

    useEffect(() => {
        if (projectList.length > 0) {
            getAllUsers();
        }
    }, [projectList, leadTopFilters])

    // const removeFilterValue = (keyValue) => {
    //     let oldFilters = structuredClone(filters);
    //     delete oldFilters[keyValue]
    //     setFilters({ ...oldFilters });
    // }
    const removeFilterValue = (keyValue) => {
        let oldFilters = filters;
        let newFilters = []
        for (const filterData of oldFilters) {
            // Check filter values and select element at pos 0 to compare with selected key
            if (!keyValue.includes(Object.keys(filterData)[0])) {
                newFilters.push(filterData);
            }
        }
        if (newFilters.length > 0) {
            localStorage.setItem('sv_filters', JSON.stringify(newFilters))
        } else {
            console.log('removed')
            localStorage.removeItem('sv_filters')
        }
        setFilters(newFilters);
    }

    return (
        <div className="main-section show-new-leads h-100 flex flex-col !pl-0 !pb-0 !pr-0">
            <div className="!px-5 !pt-5 !border-b !border-grayLight">
                <div className="d-flex flex-md-fill justify-between flex-wrap !mb-4">
                    <div className="d-flex flex-md-fill flex-col xl:items-center  xl:!flex-row">
                        <div className='flex items-center flex-col md:flex-row'>
                            <h1 className="text-2xl mb-0 black fw-po-medium !mr-2">Site Visit Tracker</h1>
                            <button className="!border !p-1 rounded-lg hover:bg-grey-100" onClick={handleRefresh}>
                                <RefreshIcon />
                            </button>
                        </div>
                    </div>
                    <div className=" d-flex justify-content-end items-center flex-md-fill space-x-4">
                        <ProjectFilter
                            handleFilter={setLeadTopFilters}
                            leadTopFilters={leadTopFilters}
                            cornerRadious={true}
                        />
                        {/* <div className='w-[200px] '>                        
                            <InputSelect
                                value={selectedProject}
                                onChange={(val) => {
                                    setSelectedProject(val)
                                    localStorage.setItem('svProject', JSON.stringify(val.value))
                                }}
                                options={projects}
                                placeholder='Select Project'
                                inputclass='m-0'
                            />
                        </div> */}
                        {/* Presale */}
                        <PresaleDropdown scheduledBy={scheduledBy} setScheduleBy={setScheduleBy} listFor={'sv_tracker'} />
                        <SearchBox searchText={searchText} setSearchText={setSearchText} placeholder={'Search User'} />
                        <div className="d-flex flex-row align-items-center !ml-2">
                            <div className="" ref={sortRef}>
                                <Dropdown align="end" className={"short-btn"} show={showSort}>
                                    <Dropdown.Toggle variant="short" id="dropdown-autoclose-true" className={`${col && Object.keys(col).length > 0 ? "active" : " "} d-flex`} onClick={() => { setShowSort(true) }}>
                                        <svg className="mr-8" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M4 6.00012H13" stroke="#44444F" strokeWidth="1.73082" strokeLinecap="round" strokeLinejoin="round"></path><path d="M4 12.0001H11" stroke="#44444F" strokeWidth="1.73082" strokeLinecap="round" strokeLinejoin="round"></path><path d="M4 18.0001H11" stroke="#44444F" strokeWidth="1.73082" strokeLinecap="round" strokeLinejoin="round"></path><path d="M15 15.0001L18 18.0001L21 15.0001" stroke="#44444F" strokeWidth="1.73082" strokeLinecap="round" strokeLinejoin="round"></path><path d="M18 6.00012V18.0001" stroke="#696974" strokeWidth="1.73082" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                        <span className="text-capitalize">Sort</span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <div className="filter-heading d-flex align-items-center justify-content-between mb-12">
                                            <h3 className="fz14 black mb-0"> Sort by</h3>
                                            <button type="button" onClick={() => setShowSort(!showSort)} className="close float-right" aria-label="Close">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.94131 5.00019L9.45935 8.52142C9.71958 8.78188 9.71939 9.20399 9.45892 9.46423C9.19845 9.72446 8.77634 9.72427 8.51611 9.4638L4.9978 5.9423L1.47122 9.46365C1.21067 9.7238 0.788564 9.72349 0.528408 9.46295C0.268252 9.2024 0.268566 8.78029 0.529109 8.52014L4.05542 4.99907L0.53071 1.47117C0.270478 1.2107 0.270669 0.788594 0.531136 0.528362C0.791603 0.26813 1.21371 0.268321 1.47395 0.528788L4.99893 4.05696L8.51612 0.544989C8.77666 0.284833 9.19877 0.285147 9.45893 0.54569C9.71909 0.806233 9.71877 1.22834 9.45823 1.4885L5.94131 5.00019Z" fill="#000">
                                                    </path>
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="form-group">
                                            <StatusDropDownDynamic
                                                value={col}
                                                onChange={handleSortColChange}
                                                options={sortingOptions}
                                                placeholder='Select Column'
                                            />
                                        </div>
                                        {
                                            col ?
                                                <>
                                                    <div className="mt-12 a-z-short">
                                                        <button className={`${btnAsc ? "active" : ""}`} onClick={() => handleSortChange("asc")} >
                                                            Ascending
                                                        </button>
                                                        <button className={`${btnDes ? "active" : ""}`} onClick={() => handleSortChange("desc")} >
                                                            Descending
                                                        </button>
                                                    </div>
                                                    <div className="remove mt-22">
                                                        <a onClick={() => {
                                                            setCol("")
                                                            setSort("")
                                                            setBtnAsc(false);
                                                            setBtnDes(false);
                                                            localStorage.removeItem('sv_sort');
                                                            localStorage.removeItem('sv_col');
                                                            toast.success('Sort removed')
                                                        }} className="d-flex align-items-center fz14  ml-auto float-right justify-content-end black-dark-700">
                                                            <svg className="mr-8" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M5.10904 3.13331L5.55709 1.78916C5.64783 1.51693 5.90259 1.33331 6.18954 1.33331H9.78954C10.0765 1.33331 10.3313 1.51693 10.422 1.78916L10.87 3.13331H13.9895C14.3577 3.13331 14.6562 3.43179 14.6562 3.79998C14.6562 4.16817 14.3577 4.46665 13.9895 4.46665H13.4181L12.9289 12.7841C12.8667 13.8412 11.9913 14.6666 10.9323 14.6666H5.04675C3.9878 14.6666 3.11238 13.8412 3.0502 12.7841L2.56094 4.46665H2.00016C1.63197 4.46665 1.3335 4.16817 1.3335 3.79998C1.3335 3.43179 1.63197 3.13331 2.00016 3.13331H5.10904ZM6.51449 3.13331H9.46459L9.30904 2.66665H6.67005L6.51449 3.13331ZM12.0825 4.46665H3.89658L4.38123 12.7058C4.40196 13.0582 4.69377 13.3333 5.04675 13.3333H10.9323C11.2853 13.3333 11.5771 13.0582 11.5979 12.7058L12.0825 4.46665ZM9.12417 6.15839C9.14714 5.79092 9.46366 5.51164 9.83113 5.53461C10.1986 5.55758 10.4779 5.87409 10.4549 6.24157L10.1549 11.0416C10.1319 11.409 9.81543 11.6883 9.44796 11.6653C9.08048 11.6424 8.80121 11.3259 8.82417 10.9584L9.12417 6.15839ZM7.15491 10.9584C7.17788 11.3259 6.8986 11.6424 6.53113 11.6653C6.16366 11.6883 5.84714 11.409 5.82417 11.0416L5.52417 6.24157C5.50121 5.87409 5.78048 5.55758 6.14796 5.53461C6.51543 5.51164 6.83194 5.79092 6.85491 6.15839L7.15491 10.9584Z" fill="#696974" />
                                                            </svg> Clear Sort
                                                        </a>
                                                    </div>
                                                </>
                                                : " "
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="filter-btn">
                            <button className={`d-flex align-items-center ${Object.keys(filters)?.length > 0 ? " border-0 pr-hover  active" : "border-0 btn"}`} type="button" onClick={() => setShowFilter(true)}>
                                <svg className="mr-8" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12.981 11.1747V15.5775C12.981 15.9053 12.7958 16.2049 12.5026 16.3515L9.04095 18.0823C8.46554 18.37 7.78852 17.9516 7.78852 17.3083V11.1747L1.92626 4.00973C1.46395 3.44468 1.86597 2.59631 2.59606 2.59631H18.1734C18.9035 2.59631 19.3056 3.44468 18.8432 4.00973L12.981 11.1747ZM11.2502 15.0426V10.8658C11.2502 10.666 11.3193 10.4724 11.4458 10.3178L16.3472 4.32713H4.42228L9.32372 10.3178C9.45022 10.4724 9.51934 10.666 9.51934 10.8658V15.908L11.2502 15.0426Z" fill="#696974"></path></svg>
                                <span className="text-capitalize">Filter {Object.keys(filters)?.length > 0 ? ` (${Object.keys(filters)?.length})` : " "}</span>
                            </button>
                        </div>
                        <button
                            className="!bg-primary text-white !pl-2 !pr-3 !py-1 !rounded-lg flex items-center font-medium text-sm"
                            onClick={() => setShowNewSiteVisitModal(true)} >
                            <PluswhiteIcon /> New Site Visit
                        </button>
                    </div>
                </div>
                <div className='flex w-100 justify-between'>
                    <Tabs
                        tabList={tabList}
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        tabName={selectedTab}
                        tabRowCount={tabRowCount}
                    />
                    {/* <p className="mb-0 font-italic black !ml-4">
                        Last Updated: {moment(refreshTime).format('h :mm a, Do MMM YY')}
                        <span className="text-underline cursor-pointer pr-hover ml-1" onClick={() => {
                            getEvents();
                        }}>
                            refresh
                        </span>
                    </p> */}

                </div>
            </div>
            <div className="flex items-center justify-between !pl-7 !pr-4 !pt-5">
                {InnerTabList.includes(selectedTab?.name) &&
                    <div className='flex items-center justify-between w-100'>
                        <div className='max-w-100'>
                            {selectedTab.name === 'Scheduled' &&
                                <div className="">
                                    <InternalTabs
                                        tabList={svScheduleTabList}
                                        selectedTab={selectedInternalTab}
                                        setSelectedTab={setSelectedInternalTab}
                                        tabName={selectedInternalTab}
                                        tabRowCount={internalTabRowCount}
                                    />
                                </div>
                            }
                            {selectedTab.name === 'Completed' &&
                                <div className="">
                                    <InternalTabs
                                        tabList={svTabList}
                                        selectedTab={selectedInternalTab}
                                        setSelectedTab={setSelectedInternalTab}
                                        tabName={selectedInternalTab}
                                        tabRowCount={internalTabRowCount}
                                    />
                                </div>
                            }
                            {selectedTab.name === 'Dead' &&
                                <div className="">
                                    <InternalTabs
                                        tabList={deadTabList}
                                        selectedTab={selectedInternalTab}
                                        setSelectedTab={setSelectedInternalTab}
                                        tabName={selectedInternalTab}
                                        tabRowCount={internalTabRowCount}
                                    />
                                </div>
                            }
                        </div>

                        <div className="flex items-center !gap-4 !mb-5">
                            {/* Button Group */}
                            {(selectedTab.name === 'Scheduled' || selectedTab.name === 'Completed') && <div className="flex border border-gray-300 rounded-lg w-max">
                                <TooltipText title='All Event'>

                                    <button
                                        onClick={() => setEventStatus("all")}
                                        className={`px-4 py-2 text-sm  ${eventStatus === "all"
                                            ? "text-blue-600 bg-blue-50 font-semibold"
                                            : "text-gray-700 hover:bg-gray-100"
                                            }`}
                                    >
                                        All Events
                                    </button>
                                </TooltipText>
                                <TooltipText title='Current Event'>
                                    <button
                                        onClick={() => setEventStatus("current")}
                                        className={`flex gap-2 px-4 py-2 text-sm ${eventStatus === "current"
                                            ? "text-blue-600 bg-blue-50 font-semibold"
                                            : "text-gray-700 hover:bg-gray-100"
                                            }`}
                                    >
                                        By Current Active
                                    </button>
                                </TooltipText>
                            </div>}
                            {/* Export Button */}
                            <button
                                className="flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-100"
                                onClick={() => setShowExportLeadModal(true)}
                            >
                                <ExportIcon className='mr-2' />
                                Export
                            </button>
                        </div>
                    </div>}
            </div>
            {(filters.length > 0 || showExportLeadModal) && <div className='flex items-center !px-5 w-[30%] !mb-5'>
                {/* <div className="border-r-2 !h-8 !mr-2"></div> */}
                {/* <FiltersTag filters={filters} removeFilterValue={removeFilterValue} /> */}
                <FiltersTag
                    filters={filters}
                    removeFilterValue={removeFilterValue}
                    reasons={reasons}
                    agencyList={agencyList}
                    propertyEnums={propertyEnums}
                    // csvList={csvList}
                    // project={project}
                    intentStatuses={intentStatuses}
                    fbFormList={fbFormList}
                    sourceStatuses={sourceStatuses}
                    statuses={statuses}
                    allUsers={allUsers}
                    showExportLeadModal={showExportLeadModal}
                    setShowExportLeadModal={setShowExportLeadModal}
                    exportOpportunities={() => {
                        if (selectedTab?.name === 'Dead') {
                            exportDeadEvents();
                        } else {
                            exportEvents();
                        }
                    }}
                    exportLoading={exportLoading}
                    leadsCount={leadsCount}
                    selectedTab={selectedTab}
                />
            </div>}
            {/* {selectedTab.name === 'Completed' && Object.keys(filters).length > 0 && <div className="border-r-2 !h-8"></div>} */}

            <div className='broker-page !px-5 h-100'>
                {selectedTab?.name === 'Scheduled' &&
                    <SiteVisitLeads
                        leads={events}
                        getEvents={getEvents}
                        searchText={searchText}
                        setShowLightModal={setShowLightModal}
                        setSelectedImg={setSelectedImg}
                        setShowDeleteModal={setShowDeleteModal}
                        setSelectedLead={setSelectedLead}
                        isLoading={isLoading}
                        selectedLead={selectedLead}
                        scheduleColumn={scheduleColumn}
                        updateColumnList={updateColumnList}
                        handleChangeSelection={handleChangeSelection}
                        saveCurrentUserColumnPrefrence={saveCurrentUserColumnPrefrence}
                        dashboardColumns={dashboardColumns}
                        setShowCustomisedColumnModal={setShowCustomisedColumnModal}
                        showCustomisedColumnModal={showCustomisedColumnModal}
                        redirectURL={'presale/opportunity-details'}
                        redirectBactTo={'site-visit-tracker'}
                        statuses={statuses}
                        intentStatuses={intentStatuses}
                        onSuccess={handleRefresh}
                        agencyList={agencyList}
                        sourceStatuses={sourceStatuses}
                        selectedInternalTab={selectedInternalTab}
                        selectedTab={selectedTab}
                        allUsers={allUsers}
                        getStats={getStats}
                        col={col}
                        sort={sort}
                        filters={filters}
                        setShowQualificationModal={setShowQualificationModal}
                    />}
                {selectedTab?.name === 'Completed' && <SiteVisitCompletedLeads
                    leads={events}
                    setSelectedLead={setSelectedLead}
                    setShowCreateLeadModal={setShowCreateLeadModal}
                    getEvents={getEvents}
                    handleCheckLeadExist={handleCheckLeadExist}
                    searchText={searchText}
                    allUsers={allUsers}
                    setEvents={setEvents}
                    handleUpdateAssignedTO={handleUpdateAssignedTO}
                    setShowLightModal={setShowLightModal}
                    setSelectedImg={setSelectedImg}
                    setShowDeleteModal={setShowDeleteModal}
                    isLoading={isLoading}
                    tabRowCount={internalTabRowCount}
                    selectedTab={selectedTab}
                    setSelectedInternalTab={setSelectedInternalTab}
                    setTabRowCount={setInternalTabRowCount}
                    tabList={svTabList}
                    completedColumn={completedColumn}
                    updateColumnList={updateColumnList}
                    handleChangeSelection={handleChangeSelection}
                    saveCurrentUserColumnPrefrence={saveCurrentUserColumnPrefrence}
                    dashboardColumns={dashboardColumns}
                    setShowCustomisedColumnModal={setShowCustomisedColumnModal}
                    showCustomisedColumnModal={showCustomisedColumnModal}
                    assignDisable={assignDisable}
                    redirectURL={'presale/opportunity-details'}
                    redirectBactTo={'site-visit-tracker'}
                    statuses={statuses}
                    intentStatuses={intentStatuses}
                    onSuccess={handleRefresh}
                    agencyList={agencyList}
                    sourceStatuses={sourceStatuses}
                    selectedInternalTab={selectedInternalTab}
                    getStats={getStats}
                    col={col}
                    sort={sort}
                    filters={filters}
                    setShowQualificationModal={setShowQualificationModal}
                />}
                {/* {selectedTab?.name === 'Attempts' && <SitiVisitAttempts leads={events} />} */}
                {selectedTab?.name === 'Cancelled' && <SiteVisitCancelled
                    leads={events}
                    searchText={searchText}
                    setShowLightModal={setShowLightModal}
                    setSelectedImg={setSelectedImg}
                    setSelectedLead={setSelectedLead}
                    setShowDeleteModal={setShowDeleteModal}
                    isLoading={isLoading}
                    cancelledColumn={cancelledColumn}
                    updateColumnList={updateColumnList}
                    handleChangeSelection={handleChangeSelection}
                    saveCurrentUserColumnPrefrence={saveCurrentUserColumnPrefrence}
                    dashboardColumns={dashboardColumns}
                    setShowCustomisedColumnModal={setShowCustomisedColumnModal}
                    showCustomisedColumnModal={showCustomisedColumnModal}
                    redirectURL={'presale/opportunity-details'}
                    redirectBactTo={'site-visit-tracker'}
                    statuses={statuses}
                    intentStatuses={intentStatuses}
                    onSuccess={handleRefresh}
                    agencyList={agencyList}
                    sourceStatuses={sourceStatuses}
                    selectedInternalTab={selectedInternalTab}
                    allUsers={allUsers}
                    selectedTab={selectedTab}
                    col={col}
                    sort={sort}
                    filters={filters}
                    setShowQualificationModal={setShowQualificationModal}
                />}
                {
                    selectedTab.name === 'Dead' && <DeadLeads
                        leads={events}
                        searchText={searchText}
                        setShowLightModal={setShowLightModal}
                        setSelectedImg={setSelectedImg}
                        setSelectedLead={setSelectedLead}
                        setShowDeleteModal={setShowDeleteModal}
                        isLoading={isLoading}
                        cancelledColumn={cancelledColumn}
                        updateColumnList={updateColumnList}
                        handleChangeSelection={handleChangeSelection}
                        saveCurrentUserColumnPrefrence={saveCurrentUserColumnPrefrence}
                        dashboardColumns={dashboardColumns}
                        setShowCustomisedColumnModal={setShowCustomisedColumnModal}
                        showCustomisedColumnModal={showCustomisedColumnModal}
                        redirectURL={'presale/opportunity-details'}
                        redirectBactTo={'site-visit-tracker'}
                        statuses={statuses}
                        intentStatuses={intentStatuses}
                        onSuccess={handleRefresh}
                        agencyList={agencyList}
                        sourceStatuses={sourceStatuses}
                        selectedInternalTab={selectedInternalTab}
                        selectedTab={selectedTab}
                        col={col}
                        sort={sort}
                        filters={filters}
                        setShowQualificationModal={setShowQualificationModal}
                    />
                }
                <LeadsFooterBar
                    showingResults={showingResults}
                    setShowingResults={setShowingResults}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    maxPage={maxPage}
                    leadsCount={leadsCount}
                    classname='!basis-0 !z-30'
                />
            </div>

            {showCreateLeadModal && <CreateLeadModal
                show={showCreateLeadModal}
                setShow={setShowCreateLeadModal}
                selectedLead={selectedLead}
                getEvents={getEvents}
                allUsers={allUsers}
            />}
            {
                showLeadExistModal && <LeadExistModal
                    show={showLeadExistModal}
                    closeModal={() => setShowLeadExistModal(false)}
                    selectedLead={selectedLead}
                    leadExistResponse={leadExistResponse}
                />
            }
            {/* {
                showFilter && <BrokerLeadsFilter
                    show={showFilter}
                    setShow={setShowFilter}
                    filtersData={filters}
                    setFiltersData={setFilters}
                    projects={projects}
                />
            } */}
            {
                showFilter && <SVTrackerFiltersModal
                    show={showFilter}
                    setShow={setShowFilter}
                    handleFilter={setFilters}
                    setFilterCount={() => { }}
                    filtersData={filters}
                    leadType={""}
                    reAssignRole={""}
                    setCurrentPage={() => { }}
                    filterToaster={() => { }}
                    propertyEnums={propertyEnums}
                    isRolesModule={isRolesModule}
                    allowedPermissions={allowedPermissions}
                    fbFormList={fbFormList}
                />
            }
            <div className='broker-image'>
                {showLightModal && <Lightbox
                    styles={{ navigationNext: { "yarl__navigation_next": "display: none !important" } }}
                    className={'broker-lightbox'}
                    open={showLightModal}
                    close={() => {
                        setShowLightModal(false)
                        setSelectedImg('')
                    }}
                    slides={[{ src: selectedImg.image, title: `${selectedImg.customer_name} - ${selectedImg.customer_mobile}` }]}
                    plugins={[Captions]}
                // video={{
                //     controls: true,
                //     playsInline: true,
                //     autoPlay: false,
                //     loop: false,
                //     muted: true,
                //     disablePictureInPicture: false,
                //     disableRemotePlayback: false,
                //     controlsList: ["nodownload" | "nofullscreen" | "noremoteplayback"].join(" "),
                //     crossOrigin: '',
                //     preload: '',
                // }}
                />}
            </div>
            {
                showDeleteModal && selectedLead.uuid && <ConfirmDeletionCustom
                    show={showDeleteModal}
                    setShow={setShowDeleteModal}
                    deleteHandler={handleDeleteSV}
                    deleteText={selectedLead.customer_name}
                />
            }
            {
                showNewSiteVisitModal && <NewSitevisitModal
                    show={showNewSiteVisitModal}
                    setShow={setShowNewSiteVisitModal}
                    onSuccess={handleRefresh}
                    statusId={statuses.find(i => i.label === 'SV Scheduled').value}
                />
            }
            {
                showQualificationModal && <ChangeQualificationModal
                    show={showQualificationModal}
                    closeModal={() => {
                        setSelectedLead({});
                        setShowQualificationModal(false)
                    }}
                    selectedLead={{ ...selectedLead, id: selectedLead?.lead_uuid }}
                    getOpportunities={handleRefresh}
                />
            }
        </div>

    )
}

export default BorkerLeads