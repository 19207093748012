import { put, all, call, takeLatest, getContext } from "redux-saga/effects";
import { browserRedirect } from '../../helpers/helpers';
import AmplifySdk from "../../helpers/amplify";

import {
  LOGIN_REQUESTING,
  loginSuccess,
  LOGIN_FORGOT_REQUESTING,
  loginForgotPasswordSuccess,
  loginForgotPasswordError,
  loginError,
} from "./actions";

import AppContext from '../../helpers/context';
import { API_METHOD, API_URLS } from "../../config/apiUrl";
import { userLogin } from "../../services/public/public.service";
import { toast } from "react-toastify";

const Amplify = AmplifySdk();
//Login API call
function loginCall(payload) {

  return userLogin(payload.email, payload.password).then(res => {
    if (res.status === 200) {
      localStorage.setItem("authToken", res.data.data.token)
      localStorage.setItem('check_expiry', true)
    }
    else {
      toast.error(res.data.message)
    }
  }).catch(e => {
    if (e.response.status === 403) {
      localStorage.setItem('company_access', 'Not Allowed')
      window.location.reload();
    }
    toast.error(e.response.data.message)
  })
  // return Amplify.Auth.signIn({ username: payload.email, password: payload.password, attributes: { email: payload.email } })
}

function forgotCall(payload) {

  return Amplify.Auth.forgotPassword(payload.email)

}

// LOGIN Worker
function* loginWorker({ payload }) {
  try {

    let response = yield call(loginCall, payload);

    if (!localStorage.getItem("authToken")) yield put(loginError({ message: "User not authenticated" }));

    // if (!response.signInUserSession) 
    else {
      // yield put(loginSuccess())
      payload.setAuthorized(true);
      // yield call(browserRedirect, '/leads');
      window.location.reload();
    }

  } catch (err) {
    yield put(loginError({ ...err, message: err.message }));
  }
}



function* forgotPassword({ payload }) {
  try {

    let response = yield call(forgotCall, payload);

    yield put(loginForgotPasswordSuccess());
  } catch (err) {
    console.log('Message', err.message)
    yield put(loginForgotPasswordError({ ...err, message: err.message }));
  }

}

// Login Watcher
export default function* loginSaga() {
  yield all([
    takeLatest(LOGIN_REQUESTING, loginWorker),
    takeLatest(LOGIN_FORGOT_REQUESTING, forgotPassword)
  ]);
}