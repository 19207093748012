import React from "react";
import SliderModal from "./SliderModal";
import { toast } from "react-toastify";
import TooltipText from "../Tooltip";
import { getInitials } from "../../helpers/helpers";
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg'


export default function ReassignLeadsManageModal({
    setShow,
    show,
    setSelectedTableData,
    selectedTableData,
    setSelectedTableValues,
    selectedTableValues,
    title
}) {

    return (
        <SliderModal
            setShow={setShow}
            show={show}
            title={`${title ? title : 'Selected Leads'} (${selectedTableData.length})`}
            body={<>
                <div className="flex flex-col h-full overflow-hidden">
                    <div className='flex items-center !mb-6'>
                        {/* <button onClick={() => setShow(false)} className='mr-2'>
                            <BackIcon />
                        </button> */}
                        {/* <h3 className='text-lg font-semibold m-0'>Selected Leads ({selectedTableData.length})</h3> */}
                    </div>
                    <div className="h-full !pl-5 !pr-5 !pb-5 overflow-auto">
                        {
                            selectedTableData.map((item, index) => (
                                <div className='flex justify-between items-center border rounded-md p-2.5 mt-2' key={`${item.name}_${index}`}>
                                    <span className="mx-2 border p-1 font-bold flex items-center justify-center rounded-full text-2xl bg-blueHighlight text-primary w-11 h-11" title={item.name}>
                                        {getInitials(item.name || '')}
                                    </span>
                                    <div>
                                        <h6 className='m-0 truncate w-[220px]'>{item.name}</h6>

                                        <p className='m-0 text-grey200 truncate w-[220px] text-xs'>{item.mobile}</p>
                                        <p className='m-0 text-grey200 truncate w-[220px] text-xs'>Status: <span className="font-semibold">{item.status_name ? item.status_name : 'New Lead'}</span> </p>

                                    </div>
                                    <div>
                                        <button
                                            className=' text-red font-semibold text-xs rounded self-start px-2.5 p-1'
                                            onClick={() => {
                                                let selectedTableDataCopy = [...selectedTableData];
                                                selectedTableDataCopy.splice(index, 1);
                                                let selectedTableValuesCopy = [...selectedTableValues]
                                                let newIndex = selectedTableValuesCopy.findIndex(i => i === item.id)
                                                selectedTableValuesCopy.splice(newIndex, 1)
                                                setSelectedTableValues(selectedTableValuesCopy);
                                                setSelectedTableData(selectedTableDataCopy);
                                            }} >Remove</button>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className="!flex justify-content-between !py-4 !px-5">
                        <button
                            className="cancel outline-btn !mr-3 !py-2.5 !px-4 !w-[112px]"
                            onClick={() => setShow(false)}
                        >
                            Cancel
                        </button>
                        <button
                            className="pr-btn !py-2.5 !px-4 !w-[calc(100%-112px)]"
                            onClick={() => setShow(false)}
                        >
                            Confirm
                        </button>

                    </div>
                </div>
            </>}
        />
    )
}