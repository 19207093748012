import React, { useEffect, useRef, useState } from 'react'
import RightModal from '../../../components/Modals/RightModal';
import InputText from '../../../components/InputGroup/InputText';
import InputSelect from '../../../components/InputGroup/InputSelect';
import { Oval } from 'react-loader-spinner';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import DateTimePicker from '../../../components/InputGroup/DateTime';
import InputTextarea from '../../../components/InputGroup/InputTextarea';
import { scheduleMeet } from '../../New Dashboard/services/dashboard.service';
import { getCompaniesForAnalytics, getUsersForSettings } from '../../../services/private/company.service';
import MobileInput from '../../../components/InputGroup/MobileInput';
import { toast } from 'react-toastify';

const ScheduleCPVisitModal = ({ show, closeModal, allScheduleCPMeets }) => {
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('')
    const [cpFirm, setCpFirm] = useState('');
    const [scheduleDate, setScheduleDate] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [meetDate, setMeetDate] = useState('');
    const [commnets, setCommnets] = useState('');
    const [companyId, setCompanyId] = useState('');
    const [showList, setShowList] = useState(false);
    const [cpList, setCpList] = useState([]);
    const [sourcingManager, setSourcingManager] = useState('');
    const [usersList, setUsersList] = useState([]);

    const cpRef = useRef();

    const loadOptions = async (value) => {
        setCpFirm(value);
        const payload = {
            "pageNumber": 1,
            "perPage": 100,
            "searchText": value,
            filters: {},
        }
        const res = await getCompaniesForAnalytics(payload)
        if (res.data.status === 200) {
            setShowList(res.data.data?.companies.length > 0 ? true : false)
            setCpList(res.data.data?.companies)
        }
    }

    const getUsers = async () => {
        const res = await getUsersForSettings();
        if (res.data.status === 200) {
            setUsersList(res.data.data.map(i => ({ ...i, label: i.name, value: i.id })))
        }
    }

    useEffect(() => {
        getUsers();
    }, []);

    const handleSubmit = async () => {
        if (!cpFirm || !name || !mobile || !meetDate || !sourcingManager) {
            toast.error('Please Fill Required Fields')
            return;
        }

        setIsSubmitting(true)
        const payload = {
            borker_company_id: companyId,
            firm_name: cpFirm,
            owner_name: name,
            owner_mobile: mobile,
            remarks: commnets,
            scheduled_date: meetDate,
            sourcing_user_id: sourcingManager
        }
        try {
            const res = await scheduleMeet(payload);
            setIsSubmitting(false)
            if (res.data.status === 200) {
                toast.success(res.data.message);
                allScheduleCPMeets();
                closeModal();
            }
        } catch (err) {
            setIsSubmitting(false)
        }
    }

    return (
        <RightModal show={show} closeModal={closeModal} modalClass={`configurationModal`}>
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <div className='flex items-center'>
                    {/* <button onClick={closeModal}>
                <BackIcon />
            </button> */}
                    <h1 className='mb-0 black inter fz20 fw-po-medium ml-2'>
                        Schedule CP Meet
                    </h1>
                </div>
                <button className='border-0 bg-tr ' onClick={closeModal}><CloseIcon /></button>
            </div>

            <div className='flex flex-col h-full  overflow-hidden'>
                <div className='h-full overflow-auto !px-5 !space-y-4 relative'>
                    <InputText
                        value={cpFirm}
                        onChange={(e) => loadOptions(e.target.value)}
                        index={2}
                        inputclass={'!mt-4'}
                        type="text"
                        placeholder="Enter CP Firm Name"
                        label="CP Firm"
                        required={true}
                    />
                    {cpFirm && showList && <div ref={cpRef} className='absolute !top-48.5 !rounded-lg max-h-[200px] w-[350px] overflow-auto bg-white z-10 shadow !px-4'>
                        {
                            cpList?.map((item, index) => (
                                <div className='flex flex-col text-sm !border-b !py-2 cursor-pointer' key={`${item.uuid}_${index}`} onClick={() => {
                                    // setReferredMobile(item?.display_number)
                                    // setReferredName(item?.name)
                                    setCpFirm(item.name)
                                    setName(item.owner_name)
                                    setMobile(item?.owner_mobile)
                                    setCompanyId(item.id)
                                    setShowList(false)
                                }}>
                                    <span className='font-semibold text-black-t'>{item.name}</span>
                                    <span className='text-grey-500 text-xs'>{item.owner_name}</span>
                                    <span className='text-grey-500 text-xs'>{item.owner_mobile}</span>
                                </div>
                            ))
                        }
                    </div>}
                    <InputText
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        index={2}
                        inputclass={'!mt-4'}
                        type="text"
                        placeholder="Enter Name"
                        label="Name"
                        required={true}
                    />
                    <MobileInput
                        label={'Mobile'}
                        value={mobile}
                        // onChange={(e) => setMobile(e.phone)}
                        onChange={(e) => setMobile(e.phone)}
                        // handleBlur={() => handleCheckCustomer()}
                        required={true}
                        inputclass={'mt-0'}
                    />

                    {/* <InputText
                value={microMarket}
                onChange={(e) => setMicroMarket(e.target.value)}
                index={2}
                type="email"
                placeholder="Enter Micromarket"
                label="Micromarket"
            /> */}
                    <DateTimePicker
                        label={'Meet Date and Time'}
                        onValueChange={(e) => {
                            setMeetDate(e)
                        }}
                        name="meetDate"
                        handelBlur={() => { }}
                        value={meetDate}
                        error={''}
                        required={true}
                        completed={false}
                    />
                    <InputSelect
                        value={sourcingManager ? usersList.find(i => i.id === sourcingManager) : ''}
                        label={'Select Sourcing Manager'}
                        placeholder={'Select Sourcing Manager'}
                        options={usersList}
                        onChange={(e) => {
                            setSourcingManager(e.value)
                        }}
                        required={true}
                    // onBlur={() => setTouchedMentorId(true)}
                    />
                    <InputTextarea
                        label={'Comments (optional)'}
                        placeholder={'Add description'}
                        value={commnets}
                        onChange={(e) => setCommnets(e.target.value)}
                    />
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between !px-5 gap-x-4 !py-4 righmodalfooter shadow-topshadow">
                    <button onClick={closeModal}
                        className='border !rounded-lg py-2.5 w-28 basis-28 grow-0 shrink-0 border-primary !text-primary font-semibold text-16 text-center'>Cancel</button>
                    {!isSubmitting && <button onClick={() => {
                        handleSubmit()
                    }}
                        className='border !rounded-lg py-2.5 w-full ml-4 !bg-primary !border-primary text-white font-semibold text-16 text-center hover:opacity-90'>
                        Schedule Meet
                    </button>
                    }
                    {isSubmitting && <button className='border rounded-lg py-2.5 w-full ml-4 !bg-primary flex items-center justify-center  hover:opacity-90' type='button'>
                        <Oval
                            height={24}
                            width={24}
                            color="#ffffff"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#ffffff"
                            strokeWidth={4}
                            strokeWidthSecondary={4}
                        />
                    </button>}
                </div >
            </div>

        </RightModal>
    )
}

export default ScheduleCPVisitModal