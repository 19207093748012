import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Consumer from '../../../../helpers/context';
import { ReactComponent as WhatsappIcon } from '../../../../assets/icons/whatsapp-icon.svg'

const CompanyIntegrations = (props) => {
  const [showFb, setShowFb] = useState(false);
  const [showLandingPage, setShowLandingPage] = useState(false);
  const [showHousing, setShowHousing] = useState(false);
  const [showAcres, setShowAcres] = useState(false);
  const [showMagicbricks, setShowMagicbricks] = useState(false);

  let { allowedPermissions, isRolesModule } = useContext(Consumer);

  useEffect(() => {
    if (!isRolesModule) {
      setShowFb(true);
      setShowLandingPage(true);
      setShowHousing(true);
      setShowAcres(true);
      setShowMagicbricks(true);
    } else {
      if (allowedPermissions.includes('create_fb_integration')) {
        setShowFb(true);
      }
      if (allowedPermissions.includes('create_landingpage_integration')) {
        setShowLandingPage(true);
      }
      if (allowedPermissions.includes('create_housing_integration')) {
        setShowHousing(true);
      }
      if (allowedPermissions.includes('create_99acres_integration')) {
        setShowAcres(true);
      }
      if (allowedPermissions.includes('create_magicbricks_integration')) {
        setShowMagicbricks(true);
      }
    }
  }, [isRolesModule, allowedPermissions]);

  return (
    <div className="integrations w-100 h-100 flex flex-col">
      <div className="settings-title">
        <div className="row" >
          <div className="col-12">
            <h1 className="fz24 mb-16 black fw-po-medium">All Integrations</h1>
            <p className="fz16 black-dark-700 mb-0">
              Connect all your lead generation channels with Propacity Platform{" "}
            </p>
          </div>

        </div>
      </div>

      <div className="ml-24 h-full overflow-auto">
        <div className="box d-flex align-items-start pl4">
          <div className="row w-100">

            {/* Direct Facebook  Card*/}
            {showFb && <div
              className="col-xl-4 col-lg-6 col-md-6 col-sm-6 cursor-pointer"
              onClick={() =>
                props.history.push(
                  "/settings/integrations/all-integrations/facebook-forms"
                )
              }
            >
              <div className="card comming-soon relative !border !border-white hover:!border-primary hover:!border-2">
                <picture>
                  <img src={`${process.env.PUBLIC_URL}/facebook.png`} />
                </picture>
                <h2 className="fz14 black fw-po-medium mb-8">Facebook Lead Ads (Beta)</h2>
                <p className="fz14 black-dark-600 mb-0">Get leads from Facebook Ads</p>
                {/* <a className="outline-btn"
                  onClick={() =>
                    props.history.push(
                      "/settings/integrations/all-integrations/facebook-forms"
                    )
                  }
                >
                  Create/View
                </a> */}
              </div>

            </div>}

            {showLandingPage && <div
              className="col-xl-4 col-lg-6 col-md-6 col-sm-6 cursor-pointer"
              onClick={() =>
                props.history.push(
                  "/settings/integrations/all-integrations/landing-page"
                )
              }
            >
              <div className="card relative !border !border-white hover:!border-primary hover:!border-2">
                <picture>
                  <img src={`${process.env.PUBLIC_URL}/landing.svg`} />
                </picture>
                <h2 className="fz14 black fw-po-medium mb-8">Landing Pages </h2>
                <p className="fz14 black-dark-600 mb-0">
                  Get leads from landing pages (Google Ads, SEO & Website)
                </p>
                {/* <a className="outline-btn"
                  onClick={() =>
                    props.history.push(
                      "/settings/integrations/all-integrations/landing-page"
                    )
                  }
                >
                  Create/View
                </a> */}
              </div>
            </div>}
            {showHousing && <div
              className="col-xl-4 col-lg-6 col-md-6 col-sm-6 cursor-pointer"
              onClick={() =>
                props.history.push(
                  "/settings/integrations/all-integrations/housing"
                )
              }
            >
              <div className="card relative !border !border-white hover:!border-primary hover:!border-2">
                <picture>
                  <img src={`${process.env.PUBLIC_URL}/Housing.png`} />
                </picture>
                <h2 className="fz14 black fw-po-medium mb-8">Housing.com </h2>
                <p className="fz14 black-dark-600 mb-0">
                  Get leads from housing.com
                </p>
                {/* <a className="outline-btn"
                  onClick={() =>
                    props.history.push(
                      "/settings/integrations/all-integrations/housing"
                    )
                  }
                >
                  Create/View
                </a> */}
              </div>
            </div>}
            {showAcres && <div
              className="col-xl-4 col-lg-6 col-md-6 col-sm-6 cursor-pointer"
              onClick={() =>
                props.history.push(
                  "/settings/integrations/all-integrations/99-acres"
                )
              }
            >
              <div className="card relative !border !border-white hover:!border-primary hover:!border-2">
                <picture>
                  <img src={`${process.env.PUBLIC_URL}/99acre.png`} />
                </picture>
                <h2 className="fz14 black fw-po-medium mb-8">99 Acres </h2>
                <p className="fz14 black-dark-600 mb-0">
                  Get leads from 99 acres</p>
                {/* <a className="outline-btn"
                  onClick={() =>
                    props.history.push(
                      "/settings/integrations/all-integrations/99-acres"
                    )
                  }
                >
                  Create/View
                </a> */}
                {/* <div className="d-flex flex-row-reverse">
            <button
              className="outline-btn mt-4 "
              style={{ width: '115px' }}
              disabled
            >
              Coming soon
            </button>
          </div> */}
              </div>
            </div>}
            {showMagicbricks && <div
              className="col-xl-4 col-lg-6 col-md-6 col-sm-6 cursor-pointer"
              onClick={() =>
                props.history.push(
                  "/settings/integrations/all-integrations/magicbricks"
                )
              }
            >
              <div className="card relative !border !border-white hover:!border-primary hover:!border-2">
                <picture>
                  <img src={`${process.env.PUBLIC_URL}/magicbricks.png`} />
                </picture>
                <h2 className="fz14 black fw-po-medium mb-8">Magicbricks </h2>
                <p className="fz14 black-dark-600 mb-0">
                  Get leads from magicbricks </p>
                {/* <a className="outline-btn"
                  onClick={() =>
                    props.history.push(
                      "/settings/integrations/all-integrations/magicbricks"
                    )
                  }
                >
                  Create/View
                </a> */}
                {/* <div className="d-flex flex-row-reverse">
            <button
              className="outline-btn mt-4 "
              style={{ width: '115px' }}
              disabled
            >
              Coming soon
            </button>
          </div> */}
              </div>

            </div>}

            {/* My Operator */}
            <div
              className="col-xl-4 col-lg-6 col-md-6 col-sm-6 cursor-pointer"
              onClick={() =>
                props.history.push(
                  "/settings/integrations/all-integrations/ivr/myoperator"
                )
              }
            >
              <div className="card relative !border !border-white hover:!border-primary hover:!border-2">
                {/* <div className="absolute bg-blue100 text-primary text-xs font-medium inter leading-5 top-5 right-6 py-0.5 !px-2 rounded-2xl">Coming Soon</div> */}
                <picture className="!max-w-[112px]">
                  <img src={`${process.env.PUBLIC_URL}/myOperator.svg`} />
                </picture>
                <h2 className="fz14 black fw-po-medium mb-2.5">IVR Integration</h2>
                <p className="fz14 black-dark-600 mb-0">Get all your calls from IVR</p>
              </div>
            </div>
            {/* Knowlarity */}
            {allowedPermissions.includes('manage_ivr') && <div
              className="col-xl-4 col-lg-6 col-md-6 col-sm-6 cursor-pointer"
              onClick={() =>
                props.history.push(
                  "/settings/integrations/all-integrations/ivr/knowlarity"
                )
              }
            >
              <div className="card relative !border !border-white hover:!border-primary hover:!border-2">
                {/* <div className="absolute bg-blue100 text-primary text-xs font-medium inter leading-5 top-5 right-6 py-0.5 !px-2 rounded-2xl">Coming Soon</div> */}
                <picture className="!max-w-[112px]">
                  <img src={`${process.env.PUBLIC_URL}/ivr.svg`} />
                </picture>
                <h2 className="fz14 black fw-po-medium mb-2.5">IVR Integration</h2>
                <p className="fz14 black-dark-600 mb-0">Get all your calls from IVR</p>
              </div>
            </div>}
            {/* Facebook integration via make */}
            {showFb && <div
              className="col-xl-4 col-lg-6 col-md-6 col-sm-6 cursor-pointer"
              onClick={() =>
                props.history.push(
                  "/settings/integrations/all-integrations/facebook-lead-ads"
                )
              }
            >
              <div className="card relative !border !border-white hover:!border-primary hover:!border-2">
                <picture>
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                  >
                    <path
                      d="M30.234 32C31.2092 32 32 31.2092 32 30.2337V1.76614C32 0.790567 31.2093 0 30.234 0H1.76615C0.790573 0 0 0.790567 0 1.76614V30.2337C0 31.2091 0.790448 32 1.76615 32H30.234Z"
                      fill="#F0F6FF"
                    />
                    <path
                      d="M22.0796 32V19.6078H26.2391L26.8619 14.7784H22.0796V11.6951C22.0796 10.2968 22.4678 9.34397 24.4729 9.34397L27.0302 9.34285V5.02335C26.588 4.9645 25.0699 4.83301 23.3037 4.83301C19.6166 4.83301 17.0923 7.08365 17.0923 11.2168V14.7784H12.9221V19.6078H17.0923V32H22.0796Z"
                      fill="#0062FF"
                    />
                  </svg> */}
                  <img src={`${process.env.PUBLIC_URL}/make_logo.png`} />
                </picture>
                <h2 className="fz14 black fw-po-medium mb-8">Connect FB ads via make.com</h2>
                <p className="fz14 black-dark-600 mb-0">Get leads via make</p>
                {/* <a className="outline-btn"
                  onClick={() =>
                    props.history.push(
                      "/settings/integrations/all-integrations/facebook-lead-ads"
                    )
                  }
                >
                  Create/View
                </a> */}
              </div>
            </div>}

            {/* Whats app */}
            <div
              className="col-xl-4 col-lg-6 col-md-6 col-sm-6 cursor-pointer"
            // onClick={() =>
            //   props.history.push(
            //     "/settings/integrations/all-integrations/ivr"
            //   )
            // }
            >
              <div className="card relative !border !border-white hover:!border-primary hover:!border-2">
                <div className="absolute bg-blue100 text-primary text-xs font-medium inter leading-5 top-5 right-6 py-0.5 !px-2 rounded-2xl">Coming Soon</div>
                <picture className="!max-w-[112px]">
                  {/* <img src={`${process.env.PUBLIC_URL}/mcube.svg`} /> */}
                  <WhatsappIcon />
                </picture>
                <h2 className="fz14 black fw-po-medium mb-2.5">Whatsapp</h2>
                {/* <p className="fz14 black-dark-600 mb-0">Get all your calls from IVR</p> */}
                <p className="fz14 black-dark-600 mb-0">Whatsapp integration coming soon.</p>

              </div>
            </div>

            {/* Mcube */}
            <div
              className="col-xl-4 col-lg-6 col-md-6 col-sm-6 cursor-pointer"
            // onClick={() =>
            //   props.history.push(
            //     "/settings/integrations/all-integrations/ivr"
            //   )
            // }
            >
              <div className="card relative !border !border-white hover:!border-primary hover:!border-2">
                <div className="absolute bg-blue100 text-primary text-xs font-medium inter leading-5 top-5 right-6 py-0.5 !px-2 rounded-2xl">Coming Soon</div>
                <picture className="!max-w-[112px]">
                  <img src={`${process.env.PUBLIC_URL}/mcube.svg`} />
                </picture>
                <h2 className="fz14 black fw-po-medium mb-2.5">IVR Integration</h2>
                <p className="fz14 black-dark-600 mb-0">Get all your calls from IVR</p>
              </div>
            </div>

            {/* Exotel */}
            <div
              className="col-xl-4 col-lg-6 col-md-6 col-sm-6 cursor-pointer"
            // onClick={() =>
            //   props.history.push(
            //     "/settings/integrations/all-integrations/facebook-lead-ads"
            //   )
            // }
            >
              <div className="card relative !border !border-white hover:!border-primary hover:!border-2">
                <div className="absolute bg-blue100 text-primary text-xs font-medium inter leading-5 top-5 right-6 py-0.5 !px-2 rounded-2xl">Coming Soon</div>
                <picture className="!max-w-[112px]">
                  <img src={`${process.env.PUBLIC_URL}/exotel.svg`} />
                </picture>
                <h2 className="fz14 black fw-po-medium mb-2.5">IVR Integration</h2>
                <p className="fz14 black-dark-600 mb-0">Get all your calls from IVR</p>
              </div>
            </div>

            {/* ozontel */}
            <div
              className="col-xl-4 col-lg-6 col-md-6 col-sm-6 cursor-pointer"
            // onClick={() =>
            //   props.history.push(
            //     "/settings/integrations/all-integrations/facebook-lead-ads"
            //   )
            // }
            >
              <div className="card relative !border !border-white hover:!border-primary hover:!border-2">
                <div className="absolute bg-blue100 text-primary text-xs font-medium inter leading-5 top-5 right-6 py-0.5 !px-2 rounded-2xl">Coming Soon</div>
                <picture className="!max-w-[112px]">
                  <img src={`${process.env.PUBLIC_URL}/ozonetel.svg`} />
                </picture>
                <h2 className="fz14 black fw-po-medium mb-2.5">IVR Integration</h2>
                <p className="fz14 black-dark-600 mb-0">Get all your calls from IVR</p>
              </div>
            </div>


            {/* IVR */}
            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
              <div className="card comming-soon relative !border !border-white hover:!border-primary hover:!border-2">
                <picture>
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <rect width="32" height="32" rx="4" fill="#F0F6FF" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M13.5588 10.9004C13.919 10.37 13.9898 9.988 13.6436 9.24579C13.1291 8.1432 11.322 5.50119 10.1593 5.08793C9.62228 4.89695 9.08509 5.02438 8.44897 5.39921C6.57582 6.50247 5.90353 8.16328 6.01097 10.2855C6.37377 17.4723 13.5357 24.6263 20.7175 24.989C22.8382 25.0965 24.4978 24.4239 25.6011 22.5497C25.9757 21.9134 26.1031 21.3758 25.9119 20.8385C25.499 19.6751 22.8583 17.8668 21.7567 17.3524C21.0144 17.0057 20.6331 17.0768 20.1029 17.4372C18.3131 18.6554 18.3353 20.2294 16.1523 18.8162C14.5152 17.7562 13.2402 16.4912 12.1811 14.8534C10.7684 12.6693 12.3418 12.691 13.5594 10.9007L13.5588 10.9004Z" fill="#0062FF" />
                  </svg>
                </picture>
                <h2 className="fz14 black fw-po-medium mb-8">IVR Integration </h2>
                <p className="fz14 black-dark-600 mb-0">
                  Get all your calls from IVR </p>
                <a className="pr-btn text-white" style={{ cursor: "auto" }}
                // onClick={() =>
                //   props.history.push(
                //     "/settings/integrations/all-integrations/magicbricks"
                //   )
                // }
                >
                  Coming Soon
                </a>
                {/* <div className="d-flex flex-row-reverse">
            <button
              className="outline-btn mt-4 "
              style={{ width: '115px' }}
              disabled
            >
              Coming soon
            </button>
          </div> 
          </div>
        </div> */}

            {/* <div className="card">
          <picture>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M30.234 32C31.2092 32 32 31.2092 32 30.2337V1.76614C32 0.790567 31.2093 0 30.234 0H1.76615C0.790573 0 0 0.790567 0 1.76614V30.2337C0 31.2091 0.790448 32 1.76615 32H30.234Z"
                fill="#F0F6FF"
              />
              <path
                d="M22.0796 32V19.6078H26.2391L26.8619 14.7784H22.0796V11.6951C22.0796 10.2968 22.4678 9.34397 24.4729 9.34397L27.0302 9.34285V5.02335C26.588 4.9645 25.0699 4.83301 23.3037 4.83301C19.6166 4.83301 17.0923 7.08365 17.0923 11.2168V14.7784H12.9221V19.6078H17.0923V32H22.0796Z"
                fill="#0062FF"
              />
            </svg>
          </picture>
          <h2 className="fz14 black fw-po-medium mb-8">Facebook Lead Ads</h2>
          <p className="fz14 black-dark-600 mb-0">
            Get new leads from FB/Instagram
          </p>
          <a href="#">Create Integration</a>
        </div> */}
          </div>
        </div >

        {/* Facebook Integration via Zapier */}
        < div className="integrations-edit w-100 py-24 d-none" >
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1 className="fz24 mb-16 black fw-po-medium">
                Facebook Integration via Zapier
              </h1>
              <p className="fz16 black-dark-700 ">
                Connect your Platform to Facebook lead ads to receive your leads
              </p>
            </div>
            <div className="col-md-4">
              <a href="" className="pr-btn d-table ml-auto">
                Setup Now
              </a>
            </div>
          </div>

          <div className="edit-api-connect w-100">
            <h2 className="fz16 black mb-12">API Key</h2>
            <p className="fz14 black-dark-700 mb-16">
              Find below the API key. This API key allow you to connect Zapier.
            </p>
          </div>
          <div className="line mb-24"></div>
          <div className="edit-table">
            <table className="table">
              <thead>
                <tr>
                  <th>User</th>
                  <th>Key</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Actions</td>
                  <td>
                    <div className="form-inline">
                      1872*************************
                      <button className="border-0 bg-tr">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M19.9152 2.07341C19.585 1.75033 19.0545 1.75326 18.7279 2.07998L15.3689 5.44086C14.0313 4.72464 12.545 4.33301 11 4.33301C7.12653 4.33301 3.62181 6.79472 1.90503 10.6615C1.80944 10.8768 1.80944 11.1225 1.90503 11.3378C2.68048 13.0844 3.82072 14.5444 5.19496 15.6202L2.07813 18.7387C1.74915 19.0679 1.75214 19.6005 2.08478 19.9259C2.41495 20.249 2.94552 20.2461 3.27207 19.9194L6.63114 16.5585C7.96872 17.2747 9.45503 17.6663 11 17.6663C14.8735 17.6663 18.3782 15.2046 20.095 11.3378C20.1906 11.1225 20.1906 10.8768 20.095 10.6615C19.3195 8.91492 18.1793 7.455 16.805 6.37912L19.9219 3.26061C20.2509 2.93144 20.2479 2.3989 19.9152 2.07341ZM14.1256 6.68476C13.1469 6.23908 12.0893 5.99967 11 5.99967C7.91781 5.99967 5.08884 7.91641 3.58573 10.9997C4.27442 12.4124 5.24145 13.5802 6.38364 14.4309L8.12538 12.6882C7.83387 12.193 7.66667 11.6159 7.66667 10.9997C7.66667 9.15873 9.15905 7.66634 11 7.66634C11.6156 7.66634 12.1922 7.8332 12.687 8.12415L14.1256 6.68476ZM13.8746 9.31111L15.6164 7.56844C16.7585 8.41919 17.7256 9.58699 18.4143 10.9997C16.9112 14.0829 14.0822 15.9997 11 15.9997C9.91071 15.9997 8.85306 15.7603 7.87437 15.3146L9.31299 13.8752C9.80785 14.1662 10.3844 14.333 11 14.333C12.8409 14.333 14.3333 12.8406 14.3333 10.9997C14.3333 10.3835 14.1661 9.80632 13.8746 9.31111ZM11.4243 9.38751C11.2889 9.35195 11.1466 9.33301 11 9.33301C10.0795 9.33301 9.33333 10.0792 9.33333 10.9997C9.33333 11.1466 9.35235 11.2891 9.38806 11.4249L11.4243 9.38751ZM10.5757 12.6118L12.6119 10.5745C12.6476 10.7102 12.6667 10.8527 12.6667 10.9997C12.6667 11.9201 11.9205 12.6663 11 12.6663C10.8534 12.6663 10.7111 12.6474 10.5757 12.6118Z"
                            fill="#5294FF"
                          />
                        </svg>
                      </button>
                      <button className="border-0 bg-tr">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6 6C4.34315 6 3 7.34315 3 9V18C3 19.6569 4.34315 21 6 21H12C13.6569 21 15 19.6569 15 18V11.0657C15 10.7219 14.8819 10.3885 14.6655 10.1214L11.7766 6.55574C11.4918 6.2042 11.0636 6 10.6111 6H6ZM11.7188 8.86712V10.05H12.6771L11.7188 8.86712ZM6 7.5C5.17157 7.5 4.5 8.17157 4.5 9V18C4.5 18.8284 5.17157 19.5 6 19.5H12C12.8284 19.5 13.5 18.8284 13.5 18V11.55H11.7188C10.8903 11.55 10.2188 10.8784 10.2188 10.05V7.5H6Z"
                            fill="#5294FF"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10 2C8.34315 2 7 3.34315 7 5V6.5L10 7.06569C10 7.75473 13.1918 8.78762 14 10.5C15.1352 12.9052 14 16.1238 14 17H16C17.6569 17 19 15.6569 19 14V7.06569C19 6.7219 18.8819 6.38855 18.6655 6.12143L15.7766 2.55574C15.4918 2.2042 15.0636 2 14.6111 2H10ZM15.7188 4.86712V6.05H16.6771L15.7188 4.86712ZM10 3.5C9.17157 3.5 8.5 4.17157 8.5 5V6.5L13 8.5C13 9.32843 14.1716 15.5 15 15.5H16C16.8284 15.5 17.5 14.8284 17.5 14V7.55H15.7188C14.8903 7.55 14.2188 6.87843 14.2188 6.05V3.5H10Z"
                            fill="#5294FF"
                          />
                        </svg>
                      </button>
                    </div>
                  </td>
                  <td>
                    <a href="#" className="outline-btn pr-hover">
                      Generate key
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div >
        {/*End Facebook Integration via Zapier  */}
      </div >
    </div >
  );
};

export default withRouter(CompanyIntegrations);
