import React from 'react'
import BasicModal from '../../../../components/Modals/BasicModal'
import { ReactComponent as InfoAlert } from '../../../../assets/new/info_alert.svg';

const AlertModal = ({ show, closeModal, closeFormModal }) => {

    return (
        <BasicModal show={show} closeModal={closeModal} modalClass='!max-w-[420px] !min-w-[420px]'>
            <div className='!p-8'>
                <div className='flex items-center justify-center'>
                    <InfoAlert />
                </div>
                <div className='!my-8 text-center'>
                    <h3 className='mb-1 text-2xl font-semibold !text-black'>Discard New Opportunity?</h3>
                    <p className='mb-0 text-black700 text-base'>All entered details will be lost.</p>
                </div>
                <div className='w-full !gap-3 flex flex-col'>
                    <button
                        type='submit'
                        className='text-base font-medium inter leading-6 !rounded-lg !text-white !bg-primary  h-[44px] text-center inter'
                        onClick={() => {
                            closeFormModal();
                        }}
                    >Discard and Close</button>
                    <button
                        type='button'
                        className='text-base font-medium inter leading-6 !rounded-lg !border !border-grayLight !text-black  h-[44px] text-center inter'
                        onClick={() => {

                            closeModal();
                        }}
                    >Keep Editing</button>

                </div>
            </div>
        </BasicModal>
    )
}

export default AlertModal