
import React from 'react';
import Select from 'react-select';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },
        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        color: '#44444f',
        zIndex: 99,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        },
    }),
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({ ...provided, zIndex: 9999 })
};



export default function InputSelect({
    index,
    name,
    error,
    isMulti,
    value,
    options,
    placeholder,
    menuPosition,
    menuPlacement,
    maxMenuHeight,
    onChange,
    onBlur,
    label,
    disable,
    handleSearch,
    styles,
    required,
    inputclass,
    isClearable = false,
}) {

    return (
        <div key={index} className={`form-group ${inputclass} ${error ? 'invalid' : ''} position-relative`}>
            {label && <label htmlFor={name}>
                {label} {required && <sup>*</sup>}
            </label>}
            <Select
                maxMenuHeight={150}
                MenuPlacement={menuPlacement}
                menuPosition={menuPosition || 'fixed'}
                isMulti={isMulti}
                name={name}
                // styles={styles}
                styles={customStyles}
                value={value}
                onChange={onChange}
                options={options}
                placeholder={placeholder}
                onBlur={onBlur}
                isDisabled={disable}
                isClearable={isClearable}
            />

            {error && (
                <div className="input-feedback mt-8">
                    {error}
                </div>
            )}
        </div>
    )
}