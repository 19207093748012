import React, { useState } from 'react'
import { Oval } from 'react-loader-spinner'
import TooltipText from '../../components/Tooltip'
import { ReactComponent as ThreedotsIcon } from '../../assets/icons/threedots.svg';
import CompletedCPVisit from './Modal/CompletedCPVisitModal';
import { createDatewithTime } from '../../helpers/date';
import NotesDropDown from '../../components/Custom Table/TableColumns/NotesDropDown';
import Lightbox from 'yet-another-react-lightbox';
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Captions from "yet-another-react-lightbox/plugins/captions";
import LeadsFooterBar from '../LeadsManager/LeadsFooterBar';

const statusOption = [
    {
        label: 'Mark as Completed',
        value: 'completed'
    }
]

const CPMeets = ({ isLoading,
    meets,
    filters,
    searchText,
    allMeets,
    allScheduleCPMeets,
    showingResults,
    setShowingResults,
    currentPage,
    setCurrentPage,
    leadsCount,
    maxPage,
}) => {
    const [status, setStatus] = useState('scheduled');
    const [showCompleteCPVisit, setShowCompleteCPVisit] = useState(false);
    const [selectedItem, setSelectedItem] = useState({})
    const [showLightModal, setShowLightModal] = useState(false);
    const [selectedImg, setSelectedImg] = useState('');

    console.log(leadsCount, 'leadsCount')
    return (
        <>
            <div className={`relative ${isLoading ? '' : 'overflow-auto'} inter !w-100 !border !rounded-lg !max-h-[calc(100vh-162px)] !min-h-[calc(100vh-162px)] h-100`}>
                <table class="min-w-full text-sm text-left ">
                    <thead class="text-sm  text-[#344054]">
                        <tr className='border-b-2'>
                            <th scope="col" class="!pl-3 !py-2 text-sm !text-black700 !font-normal inter !sticky !left-0 ">
                                S.No.
                            </th>
                            <th className='!pl-3 !py-2 !sticky !left-14 '>
                                Owner Name
                            </th>
                            <th className='!pl-3 !py-2'>
                                CP Firm Name
                            </th>
                            <th className='!pl-3 !py-2'>
                                Scheduled Time
                            </th>

                            <th className='!pl-3 !py-2'>
                                Meeting Status
                            </th>
                            <th className='!pl-3 !py-2'>
                                Completed Time
                            </th>
                            <th className='!pl-3 !py-2'>
                                Address
                            </th>
                            <th className='!pl-3 !py-2'>Sourcing</th>
                            <th className='!pl-3 !py-2'>Is App Installed</th>
                            <th className='!pl-3 !py-2'>CP Registered</th>
                            <th className='!pl-3 !py-2'>New User</th>
                            <th className='!pl-3 !py-2'>
                                Completed Note
                            </th>
                            <th className='!pl-3 !py-2'>
                                Scheduled Note
                            </th>
                            <th className='!pl-3 !py-2'>
                                Image
                            </th>

                            {/* <th scope="col" className="!pl-3 !py-2 !sticky !right-0">
                            <TooltipText id="button-tooltip" title='Customise Columns' className='leadmanager-tooltip'>
                                <button onClick={() => setShowCustomisedColumnModal(true)}>
                                    <TableIcon />
                                </button>
                            </TooltipText>
                        </th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allMeets?.length > 0 && allMeets?.map((item, index) => (
                                <tr class=" border-b !px-3 !h-[48px]" key={`${item?.uuid}_${index}`}>
                                    <td className='!px-3 !whitespace-nowrap !py-2 !sticky !left-0 bg-white'>{index + 1}</td>
                                    <td className='!px-3 !whitespace-nowrap !py-2 !sticky !left-14 bg-white'>{item?.owner_name || '-'}</td>
                                    <td className='!px-3 !whitespace-nowrap !py-2'>{item?.firm_name}</td>
                                    <td className='!px-3 !whitespace-nowrap !py-2'>{item?.scheduled_date ? createDatewithTime(item?.scheduled_date) : '-'}</td>

                                    <td className='!px-3 !whitespace-nowrap !py-2'>
                                        {item?.status === 'completed' && <div className=' w-[120px] !text-green text-center !border !border-green !bg-[#05A36F33] !rounded-lg !px-2 !py-1.5 text-sm font-medium cp-meet-status-completed-shadow'>
                                            Completed
                                        </div>}
                                        {item?.status === 'scheduled' &&
                                            <>
                                                <button
                                                    className={`w-[120px] text-capitalize flex items-center !rounded-lg text-center !px-2 !py-1.5 !border !border-black600 text-sm font-medium cp-meet-status-scheduled-shadow`}
                                                    type="button"
                                                    id="dropdownMenuButton"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"

                                                >
                                                    Scheduled
                                                    <svg className="!ml-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#344054" />
                                                    </svg>
                                                </button>
                                                <div className="dropdown-menu !p-2" aria-labelledby="dropdownMenuButton">
                                                    {
                                                        statusOption.map(option => {
                                                            return <a
                                                                key={option.value}
                                                                onClick={() => {
                                                                    if (option.value === 'completed') {
                                                                        setStatus(option.value)
                                                                        setSelectedItem(item)
                                                                        setShowCompleteCPVisit(true)
                                                                    }
                                                                }}
                                                                className={`dropdown-item px-0`}
                                                            >
                                                                {option.label}
                                                            </a>
                                                        })
                                                    }
                                                </div>
                                            </>
                                        }
                                    </td>
                                    <td className='!px-3 !whitespace-nowrap !py-2'>{item?.completed_time ? createDatewithTime(item?.completed_time) : '-'}</td>
                                    <td className='!px-3 !whitespace-nowrap !py-2'>{
                                        item?.location && item?.location?.googleAddress?.length > 15 ? <TooltipText title={item?.location?.googleAddress}>
                                            <p className='w-[150px] truncate mb-0'>{item?.location?.googleAddress}</p>
                                        </TooltipText> :
                                            <span>{item?.location?.googleAddress || '-'}</span>
                                    }</td>

                                    <td className='!px-3 !whitespace-nowrap !py-2'>{item?.sourcing_user_name || '-'}</td>
                                    <td className='!px-3 !whitespace-nowrap !py-2'>{item?.is_app_installed ? 'Yes' : 'No'}</td>
                                    <td className='!px-3 !whitespace-nowrap !py-2'>{item?.is_registered ? 'Yes' : 'No'}</td>
                                    <td className='!px-3 !whitespace-nowrap !py-2'>{item?.is_user_new ? 'Yes' : 'No'}</td>
                                    <td className='!px-3 !whitespace-nowrap !py-2'>
                                        <div className='view-notes'>

                                            <NotesDropDown
                                                note={item.completed_note}
                                                date={item.created_on}
                                            />
                                        </div>
                                    </td>
                                    <td className='!px-3 !whitespace-nowrap !py-2'>
                                        <div className='view-notes'>

                                            <NotesDropDown
                                                note={item.remarks}
                                                date={item.created_on}
                                            />
                                        </div>
                                    </td>
                                    <td className='!px-3 !whitespace-nowrap !py-2'>
                                        {item?.image_url ? <button
                                            className='!px-4 py-1 !border !rounded-lg'
                                            onClick={() => {
                                                setSelectedImg(item);
                                                setShowLightModal(true);
                                            }}
                                        >
                                            View Image
                                        </button> : '-'}
                                    </td>
                                    {/* <td className='dropdown view-more bg-white !sticky right-0'>
                            <button className="!w-[50px] hover:bg-black100 flex items-center justify-center !h-7 rounded-lg" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <ThreedotsIcon />
                            </button>
                            <div className="dropdown-menu dropdown-menu-right p-2" aria-labelledby="dropdownMenuButton">
                                <a className="dropdown-item"
                                    onClick={() => {
                                        setShowCompleteCPVisit(true)
                                    }}
                                >
                                    Complete CP Visit
                                </a>
                            </div>
                        </td> */}
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                {isLoading && <div className='d-flex w-full justify-content-center flex-column items-center !max-h-[calc(100vh-245px)] !min-h-[calc(100vh-245px)]'>
                    <Oval
                        height={50}
                        width={50}
                        color="#0062FF"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#0062FF"
                        strokeWidth={4}
                        strokeWidthSecondary={4}
                    />
                </div>}
                {!isLoading && allMeets?.length === 0 && <div className='d-flex justify-content-center flex-column text-center !max-h-[calc(100vh-245px)] !min-h-[calc(100vh-245px)]'>
                    <div className='flex items-center flex-col'>

                        <figure><svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none"><circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF"></circle><path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF"></path><circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974"></circle><circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE"></circle><circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC"></circle><path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974"></path><path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE"></path></svg></figure>
                        {(filters?.length > 0 || searchText) &&
                            <>
                                <h2 className="po black fz16 fw-po-medium mb-8">Sorry, we couldn’t find any results</h2>
                                <p className='text-center black-dark-600 fz14 mb-0'> Please try again</p>
                            </>
                        }
                        {
                            !searchText && !filters?.length &&
                            <>
                                <h2 className="po black fz16 fw-po-medium mb-8"> No Meet Found</h2>
                            </>
                        }
                    </div>
                </div>}
            </div>
            <LeadsFooterBar
                showingResults={showingResults}
                setShowingResults={setShowingResults}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                maxPage={maxPage}
                leadsCount={leadsCount}
                classname='!relative !basis-0 !w-full'
            />
            {showCompleteCPVisit && <CompletedCPVisit
                show={showCompleteCPVisit}
                closeModal={() => setShowCompleteCPVisit(false)}
                selectedItem={selectedItem}
                allScheduleCPMeets={allScheduleCPMeets}
            />}

            {showLightModal && <Lightbox
                styles={{ navigationNext: { "yarl__navigation_next": "display: none !important" } }}
                className={'broker-lightbox'}
                open={showLightModal}
                close={() => {
                    setShowLightModal(false)
                    setSelectedImg('')
                }}
                slides={[{ src: selectedImg.image_url, title: `${selectedImg.owner_name} - ${selectedImg.firm_name}` }]}
                plugins={[Captions]}
            // video={{
            //     controls: true,
            //     playsInline: true,
            //     autoPlay: false,
            //     loop: false,
            //     muted: true,
            //     disablePictureInPicture: false,
            //     disableRemotePlayback: false,
            //     controlsList: ["nodownload" | "nofullscreen" | "noremoteplayback"].join(" "),
            //     crossOrigin: '',
            //     preload: '',
            // }}
            />}
        </>
    )
}

export default CPMeets