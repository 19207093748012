import React, { useState } from 'react'
import RightModal from '../../../components/Modals/RightModal';
import { Oval } from 'react-loader-spinner';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import InputTextarea from '../../../components/InputGroup/InputTextarea';
import DateTimePicker from '../../../components/InputGroup/DateTime';
import { ReactComponent as CameraIcon } from '../../../assets/new/camera.svg';
import { ReactComponent as AttachmentIcon } from '../../../assets/new/attachment.svg';
import CaptureImageModal from './CaptureImageModal';
import { uploadFile } from '../../../services/public/public.service';
import { createDatewithTime } from '../../../helpers/date';
import { toast } from 'react-toastify';
import { completeMeet } from '../../New Dashboard/services/dashboard.service';
import LocationPicker from './LocationPicker';

const CompletedCPVisit = ({ show, closeModal, selectedItem, allScheduleCPMeets }) => {
    const [name, setName] = useState('');
    const [cpFirm, setCpFirm] = useState('');
    const [scheduleDate, setScheduleDate] = useState('');
    const [remarks, setRemarks] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [meetDate, setMeetDate] = useState('');
    const [comments, setCommnets] = useState('')
    const [showCaptureModal, setShowCaptureModal] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [isAppInstalled, setIsAppInstalled] = useState(true)
    const [image, setImage] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [googleAddress, setGoogleAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');

    const handleUpdateLocation = (city, address, state, lat, long) => {
        setCity(city);
        setGoogleAddress(address);
        setState(state);
        setLatitude(lat);
        setLongitude(long)
    }

    console.log(googleAddress, 'gogole address')

    const handleUploadImage = async (e) => {
        const file = e.target.files[0];

        const formData = new FormData();
        formData.append("file", file)
        const response = await uploadFile(formData);
        if (response.status === 200) {
            setImageUrl(response.data.data.s3_url)
        }
    }

    const handleSubmit = async () => {
        if (!meetDate || !imageUrl || !googleAddress) {
            toast.error('Please Fill Required fields');
            return;
        }
        const payload = {
            completed_note: remarks,
            image_url: imageUrl,
            location: {
                longitude: longitude,
                latitude: latitude,
                googleAddress: googleAddress
            },
            is_app_installed: isAppInstalled,
            // is_registered: false,
            completed_time: meetDate

        }
        const res = await completeMeet(selectedItem?.uuid, payload);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            allScheduleCPMeets();
            closeModal();
        }
    }

    return (
        <RightModal show={show} closeModal={closeModal} modalClass={`configurationModal`}>
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <div className='flex items-center'>
                    {/* <button onClick={closeModal}>
                <BackIcon />
            </button> */}
                    <h1 className='mb-0 black inter fz20 fw-po-medium ml-2'>
                        Mark CP Meet Completed
                    </h1>
                </div>
                <button className='border-0 bg-tr ' onClick={closeModal}><CloseIcon /></button>
            </div>

            <div className='flex flex-col h-full  overflow-hidden'>
                <div className='h-full overflow-auto !px-5 !space-y-4'>

                    <div className='!px-3 !py-2 bg-[#F2F3F6] !rounded-lg !mt-4 space-y-4'>
                        <div className='flex justify-between items-center text-sm'>
                            <span className='text-[#647187] font-medium'>Name</span>
                            <span className='text-[#314155] font-semibold'>{selectedItem?.owner_name}</span>
                        </div>
                        <div className='flex justify-between items-center text-sm'>
                            <span className='text-[#647187] font-medium'>CP Firm</span>
                            <span className='text-[#314155] font-semibold'>{selectedItem?.firm_name}</span>
                        </div>
                        <div className='flex justify-between items-center text-sm'>
                            <span className='text-[#647187] font-medium'>Scheduled</span>
                            <span className='text-[#314155] font-semibold'>{createDatewithTime(selectedItem?.scheduled_date)}</span>
                        </div>
                    </div>

                    <DateTimePicker
                        label={'Meet Date and Time'}
                        onValueChange={(e) => {
                            setMeetDate(e)
                        }}
                        name="meetDate"
                        handelBlur={() => { }}
                        value={meetDate}
                        error={''}
                        required={true}
                        completed={true}
                    />
                    <div className=''>
                        <label htmlFor="">
                            App Signup
                            <sup>*</sup>
                        </label>
                        <div className='grid grid-cols-2 gap-2'>
                            <div className='w-full'>
                                <label className='mb-0 flex items-center justify-between !border !py-4 !px-3 !rounded-lg !border-primary bg-[#2064FA0A]'>
                                    <span className='text-sm text-black-t font-medium'>Yes</span>
                                    <input type="radio" name="app" id="" value={true} checked={isAppInstalled} onChange={() => setIsAppInstalled(true)} />
                                </label>
                            </div>
                            <div className='w-full'>
                                <label className='mb-0 flex items-center justify-between !border !py-4 !px-3 !rounded-lg'>
                                    <span className='text-sm text-black-t font-medium'>No</span>
                                    <input type="radio" name="app" id="" value={false} checked={!isAppInstalled} onChange={() => setIsAppInstalled(false)} />
                                </label>
                            </div>
                        </div>
                    </div>

                    {!imageUrl && <div>
                        <label htmlFor="">Image
                            <sup>*</sup>
                        </label>
                        <div
                            className='bg-black100 text-[#647187] !py-3 !px-3 !border !rounded-lg flex items-center justify-center cursor-pointer'
                            onClick={() => {
                                setShowCaptureModal(true)
                            }}
                        >
                            <CameraIcon className='!mr-2' />
                            Open Camera
                        </div>
                        <div className='flex justify-end !mt-2  text-primary-blue-dark'>
                            <input type="file" className='d-none' id={`upload-img`} accept="image/*" multiple onChange={(e) => handleUploadImage(e)} />
                            <button className='flex items-center'
                                onClick={() => {
                                    document.getElementById(`upload-img`).click();
                                }}
                            >
                                <AttachmentIcon /> <span className='underline text-sm font-medium'>Upload File</span>
                            </button>
                        </div>
                    </div>}
                    {
                        imageUrl && <div className=''>
                            <div className='flex items-center justify-between'>
                                <label htmlFor="">Image
                                    <sup>*</sup>
                                </label>
                                <button className='!border font-semibold !rounded-lg text-primary-blue-dark !py-1.5 !px-3 text-xs'
                                    onClick={() => setImageUrl('')}
                                >Remove</button>
                            </div>
                            <div className='!border !rounded-lg !p-3 w-100 !mt-1.5'>
                                <img src={imageUrl} alt="" className='w-100 h-[160px] rounded-lg object-fill' />
                            </div>
                        </div>
                    }
                    <LocationPicker
                        googleAddress={googleAddress}
                        previousCity={city}
                        latitude={latitude}
                        longitude={longitude}
                        previousState={state}
                        handleUpdateLocation={handleUpdateLocation}
                        showMap={false}
                    />
                    <div className='!mt-4'>

                        <InputTextarea
                            inputclass={'!mt-2'}
                            label={'Comments (optional)'}
                            placeholder={'Add description'}
                            value={comments}
                            onChange={(e) => setCommnets(e.target.value)}
                        />
                    </div>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between !px-5 gap-x-4 !py-4 righmodalfooter shadow-topshadow">
                    <button onClick={closeModal}
                        className='border rounded-lg py-2.5 w-28 basis-28 grow-0 shrink-0 border-primary !text-primary font-semibold text-16 text-center'>Cancel</button>
                    {!isSubmitting && <button onClick={() => {
                        handleSubmit()
                    }}
                        className='border rounded-lg py-2.5 w-full ml-4 !bg-primary !border-primary text-white font-semibold text-16 text-center hover:opacity-90'>
                        Mark Completed
                    </button>
                    }
                    {isSubmitting && <button className='border rounded-lg py-2.5 w-full ml-4 !bg-primary flex items-center justify-center  hover:opacity-90' type='button'>
                        <Oval
                            height={24}
                            width={24}
                            color="#ffffff"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#ffffff"
                            strokeWidth={4}
                            strokeWidthSecondary={4}
                        />
                    </button>}
                </div >
            </div>
            {
                showCaptureModal && <CaptureImageModal
                    show={showCaptureModal}
                    closeModal={() => setShowCaptureModal(false)}
                    setImageUrl={setImageUrl}
                />
            }
        </RightModal>
    )
}

export default CompletedCPVisit;