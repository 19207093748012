import moment from 'moment'
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ReactComponent as ThreedotsIcon } from '../../assets/icons/threedots.svg';
import LeadExistModal from './Modal/LeadExistModal';
import Consumer from '../../helpers/context';
import TooltipText from '../../components/Tooltip';
import InputSelect from '../../components/InputGroup/InputSelect';
import useDocumentTitle from '../../custom hooks/useDocumentTitile';
import { Oval } from 'react-loader-spinner';
import InternalTabs from '../../components/HOC/InternalTabs';
import { button } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import SVColumnCustomisation from './Modal/svColumnCustomisation';
import RenderTableData from './RenderSvTableData';
import { ReactComponent as ExpiryInfoIcon } from '../../assets/icons/expiry_info.svg';
import { ReactComponent as RedInfoIcon } from '../../assets/icons/red_info.svg';
import { sendWhatsappInvitation } from '../../services/private/borker.service';
import { toast } from 'react-toastify';
import NewEOIModal from '../../components/Modals/NewEOIModal';
import NewBookingModal from '../../components/Modals/NewBookingModal';
import { assignClosingAndTagging, getTaggingConflictByUUID } from '../New Dashboard/services/dashboard.service';
import TaggingConflictModal from '../New Dashboard/Components/Modals/TaggingConflictModal';
import SVScheduleModal from '../New Dashboard/Components/Modals/SVScheduleModal';
import { ReactComponent as TableIcon } from '../../assets/new/table.svg';
import MarkDeadModal from '../New Dashboard/Components/Modals/MarkDeadModal';
import SortIconDisplay from '../../components/Custom Table/sortIconDisplay';

const getClassName = (exist) => {
    switch (exist) {
        case 'yes':
            return 'hot-btn'
        case 'no':
            return 'dead-btn'
    }
}

const tableHeight = (filterLength) => {
    if (filterLength) {
        return `max-h-[calc(100vh-300px)] min-h-[calc(100vh-300px)]`
    } else {
        return 'max-h-[calc(100vh-245px)] min-h-[calc(100vh-245px)]'
    }
}

const SiteVisitCompletedLeads = ({
    leads,
    handleCheckLeadExist,
    searchText,
    allUsers,
    setShowDeleteModal,
    setShowLightModal,
    setSelectedImg,
    setSelectedLead,
    isLoading,
    tabList,
    setSelectedTab,
    tabRowCount,
    completedColumn,
    updateColumnList,
    handleChangeSelection,
    saveCurrentUserColumnPrefrence,
    dashboardColumns,
    setShowCustomisedColumnModal,
    showCustomisedColumnModal,
    assignDisable,
    redirectURL,
    redirectBactTo,
    getEvents,
    statuses,
    intentStatuses,
    onSuccess,
    agencyList,
    sourceStatuses,
    selectedInternalTab,
    selectedTab,
    handleUpdateAssignedTO,
    getStats,
    col,
    sort,
    filters,
    setShowQualificationModal
}) => {
    const [tableColumn, setTableColumn] = useState([])
    const [showNotRegisterInfo, setShowNotRegisteredInfo] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState('');
    const [showEOIDoneModal, setShowEOIDoneModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [showBookingModal, setShowBookingModal] = useState(false);
    const [selectedClosing, setSelectedClosing] = useState({});
    const [showTaggingConflictModal, setShowTaggingConflictModal] = useState(false);
    const [conflictData, setConflictData] = useState([]);
    const [opportunityData, setOpportunityData] = useState({});
    const [selectedSV, setSelectedSV] = useState({});
    const [showSVModal, setShowSVModal] = useState(false);
    const [markDead, setMarkDead] = useState(false)

    let { allowedPermissions, userProfile } = useContext(Consumer);

    useDocumentTitle('Site Visit Tracker (Completed)');

    const history = useHistory();

    useEffect(() => {
        const sortedList = completedColumn.sort((a, b) => a.placement - b.placement)
        setTableColumn(sortedList);
    }, [completedColumn]);

    const registeredRef = useRef();

    const handleOutsideClick = (e) => {
        if (registeredRef.current && !registeredRef.current.contains(e.target)) {
            setShowNotRegisteredInfo(false);
            document.getElementById(`lead_${selectedIndex}`).classList.add('sticky-td')
            document.getElementById(`lead_${selectedIndex}`).classList.remove('relative')
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick)
        return (() => document.removeEventListener('mousedown', handleOutsideClick))
    }, [showNotRegisterInfo]);

    const handleSendInvite = async (lead) => {
        const payload = {
            owner_mobile: lead.company_owner_mobile,
            owner_name: lead.company_owner_name
        }
        const res = await sendWhatsappInvitation(payload)
        if (res.data.status) {
            toast.success(res.data.message);
            setShowNotRegisteredInfo(false);
        }
    }

    const getConflict = async (item, lead) => {
        setSelectedClosing(item)
        setSelectedSV(lead);
        const res = await getTaggingConflictByUUID(lead.lead_uuid)
        if (res.data.status === 200) {
            console.log(res.data.data, 'conflict')
            setConflictData(res.data.data.conflicts.map(i => ({ ...i, is_selected: i.is_tagged })))
            setOpportunityData(res.data.data?.opportunity_info)
            setShowTaggingConflictModal(true);
        }
    }

    const handleAssignTagging = async (manager, lead) => {
        const payload = {
            pre_sale_user_id: !lead?.broker_user_id ? lead?.pre_sale_user_id : null,
            closing_user_id: manager.value,
            broker_user_id: lead?.broker_user_id || null,
            broker_company_id: lead?.broker_company_id || null,
            remarks: ""
        }
        const res = await assignClosingAndTagging(lead.lead_uuid, payload)
        if (res.data.status === 200) {
            onSuccess();
        }
    }

    return (
        <>
            {/* // <div className='sv-completed'> */}

            {/* // <div className="user-table ml-24"> */}
            <div className={`relative ${(isLoading || leads?.length === 0) ? '' : 'overflow-auto'} !w-100 !border !rounded-lg ${tableHeight(filters?.length)} h-100`}>
                <table className='table'>
                    <thead className='!z-[99]'>
                        <tr>
                            <th className=''>S.No.</th>
                            {
                                tableColumn?.map((item, index) => {
                                    if (item.label === 'Lead Creation' && !allowedPermissions?.includes('create_broker_lead')) {
                                        return null
                                    }
                                    return (
                                        <th
                                            key={`${item.db_value}_${index}`}
                                            className={`${item.label === 'Name' ? 'sticky-th' : ''}`}
                                        >
                                            <span className='flex items-center gap-1 w-max'>
                                                {item.label}
                                                <SortIconDisplay sortCol={col?.label} sort={sort} colName={item?.label} />
                                            </span>
                                        </th>
                                    )
                                })
                            }
                            {/* <th className='sticky-th'>Name</th>
                                <th>Mobile No.</th>
                                <th>Email</th>
                                <th>Completed time</th>
                                {(allowedPermissions?.includes('create_broker_lead')) && <th>Lead Creation</th>}
                                <th>Schedule Date</th>
                                <th>Closing Manager</th>
                                <th>Sourcing Manager</th>
                                <th>Lead Exist</th>
                                <th>CP User</th>
                                <th>CP User Mobile</th>
                                <th>CP Firm</th>
                                <th>CP Owner Mobile</th>
                                <th>CP Email</th>
                                <th>CP User Registered</th>
                                <th>Completed by</th>
                                <th>Create lead by</th>
                                <th>Created lead time</th>
                                <th>Alt. Mobile No.</th>
                                <th>Preference</th>
                                <th>Budget</th>
                                <th>Purpose</th>
                                <th>Possession Preference</th>
                                <th>Reference Name</th>
                                <th>Reference Mobile</th>
                                <th>Reference Society</th>
                                <th>Reference Flat No</th>
                                <th>Profession</th>
                                <th>Address</th>
                                <th>Company</th>
                                <th>Designation</th>
                                <th>Project</th>
                                <th>Source</th>
                                <th>SV Type </th>
                                <th>Remarks</th>
                                <th>View Photo</th> */}
                            {/* <th>Status</th> */}
                            <th className="!pl-3 !py-2 !sticky -right-3">
                                <TooltipText id="button-tooltip" title='Customise Columns' className='leadmanager-tooltip'>
                                    <button className='!w-[50px] flex items-center justify-center' onClick={() => setShowCustomisedColumnModal(true)}>
                                        <TableIcon />
                                    </button>
                                </TooltipText>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (leads.length > 0 && !isLoading) && leads.map((lead, index) => (
                                <tr key={lead.uuid}>
                                    <td className='z-50'>{index + 1}</td>
                                    {
                                        tableColumn?.map((item, tableIndex) => {
                                            return (
                                                <RenderTableData
                                                    item={item}
                                                    lead={lead}
                                                    index={index}
                                                    setSelectedImg={setSelectedImg}
                                                    setShowLightModal={setShowLightModal}
                                                    allUsers={allUsers}
                                                    handleCheckLeadExist={handleCheckLeadExist}
                                                    handleSendInvite={handleSendInvite}
                                                    redirectURL={redirectURL}
                                                    redirectBactTo={redirectBactTo}
                                                    statuses={statuses}
                                                    intentStatuses={intentStatuses}
                                                    onSuccess={onSuccess}
                                                    agencyList={agencyList}
                                                    sourceStatuses={sourceStatuses}
                                                    getConflict={getConflict}
                                                    handleAssignTagging={handleAssignTagging}
                                                    tab={selectedTab?.name}
                                                    subTab={selectedInternalTab?.name}
                                                    handleUpdateAssignedTO={handleUpdateAssignedTO}
                                                />
                                            )
                                        })
                                    }
                                    {/* <td className="text-capitalize sticky-td z-10">
                                            <div className={`${lead?.lead_uuid ? 'cursor-pointer' : ''}`} onClick={() => {
                                                if (lead?.lead_uuid) {
                                                    window.open(`leads/${lead?.lead_uuid}`)
                                                }
                                            }}>
                                                {(lead.customer_name && lead.customer_name.length > 20) ?
                                                    <TooltipText title={lead.customer_name}>
                                                        <div className='w-[100px] truncate'>
                                                            {lead.customer_name}
                                                        </div>
                                                        <div className='w-full flex justify-end !mt-2'>
                                                            <button
                                                                className='text-white !bg-[#2064FA] focus:!bg-[#2064FA] focus:!text-white rounded-full !px-2.5 !py-1.5'
                                                                onClick={() => handleSendInvite(lead)}
                                                            >
                                                                Invite CP
                                                            </button>
                                                        </div>
                                                    </div>}
                                                </div>}
                                            </div>
                                            {!lead.customer_name && '-'}
                                        </td>
                                        <td>{lead?.customer_mobile || '-'}</td>
                                        <td>
                                            {(lead?.customer_email && lead?.customer_email.length > 20) ?
                                                <TooltipText title={lead?.customer_email}>
                                                    <div className='w-[100px] truncate'>
                                                        {lead?.customer_email}
                                                    </div>
                                                </TooltipText>
                                                : (lead?.customer_email || '-')}

                                        </td>
                                        <td>{lead?.completed_time ? moment(lead?.completed_time).format("h:mm a, DD MMM YY") : '-'}</td>
                                        {allowedPermissions?.includes('create_broker_lead') && <td>
                                            {lead?.create_lead_by ? <a className={`border text-textblack block !w-[117px] !py-2 px-3 rounded-lg hover:bg-black700/10  `} onClick={() => {
                                                window.open(`/leads/${lead.lead_uuid}`)
                                            }}>
                                                View Lead
                                            </a> :
                                                <a className={`border border-primary text-primary !py-2 px-3 rounded-lg hover:bg-primary/10`} onClick={() => {
                                                    handleCheckLeadExist(lead, 'triple')
                                                }}>
                                                    Create Lead
                                                </a>
                                            }
                                        </td>}
                                        <td>{lead?.schedule_date ? moment(lead?.schedule_date).format("h:mm a, DD MMM YY") : '-'}</td>
                                        <td>
                                            {!lead.closing_manager && !lead.create_lead_by ? <div className='w-[220px]'>
                                                <InputSelect
                                                    placeholder={'Select Closing Manager'}
                                                    name='assignTo'
                                                    options={allUsers}
                                                    value={''}
                                                    onChange={(e) => {
                                                        handleCheckLeadExist(lead, 'table', e.value)
                                                    }}
                                                    isMulti={false}
                                                    disable={assignDisable}
                                                />
                                            </div> : lead.closing_manager || '-'}
                                        </td>
                                        <td>
                                            {lead?.sourcing_manager || '-'}
                                        </td>
                                        <td>
                                            <span className={`${(lead?.is_lead_existed || lead?.lead_uuid) ? getClassName('yes') : getClassName('no')} `} >
                                                {(lead?.is_lead_existed || lead?.lead_uuid) ? 'Yes' : 'No'}
                                            </span>
                                        </td>
                                        <td>{lead?.broker_name || '-'}</td>
                                        <td>{lead?.broker_mobile || '-'}</td>
                                        <td>
                                            {(lead?.company_name || lead?.broker_company_name) && <div className='flex items-center'>
                                                {lead?.company_name && <button onClick={() => {
                                                    if (lead?.broker_company_id) {
                                                        window.open(`/cp-manager/users/${lead?.cp_firm_uuid}?company=${lead.company_name}&id=${lead.cp_firm_id}`)
                                                    }
                                                }}>
                                                    {lead?.company_name?.length > 20 ?
                                                        <TooltipText title={lead?.company_name}>
                                                            <div className='w-[100px] truncate'>
                                                                {lead?.company_name}
                                                            </div>
                                                        </TooltipText>
                                                        : lead?.company_name
                                                    }
                                                </button>}
                                                {lead?.broker_company_name && <button onClick={() => {
                                                    if (lead?.broker_company_id) {

                                                        window.open(`/cp-manager/users/${lead?.cp_firm_uuid}?company=${lead.company_name}&id=${lead.cp_firm_id}`)
                                                    }
                                                }}>
                                                    {lead?.broker_company_name?.length > 20 ?
                                                        <TooltipText title={lead?.broker_company_name}>
                                                            <div className='w-[100px] truncate'>
                                                                {lead?.broker_company_name}
                                                            </div>
                                                        </TooltipText>
                                                        : lead?.broker_company_name}
                                                </button>}
                                                {(!lead.broker_company_id && lead.company_owner_mobile && lead.company_owner_name) && <div className='view-notes sv-info !ml-2'>
                                                    <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <ExpiryInfoIcon />
                                                    </button>
                                                    <div className="dropdown-menu info_box !border !border-red" aria-labelledby="dropdownMenuButton">

                                                        <div className="notes drop-notes items-center ">
                                                            <div className="left-icon active:!bg-transparent">
                                                                <RedInfoIcon />
                                                            </div>
                                                            <div className="right-text">
                                                                <p className='text-[#344054] text-xs mb4 ro'>CP of this customer is not registered with us</p>
                                                            </div>
                                                        </div>
                                                        <div className='w-full flex justify-end !mt-2'>
                                                            <button
                                                                className='text-white !bg-[#2064FA] focus:!bg-[#2064FA] focus:!text-white rounded-full !px-2.5 !py-1.5'
                                                                onClick={() => handleSendInvite(lead)}
                                                            >
                                                                Invite CP
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>}
                                            </div>}
                                            {
                                                (!lead?.company_name && !lead?.broker_company_name && '-')
                                            }
                                        </td>
                                        <td>{lead.company_owner_mobile || '-'}</td>
                                        <td>
                                            {(lead?.company_owner_email && lead?.company_owner_email.length > 20) ?
                                                <TooltipText title={lead?.company_owner_email}>
                                                    <div className='w-[100px] truncate'>
                                                        {lead?.company_owner_email}
                                                    </div>
                                                </TooltipText>
                                                : (lead?.company_owner_email || '-')}
                                        </td>
                                        <td>
                                            {lead.broker_company_id ? 'Yes' : (lead.company_owner_mobile && lead.company_owner_name) ? 'No' : '-'}
                                        </td>
                                        <td>{lead?.completed_by || '-'}</td>
                                        <td>{lead?.create_lead_by || '-'}</td>
                                        <td>{lead?.created_lead_time ? moment(lead?.created_lead_time).format("h:mm a, DD MMM YY") : '-'}</td>
                                        <td>{lead?.customer_alternate_number || '-'}</td>
                                        <td>{lead?.preference || '-'}</td>
                                        <td>{lead?.investement || '-'}</td>
                                        <td className='capitalize '>{lead?.purpose || '-'}</td>
                                        <td>{lead?.possession_pref || '-'}</td>
                                        <td>{lead.reference_name || '-'}</td>
                                        <td>{lead.reference_mobile || '-'}</td>
                                        <td>{lead.reference_society || '-'}</td>
                                        <td>{lead.reference_flat_no || '-'}</td>
                                        <td>{lead?.customer_profession || '-'}</td>
                                        <td>
                                            {lead?.customer_address ? <TooltipText title={lead?.customer_address}>
                                                <div className='w-[200px] truncate'>
                                                    {
                                                        lead?.customer_address
                                                    }
                                                </div>
                                            </TooltipText> : '-'}
                                        </td>
                                        <td>{lead?.customer_company || '-'}</td>
                                        <td>{lead?.customer_designation || '-'}</td>
                                        <td>{lead.project_name}</td>
                                        <td>{lead?.about_project || '-'}</td>
                                        <td className='capitalize'>{lead?.sv_type || '-'}</td>
                                        <td>
                                            {lead?.remarks ? <TooltipText title={lead?.remarks}>
                                                <div className='w-[200px] truncate'>
                                                    {
                                                        lead?.remarks
                                                    }
                                                </div>
                                            </TooltipText> : '-'}
                                        </td>
                                        <td>
                                            {lead.image ? <a className={`border text-textblack block w-max !py-2 px-3 rounded-lg hover:bg-black700/10  `}
                                                onClick={() => {
                                                    setSelectedImg(lead);
                                                    setShowLightModal(true)
                                                }}
                                            >
                                                View
                                            </a> : '-'}
                                        </td> */}
                                    <td className='dropdown view-more !sticky -right-3'>
                                        <button className="!w-[50px] !h-7 rounded-lg hover:bg-black100 flex items-center justify-center" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <ThreedotsIcon />
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-right !p-2 !w-[220px]" aria-labelledby="dropdownMenuButton">
                                            {/* {(allowedPermissions?.includes('create_broker_lead') && !lead?.create_lead_by) && <a className="dropdown-item text-capitalize"
                                                    onClick={() => {
                                                        handleCheckLeadExist(lead, 'triple_dot')
                                                    }}
                                                >
                                                    Create Lead
                                                </a>} */}
                                            <a className="dropdown-item"
                                                onClick={() => {
                                                    setSelectedItem(lead);
                                                    setShowEOIDoneModal(true);
                                                }}
                                            >
                                                Add Token
                                            </a>

                                            <a className="dropdown-item"
                                                onClick={() => {
                                                    setSelectedItem(lead);
                                                    setShowBookingModal(true);
                                                }}
                                            >
                                                Add Booking
                                            </a>
                                            <a className="dropdown-item"
                                                onClick={() => {
                                                    setSelectedLead(lead);
                                                    setShowQualificationModal(true);
                                                }}
                                            >
                                                Change Qualification Status
                                            </a>
                                            <a className='dropdown-item'
                                                onClick={() => {
                                                    setShowSVModal(true);
                                                    setSelectedItem(lead)
                                                }}
                                            >
                                                Revisit
                                            </a>
                                            <a className="dropdown-item"
                                                onClick={() => {
                                                    window.open(`/presale/opportunity-details/${lead?.lead_uuid}`)
                                                }}
                                            >
                                                View Timeline
                                            </a>

                                            <a className="dropdown-item"
                                                onClick={() => {
                                                    window.open(`/presale/opportunity-details/${lead?.lead_uuid}`)
                                                }}
                                            >
                                                View Details
                                            </a>
                                            <a className="dropdown-item"
                                                onClick={() => {
                                                    window.open(`/dashboard/details/${lead?.customer_uuid}`)
                                                }}
                                            >
                                                View Customer
                                            </a>
                                            {/* <a className="dropdown-item text-capitalize"
                                                    onClick={() => {
                                                        localStorage.setItem('searchText', lead?.customer_mobile)
                                                        window.open('/');
                                                    }}
                                                >
                                                    View Lead
                                                </a> */}
                                            {lead?.image && <a className="dropdown-item text-capitalize"
                                                onClick={() => {
                                                    setSelectedImg(lead);
                                                    setShowLightModal(true)
                                                }}
                                            >
                                                View Image
                                            </a>}
                                            {allowedPermissions?.includes('delete_sv_event') && !lead.lead_uuid && <a className="dropdown-item text-capitalize del-menu"
                                                onClick={() => {
                                                    setSelectedLead(lead);
                                                    setShowDeleteModal(true)
                                                }}
                                            >
                                                Delete SV
                                            </a>}
                                            <a className="dropdown-item text-capitalize del-menu"
                                                onClick={() => {
                                                    setSelectedLead(lead);
                                                    setSelectedItem(lead);
                                                    setMarkDead(true)
                                                }}
                                            >
                                                Mark as Dead
                                            </a>
                                        </div>
                                    </td>
                                </tr >
                            ))
                        }
                    </tbody >
                </table >
                {(leads?.length === 0 && !isLoading) && <div className='d-flex justify-content-center flex-column items-center !max-h-[50vh] !min-h-[50vh]'>
                    <div className='flex items-center flex-col'>

                        <figure><svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none"><circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF"></circle><path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF"></path><circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974"></circle><circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE"></circle><circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC"></circle><path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974"></path><path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE"></path></svg></figure>
                        {leads?.length == 0 && (searchText) &&
                            <>
                                <h2 className="po black fz16 fw-po-medium mb-8">Sorry, we couldn’t find any results</h2>
                                <p className='text-center black-dark-600 fz14 mb-0'> Please try again</p>
                            </>
                        }
                        {
                            leads?.length == 0 && !searchText &&
                            <>
                                <h2 className="po black fz16 fw-po-medium mb-8"> No Leads Found</h2>
                                {/* <p className='text-center black-dark-600 fz14 mb-0'>Please add new leads to view</p>                                */}
                            </>
                        }
                    </div>
                </div>}
                {
                    isLoading && <div className='d-flex w-full justify-content-center flex-column items-center !max-h-[calc(100vh-245px)] !min-h-[calc(100vh-245px)]'>
                        <Oval
                            height={50}
                            width={50}
                            color="#0062FF"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#0062FF"
                            strokeWidth={4}
                            strokeWidthSecondary={4}
                        />
                    </div>
                }
                {
                    showCustomisedColumnModal &&
                    <SVColumnCustomisation
                        dashboardColumns={dashboardColumns}
                        activeColumnTab='Completed'
                        show={showCustomisedColumnModal}
                        setShow={setShowCustomisedColumnModal}
                        updateColumnList={updateColumnList}
                        handleChangeSelection={handleChangeSelection}
                        saveCurrentUserColumnPrefrence={saveCurrentUserColumnPrefrence}
                    />
                }
                {
                    showEOIDoneModal &&
                    <NewEOIModal
                        show={showEOIDoneModal}
                        closeModal={() => {
                            setShowEOIDoneModal(false);
                            setSelectedItem({});
                        }}
                        lead={{ ...selectedItem, id: selectedItem?.lead_uuid, mobile: selectedItem?.customer_mobile, project: selectedItem?.project_name }}
                        data={{ ...selectedItem, id: selectedItem?.lead_uuid, mobile: selectedItem?.customer_mobile, project: selectedItem?.project_name }}
                        onSuccess={() => {
                            setSelectedItem({});
                            setShowEOIDoneModal(false);
                            getEvents();
                            getStats();
                        }}
                    />
                }
                {
                    showBookingModal &&
                    <NewBookingModal
                        show={showBookingModal}
                        closeModal={() => setShowBookingModal(false)}
                        lead={{ ...selectedItem, id: selectedItem?.lead_uuid, mobile: selectedItem?.customer_mobile, project: selectedItem?.project_name }}
                        data={{ ...selectedItem, id: selectedItem?.lead_uuid, mobile: selectedItem?.customer_mobile, project: selectedItem?.project_name }}
                        onSuccess={() => {
                            setSelectedItem({});
                            setShowBookingModal(false);
                            getEvents();
                            getStats();
                        }}
                    />
                }
            </div >
            {/* // </div > */}

            {
                showTaggingConflictModal && <TaggingConflictModal
                    show={showTaggingConflictModal}
                    closeModal={() => setShowTaggingConflictModal(false)}
                    conflictData={conflictData}
                    setConflictData={setConflictData}
                    opportunityData={opportunityData}
                    onSuccess={onSuccess}
                    allUsers={allUsers}
                    selectedClosing={selectedClosing}
                    selectedSV={selectedSV}
                />
            }
            {showSVModal && <SVScheduleModal
                show={showSVModal}
                setShow={() => {
                    setShowSVModal(false);
                }}
                leadName={selectedItem?.customer_name || selectedItem?.name}
                leadId={selectedItem?.lead_uuid}
                onSuccess={() => {
                    getEvents();
                    getStats();
                }}
                intentOptions={intentStatuses}
                selectedIntent={selectedItem?.intent}
                statusId={statuses.find(i => i.label === 'SV Scheduled').value}
                reassindedUserUuid={selectedItem?.presales_manager}
                isEdit={false}
                svUUID={''}
                isNew={true}
            />}
            {markDead && <MarkDeadModal
                show={markDead}
                setShow={setMarkDead}
                uuid={selectedItem?.lead_uuid}
                updateData={() => { }}
                selectedOption={{}}
                data={selectedItem}
                statusId={statuses.find(i => i.label === 'Dead Lead').value}
                reassindedUserUuid={selectedItem?.assigned_user_uuid}
                onSuccess={() => {
                    getEvents();
                    getStats();
                }}
            />}
            {/* // </div > */}
        </>
    )
}

export default SiteVisitCompletedLeads