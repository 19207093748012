import React from 'react'

import { ReactComponent as StatusIcon } from '../../../../../assets/icons/status_icon.svg';
import { ReactComponent as SuccessIcon } from '../../../../../assets/icons/success_layer.svg'
import { ReactComponent as InfoAlert } from '../../../../../assets/new/info_alert.svg';
import moment from 'moment';
import BasicModal from '../../../../../components/Modals/BasicModal';
import { useHistory } from 'react-router-dom';

const OpportunityExist = ({ show, closeModal, leadExistResponse }) => {
    const history = useHistory();

    return (
        <BasicModal show={show} closeModal={closeModal} modalClass='!max-w-[500px] !min-w-[500px]'>
            <div className='!p-6'>
                <div className='!mb-5'>
                    <InfoAlert />
                </div>
                <h1 className='text-lg font-semibold text-textblack'>Opportunity Already Exists</h1>
                <p className='text-sm text-grey-500 !mb-5'> The customer already exists with <span className='text-[#344054] font-medium'>{leadExistResponse?.customer_mobile}</span> for this Opportunty.</p>
                <div className=''>
                    <div className='w-[351px] !p-3.5 border-y border-r !rounded-lg border-l-4 border-l-green existing-opp-card-shadow'>
                        <h1 className='text-base font-bold text-textblack !mb-1.5'>
                            {leadExistResponse?.customer_name.split(' ')[0]}
                            <span className='h-1 w-1 mx-1.5 rounded-full inline-block bg-grey-500 !border !border-grey-500'></span>
                            {leadExistResponse?.project_name}
                        </h1>
                        <p className='text-xs !mb-2 text-grey-500 font-medium flex items-center'>
                            <span>{leadExistResponse?.customer_mobile}</span>
                            {leadExistResponse?.pre_sale_name && <>
                                <span className='h-1 w-1 mx-1.5 rounded-full inline-block bg-grey-500 !border !border-grey-500'></span>
                                <p className='text-sm mb-0 font-medium text-grey-500'>
                                    <span>Assigned: </span>
                                    <span>{leadExistResponse?.pre_sale_name}</span>
                                </p>
                            </>
                            }
                            {/* <span>{leadExistResponse?.project_name}</span> */}
                        </p>
                        <div className=''>
                            {leadExistResponse?.status_display_name && <div className='flex rounded-lg w-max !mb-2 bg-[#F2F3F6] items-center !p-2 '>
                                <StatusIcon className='mr-1.5 !h-5 !w-5' />
                                <p className='text-sm mb-0 text-black-t'>{leadExistResponse?.status_display_name}</p>
                            </div>}
                            {/* <div className='flex rounded-lg !mb-2 bg-[#F2F3F6] items-center !p-2 !w-[125px] '>
                            {/* <StatusIcon className='mr-1.5 !h-5 !w-5' />
                        <p className='text-sm mb-0'>{leadExistResponse?.status?.data?.display_name}</p>
                    </div> */}
                        </div>

                        <p className='text-xs font-medium text-grey-500 flex justify-end mb-0'>
                            <span>{moment(leadExistResponse?.created_on).format('Do MMM YYYY')}</span>
                        </p>
                    </div>
                </div>

                <div className='w-full !mt-5 !pt-5 !gap-3 grid grid-cols-2'>
                    <button
                        type='button'
                        className='text-base font-medium inter leading-6 !rounded-lg !border !border-grayLight !text-black  h-[44px] text-center inter'
                        onClick={() => {

                            closeModal();
                        }}
                    >Close</button>
                    <button
                        type='submit'
                        className='text-base font-medium inter leading-6 !rounded-lg !text-white !bg-primary  h-[44px] text-center inter'
                        onClick={() => {
                            history.push(`/presale/opportunity-details/${leadExistResponse?.uuid}`)
                            closeModal();
                        }}
                    >Open Opportunity</button>
                </div>
            </div>
        </BasicModal >
    )
}

export default OpportunityExist;