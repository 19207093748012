import React, { useContext, useState, useEffect } from 'react';
import { addFinalFollowupSchema, addFollowupSchema, updateFollowupSchema } from '../../../../validators/leads.validator';
import { Formik } from 'formik';
import moment from 'moment';
import { getActiveFollowupByLeadId, insertFollowup } from '../../../../services/private/followups.service';
import { toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';
import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { getLeadAccess, getLeadStatusAssignedToConfigByStatusId, getLeadStatusShareConfigByStatusID, getLeadStatusShareConfigByStatusId, getUsersForSettings, saveLeadAccess } from '../../../../services/private/company.service';
import { validityUnit } from '../../../../helpers/enums';
import { ReactComponent as PlusPrimaryIcon } from '../../../../assets/icons/PlusPrimary.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import Consumer from '../../../../helpers/context';
import { checkIsArrayEmpty, checkIsSomeItemAvailableInList } from '../../../../helpers/helpers';
import { Oval } from 'react-loader-spinner';
import SliderModal from '../../../../components/Modals/SliderModal';
import InputText from '../../../../components/InputGroup/InputText';
import InputSelect from '../../../../components/InputGroup/InputSelect';
import DateTimePicker from '../../../../components/InputGroup/DateTime';
import { searchLeadByName } from '../../../../services/private/leads.service';
import { changeStatus, getOpportunityDetails, rescheduleFollowup } from '../../services/dashboard.service';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },
        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        color: state.isSelected ? '#44444F' : '#44444F',
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};

export default function UpdateFollowupModal({
    show,
    setShow,
    leadId,
    onSuccess,
    leadName,
    intentOptions,
    selectedIntent,
    selectedStatus,
    statusLabel,
}) {

    const [followupData, setFollowupData] = useState({});

    // this is a hack update it when you have time :) @ankit
    const [selectedLeadId, setSelectedLeadId] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [allUsers, setAllUsers] = useState([]);

    const [usersForAssigned, setUsersForAssigned] = useState([]);
    const [leadDetails, setLeadDetails] = useState({})

    const { userProfile, allowedPermissions } = useContext(Consumer);
    const ROLE_NAME = localStorage.getItem('role');

    const getAllUsers = () => {
        getUsersForSettings().then(res => {
            if (res.status === 200) {
                const userData = res.data.data.map((data) => {
                    return {
                        label: data.name + " - " + data.email,
                        // label: data.name,
                        value: data.id
                    }
                })
                const usersForAssign = res.data.data.map((data) => {
                    return {
                        label: data.name + " - " + data.email,
                        // label: data.name,
                        value: data.id,
                        uuid: data.uuid
                    }
                });
                setUsersForAssigned(usersForAssign);
                setAllUsers(userData)
            }
        })
    }

    useEffect(() => {
        if (leadId) {
            // getActiveFollowup(leadId)
            getLeadDetails();
            setSelectedLeadId(leadId)

        }
    }, [leadId]);

    useEffect(() => {
        getAllUsers();
    }, [])
    const getAllLeadsForInput = async (leadName) => {
        return searchLeadByName(leadName).then(res => {
            if (res.status === 200) {
                const leadOptionsReq = res.data.data.map((data) => {
                    return {
                        label: <>
                            <span>
                                {data.name}
                            </span>
                            <span>
                                {" (" + data.mobile + ") "}
                            </span>
                        </>,
                        value: data.uuid
                    }
                })
                return (leadOptionsReq)
            }
        })
    }


    const getLeadDetails = async () => {
        getOpportunityDetails(leadId).then(res => {
            if (res.status === 200) {
                setLeadDetails(res.data.data)
            }
        })
    }

    const updateFollowupCall = async (scheduleDate, remarks, intentId) => {
        setIsSubmitting(true);
        const payload = {
            scheduleDate: scheduleDate,
            intentId: intentId,
            remarks: remarks,
        }
        try {
            let res = {}

            res = await rescheduleFollowup(leadId, payload)
            if (res.status === 200) {
                toast.success("Followup Updated")
                onSuccess();
                setShow(false);
            }
            setIsSubmitting(false);
        } catch (err) {
            toast.error(err?.response?.data?.maessage)
            setIsSubmitting(false);
        }

    }

    const handleSumitForm = (values) => {
        if (followupData && followupData.schedule_date && !values.remarks) {
            toast.error('Please Enter Comments');
            return;
        }
        updateFollowupCall(values.scheduleDate, values.remarks, values.intent[0]?.value, values.info?.value)
    }

    const intentVal = useMemo(() => {
        // if ((statusLabel === 'Not Connected' || statusLabel === 'In Progress') && !selectedStatus) {
        //     const intent = intentOptions.filter((intent) => intent.label === 'Cold Lead');
        //     return intent
        // }
        if (selectedIntent) {
            const intent = intentOptions.filter((intent) => (intent.value === selectedIntent || intent.label === selectedIntent));
            return intent
        } else {
            const intent = intentOptions.filter(i => i.label === leadDetails?.intent_display_name)
            return intent
        }
    }, [leadDetails]);

    console.log(leadDetails, 'date')
    return (
        <SliderModal
            show={show}
            setShow={setShow}
            title={
                `Edit Followup`
            }
            body={
                <>
                    <Formik
                        initialValues={{
                            scheduleDate: leadDetails?.latest_followup?.schedule_date ? moment(leadDetails?.latest_followup?.schedule_date).add('5:30', 'hours') : '',
                            remarks: leadDetails?.latest_followup?.remarks ? leadDetails?.latest_followup?.remarks : '',
                            leadId: leadId || '',
                            intent: intentVal || '',
                        }}
                        onSubmit={(values, actions) => {
                            handleSumitForm(values)
                        }}
                        validateOnBlur={false}
                        validateOnChange={false}
                        validationSchema={updateFollowupSchema}
                        enableReinitialize
                    >
                        {
                            (props) => {
                                const { values, touched, errors, handleChange, handleBlur, handleReset, handleSubmit } = props;
                                return (
                                    <form onSubmit={handleSubmit} className='flex flex-col h-full overflow-hidden'>
                                        <div className='h-full overflow-auto !px-5 !pb-6'>
                                            <div className={`form-group ${errors['leadId'] ? 'invalid' : ''}  position-relative`}>
                                                <label className=''>Lead Name</label>
                                                {!leadId ?
                                                    <>
                                                        <AsyncSelect
                                                            cacheOptions
                                                            loadOptions={getAllLeadsForInput}
                                                            defaultOptions
                                                            value={values['leadId']}
                                                            touched={touched['leadId']}
                                                            error={errors['leadId']}
                                                            onChange={(e) => {
                                                                const value = { target: { name: "leadId", value: e } }
                                                                handleChange(value)
                                                                setSelectedLeadId(e.value)
                                                                // getActiveFollowup(e.value)
                                                            }}
                                                        // onInputChange={getAllLeadsForInput}
                                                        />
                                                        {errors['leadId'] && (
                                                            <>
                                                                <div className="input-feedback mt-8">{errors['leadId']}
                                                                </div>
                                                            </>

                                                        )}
                                                    </>
                                                    : <div className='text-capitalize fz16 fw-po-medium'>
                                                        {followupData.customername || leadName}
                                                    </div>
                                                }
                                            </div>

                                            <DateTimePicker
                                                handleBlur={handleBlur}
                                                onValueChange={(e) => {
                                                    const changeVal = {
                                                        target: {
                                                            value: e,
                                                            name: "scheduleDate"
                                                        }
                                                    }
                                                    handleChange(changeVal)
                                                }}
                                                label="Select Followup Date & Time"
                                                name="scheduleDate"
                                                error={errors['scheduleDate']}
                                                value={values['scheduleDate'] ? values['scheduleDate'] : ''}
                                            />

                                            <InputSelect
                                                index={4}
                                                name="intent"
                                                maxMenuHeight="150px"
                                                styles={customStyles}
                                                error={errors['intent']}
                                                touched={touched['intent']}
                                                options={intentOptions}
                                                placeholder="Select Intent"
                                                value={values.intent}
                                                onChange={(e) => {
                                                    const value = {
                                                        target: {
                                                            name: "intent",
                                                            value: [e]
                                                        }
                                                    }
                                                    handleChange(value)
                                                }}
                                                label='Intent'
                                            />

                                            <div className='flex items-center justify-between !mt-4 !mb-2'>
                                                <label className='m-0'>Comments {followupData && followupData.schedule_date ? '' : '(optional)'}</label>
                                                <button
                                                    type='button'
                                                    className='!border !border-grayLight rounded text-sm !text-black700 inter px-2.5 !gap-x-1 py-1 flex items-center flex-nowrap !bg-white hover:!bg-black200'
                                                    onClick={() => {
                                                        const value = {
                                                            target: {
                                                                name: "remarks",
                                                                value: ''
                                                            }
                                                        }
                                                        handleChange(value)
                                                    }}
                                                >Clear <CloseIcon className='h-3 w-3' /></button>
                                            </div>
                                            <textarea
                                                className="form-control mb-16"
                                                name="remarks"
                                                value={values['remarks']}

                                                placeholder="Enter any follow up Comments..."
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                defaultValue={followupData.remarks}
                                            >

                                            </textarea>
                                        </div>
                                        <div className="form-inline justify-content-between px-20 filter-ok-cancel">
                                            <button
                                                type='button'
                                                className="cancel outline-btn"
                                                onClick={() => setShow(false)}
                                            >
                                                Cancel
                                            </button>
                                            {!isSubmitting && <button
                                                className="pr-btn"
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                Update Followup
                                            </button>}
                                            {isSubmitting && <button className="pr-btn flex items-center justify-center" type='button'>
                                                <Oval
                                                    height={24}
                                                    width={24}
                                                    color="#ffffff"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                    ariaLabel='oval-loading'
                                                    secondaryColor="#ffffff"
                                                    strokeWidth={4}
                                                    strokeWidthSecondary={4}
                                                />
                                            </button>}
                                        </div>
                                    </form>
                                )
                            }}
                    </Formik>

                </>
            }
        />
    )
}