import React, { useRef, useState } from 'react'
import Webcam from 'react-webcam';
import BasicModal from '../../../components/Modals/BasicModal';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { API_URLS } from '../../../config/apiUrl';
import { Oval } from 'react-loader-spinner';
import axios from 'axios';

const CaptureImageModal = ({ show, closeModal, setImageUrl }) => {
    const webcamRef = useRef();
    const [isLoading, setIsLoading] = useState(true);

    const handleSubmitImage = async (imageSrc) => {
        try {
            // Convert base64 image to Blob
            const blobData = await fetch(imageSrc).then(res => res.blob());

            // Request a signed URL from the backend
            setIsLoading(true)
            const response = await axios.post(`${API_URLS.baseurl}/public/getS3PresignedUrl`, {
                key: `img_${new Date().getTime()}.jpg`,
                contentType: blobData.type,
            });
            const signedUrl = response.data.data.url;
            // Upload the image to S3 using the signed URL
            await axios.put(signedUrl, blobData, {
                headers: {
                    'Content-Type': blobData.type,
                },
            }).then(res => {
                setIsLoading(false);
                setImageUrl(signedUrl.split('?')[0]);
                closeModal()
            });

        } catch (error) {
            setIsLoading(false);
            console.error('Error uploading the file:', error);
        }
    }

    return (
        <BasicModal show={show} closeModal={closeModal} modalClass='!min-w-[600px] md:!mx-auto !mx-4 capture-image'>
            <div className='absolute -top-10 flex items-center justify-center w-full'>
                <button onClick={closeModal} className='!h-8 !w-8 rounded-full bg-white flex items-center justify-center'>
                    <CloseIcon />
                </button>
            </div>
            <Webcam
                audio={false}
                // height={'60vh'}
                // width={'100%'}
                ref={webcamRef}
                screenshotFormat="image/jpeg" >
                {({ getScreenshot }) => (
                    <button
                        className='w-full flex items-center justify-center'
                        onClick={() => {
                            const imageSrc = getScreenshot();
                            handleSubmitImage(imageSrc)
                        }}
                    >
                        <span className=' w-full flex flex-col items-center justify-center  text-center  absolute bottom-2'>
                            <span className='w-[64px] h-[64px] !border-2 rounded-full border-white flex items-center justify-center !p-1'>
                                <span className={`h-[48px] w-[48px] bg-white rounded-full ${isLoading ? 'flex items-center justify-center' : ''}`}>
                                    {/* <CameraIcon className='!h-[40px] !w-[40px]' /> */}
                                    {isLoading && <Oval
                                        height={24}
                                        width={24}
                                        color="#0062FF"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                        ariaLabel='oval-loading'
                                        secondaryColor="#0062FF"
                                        strokeWidth={8}
                                        strokeWidthSecondary={8}
                                    />}
                                </span>
                            </span>
                            {/* <p className='mb-0 uppercase text-white !mt-2 font-bold text-sm sm:text-lg'> CAPTURE</p> */}
                        </span>
                    </button>
                )}
            </Webcam>
        </BasicModal>
    )
}

export default CaptureImageModal