import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import PopUpModal from './PopUpModal'
import { Oval } from 'react-loader-spinner'
import InputText from '../InputGroup/InputText'

const ConfirmDeletion = ({ show, setShow, deleteHandler, title, deleteText, subText, deleteLeading }) => {

    const [inputText, setInputText] = useState('');
    const [error, setError] = useState(false);

    return (
        <PopUpModal
            setShow={setShow}
            show={show}
            heading={<>
                {title ? title : 'Confirm Delete?'}
            </>}
            body={<>

                <p className='black-dark-800 fz16 po m-0'>Are you sure you want to delete {` ${deleteText ? deleteText : 'Lead(s)'}`}?</p>
                <div className='!px-5 !mt-4'>

                    <InputText
                        label={'Type DELETE to confirm'}
                        value={inputText}
                        onChange={(e) => setInputText(e.target.value)}
                        error={error ? 'Sorry, please enter the text exactly as displayed to confirm.' : ''}
                    />
                </div>
                {subText && <p className='black-dark-800 fz16 po m-0'>{subText}</p>}

                <div className="justify-content-end d-flex modal-footer mt-3">
                    <Button variant="" className="cancel" onClick={() => setShow(false)}>
                        Cancel
                    </Button>
                    {!deleteLeading && <Button variant="" className='del-btn' onClick={() => {
                        if (!inputText || inputText !== 'DELETE') {
                            setError(true);
                            return;
                        }
                        deleteHandler();
                    }}>
                        Delete
                    </Button>}
                    {deleteLeading && <button className="del-btn px-20 flex items-center justify-center" type='button'>
                        <Oval
                            height={24}
                            width={24}
                            color="#ffffff"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#ffffff"
                            strokeWidth={4}
                            strokeWidthSecondary={4}
                        />
                    </button>}
                </div>
            </>}
        />
    )
}

export default ConfirmDeletion