import React, { useContext, useEffect, useMemo, useState } from 'react'
import RightModal from '../../../../../components/Modals/RightModal'
import InputSelect from '../../../../../components/InputGroup/InputSelect';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close.svg';
import DateTimePicker from '../../../../../components/InputGroup/DateTime';
import { getProjectsForFilter, reassignLeads } from '../../../../../services/private/leads.service';
import TooltipText from '../../../../../components/Tooltip';
import Consumer from '../../../../../helpers/context';
import { getLeadAccess, getLeadStatusAssignedToConfigByStatusId, getLeadStatusShareConfigByStatusId, getUsersForSettings, saveLeadAccess } from '../../../../../services/private/company.service';
import { checkIsSomeItemAvailableInList } from '../../../../../helpers/helpers';
import moment from 'moment';
import InputText from '../../../../../components/InputGroup/InputText';
import { validityUnit } from '../../../../../helpers/enums';
// import { ReactComponent as PlusPrimaryIcon } from '../../../../assets/icons/PlusPrimary.svg';
import { toast } from 'react-toastify';
import MobileInput from '../../../../../components/InputGroup/MobileInput';
import { customerDashboard, findCustomerWithMobile, insertOpportunity } from '../../../services/dashboard.service';
import { Oval } from 'react-loader-spinner';
import AsyncSelect from 'react-select/async';
import { InProgessReasonsList, NotConnectedReasonsList } from '../../../../../utils/constants';
import BasicModal from '../../../../../components/Modals/BasicModal';
import { ReactComponent as NavRight } from '../../../../../assets/icons/NavRight.svg';
import BasicDetails from './BasicDetails';
import ReferralDetails from './ReferralDetails';
import OpportunityExist from './OpportunityExist';
import AlertModal from '../AlertModal';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        color: '#44444f',
        zIndex: 99,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        },
    }),
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({ ...provided, zIndex: 9999 })
};

const AddOpportunityModal = ({ closeModal, show, handleSuccess, uuid, customerName, customerMobile, isDisable }) => {
    const [projects, setProjects] = useState([]);
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('')
    const [usersForAssigned, setUsersForAssigned] = useState([]);
    const [remarks, setRemarks] = useState('')
    const [users, setUsers] = useState([
        {
            access: 'edit',
            validity: 30,
            validity_unit: 'days'
        }
    ]);

    const [allUsers, setAllUsers] = useState([]);
    const [source, setSource] = useState('');
    const [sourceList, setSourceList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedPresale, setSelectedPresale] = useState('');
    const [selectedClosing, setSelectedClosing] = useState('');
    const [steps, setSteps] = useState('basic');
    const [referredName, setReferredName] = useState('');
    const [referredMobile, setReferredMobile] = useState('');
    const [referredBySociety, setReferredBySociety] = useState('')
    const [referredFlatNo, setReferredFlatNo] = useState('');
    const [showSubmitOpportunityExistModal, setShowSubmitOpportunityExistModal] = useState(false);
    const [opportunityDetail, setOpportunityDetail] = useState({});
    const { userProfile, allowedPermissions, companyConfig } = useContext(Consumer);
    const [showAlertModal, setShowAlertModal] = useState(false);

    const ROLE_NAME = localStorage.getItem('role');


    useEffect(() => {
        if (uuid && customerName && customerMobile) {
            // setCustomer({
            //     name: customerName,
            //     mobile: customerMobile,
            //     value: uuid
            // })
            setName(customerName);
            setMobile(customerMobile)
        }
    }, [uuid, customerName, customerMobile]);

    const getAllUsers = () => {
        getUsersForSettings().then(res => {
            if (res.status === 200) {
                const usersForAssign = res.data.data.map((data) => {
                    return {
                        label: data.name + " - " + data.email,
                        // label: data.name,
                        value: data.id
                    }
                });
                setUsersForAssigned(usersForAssign);
            }
        })
    }

    const handleSubmit = async () => {
        // (projects.length === 0 && (!followupDate || !intentId))
        if (!name || !mobile || !source.value || (isDisable && projects.length === 0)) {
            toast.error('Please fill required fields');
            return;
        }
        if (isDisable && projects.length > 0) {
            let empty = projects.some(p => (!p.value))
            if (empty) {
                toast.error('Please fill required fields');
                return;
            }
        }

        if (source.label === 'Reference' && (!referredName || !referredMobile)) {
            toast.error('Please fill required fields');
            return;
        }
        const payload = {
            customer_uuid: uuid || '',
            name: name,
            countryCode: mobile?.slice(0, 2),
            mobile: mobile?.slice(2, 12),
            sourceId: source.value,
            preSaleUserId: selectedPresale || null,
            sourcingUserId: null,
            closingUserId: selectedClosing || null,
            remarks: remarks,
            referredBy: referredName || null,
            referredByNumber: referredMobile || null,
            referredBySociety: referredBySociety || null,
            referredByFlatNo: referredFlatNo || null,
            projects: projects?.length > 0 ? projects.map(i => ({
                projectId: i.value,
                preSaleUserId: i.preSaleUserId || null,
                closingUserId: i.closingUserId || null,
                remarks: i.remarks
            })) : [{
                projectId: null,
                preSaleUserId: selectedPresale || null,
                closingUserId: selectedClosing || null,
                remarks: remarks
            }]
        }
        try {
            setLoading(true);
            const res = await insertOpportunity(payload);
            setLoading(false);
            if (res.data.status === 200) {
                if (Object.keys(res.data.data?.opportunityData[0]?.opportunity_info).length > 0) {
                    setOpportunityDetail(res.data.data?.opportunityData[0].opportunity_info)
                    setShowSubmitOpportunityExistModal(true)
                } else {
                    toast.success(res.data.message)
                    handleSuccess();
                    closeModal();
                }
            }
        } catch (err) {
            toast.success(err.response.data.message)
            setLoading(false);
        }
    }

    return (
        <BasicModal show={show} closeModal={closeModal} modalClass='lg:!max-w-[810px] !max-w-[95%] md:!mx-auto !mx-4 !rounded-lg'>
            {/* <RightModal closeModal={closeModal} show={show} modalClass={'!w-[400px]'}> */}
            <div className="flex flex-col sticky top-0 left-0 !bg-white z-10  !pt-4 !rounded-t-lg">
                <h1 className='text-black text-2xl inter flex justify-between !mb-4 !px-5'>New Opportunity
                    <button onClick={() => {
                        setShowAlertModal(true)
                    }} className='modal-close'>
                        <CloseIcon />
                    </button>
                </h1>
                <div className='flex items-center no-scrollbar border-b border-[#E2E2EA]'>
                    <ul className='flex gap-x-3 items-center mb-0 pb-2  md:!px-0 lg:w-[100%] sm:w-[90%] w-[80%] md:!mx-5  !mx-auto no-scrollbar lg:overflow-hidden overflow-auto'>
                        <li
                            className={`flex items-center text-textblack text-sm leading-6 inter whitespace-nowrap  gap-3`}
                        // onClick={() => {
                        //     setSteps('basic');
                        // }}
                        >
                            <span className={`${steps === 'basic' ? 'before:h-px font-semibold' : ' '}  relative  before:w-full before:content-[''] before:bg-black700 before:absolute before:left-0 before:-bottom-2`}>Customer Details</span>  {source.label === 'Reference' && <NavRight />}
                        </li>
                        {source.label === 'Reference' && <li
                            className={`flex items-center text-textblack text-sm leading-6 inter whitespace-nowrap  gap-3`}
                        // onClick={() => {
                        //     setSteps('referral');
                        // }}
                        >
                            <span className={`${steps === 'referral' ? 'before:h-px font-semibold' : ' '}  relative  before:w-full before:content-[''] before:bg-black700 before:absolute before:left-0 before:-bottom-2`}>Referral Details</span>
                        </li>}
                    </ul>

                </div>
            </div>
            {
                steps === 'basic' && <BasicDetails
                    handleSuccess={handleSuccess}
                    name={name}
                    setName={setName}
                    mobile={mobile}
                    setMobile={setMobile}
                    projects={projects}
                    setProjects={setProjects}
                    remarks={remarks}
                    setRemarks={setRemarks}
                    source={source}
                    setSource={setSource}
                    loading={loading}
                    setLoading={setLoading}
                    selectedPresale={selectedPresale}
                    setSelectedClosing={setSelectedClosing}
                    selectedClosing={selectedClosing}
                    setSelectedPresale={setSelectedPresale}
                    handleSubmit={handleSubmit}
                    setSteps={setSteps}
                    closeModal={closeModal}
                    setShowAlertModal={setShowAlertModal}
                    isDisable={isDisable}
                />
            }
            {
                steps === 'referral' && <ReferralDetails
                    referredName={referredName}
                    setReferredName={setReferredName}
                    referredMobile={referredMobile}
                    setReferredMobile={setReferredMobile}
                    referredBySociety={referredBySociety}
                    setReferredBySociety={setReferredBySociety}
                    referredFlatNo={referredFlatNo}
                    setReferredFlatNo={setReferredFlatNo}
                    handleSubmit={handleSubmit}
                    loading={loading}
                    setSteps={setSteps}
                />
            }
            {showSubmitOpportunityExistModal && <OpportunityExist
                show={showSubmitOpportunityExistModal}
                closeModal={() => {
                    setShowSubmitOpportunityExistModal(false)
                    // setMobile('+91')
                }}
                leadExistResponse={opportunityDetail}
            />}
            {
                showAlertModal && <AlertModal
                    show={showAlertModal}
                    closeModal={() => setShowAlertModal(false)}
                    closeFormModal={() => {
                        closeModal();
                        setShowAlertModal(false)
                    }}
                />
            }
            {/* </RightModal> */}
        </BasicModal>
    )
}

export default AddOpportunityModal