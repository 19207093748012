import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import { useCallback } from "react";
import {
    getCompaniesForAnalytics,
    makeCompanyPremiumByUUID,
    getPlusAppFilterUsers,
    getCompanyNotes,
    saveCompanyNote,
    updateCompanyNote,
    makeCompanyFreemium,
    updateRm,
    getUsersForSettings
} from "../../services/private/company.service";
import { createDatewithTime } from "../../helpers/date"
import EditCompanyModal from "../../components/Modals/EditCompanyModal";
import { useHistory } from "react-router";
import UserLimitExeedModal from "../../components/Modals/userLimitExeedModal";
import {
    OverlayTrigger,
    Tooltip
} from 'react-bootstrap';
import Consumer from '../../helpers/context';
import { isNumber } from "../../helpers/helpers";
import StatsCard from "./StatsCard";
import TooltipText from "../../components/Tooltip";
import LeadsFooterBar from "../LeadsManager/LeadsFooterBar";
import AddRemarksModal from "./Modal/AddRemarksModal";
import MakeCompanyFreemium from "./Modal/MakeCompanyFreemium";
import UpdateCompanyRMModal from "./Modal/UpdateCompanyRMModal";
import AddNewCPFirm from "./Modal/AddNewCPFirm";
import InputSelect from "../../components/InputGroup/InputSelect";
import { Oval } from "react-loader-spinner";
import CompanyFiltersTag from "./CompanyFiltersTag";

const USER_UUID = localStorage.getItem('user');

export default function CompanyAnalytics({
    companyDetails,
    getCompanyData,
    companyStats,
    handleFilter,
    selectedStats,
    showingResults,
    setShowingResults,
    currentPage,
    setCurrentPage,
    leadsCount,
    maxPage,
    companyStartIndex,
    isLoading,
    searchText,
    filters,
    removeFilterValue
}) {

    const [selectedCompany, setSelectedCompany] = useState({});
    const [showCompanyModal, setShowCompanyModal] = useState(false);
    const [showRemarksModal, setShowRemarksModal] = useState(false);
    const [remarksList, setRemarksList] = useState([]);
    const [selectedItem, setSelectedItem] = useState({});
    const [showChangePlanModal, setShowChangePlanModal] = useState(false)
    const [showUpdateRMModal, setShowUpdateRMModal] = useState(false);
    const [usersList, setUsersList] = useState([])

    // const [roles, setRoles] = useState([]);
    // const [users, setusers] = useState();
    // const [reAssignUser, setReAssignUser] = useState();
    const [error, setError] = useState(false);

    let { allowedPermissions, isRolesModule } = useContext(Consumer);

    const ROLE_NAME = localStorage.getItem('role');
    const history = useHistory();

    const handlePremium = (uuid) => {
        makeCompanyPremiumByUUID(uuid).then(res => {
            if (res.status === 200) {
                toast.success(res.data.message)
                getCompanyData();

            }
        }).catch((e) => {
            toast.error(e.response.data.message);
        });
    }

    const getClassName = (type) => {
        switch (type) {
            case 'freemium':
                return 'cold-btn'
            case 'premium':
                return 'hot-btn'
            case 'free':
                return 'dead-btn'
        }
    }

    const getRemarks = async (uuid) => {
        const res = await getCompanyNotes(uuid);
        if (res.data.status === 200) {
            setRemarksList(res.data.data)
            setShowRemarksModal(true);
        }
    }
    const getUsers = async () => {
        const res = await getUsersForSettings();
        if (res.data.status === 200) {
            setUsersList(res.data.data.map(i => ({ ...i, label: i.name, value: i.id })))
        }
    }
    useEffect(() => {
        getUsers();
    }, []);

    const handleChangeRemarks = async (remarks) => {
        // const res = await updateRemarksByUUID(selectedItem.uuid, { remarks });
        const res = await saveCompanyNote(selectedItem.uuid, { note: remarks });
        if (res.data.status === 200) {
            toast.success(res.data.message);
            setSelectedItem({});
            setShowRemarksModal(false);
            getCompanyData();
        }
    }

    const handleUpdateRemark = async (selectedRemark) => {
        const res = await updateCompanyNote(selectedRemark.uuid, { note: selectedRemark.note })
        if (res.data.status === 200) {
            toast.success(res.data.message);
            setSelectedItem({});
            setShowRemarksModal(false);
            setRemarksList([])
            getCompanyData();
        }
    }

    const handleChangeCompany = async (plan) => {
        const res = await makeCompanyFreemium(selectedItem.uuid, { plan_id: plan })
        if (res.data.status === 200) {
            setShowChangePlanModal(false);
            toast.success(res.data.message);
            getCompanyData();
        }
    }

    const handleUpdateRM = async (uuid, value) => {

        const payload = {
            user_id: value
        }
        const res = await updateRm(uuid, payload);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getCompanyData();
            // closeModal();
        }
    }

    return (
        <>
            {/* {showCompanyModal ?
                <EditCompanyModal
                    show={showCompanyModal}
                    setShow={setShowCompanyModal}
                    selectedCompany={selectedCompany}
                    getCompanyData={getCompanyData}
                />
                : null} */}
            {showCompanyModal ?
                <AddNewCPFirm
                    show={showCompanyModal}
                    closeModal={() => {
                        setSelectedCompany({})
                        setShowCompanyModal(false)
                    }}
                    selectedCompany={selectedCompany}
                    getCompanyData={getCompanyData}
                />
                : null}

            <div className="users w-100 h-full flex flex-col">
                {/* <div className="grid grid-cols-4 !gap-4 !mb-4">
                    <StatsCard statsCount={companyStats.total} statsType={'Total'} handleFilter={handleFilter} selectedStats={selectedStats} />
                    <StatsCard statsCount={companyStats.not_paid} statsType={'Free'} handleFilter={() => { }} selectedStats={selectedStats} />
                    <StatsCard statsCount={companyStats.premium} statsType={'Premium'} handleFilter={handleFilter} selectedStats={selectedStats} />
                    <StatsCard statsCount={companyStats.freemium} statsType={'Freemium'} handleFilter={handleFilter} selectedStats={selectedStats} />
                </div> */}
                <div className="urer-table pr-0">
                    <div className="def-table !h-[78vh] pr-0">
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>CP Firm Name</th>
                                    <th>Name</th>
                                    <th>Mobile</th>
                                    <th>Email</th>
                                    <th>Signup Date</th>
                                    <th>Operational City</th>
                                    <th>Sourcing Manager</th>
                                    <th>Sourcing Manager Mobile</th>
                                    <th>Mircomarket</th>
                                    {/* <th>Subscription Type</th>
                                    <th>Subscription Date</th> */}
                                    <th>Remarks</th>
                                    <th className="sticky right-0"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {companyDetails && companyDetails?.length > 0 ? companyDetails.map((company, index) => {
                                    const { uuid, name, owner_name, owner_mobile, subscription_type, owner_email, is_subscribed, subscription_expiry, created_on, subscription_date, operational_cities, remarks, latest_note } = company;
                                    return (
                                        <tr key={index}>
                                            <td>{index + companyStartIndex}</td>
                                            <td className={`text-capitalize`}>
                                                {name && name?.length > 20 ?
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={
                                                            <Tooltip id="button-tooltip" >
                                                                <div className='info-msg'>
                                                                    <p className='text-capitalize'>{name}</p>
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span
                                                            className='text-capitalize three-dots cursor-pointer'
                                                            onClick={() => {
                                                                history.push(`/cp-manager/users/${uuid}?company=${name}&id=${company.id}`)
                                                            }}
                                                        >{name}</span>
                                                    </OverlayTrigger>
                                                    :
                                                    <span
                                                        className='text-capitalize cursor-pointer'
                                                        onClick={() => {
                                                            history.push(`/cp-manager/users/${uuid}?company=${name}&id=${company.id}`)
                                                        }}
                                                    >{name}</span>
                                                }
                                                {
                                                    !name && '-'
                                                }
                                            </td>
                                            <td>
                                                {owner_email?.length > 20 ?
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={
                                                            <Tooltip id="button-tooltip" >
                                                                <div className='info-msg'>
                                                                    <p className=''>{owner_name}</p>
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span className='three-dots'>{owner_name}</span>
                                                    </OverlayTrigger>
                                                    :
                                                    <span className=''>{owner_name}</span>
                                                }
                                            </td>
                                            <td>{owner_mobile ? owner_mobile : "-"}</td>

                                            <td>{owner_email ? owner_email : "-"}</td>
                                            <td>{createDatewithTime(created_on)}</td>
                                            <td className="!w-[200px]">
                                                {operational_cities?.length > 0 ?
                                                    <TooltipText title={operational_cities?.join(', ')}>
                                                        <div className="w-[200px] truncate">
                                                            {operational_cities?.join(', ')}
                                                        </div>
                                                    </TooltipText>
                                                    : '-'
                                                }
                                            </td>
                                            <td>
                                                {!company?.rm_name ? <div className='w-[225px]'>
                                                    <InputSelect
                                                        placeholder={'Select Sourcing Manager'}
                                                        name='assignTo'
                                                        options={usersList}
                                                        value={''}
                                                        onChange={(e) => {
                                                            // const leadList = [...leads]
                                                            // leadList[index]['assigned_to'] = e.value
                                                            // setEvents(leadList)
                                                            handleUpdateRM(company.uuid, e.value)
                                                        }}
                                                        isMulti={false}
                                                    />
                                                </div> : company?.rm_name || '-'}
                                            </td>
                                            <td>
                                                {company?.rm_mobile || '-'}
                                            </td>
                                            <td>{company?.micromarket || '-'}</td>
                                            {/* <td >
                                                <button className={`${getClassName(subscription_type ? subscription_type.toLowerCase() : 'free')} w-20 capitalize`}>
                                                    {subscription_type ? subscription_type : 'Free'}
                                                </button>
                                            </td>
                                            <td>{subscription_date ? createDatewithTime(subscription_date) : '-'}</td> */}
                                            <td>
                                                {
                                                    latest_note && <TooltipText title={'Edit/Show Remarks'}>
                                                        <div
                                                            className='truncate w-[200px] cursor-pointer'
                                                            onClick={() => {
                                                                setSelectedItem(company)
                                                                getRemarks(uuid)
                                                            }}
                                                        >{latest_note || remarks}</div>
                                                    </TooltipText>
                                                }
                                                {
                                                    !latest_note && <span className="border rounded-lg flex cursor-pointer w-20 hover:bg-black200 items-center justify-center" onClick={() => {
                                                        setSelectedItem(company)
                                                        getRemarks(uuid)
                                                        // setShowRemarksModal(true);
                                                    }}>
                                                        + Add
                                                    </span>
                                                }
                                            </td>
                                            <td className="dropdown view-more ">
                                                {(allowedPermissions?.includes('plus_app_manage_subscription') || allowedPermissions?.includes('plus_app_manage_users')) &&
                                                    <button
                                                        className=" border-0 !w-[50px] !h-7 rounded-lg hover:bg-black100 flex items-center justify-center"
                                                        type="button"
                                                        id="dropdownMenuButton"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z"
                                                                fill="#B5B5BE"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z"
                                                                fill="#B5B5BE"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                // clipRule="evenodd"
                                                                d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z"
                                                                fill="#B5B5BE"
                                                            />
                                                        </svg>
                                                    </button>}
                                                <div
                                                    className="dropdown-menu dropdown-menu-right w-auto"
                                                    aria-labelledby="dropdownMenuButton"
                                                    onClick={() => setSelectedCompany(company)}
                                                >

                                                    {allowedPermissions?.includes('plus_app_manage_users') && <a
                                                        className="dropdown-item"
                                                        data-toggle="modal"
                                                        data-target="#exampleModal"
                                                        onClick={
                                                            () => {
                                                                setShowCompanyModal(true)
                                                            }
                                                        }
                                                    >
                                                        Edit Company
                                                    </a>}
                                                    {allowedPermissions?.includes('plus_app_manage_subscription') && (subscription_type?.toLowerCase() === 'freemium' || !subscription_type) && <a
                                                        className="dropdown-item"
                                                        data-toggle="modal"
                                                        data-target="#exampleModal"
                                                        onClick={() => handlePremium(uuid)}
                                                    >
                                                        Make Company Premium
                                                    </a>}
                                                    {allowedPermissions?.includes('plus_app_manage_subscription') && !subscription_type && <a
                                                        className="dropdown-item"
                                                        data-toggle="modal"
                                                        data-target="#exampleModal"
                                                        onClick={() => {
                                                            setSelectedItem(company)
                                                            setShowChangePlanModal(true)
                                                        }}
                                                    >
                                                        Make Company Freemium
                                                    </a>}
                                                    <a
                                                        className="dropdown-item"
                                                        data-toggle="modal"
                                                        data-target="#exampleModal"
                                                        onClick={() => {
                                                            setSelectedItem(company)
                                                            setShowUpdateRMModal(true)
                                                        }}
                                                    >
                                                        Update Sourcing Manager
                                                    </a>
                                                    {/* <a
                                                        className="dropdown-item"
                                                        data-toggle="modal"
                                                        data-target="#exampleModal"
                                                        onClick={() => {
                                                            setSelectedItem(company)
                                                            setShowUpdateRMModal(true)
                                                        }}
                                                    >
                                                        View Leads
                                                    </a> */}
                                                    <a
                                                        className="dropdown-item"
                                                        data-toggle="modal"
                                                        data-target="#exampleModal"
                                                        onClick={() => {
                                                            // setSelectedItem(company)
                                                            // setShowUpdateRMModal(true)
                                                            history.push(`/cp-manager/users/${uuid}?company=${name}&id=${company.id}`)
                                                        }}
                                                    >
                                                        View Users
                                                    </a>
                                                    {/* <Link
                                                        className="dropdown-item"
                                                        data-toggle="modal"
                                                        data-target="#exampleModal"
                                                        to={`/cp-manager/refer-earn/${uuid}`}
                                                    >
                                                        View Refer & earn
                                                    </Link> */}

                                                </div>

                                            </td>
                                        </tr>
                                    )
                                }) : null}
                            </tbody>
                        </table>
                        {(companyDetails?.length === 0 && !isLoading) && <div className='d-flex justify-content-center flex-column text-center h-full'>
                            <div className='flex items-center flex-col'>

                                <figure><svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none"><circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF"></circle><path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF"></path><circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974"></circle><circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE"></circle><circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC"></circle><path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974"></path><path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE"></path></svg></figure>
                                {companyDetails?.length == 0 && (searchText) &&
                                    <>
                                        <h2 className="po black fz16 fw-po-medium mb-8">Sorry, we couldn’t find any results</h2>
                                        <p className='text-center black-dark-600 fz14 mb-0'> Please try again</p>
                                    </>
                                }
                                {
                                    companyDetails?.length == 0 && !searchText &&
                                    <>
                                        <h2 className="po black fz16 fw-po-medium mb-8"> No Channel Partner Found</h2>
                                        {/* <p className='text-center black-dark-600 fz14 mb-0'>Please add new leads to view</p>                                */}
                                    </>
                                }
                            </div>
                        </div>}
                        {isLoading && <div className='d-flex justify-content-center flex-column text-center h-full'>
                            <div className='flex items-center flex-col'>
                                <Oval
                                    height={50}
                                    width={50}
                                    color="#0062FF"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor="#0062FF"
                                    strokeWidth={4}
                                    strokeWidthSecondary={4}
                                />

                            </div>
                        </div>}
                    </div>
                    <LeadsFooterBar
                        showingResults={showingResults}
                        setShowingResults={setShowingResults}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        maxPage={maxPage}
                        leadsCount={leadsCount}
                        classname='!relative !basis-0 !w-full'
                    />
                </div>
                {
                    showRemarksModal && <AddRemarksModal
                        show={showRemarksModal}
                        closeModal={() => {
                            setSelectedItem({})
                            setShowRemarksModal(false)
                            setRemarksList([]);
                        }}
                        item={selectedItem}
                        handleSubmit={handleChangeRemarks}
                        remarksList={remarksList}
                        setRemarksList={setRemarksList}
                        handleUpdateRemark={handleUpdateRemark}
                    />
                }

                {showChangePlanModal && selectedItem.uuid &&
                    <MakeCompanyFreemium
                        show={showChangePlanModal}
                        closeModal={() => setShowChangePlanModal(false)}
                        handleSubmit={handleChangeCompany}
                    />}

                {
                    showUpdateRMModal && selectedItem.uuid &&
                    <UpdateCompanyRMModal
                        show={showUpdateRMModal}
                        closeModal={() => setShowUpdateRMModal(false)}
                        selectedItem={selectedItem}
                        getCompanyData={getCompanyData}
                    />
                }
            </div>

        </>
    )
}
