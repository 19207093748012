import React, { useEffect, useState } from 'react';
import { Button, Image } from "react-bootstrap";

import closeIcon from "../../../assets/icons/closeIntentImage.svg";
import checkIcon from "../../../assets/icons/checkIcon.svg";
import TooltipText from '../../Tooltip';
import SearchInput from '../../InputGroup/SearchInput';

export default function RadioCheckAccordion({
    value,
    setValue,
    selectionValues,
    accordionName,
    label,
    showSearch = true
}
) {
    const [viewMore, setviewMore] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [lists, setLists] = useState([])

    const handleCheckChange = (newValue, type) => {
        if (type === true) {
            setValue([newValue])
        }
        else {
            const updatedValue = value?.filter(data => data.value != newValue.value)
            setValue(updatedValue)
        }
    }

    const checkSelected = (id) => {
        const filterSelection = value?.find(data => data?.value == id)
        if (filterSelection) return true
        return false
    }


    useEffect(() => {
        if (searchText) {
            let filtered = selectionValues.filter(s => s.label.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
            setLists(filtered);
        } else {
            setLists(selectionValues);
        }
    }, [searchText, selectionValues]);

    const selectedFilters = () => {
        return (<>
            {
                value?.length > 0 ? value.map((data, index) => {
                    return (
                        <li>
                            <Image src={checkIcon} />
                            <span className="px-12">{data.label}</span>
                            <Image src={closeIcon} onClick={() => {
                                handleCheckChange(data, false)
                            }} />
                        </li>
                    )
                }) : <>
                </>
            }
        </>)
    }

    return (
        <>
            <div className="filter-card cursor-pointer">
                <div className="filter-header collapsed" data-toggle="collapse" data-target={"#collapse" + accordionName} aria-expanded="false" aria-controls={"collapse" + accordionName} id={"heading" + accordionName}>
                    <div className="d-flex flex-row align-items-center justify-content-between">
                        <h2 className="fz16 black mb-0 fw-regular">{label} {value?.length > 0 && <span>{value?.length}</span>}</h2>
                        <div className="icon mr-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#92929D" />
                        </svg></div>
                    </div>
                </div>
                <div id={"collapse" + accordionName} className="filter-body collapse" aria-labelledby={"heading" + accordionName} data-parent="#accordion">
                    <div className="px-20">
                        <div className="fix-filter">
                            {/* <ul className="select-filter">
                                {selectedFilters()}
                            </ul> */}
                            {value?.length > 0 ?
                                <button className="border-0 bg-tr clear-btn px-0" onClick={() => setValue([])}>Clear All</button>
                                : ""
                            }
                            {showSearch && <div className='w-full'>
                                <SearchInput
                                    placeholder={`Search ${label}`}
                                    searchText={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    handelClear={() => setSearchText('')}

                                /></div>}
                            <div className="filter-list">
                                <div className="row">
                                    {!viewMore && lists.map((data, index) => {
                                        return (
                                            index < 8 && <div className={`${lists.length === 1 ? 'w-full !pl-3' : 'col-6'}`} key={`newLead_${data.label}_${data.value}_${accordionName}`}>
                                                <div className="form-check !flex !items-center">
                                                    <input
                                                        className="form-check-input !mt-0"
                                                        id={`newLead_${data.label}_${data.value}_${accordionName}`}
                                                        checked={checkSelected(data.value)}
                                                        type="radio"
                                                        name={`newLead_${data.label}_${data.value}_${accordionName}`}
                                                        onChange={(e) => handleCheckChange(data, e.target.checked)}
                                                    />
                                                    {data.label.length > 20 ? <TooltipText title={data.label || ''}>
                                                        <label className={`form-check-label cursor-pointer  ${lists.length > 1 ? 'truncate w-[150px]' : ''}`} htmlFor={`newLead_${data.label}_${data.value}_${accordionName}`}>
                                                            {data.label}
                                                        </label>
                                                    </TooltipText> :
                                                        <label className="form-check-label cursor-pointer" htmlFor={`newLead_${data.label}_${data.value}_${accordionName}`}>
                                                            {data.label}
                                                        </label>}
                                                </div>
                                            </div>
                                        )
                                    })}
                                    {viewMore && lists.map((data, index) => {
                                        return (

                                            <div className='col-6' key={`newLead_${data.label}_${data.value}_${accordionName}`}>
                                                <div className="form-check !flex !items-center">
                                                    <input
                                                        className="form-check-input !mt-0"
                                                        id={`newLead_${data.label}_${data.value}_${accordionName}`}
                                                        checked={checkSelected(data.value)}
                                                        type="radio"
                                                        name={`newLead_${data.label}_${data.value}_${accordionName}`}
                                                        onChange={(e) => handleCheckChange(data, e.target.checked)} />
                                                    {data.label.length > 20 ? <TooltipText title={data.label || ''}>
                                                        <label className="form-check-label cursor-pointer w-[150px] truncate" htmlFor={`newLead_${data.label}_${data.value}_${accordionName}`}>
                                                            {data.label}
                                                        </label>
                                                    </TooltipText> :
                                                        <label className="form-check-label cursor-pointer" htmlFor={`newLead_${data.label}_${data.value}_${accordionName}`}>
                                                            {data.label}
                                                        </label>}
                                                </div>
                                            </div>
                                        )
                                    })}

                                    {lists?.length > 8 && <button className={`border-0 black-dark-700 pr-hover ml-auto flex items-center fz12 fw-po-medium bg-tr mb-14 mr-1 mt-16 ro  ${!viewMore ? '' : 'view-less'}`} onClick={() => setviewMore(!viewMore)}>{!viewMore ? 'View more' : 'View less'}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#92929D"></path></svg>
                                    </button>}
                                    {
                                        lists?.length === 0 && <div className='!px-5 !pt-4'>No Result Found</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}