import React, { useContext, useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { checkAuthorization } from '../helpers/helpers';
import Consumer from '../helpers/context';

const AuthRoute = ({
  component: Component,
  redirect: pathname,
  ...rest
}) => {
  const [baseRoute, setBaseRoute] = useState();
  const { userProfile } = useContext(Consumer)

  useEffect(() => {
    if (userProfile?.user_category == 'admin') {
      console.log('11')
      setBaseRoute('dashboard')
    } else if (userProfile?.user_category == 'receptionist') {
      console.log('22')
      setBaseRoute('site-visit-tracker')
    } else if (userProfile?.user_category == 'presale') {
      console.log('33')
      setBaseRoute('presale')
    } else if (userProfile?.user_category == 'sourcing') {
      console.log('44')
      setBaseRoute('site-visit-tracker')
    } else if (userProfile?.user_category == 'closing') {
      console.log('55')
      setBaseRoute('presale')
    }
  }, []);

  const getBaseRoute = () => {
    if (userProfile?.user_category == 'admin') {
      return 'dashboard';
    } else if (userProfile?.user_category == 'receptionist') {
      return 'site-visit-tracker';
    } else if (userProfile?.user_category == 'presale') {
      return 'presale';
    } else if (userProfile?.user_category == 'sourcing') {
      return 'site-visit-tracker';
    } else if (userProfile?.user_category == 'closing') {
      return 'presale';
    }
  }
  console.log(baseRoute, 'baseRoute', userProfile?.user_category)
  const Routes = (props) => {
    if (rest.path == '/404') {
      return (<Component {...rest} {...props} />)
    }
    else if ((rest.accountAccess == 'Not Allowed' && rest.path == '/access-denied')) {
      return (<Component {...rest} {...props} />)
    }
    else if (rest.authorized === false) {
      return (
        <Route
          {...rest}
          render={props =>

            <Component {...rest} {...props} />

          }
        />
      );
    } else {
      return (

        <Redirect
          to={{
            pathname: `/${+userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID ? getBaseRoute() : 'leads'}`,
            state: { from: props.location },

          }}
        />
      );
    }
  }
  return (
    <Routes />
  );
};

AuthRoute.defaultProps = { redirect: '/' };

// AuthRoute.propTypes = {
//   component: PropTypes.object.isRequired,
//   redirect: PropTypes.string,
// };

export default AuthRoute;