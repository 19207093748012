import React, { useState, useEffect, useMemo, useContext } from 'react';
import DatePicker from 'react-datepicker';
import SliderModal from '../SliderModal';
import { getMyUsers, getPlusAppFilterUsers } from '../../../services/private/company.service';
import CheckAccordion from '../FilterModal/Check.accordion';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import StaticFieldAccordian from '../FilterModal/StaticFieldAccordian';
import DynamicAccordion from '../FilterModal/Dynamic.accordion';
import { getAllCities } from '../../../services/public/public.service';
import { getLeadsFilter } from '../../../services/private/leads.service';
import RadioCheckAccordion from '../FilterModal/RadioCheck.accordion';

const defaultStaticFilterList = [
    'Subscription',
    'Date Purchased',
    'Amount Paid',
    'Referral Code',
    'Date Joined'
];

const searchFilterkey = [
    'Assigned To',
    'Assigned User',
    'Shared With',
    'Marked By',
    'Sourcing Manager'
]

const sharedWithSearchKey = [
    'Shared With',
    'Marked By',
    'Sourcing Manager'
]

const SUBSCRIPTION_TYPE = [
    {
        label: 'Premium',
        value: 'premium'
    },
    {
        label: 'Freemium',
        value: 'freemium'
    },
    // {
    //     label: 'Free',
    //     value: 'free'
    // },
]

export default function ProjectVisitFilterModal({
    show,
    setShow,
    handleFilter,
    filterData,
    setFilterCount,
    filtersData,
    filterToaster,
    setCurrentPage,
}) {

    // Filter Values

    const [dateJoined, setJoinedDate] = useState({});
    const [filterSelected, setFilterSelected] = useState(false)
    const [filterDate, setFilterDate] = useState({});
    const [defaultFilterList, setDefaultFilterList] = useState(defaultStaticFilterList);
    const [city, setCity] = useState([]);
    const [selectedRM, setSelectedRM] = useState([]);
    const [visitStatus, setVisitStatus] = useState([]);
    const [appInstalled, setAppInstalled] = useState([]);
    const [scheduleDate, setScheduleDate] = useState({});
    const [createdDate, setCreatedDate] = useState({});
    const [createdBy, setCreatedBy] = useState([]);
    const [completedTime, setCompletedTime] = useState({});

    // console.log(filtersData, 'filtersData')

    const [inputValues, setInputValues] = useState({
        City: '',
        'Sourcing Manager': '',
        'Created By': ''
    });

    const [inputValuesNotFound, setInputValuesNotFound] = useState({
        City: '',
        'Sourcing Manager': '',
        'Created By': ''

    });

    const [searchResults, setSearchResults] = useState({
        City: [],
        'Sourcing Manager': [],
        'Created By': []
    });

    const [searchDefultResults, setSearchDefaultResults] = useState({
        "Assigned To": [],
        "Assigned User": [],
        'Shared With': [],
        'Marked By': [],
        'Sourcing Manager': [],
        'Created By': []
    });

    const ROLE_NAME = localStorage.getItem('role');


    const handleJoinDateChange = (name, date) => {
        setFilterDate({ ...filterDate, [name]: date });
        setJoinedDate({ ...dateJoined, [name]: date });
    }

    const handleCreatedDateChange = (name, date) => {
        setCreatedDate({ ...createdDate, [name]: date })
    }

    const handleCompletedDateChange = (name, date) => {
        setCompletedTime({ ...completedTime, [name]: date })
    }

    const handleScheduleDateChange = (name, date) => {
        setScheduleDate({ ...scheduleDate, [name]: date })
    }

    const getFilterData = async (type, value) => {

        // const res = await getPlusAppFilterUsers({ filterType: type, value: value });
        const res = await getLeadsFilter({ filterType: type, value: value });
        if (res.status === 200) {
            return res.data.data.map(i => ({ ...i, id: i.user_id }))
        }
        else {
            return []
        }
    }

    const getAllCitiesByName = async (searchText) => {
        const res = await getAllCities(searchText)

        if (res.data.status === 200) {
            const cities = res.data?.data.map((city) => { return { label: city.name, value: city.name } });
            return cities
        } else {
            return []
        }
    }

    const getDefaultUser = async () => {
        const res = await getMyUsers();
        if (res.data.status === 200) {
            const defaultUser = res.data.data.map(u => ({ ...u, value: u.name, user_id: u.id }))
            setSearchResults((prevState) => ({
                ...prevState,
                'Sourcing Manager': [...defaultUser],
                'Created By': [...defaultUser]
            }));

            setSearchDefaultResults((prevState) => ({
                ...prevState,
                'Sourcing Manager': [...defaultUser],
                'Created By': [...defaultUser]
            }))
            // getBrokerCompany();
        }
    }

    const handleInputChange = async (e) => {
        const inputValuesData = { ...inputValues, [e.target.name]: e.target.value }
        setInputValues(inputValuesData);
        // setFilterSelected(true)
        //if user make input field blank using backspace       
        if (searchFilterkey.includes(e.target.name) && !e.target.value) {
            setSearchResults({ ...searchResults, [e.target.name]: searchDefultResults[e.target.name] });
            return false
        }

        const fieldName = e.target.name;
        if (inputValuesData[fieldName].length < 3) {
            setSearchResults({ ...searchResults, [fieldName]: [] });
            return false
        };

        let filterData = [];
        if (fieldName === 'City') {
            filterData = await getAllCitiesByName(inputValuesData[fieldName])
        } else {
            filterData = await getFilterData(searchFilterkey.includes(fieldName) ? 'Assign To' : sharedWithSearchKey.includes(fieldName) ? 'Shared With' : fieldName, inputValuesData[fieldName])
        }

        if (!filterData.length) {
            setInputValuesNotFound({ ...inputValuesNotFound, [fieldName]: 'No result found.' })
        } else {
            setInputValuesNotFound({ ...inputValuesNotFound, [fieldName]: '' })
        }
        setSearchResults({ ...searchResults, [fieldName]: filterData });
    }

    useEffect(() => {
        if (dateJoined?.length || selectedRM?.length || Object.keys(createdDate)?.length || Object.keys(completedTime)?.length || Object.keys(scheduleDate)?.length || visitStatus?.length || appInstalled?.length || createdBy?.length) {
            setFilterSelected(true)
        } else {
            setFilterSelected(false)
        }
    }, [selectedRM, city, dateJoined, createdDate, completedTime, scheduleDate, visitStatus, appInstalled, createdBy])

    useEffect(() => {
        getDefaultUser();
    }, []);

    const sendFilterRequest = () => {
        let filterArr = [];

        if (city.length > 0) {
            filterArr.push({
                'city': city.map((c) => {
                    return c.value
                })
            })
        }

        if (selectedRM.length > 0) {
            filterArr.push({
                sourcing_user_id: selectedRM?.map(s => s.user_id)
            })
        }

        if (createdBy.length > 0) {
            filterArr.push({
                created_by: createdBy?.map(s => s.user_id)
            })
        }

        if (visitStatus.length > 0) {
            filterArr.push({
                status: visitStatus[0]?.value
            })
        }

        if (appInstalled.length > 0) {
            filterArr.push({
                is_app_installed: appInstalled[0]?.value
            })
        }

        if (Object.keys(dateJoined).length > 0) {
            let startDate = dateJoined.startDate;
            let endDate = dateJoined.endDate || dateJoined.startDate;
            filterArr.push({
                'date_joined': {
                    'From': startDate,
                    'To': endDate
                }
            })
        }

        if (Object.keys(createdDate).length > 0) {
            let startDate = createdDate.startDate;
            let endDate = createdDate.endDate || createdDate.startDate;
            filterArr.push({
                'created_date': {
                    'From': startDate,
                    'To': endDate
                }
            })
        }

        if (Object.keys(completedTime).length > 0) {
            let startDate = completedTime.startDate;
            let endDate = completedTime.endDate || completedTime.startDate;
            filterArr.push({
                'completed_time': {
                    'From': startDate,
                    'To': endDate
                }
            })
        }
        if (Object.keys(scheduleDate).length > 0) {
            let startDate = scheduleDate.startDate;
            let endDate = scheduleDate.endDate || scheduleDate.startDate;
            filterArr.push({
                'scheduled_date': {
                    'From': startDate,
                    'To': endDate
                }
            })
        }

        setFilterCount(filterArr.length)
        handleFilter(filterArr);
        filterData(filterArr);
        filterToaster();
        setShow(false)
    }

    const handelRemoveClick = () => {
        if (filterSelected || filtersData.length > 0 || Object.keys(dateJoined).length > 0 || city.length || selectedRM.length || Object.keys(scheduleDate)?.length || Object.keys(createdDate)?.length || Object(completedTime)?.length || appInstalled?.length || visitStatus?.length || createdBy?.length) {
            toast.success("All filters Removed");
            setFilterCount(0);
            handleFilter([]);
            filterData([]);
            setCity([])
            setJoinedDate({})
            setSelectedRM([])
            setCreatedBy([]);
            setCompletedTime({});
            setScheduleDate({});
            setCreatedDate({});
            setVisitStatus([]);
            setAppInstalled([]);
        }
    }

    /***
     * update filter value after refresh if filter applied
     */
    const updateFilterValaue = () => {
        let filters = filtersData;
        let filtersArr = {};
        for (const filterData of filters) {
            filtersArr = { ...filtersArr, ...filterData }
        }

        let filterKeys = Object.keys(filtersArr);

        if (filterKeys.includes('city')) {
            const filteredCity = filtersArr.city.map(i => ({
                label: i,
                value: i,
                checked: true
            }))

            setCity(filteredCity || [])
        }
        if (filterKeys.includes('rm')) {
            setSelectedRM(filtersArr['rm']);
        }

        if (filterKeys.includes('is_app_installed')) {
            if (filtersArr['is_app_installed']) {
                setAppInstalled([{
                    label: 'Yes',
                    value: true
                }])
            } else {
                setAppInstalled([{
                    label: 'No',
                    value: false
                }])
            }
        }
        if (filterKeys.includes('status')) {
            if (filtersArr['status']) {
                setVisitStatus([{
                    label: 'Yes',
                    value: true
                }])
            } else {
                setVisitStatus([{
                    label: 'No',
                    value: false
                }])
            }
        }

        if (filterKeys.includes("date_joined")) {
            const { From, To } = filtersArr.date_joined
            const date = {
                'startDate': From,
                'endDate': To,
            }
            setJoinedDate(date || {})
        }

        if (filterKeys.includes("completed_time")) {
            const { From, To } = filtersArr.completed_time
            const date = {
                'startDate': From,
                'endDate': To,
            }
            setCompletedTime(date || {})
        }
        if (filterKeys.includes("scheduled_date")) {
            const { From, To } = filtersArr.scheduled_date
            const date = {
                'startDate': From,
                'endDate': To,
            }
            setScheduleDate(date || {})
        }
        if (filterKeys.includes("created_date")) {
            const { From, To } = filtersArr.created_date
            const date = {
                'startDate': From,
                'endDate': To,
            }
            setCreatedDate(date || {})
        }
    }

    useEffect(() => {
        updateFilterValaue();
    }, [
        filtersData,
        show,
    ])

    useEffect(() => {
        let filters = filtersData;
        const filtersArr = [];
        for (const filterData of filters) {
            filtersArr.push(Object.keys(filterData)[0])
        }

        if (!filtersArr.includes("date_joined")) {
            setJoinedDate({})
        }
        if (!filtersArr.includes('rm')) {
            setSelectedRM([])
        }
        setFilterCount(filtersArr.length);
    }, [filtersData])


    return (
        <SliderModal
            show={show}
            setShow={setShow}
            title={'Filters'}
            titleRight={<>
                {filterSelected || filtersData.length > 0 ?
                    <a className="fz14 h-font mr-24 black-dark-700 fw-po-medium pr-hover" onClick={handelRemoveClick}>Remove all</a>
                    : " "}
            </>
            }
            body={
                <>
                    <div className="filter">
                        <div className='w-full'></div>
                        <div className="all-fliters">
                            <div id="accordion">
                                <RadioCheckAccordion
                                    value={visitStatus}
                                    setValue={setVisitStatus}
                                    selectionValues={[
                                        {
                                            label: 'Scheduled',
                                            value: 'scheduled'
                                        },

                                        {
                                            label: 'Completed',
                                            value: 'completed'
                                        },

                                    ]}
                                    accordionName="status"
                                    label="Status"
                                    showSearch={false}
                                />
                                <RadioCheckAccordion
                                    value={appInstalled}
                                    setValue={setAppInstalled}
                                    selectionValues={[
                                        {
                                            label: 'Yes',
                                            value: true
                                        },

                                        {
                                            label: 'No',
                                            value: false
                                        },

                                    ]}
                                    accordionName="is_app_installed"
                                    label="Is App Installed"
                                    showSearch={false}
                                />
                                <div className="filter-card cursor-pointer">
                                    <div className="filter-header collapsed" data-toggle="collapse" data-target="#collapsescheduledate" aria-expanded="false" aria-controls="collapsescheduledate" id="headingDateJoined">
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <h2 className="fz16 black mb-0 fw-regular">
                                                Schedule Date
                                                {Object.keys(scheduleDate).length > 0 && <span>{Object.keys(scheduleDate).length}</span>}
                                            </h2>

                                            <div className="icon mr-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#92929D" />
                                            </svg></div>
                                        </div>
                                    </div>
                                    <div id="collapsescheduledate" className="filter-body collapse" aria-labelledby="headingDateJoined" data-parent="#accordion">
                                        <button className={`border-0 bg-tr mx-3 clear-btn px-0 ${scheduleDate.startDate ? "" : 'd-none'} `} onClick={() => {
                                            setScheduleDate({})
                                        }}
                                        >
                                            Clear All
                                        </button>
                                        <div className="d-flex justify-content-between align-center mx-3" >
                                            <div className='mr-8 mb-20 w-100'>
                                                <Form.Label>
                                                    Start Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={scheduleDate.startDate ? new Date(scheduleDate.startDate) : ''}
                                                    placeholderText={'Start Date'}
                                                    maxDate={new Date()}
                                                    onChange={(date) => handleScheduleDateChange('startDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                            <div className='w-100'>
                                                <Form.Label>
                                                    End Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={scheduleDate.endDate ? new Date(scheduleDate.endDate) : ''}
                                                    placeholderText={'End Date'}
                                                    maxDate={new Date()}
                                                    minDate={scheduleDate.startDate}
                                                    onChange={(date) => handleScheduleDateChange('endDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-card cursor-pointer">
                                    <div className="filter-header collapsed" data-toggle="collapse" data-target="#collapsecreateddate" aria-expanded="false" aria-controls="collapsecreateddate" id="headingDateJoined">
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <h2 className="fz16 black mb-0 fw-regular">
                                                Created Date
                                                {Object.keys(createdDate).length > 0 && <span>{Object.keys(createdDate).length}</span>}
                                            </h2>

                                            <div className="icon mr-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#92929D" />
                                            </svg></div>
                                        </div>
                                    </div>
                                    <div id="collapsecreateddate" className="filter-body collapse" aria-labelledby="headingDateJoined" data-parent="#accordion">
                                        <button className={`border-0 bg-tr mx-3 clear-btn px-0 ${createdDate.startDate ? "" : 'd-none'} `} onClick={() => {
                                            setCreatedDate({})
                                        }}
                                        >
                                            Clear All
                                        </button>
                                        <div className="d-flex justify-content-between align-center mx-3" >
                                            <div className='mr-8 mb-20 w-100'>
                                                <Form.Label>
                                                    Start Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={createdDate.startDate ? new Date(createdDate.startDate) : ''}
                                                    placeholderText={'Start Date'}
                                                    maxDate={new Date()}
                                                    onChange={(date) => handleCreatedDateChange('startDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                            <div className='w-100'>
                                                <Form.Label>
                                                    End Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={createdDate.endDate ? new Date(createdDate.endDate) : ''}
                                                    placeholderText={'End Date'}
                                                    maxDate={new Date()}
                                                    minDate={createdDate.startDate}
                                                    onChange={(date) => handleCreatedDateChange('endDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-card cursor-pointer">
                                    <div className="filter-header collapsed" data-toggle="collapse" data-target="#collapsecompletedtime" aria-expanded="false" aria-controls="collapsecompletedtime" id="headingDateJoined">
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <h2 className="fz16 black mb-0 fw-regular">
                                                Completed Time
                                                {Object.keys(completedTime).length > 0 && <span>{Object.keys(completedTime).length}</span>}
                                            </h2>

                                            <div className="icon mr-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#92929D" />
                                            </svg></div>
                                        </div>
                                    </div>
                                    <div id="collapsecompletedtime" className="filter-body collapse" aria-labelledby="headingDateJoined" data-parent="#accordion">
                                        <button className={`border-0 bg-tr mx-3 clear-btn px-0 ${completedTime.startDate ? "" : 'd-none'} `} onClick={() => {
                                            setCreatedDate({})
                                        }}
                                        >
                                            Clear All
                                        </button>
                                        <div className="d-flex justify-content-between align-center mx-3" >
                                            <div className='mr-8 mb-20 w-100'>
                                                <Form.Label>
                                                    Start Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={completedTime.startDate ? new Date(completedTime.startDate) : ''}
                                                    placeholderText={'Start Date'}
                                                    maxDate={new Date()}
                                                    onChange={(date) => handleCompletedDateChange('startDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                            <div className='w-100'>
                                                <Form.Label>
                                                    End Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={completedTime.endDate ? new Date(completedTime.endDate) : ''}
                                                    placeholderText={'End Date'}
                                                    maxDate={new Date()}
                                                    minDate={completedTime.startDate}
                                                    onChange={(date) => handleCompletedDateChange('endDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <DynamicAccordion
                                    accordionName="Sourcing Manager"
                                    placeholder='Enter user name to search'
                                    value={selectedRM}
                                    setValue={setSelectedRM}
                                    searchResults={searchResults['Sourcing Manager']}
                                    setSearchResults={(data) => setSearchResults({ ...searchResults, ["Sourcing Manager"]: structuredClone(data) })}
                                    inputValues={inputValues["Sourcing Manager"]}
                                    handleInputChange={handleInputChange}
                                    notFound={inputValuesNotFound["Sourcing Manager"]}
                                    setInputValues={setInputValues}
                                    inputValuesNotFound={inputValuesNotFound}
                                    setInputValuesNotFound={setInputValuesNotFound}
                                    searchDefultResults={searchDefultResults}
                                />
                                <DynamicAccordion
                                    accordionName="Created By"
                                    placeholder='Enter user name to search'
                                    value={createdBy}
                                    setValue={setCreatedBy}
                                    searchResults={searchResults['Created By']}
                                    setSearchResults={(data) => setSearchResults({ ...searchResults, ["Created By"]: structuredClone(data) })}
                                    inputValues={inputValues["Created By"]}
                                    handleInputChange={handleInputChange}
                                    notFound={inputValuesNotFound["Created By"]}
                                    setInputValues={setInputValues}
                                    inputValuesNotFound={inputValuesNotFound}
                                    setInputValuesNotFound={setInputValuesNotFound}
                                    searchDefultResults={searchDefultResults}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-inline justify-content-between px-20 filter-ok-cancel">
                        <button className="cancel outline-btn" onClick={() => setShow(false)}>Cancel</button>
                        <button className="pr-btn" onClick={sendFilterRequest}>Apply Filter</button>
                    </div>
                </>
            }
        />
    )
}