import React, { useCallback, useContext, useEffect, useState } from 'react'
import Tabs from '../../components/HOC/Tabs';
import CompanyAnalytics from './Company';
import CallsRequested from './CallsRequested';
import { getAllRequests, getCompaniesForAnalytics, getPlusAppFilterUsers, updateRm } from '../../services/private/company.service';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as SearchCloseIcon } from '../../assets/icons/searchClose.svg';
import { toast } from 'react-toastify';
import PlusAppFilterModal from '../../components/Modals/PlusAppFilterModal/PlusAppFilterModal';
import BannerList from './BannerList';
import { useLocation } from 'react-router-dom';
import useDocumentTitle from '../../custom hooks/useDocumentTitile';
import { SVEvents } from './SVEvents';
import { getAllBrokersSV } from '../../services/private/borker.service';
import AddNewCPFirm from './Modal/AddNewCPFirm';
import Consumer from '../../helpers/context';
import LeadsFooterBar from '../LeadsManager/LeadsFooterBar';
import { ReactComponent as PluswhiteIcon } from '../../assets/icons/Pluswhite.svg';
import CPMeets from './CPMeets';
import CPProjectVisits from './CPProjectVisits';
import ScheduleCPVisitModal from './Modal/ScheduleCPVisitModal';
import './CPManager.css';
import { getAllMeets, getColumnPreferenceForOpportunityDashboard, opportunityDashboard, opportunityStats } from '../New Dashboard/services/dashboard.service';
import CPOpportunities from '../Settings/Broker-CP/CPOpportunities';
import InternalTabs from '../New Dashboard/Tabs/InternalTabs';
import ProjectVisitFilterModal from '../../components/Modals/PlusAppFilterModal/ProjectVisitFilterModal';
import { ConvertFitersArrayToObject } from '../../helpers/helpers';
import CompanyFiltersTag from './CompanyFiltersTag';

const tabList = [
    { name: 'CP Firms' },
    {
        name: 'CP Meets',
        value: 'cp_meets'
    },
    {
        name: 'CP Project Visits',
        value: 'cp_project_visits'
    },

    { name: 'Opportunities' },
    { name: 'Calls Requested' },
    { name: 'Banners' },
]

const svTabList = [
    {
        name: 'All',
        value: 'all_leads',
        count: 0
    },
    {
        name: 'SV Scheduled',
        value: 'scheduled',
        count: 0,
    },
    {
        name: 'SV Completed',
        value: 'completed',
        count: 0
    },
    {
        name: 'SV Cancelled',
        value: 'cancelled',
        count: 0
    },
    {
        name: 'Token',
        value: 'eoi_only',
        count: 0
    },
    {
        name: 'Booking',
        value: 'booking_only',
        count: 0
    },
    {
        name: 'Dead',
        value: 'dead_leads',
        count: 0
    }
]

const SV_LIST = ['SV Scheduled', 'SV Completed', 'SV Cancelled']

const Dashboard = () => {
    const [selectedTab, setSelectedTab] = useState({
        name: 'CP Firms'
    },);
    const [companyDetails, setCompanyDetails] = useState([]);
    const [allCompanyDetails, setAllCompanyDetails] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [filterShow, setfilterShow] = useState(false);
    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem('cpFilter')) || []);
    const [callBookingList, setCallBookingList] = useState([]);
    const [companyStats, setCompanyStats] = useState({});
    const [selectedStats, setSelectedStats] = useState('Total')
    const [filterCount, setFilterCount] = useState(0);
    const [showFilterToaster, setShowFilterToaster] = useState(false);
    const [callRequestedCount, setCallRequestedCount] = useState('');
    const [showingResults, setShowingResults] = useState(100);
    const [currentPage, setCurrentPage] = useState(1)
    const [leadsCount, setLeadsCount] = useState('');
    const [maxPage, setMaxPage] = useState(1);
    const [companyStartIndex, setCompanyStartIndex] = useState('');

    const [showingCallResults, setShowingCallResults] = useState(100);
    const [currentCallPage, setCurrentCallPage] = useState(1)
    const [callLeadsCount, setCallLeadsCount] = useState('');
    const [callMaxPage, setCallMaxPage] = useState(1);
    const [callStartIndex, setCallStartIndex] = useState('');
    const [selectedInternalTab, setSelectedInternalTab] = useState({
        name: 'All',
        value: 'all_leads',
        count: 0
    });
    const [internalTabRowCount, setInternalTabRowCount] = useState({})
    const [events, setEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [svMaxPage, setSVMaxPage] = useState(1);
    const [svLeadCount, setSVLeadCount] = useState('');
    const [svCurrentPage, setSVCurrentPage] = useState(1);
    const [svShowingResults, setSVShowingResults] = useState(10);
    const [showAddCPFirm, setShowAddCPFirm] = useState(false);
    const [companyLoading, setCompanyLoading] = useState(false);
    const [showScheduleCPMeet, setShowScheduleCPMeet] = useState(false);
    const [allMeets, setAllMeets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [opportunityData, setOpportunityData] = useState([]);
    const [opportunityDashboardColumns, setOpportunityDashboardColumns] = useState([]);
    const [intentStatuses, setIntentStatuses] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [sourceStatuses, setSourceStatuses] = useState([]);
    const [firmCount, setFirmCount] = useState('');
    const [showProjectVisitFilter, setShowProjectVisitFilter] = useState(false);
    const [tabRowCount, setTabRowCount] = useState({});
    const [showingCPResults, setShowingCPResults] = useState(100);
    const [currentCPPage, setCurrentCPPage] = useState(1);
    const [maxCPPage, setMaxCPPage] = useState('');
    const [leadsCPCount, setLeadsCPCount] = useState('');

    useDocumentTitle('CP Manager');
    let { allowedPermissions, userProfile, isRolesModule } = useContext(Consumer);
    // let { companyConfig, allowedPermissions, isRolesModule,  } = useContext(Consumer);
    const location = useLocation();

    useEffect(() => {
        if (location.search) {
            const params = location.search.split('=');
            setSelectedTab({ name: params[1] })
        } else {
            setSelectedTab({ name: 'CP Firms' })
        }
    }, [location.search])

    const getCompanyData = async () => {
        setCompanyLoading(true)
        await getCompaniesForAnalytics(
            { filters, search: searchText },
            {
                perPage: showingResults,
                pageNumber: currentPage

            }).then(res => {
                if (res.status === 200) {
                    setCompanyLoading(false)
                    setCompanyDetails(res.data.data.companies)
                    setAllCompanyDetails(res.data.data.companies)

                    setCompanyStats(res.data.data.stat)
                    const totalPages = Math.ceil(
                        Number(res.data.data.stat[0].count) / showingResults
                    );
                    setLeadsCount(res.data.data.stat[0].count)
                    setTabRowCount({ 'CP Firms': res.data.data.stat[0].count })
                    setMaxPage(totalPages)
                    setFirmCount(res.data.data.stat[0].count)
                    setCompanyStartIndex((currentPage - 1) * showingResults + 1);
                }
            }).catch((err) => {
                console.log("Something went wrong", err);
                setCompanyLoading(false)
            });
    }

    // useEffect(() => {
    //     if (callLeadsCount || firmCount) {
    //         setTabRowCount({
    //             ...tabRowCount,
    //             'CP Firms': firmCount,
    //             'Calls Requested': callLeadsCount
    //         })
    //     }
    // }, [callLeadsCount, firmCount]);

    const getAllBookedRequest = async () => {

        try {
            const res = await getAllRequests({
                perPage: showingCallResults,
                pageNumber: currentCallPage,
                searchText
            });
            if (res.data.status === 200) {
                setCallBookingList(res.data.data.callRequested)
                setCallRequestedCount(res.data.data.count.count)
                const totalPages = Math.ceil(
                    Number(+res.data.data.count.count) / showingCallResults
                );
                setCallLeadsCount(res.data.data.count.count)
                setCallMaxPage(totalPages)
                setCallStartIndex((currentCallPage - 1) * showingCallResults + 1)
            }
        } catch (err) {
            console.log({ err })
        }
    }

    const filterToaster = useCallback(() => {
        setShowFilterToaster(true)
    }, []);

    const handleClearSearch = () => {
        setSearchText('');
        getPlusAppFilterUsers({}).then((res) => {
            if (res.data.status === 200) {
                setCompanyDetails(res.data.data.companies)
                setAllCompanyDetails(res.data.data.companies)
            }

        }).catch((err) => {
            console.log({ err })
            toast.error(err.response?.data.message)
        }
        )
    }

    const filterData = (filter) => {
        setFilters(filter);
        // getPlusAppFilterUsers({ filters: filter }).then((res) => {
        //     if (res.data.status === 200) {
        //         setCompanyDetails(res.data.data.companies)
        //         setAllCompanyDetails(res.data.data.companies)
        //         toast.success('Filter Applied Successfully');
        //     }
        // })
        //     .catch((err) => {
        //         console.log({ err })
        //         toast.error(err.response?.data.message)
        //     })

    };

    const getOpportunityColumns = async () => {
        const res = await getColumnPreferenceForOpportunityDashboard();
        if (res.data.status === 200) {
            let dashboardColumns = [];
            res.data.data.forEach(items => {
                let columnsList = [];
                let selectedColumn = [];

                items.columns.forEach(column => {
                    if (column.is_selected) {
                        selectedColumn.push(column);
                    }

                    if (!column.is_selected) {
                        columnsList.push(column);
                    }
                })

                // adding column according to tab with sorting
                dashboardColumns.push({
                    tab: items.tab,
                    columns: {
                        selected: selectedColumn.sort((a, b) => a.placement - b.placement),
                        notSelected: columnsList
                    }
                })

                // data for table columns

            })
            setOpportunityDashboardColumns(dashboardColumns)
        }
    }

    // Opportunities
    const getEvents = async () => {
        setIsLoading(true)
        const payload = {
            "pageNumber": currentPage,
            "perPage": showingResults,
            "searchText": searchText,
            "tab": selectedInternalTab?.value,
            filters: {}
        }
        try {
            const res = await getAllBrokersSV('', payload);
            if (res.data.status === 200) {
                setIsLoading(false)
                setEvents(res.data.data?.entries);
                const totalPages = Math.ceil(
                    Number(res.data.data?.stats[selectedInternalTab.value]) / svShowingResults
                );
                setSVMaxPage(totalPages);
                setSVLeadCount(res.data.data?.stats[selectedInternalTab.value]);
                let stats = {}
                svTabList.forEach(i => {
                    stats = { ...stats, [i.name]: `${res.data.data?.stats[i.value]}` }
                })
                setInternalTabRowCount(stats)
            }
        } catch (err) {
            if (err.response.data.message === 'No site visits found' || err.response.data.status === 404) {
                setEvents([]);
                setIsLoading(false)
                // setTabRowCount({})
            }
        }
    }
    const getOpportunities = async () => {
        // const combineFilters = [...filters, ...leadTopFilters]
        // const filterResult = combineFilters.reduce((acc, obj) => {
        //     // const value = Object.values(obj)[0]; // Extract the nested object
        //     return { ...acc, ...obj }; // Merge it into the accumulator
        // }, {})
        const payload = {
            limit: showingResults,
            pageNumber: 1,
            filters: {
                "is_tagged": true
            },
            sort: '',
            col: '',
            text: '',
            filterType: SV_LIST.includes(selectedInternalTab?.name) ? 'site_visits' : selectedInternalTab?.value,
            site_visits_filter: SV_LIST.includes(selectedInternalTab?.name) ? selectedInternalTab?.value : '',
            followup_filter: ''
        }
        try {
            setIsLoading(true)
            const res = await opportunityDashboard(payload);
            setIsLoading(false)
            if (res.data.status === 200) {

                if (selectedInternalTab.name === 'All') {
                    const ids = res.data.data.allLeads.map(l => l.id)
                    localStorage.setItem('leadIds', JSON.stringify(ids))
                    setOpportunityData(res.data.data?.allLeads)
                } else if (selectedInternalTab?.name === 'Dead') { // Dead Lead
                    const ids = res.data.data.deadLeads.map(l => l.id)
                    localStorage.setItem('leadIds', JSON.stringify(ids))
                    setOpportunityData(res.data.data?.deadLeads)
                } else if (selectedInternalTab?.name === 'Token') { // Token Leads
                    const ids = res.data.data.eoiOnly.map(l => l.id)
                    localStorage.setItem('leadIds', JSON.stringify(ids))
                    setOpportunityData(res.data.data?.eoiOnly)
                } else if (selectedInternalTab?.name === 'Booking') { // Booking Leads
                    const ids = res.data.data.bookingOnly.map(l => l.id)
                    localStorage.setItem('leadIds', JSON.stringify(ids))
                    setOpportunityData(res.data.data?.bookingOnly)
                } else if (selectedInternalTab?.name === 'Site Visits') {      // Site visits Leads           
                    const ids = res.data.data.sitevisits.map(l => l.id)
                    localStorage.setItem('leadIds', JSON.stringify(ids))
                    setOpportunityData(res.data.data?.sitevisits)
                } else if (selectedInternalTab?.name === 'SV Scheduled Revisit') {
                    const ids = res.data.data.siteVisitsScheduled.map(l => l.id)
                    localStorage.setItem('leadIds', JSON.stringify(ids))
                    setOpportunityData(res.data.data?.siteVisitsScheduled)
                }
            }
        } catch (err) {
            setIsLoading(false)
        }
    }

    const opportuniStats = async () => {
        // const combineFilters = [...filters, ...leadTopFilters]
        // const filterResult = combineFilters.reduce((acc, obj) => {
        //     // const value = Object.values(obj)[0]; // Extract the nested object
        //     return { ...acc, ...obj }; // Merge it into the accumulator
        // }, {})
        const payload = {
            limit: showingResults,
            pageNumber: 1,
            filters: {
                "is_tagged": true
            },
            sort: '',
            col: '',
            text: '',
            filterType: SV_LIST.includes(selectedInternalTab?.name) ? 'site_visits' : selectedInternalTab?.value,
            site_visits_filter: SV_LIST.includes(selectedInternalTab?.name) ? selectedInternalTab?.value : '',
        }
        const res = await opportunityStats(payload)
        if (res.data.status === 200) {
            const stats = res.data.data;
            svTabList.forEach(item => {
                if (item.name === 'All') {
                    const totalPages = Math.ceil(
                        Number(stats?.allLeadsCount) / showingResults
                    );
                    setMaxPage(totalPages);
                    item.count = stats.allLeadsCount
                    localStorage.setItem('leadCount', res.data.data?.allLeadsCount)
                    if (selectedInternalTab?.value === item?.value) {
                        setLeadsCount(stats?.allLeadsCount);
                    }
                }
                if (item.name === 'Token') {
                    const totalPages = Math.ceil(
                        Number(stats?.eoiOnlyCount) / showingResults
                    );
                    setMaxPage(totalPages);

                    item.count = stats.eoiOnlyCount
                    localStorage.setItem('leadCount', res.data.data?.eoiOnlyCount)
                    if (selectedInternalTab?.value === item?.value) {
                        setLeadsCount(stats?.eoiOnlyCount);
                    }
                }
                if (item.name === 'Booking') {
                    const totalPages = Math.ceil(
                        Number(stats?.bookingsOnlyCount) / showingResults
                    );
                    setMaxPage(totalPages);

                    item.count = stats.bookingsOnlyCount
                    localStorage.setItem('leadCount', res.data.data?.bookingsOnlyCount)
                    if (selectedInternalTab?.value === item?.value) {
                        setLeadsCount(stats?.bookingsOnlyCount);
                    }
                }
                if (item.name === 'Dead') {
                    const totalPages = Math.ceil(
                        Number(stats?.deadLeadsCount) / showingResults
                    );
                    setMaxPage(totalPages);

                    item.count = stats.deadLeadsCount
                    localStorage.setItem('leadCount', res.data.data?.deadLeadsCount)
                    if (selectedInternalTab?.value === item?.value) {
                        setLeadsCount(stats?.deadLeadsCount);
                    }
                }
                if (item.name === 'SV Scheduled') {
                    const totalPages = Math.ceil(
                        Number(stats?.siteVisitScheduledCount) / showingResults
                    );
                    setMaxPage(totalPages);

                    item.count = stats.siteVisitScheduledCount
                    localStorage.setItem('leadCount', res.data.data?.siteVisitScheduledCount)
                    if (selectedInternalTab?.value === item?.value) {
                        setLeadsCount(stats?.siteVisitScheduledCount);
                    }
                }
                if (item.name === 'SV Completed') {
                    const totalPages = Math.ceil(
                        Number(stats?.siteVisitCompletedCount) / showingResults
                    );
                    setMaxPage(totalPages);

                    item.count = stats.siteVisitCompletedCount
                    localStorage.setItem('leadCount', res.data.data?.siteVisitCompletedCount)
                    if (selectedInternalTab?.value === item?.value) {
                        setLeadsCount(stats?.siteVisitCompletedCount);
                    }
                }
                if (item.name === 'SV Cancelled') {
                    const totalPages = Math.ceil(
                        Number(stats?.siteVisitCancelledCount) / showingResults
                    );
                    setMaxPage(totalPages);

                    item.count = stats.siteVisitCancelledCount || 0
                    localStorage.setItem('leadCount', res.data.data?.siteVisitCancelledCount)
                    if (selectedInternalTab?.value === item?.value) {
                        setLeadsCount(stats?.siteVisitCancelledCount);
                    }
                }
            })
        }
    }

    const allScheduleCPMeets = async () => {
        const filterResult = ConvertFitersArrayToObject(filters)
        const payload = {
            "sortColumn": "",
            "sortOrder": "",
            "pageNumber": currentCPPage,
            "perPage": showingCPResults,
            "searchText": searchText,
            "is_project_visit": selectedTab?.name === 'CP Project Visits' ? true : false, //new for new meet
            'filters': filterResult
        }
        const res = await getAllMeets(payload)
        if (res.data.status === 200) {
            setAllMeets(res.data.data?.cpVisits)
            setTabRowCount({
                'CP Meets': res.data.data?.cpMeetCount,
                'CP Project Visits': res.data.data?.cpProjectVisitCount
            })
            if (selectedTab.name === 'CP Meets') {
                const totalPages = Math.ceil(
                    Number(res.data.data?.cpMeetCount) / showingCPResults
                );
                setMaxCPPage(totalPages);
                setLeadsCPCount(res.data.data?.cpMeetCount)
            }
            if (selectedTab?.name === 'CP Project Visits') {
                const totalPages = Math.ceil(
                    Number(res.data.data?.cpProjectVisitCount) / showingCPResults
                );
                setMaxCPPage(totalPages);
                setLeadsCPCount(res.data.data?.cpProjectVisitCount)
            }
        }
    }
    useEffect(() => {
        if (selectedTab.name === 'Calls Requested') {
            getAllBookedRequest();

        } else if (selectedTab.name === 'Opportunities') {
            // getEvents()
            getOpportunities();
            opportuniStats();
        } else if (selectedTab.name === 'CP Firms') {
            getCompanyData();
        } else if (selectedTab.name === 'CP Meets' || selectedTab?.name === 'CP Project Visits') {
            allScheduleCPMeets();
        }
    }, [searchText, selectedTab]);

    useEffect(() => {
        setFilters([]);
    }, [selectedTab]);

    useEffect(() => {
        if (selectedTab.name === 'CP Firms') {
            getCompanyData();
        } else if (selectedTab?.name === 'CP Meets' || selectedTab?.name === 'CP Project Visits') {
            allScheduleCPMeets();
        }

    }, [showingResults, currentPage, filters, showingCPResults, currentCPPage]);

    useEffect(() => {
        if (selectedTab.name === 'Calls Requested') {
            getAllBookedRequest()
        } else if (selectedTab.name === 'Opportunities') {
            // getEvents()
            getOpportunities();
            opportuniStats();
        }

    }, [showingCallResults, currentCallPage, filters]);


    useEffect(() => {
        if (selectedTab?.name === 'Opportunities' && selectedInternalTab) {
            getOpportunities();
            opportuniStats();
        }
    }, [selectedInternalTab, svCurrentPage, svShowingResults]);

    // useEffect(() => {
    //     getAllBookedRequest()
    // }, [selectedTab]);

    useEffect(() => {
        getOpportunityColumns()
    }, []);

    const removeFilterValue = (keyValue) => {
        let oldFilters = filters;
        let newFilters = []
        for (const filterData of oldFilters) {
            // Check filter values and select element at pos 0 to compare with selected key
            if (!keyValue.includes(Object.keys(filterData)[0])) {
                newFilters.push(filterData);
            }
        }
        if (newFilters.length > 0) {
            localStorage.setItem('cpFilter', JSON.stringify(newFilters))
        } else {
            localStorage.removeItem('cpFilter')
        }
        setFilters(newFilters);
    }

    return (
        <div className="main-section show-new-leads flex flex-col !pl-0 !pb-0 !pr-0">
            <div className="!px-5 !pt-5 !border-b !border-grayLight">
                <div className="row d-flex d-flex flex-md-fill flex-wrap mb-2">
                    <div className="col-md-7 col-6 d-flex flex-md-fill flex-column">
                        <h1 className="text-xl m-0 black fw-po-medium">CP Manager</h1>
                        {/* <p className="fz16 black-dark-700 mb-0 d-md-block d-none">Manage all subscriptions for plus app users</p> */}
                    </div>
                    <div className="col-md-5 col-6 d-flex justify-content-end align-items-md-end aling-items-center d-flex flex-md-fill">
                        <div>
                            <div className={`border rounded px-2 py-1 flex mr-3 items-center ${searchText ? 'border-primary' : ''} `}>
                                <SearchIcon className='mr-2' />
                                <input
                                    type="text"
                                    placeholder={selectedTab.name === 'Opportunities' ? 'Search For Opportunities' : 'Search For Users'}
                                    className=' w-100 focus:outline-none text-sm'
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                                {searchText &&
                                    <div className="cursor-pointer" onClick={() => handleClearSearch()}>
                                        <SearchCloseIcon />
                                    </div>}
                            </div>
                        </div>
                        {(selectedTab.name === 'CP Firms' || selectedTab.name === 'CP Meets') && <div className="filter-btn mr-12">
                            <button className={`d-flex align-items-center ${filters?.length > 0 ? " border-0 pr-hover  active" : "border-0 btn"}`} type="button" onClick={() => {
                                if (selectedTab?.name === 'CP Firms') {
                                    setfilterShow(true)
                                } else if (selectedTab?.name === 'CP Meets') {
                                    setShowProjectVisitFilter(true)
                                }
                            }}>
                                <svg className="mr-8" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12.981 11.1747V15.5775C12.981 15.9053 12.7958 16.2049 12.5026 16.3515L9.04095 18.0823C8.46554 18.37 7.78852 17.9516 7.78852 17.3083V11.1747L1.92626 4.00973C1.46395 3.44468 1.86597 2.59631 2.59606 2.59631H18.1734C18.9035 2.59631 19.3056 3.44468 18.8432 4.00973L12.981 11.1747ZM11.2502 15.0426V10.8658C11.2502 10.666 11.3193 10.4724 11.4458 10.3178L16.3472 4.32713H4.42228L9.32372 10.3178C9.45022 10.4724 9.51934 10.666 9.51934 10.8658V15.908L11.2502 15.0426Z" fill="#696974"></path></svg>
                                <span className="text-capitalize">Filter {filters?.length > 0 ? ` (${filters?.length})` : " "}</span>

                            </button>
                        </div>}
                        {selectedTab?.name === 'CP Firms' && allowedPermissions.includes('cp_create_company') &&
                            <button
                                className="pr-btn px-16 border-0 lh-20 flex items-center"
                                onClick={() => setShowAddCPFirm(true)}
                            >
                                <PluswhiteIcon />
                                Add CP Firm
                            </button>
                        }
                        {selectedTab?.name === 'CP Meets' &&

                            <button
                                className="pr-btn px-16 border-0 lh-20 flex items-center"
                                onClick={() => setShowScheduleCPMeet(true)}
                            >
                                <PluswhiteIcon />
                                Schedule CP Meet
                            </button>
                        }
                    </div>
                </div>
                <Tabs
                    tabList={tabList}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    tabName={selectedTab}
                    tabRowCount={tabRowCount}
                />
            </div>
            <div className='!mt-4 !px-5 dashboard-sv h-100'>
                {selectedTab.name === 'Opportunities' &&
                    <InternalTabs
                        tabList={svTabList}
                        selectedTab={selectedInternalTab}
                        setSelectedTab={setSelectedInternalTab}
                    />
                }
                {
                    filters.length > 0 ? <CompanyFiltersTag filters={filters} removeFilterValue={removeFilterValue} /> : " "
                }
                {selectedTab.name === 'CP Firms' &&
                    <CompanyAnalytics
                        companyDetails={companyDetails}
                        getCompanyData={getCompanyData}
                        companyStats={companyStats}
                        // handleFilter={handleStatsFilter}
                        selectedStats={selectedStats}
                        showingResults={showingResults}
                        setShowingResults={setShowingResults}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        leadsCount={leadsCount}
                        maxPage={maxPage}
                        companyStartIndex={companyStartIndex}
                        isLoading={companyLoading}
                        searchText={searchText}
                        removeFilterValue={removeFilterValue}
                        filters={filters}
                    />}
                {selectedTab.name === 'Calls Requested' &&
                    <CallsRequested
                        getAllBookedRequest={getAllBookedRequest}
                        callBookingList={callBookingList}
                        showingResults={showingCallResults}
                        setShowingResults={setShowingCallResults}
                        currentPage={currentCallPage}
                        setCurrentPage={setCurrentCallPage}
                        leadsCount={callLeadsCount}
                        maxPage={callMaxPage}
                        callStartIndex={callStartIndex}

                    />}
                {selectedTab.name === 'Banners' && <BannerList />}
                {selectedTab.name === 'Opportunities' &&
                    // <SVEvents
                    //     events={events}
                    //     isLoading={isLoading}
                    //     searchText={searchText}
                    //     selectedTab={selectedInternalTab}
                    //     setSelectedTab={setSelectedInternalTab}
                    //     currentPage={svCurrentPage}
                    //     setCurrentPage={setSVCurrentPage}
                    //     setShowingResults={setSVShowingResults}
                    //     showingResults={svShowingResults}
                    //     leadsCount={svLeadCount}
                    //     maxPage={svMaxPage}
                    //     tabRowCount={internalTabRowCount}
                    //     setTabRowCount={setInternalTabRowCount}
                    //     tabList={svTabList}
                    // />
                    <CPOpportunities
                        opportunityData={opportunityData}
                        getColumns={getOpportunityColumns}
                        dashboardColumns={opportunityDashboardColumns}
                        setDashboardColumns={setOpportunityDashboardColumns}
                        selectedInternalTab={selectedInternalTab}
                        intentStatuses={intentStatuses}
                        sourceStatuses={sourceStatuses}
                        statuses={statuses}
                        userProfile={userProfile}
                        allowedPermissions={allowedPermissions}
                        isRolesModule={isRolesModule}
                        getOpportunities={getOpportunities}
                        searchText={searchText}
                        isLoading={isLoading}
                    />
                }
                {
                    selectedTab.name === 'CP Meets' && <CPMeets
                        allMeets={allMeets}
                        allScheduleCPMeets={allScheduleCPMeets}
                        filters={filters}
                        searchText={searchText}
                        showingResults={showingCPResults}
                        setShowingResults={setShowingCPResults}
                        currentPage={currentCPPage}
                        setCurrentPage={setCurrentCPPage}
                        leadsCount={leadsCPCount}
                        maxPage={maxCPPage}
                    />
                }
                {
                    selectedTab.name === 'CP Project Visits' && <CPProjectVisits
                        allMeets={allMeets}
                        filters={filters}
                        searchText={searchText}
                        showingResults={showingCPResults}
                        setShowingResults={setShowingCPResults}
                        currentPage={currentCPPage}
                        setCurrentPage={setCurrentCPPage}
                        leadsCount={leadsCPCount}
                        maxPage={maxCPPage}
                    />
                }
                {/* {(selectedTab.name !== 'Banners' && selectedTab.name !== 'Opportunities') &&
                   } */}
            </div>

            {filterShow && <PlusAppFilterModal
                show={filterShow}
                setShow={setfilterShow}
                handleFilter={setFilters}
                filterData={filterData}
                setFilterCount={setFilterCount}
                filtersData={filters}
                setCurrentPage={setCurrentPage}
                filterToaster={filterToaster}
            />}
            {
                showProjectVisitFilter && <ProjectVisitFilterModal
                    show={showProjectVisitFilter}
                    setShow={setShowProjectVisitFilter}
                    handleFilter={setFilters}
                    filterData={filterData}
                    setFilterCount={setFilterCount}
                    filtersData={filters}
                    setCurrentPage={setCurrentPage}
                    filterToaster={filterToaster}
                />
            }
            {
                showAddCPFirm && <AddNewCPFirm show={showAddCPFirm} closeModal={() => setShowAddCPFirm(false)} getCompanyData={getCompanyData} />
            }
            {
                showScheduleCPMeet && <ScheduleCPVisitModal
                    show={showScheduleCPMeet}
                    closeModal={() => setShowScheduleCPMeet(false)}
                    allScheduleCPMeets={allScheduleCPMeets}
                />
            }
        </div>
    )
}

export default Dashboard