import React from 'react'
import ASCIcon from '../../assets/icons/asc_sort.svg';
import DSCIcon from '../../assets/icons/dsc_sort.svg';

const COLNAMEMAPING = {
  'Date': 'Created Date',
  'Assigned To': 'Assigned User',
  'dead_lead_date': 'Marked Date'
}
const SORTNAME = ['Date', 'Assigned To', 'dead_lead_date'];

const SortIconDisplay = ({ sortCol, sort, colName }) => {

  return (
    <>
      {(SORTNAME.includes(sortCol) ? COLNAMEMAPING[sortCol] : sortCol) == colName && sort === 'asc' && <span className='ml-2'> <img src={ASCIcon} className='!h-5 !w-5' alt="sort icon1" /> </span>}
      {(SORTNAME.includes(sortCol) ? COLNAMEMAPING[sortCol] : sortCol) == colName && sort === 'desc' && <span className='ml-2'> <img src={DSCIcon} className='!h-5 !w-5' alt="sort icon2" /> </span>}
    </>
  )
}

export default SortIconDisplay;