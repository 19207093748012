import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import RightModal from '../../../../components/Modals/RightModal'
import InputSelect from '../../../../components/InputGroup/InputSelect';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import DateTimePicker from '../../../../components/InputGroup/DateTime';
import { getProjectsForFilter, reassignLeads } from '../../../../services/private/leads.service';
import TooltipText from '../../../../components/Tooltip';
import Consumer from '../../../../helpers/context';
import { getLeadAccess, getLeadStatusAssignedToConfigByStatusId, getLeadStatusShareConfigByStatusId, getUsersForSettings, saveLeadAccess } from '../../../../services/private/company.service';
import { checkIsSomeItemAvailableInList } from '../../../../helpers/helpers';
import moment from 'moment';
import InputText from '../../../../components/InputGroup/InputText';
import { validityUnit } from '../../../../helpers/enums';
import { ReactComponent as PlusPrimaryIcon } from '../../../../assets/icons/PlusPrimary.svg';
import { toast } from 'react-toastify';
import MobileInput from '../../../../components/InputGroup/MobileInput';
import { customerDashboard, insertOpportunity } from '../../services/dashboard.service';
import { Oval } from 'react-loader-spinner';
import AsyncSelect from 'react-select/async';
import { InProgessReasonsList, NotConnectedReasonsList } from '../../../../utils/constants';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        color: '#44444f',
        zIndex: 99,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        },
    }),
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({ ...provided, zIndex: 9999 })
};

const CreateOpportunities = ({ closeModal, show, leadId, statusId, onSuccess, reassindedUserUuid, getOpportunities, statuses, uuid, customerName, customerMobile }) => {
    const [sameTime, setSameTime] = useState(false);
    const [projectList, setProjectList] = useState([]);
    const [projects, setProjects] = useState([]);
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('')

    const [statusShareUsers, setStatusShareUsers] = useState([]);
    const [leadsShareUsers, setLeadsShareUsers] = useState([]);
    const [assignedTo, setAssignedTo] = useState('');
    const [usersForAssigned, setUsersForAssigned] = useState([]);
    const [remarks, setRemarks] = useState('')
    const [users, setUsers] = useState([
        {
            access: 'edit',
            validity: 30,
            validity_unit: 'days'
        }
    ]);
    const [selectedLeadId, setSelectedLeadId] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [customer, setCustomer] = useState('');
    const [source, setSource] = useState('');
    const [sourceList, setSourceList] = useState([]);
    const [intentStatuses, setIntentStatuses] = useState([])
    const [referredName, setReferredName] = useState('');
    const [referredMobile, setReferredMobile] = useState('');
    const [referredBySociety, setReferredBySociety] = useState('')
    const [referredFlatNo, setReferredFlatNo] = useState('');
    const [loading, setLoading] = useState(false)
    const [followupDate, setFollowupDate] = useState('');
    const [intentId, setIntentId] = useState('');
    const [closingManager, setClosingManager] = useState('')
    const [selectedStatus, setSelectedStatus] = useState('');
    const [statusReason, setStatusReason] = useState('');
    const [statusList, setStatusList] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [showList, setShowList] = useState(false);
    const [svScheduleType, setSVScheduleType] = useState('offline');
    const [meetLink, setMeetLink] = useState('');
    const [svConfirm, setSVConfirm] = useState('yes');
    const [svScheduleDate, setSVScheduleDate] = useState('');

    const { userProfile, allowedPermissions, companyConfig } = useContext(Consumer);

    const ROLE_NAME = localStorage.getItem('role');

    const nameRef = useRef();

    const handleMouseClick = (e) => {
        if (nameRef.current && !nameRef.current.contains(e.target)) {
            setShowList(false)
        }
    }


    const getProjects = async () => {
        const res = await getProjectsForFilter();
        if (res.data.status === 200) {
            const mapped = res.data.data.map((item) => ({ ...item, label: item.name, value: item.id }))
            setProjectList(mapped);
            // setAllProject(mapped)
        }
    }

    const getAllUsers = () => {
        getUsersForSettings().then(res => {
            if (res.status === 200) {
                const userData = res.data.data.map((data) => {
                    return {
                        label: data.name + " - " + data.email,
                        // label: data.name,
                        value: data.id
                    }
                })
                const usersForAssign = res.data.data.map((data) => {
                    return {
                        label: data.name + " - " + data.email,
                        // label: data.name,
                        value: data.id
                    }
                });
                setUsersForAssigned(usersForAssign);
                setAllUsers(userData)
            }
        })
    }

    const getShareLeadAccess = async () => {
        const res = await getLeadAccess(leadId);
        if (res.data.status === 200) {
            if (res.data.data.length > 0) {
                const mapped = res.data.data.map(item => ({
                    user_id: item.user_id,
                    validity: item.validity,
                    validity_unit: item?.validity_unit,
                    access: item.access
                }))
                // const results = allUsers.filter((item) => !mapped.some((mapVal) => item.value === mapVal.user_id));
                // setAllUsers(results)
                setLeadsShareUsers(mapped);
            }
        }
    }

    const getShareLeadAccessAssignedToByStatus = async () => {
        const res = await getLeadStatusAssignedToConfigByStatusId({ status_id: statusId });
        if (res.data.status === 200) {
            if (res.data.data?.user_uuid) {
                setAssignedTo(res.data.data?.user_uuid)
            } else {
                setAssignedTo(reassindedUserUuid)
            }
        }
    }

    const getShareLeadAccessUsingStatusId = async () => {
        const res = await getLeadStatusShareConfigByStatusId({ status_id: statusId });
        if (res.data.status === 200) {
            if (res.data.data.length > 0) {
                const mapped = res.data.data.map(item => ({
                    user_id: item.user_id,
                    validity: item.validity,
                    validity_unit: item?.validity_unit,
                    access: item.access
                }))
                // const results = allUsers.filter((item) => !mapped.some((mapVal) => item.value === mapVal.user_id));
                // setAllUsers(results)
                setStatusShareUsers(mapped);
            }
        }
    }

    useEffect(() => {
        if (uuid && customerName && customerMobile) {
            setCustomer({
                name: customerName,
                mobile: customerMobile,
                value: uuid
            })
            setName(customerName);
            setMobile(customerMobile)
        }
    }, [uuid, customerName, customerMobile]);

    useEffect(() => {
        if (statusShareUsers.length > 0 && (leadsShareUsers.length === 0 || !userProfile?.maintain_lead_access)) {
            setUsers(statusShareUsers);
        } else if (leadsShareUsers.length > 0 && statusShareUsers.length === 0) {
            setUsers(leadsShareUsers);
        } else if (statusShareUsers.length > 0 && leadsShareUsers.length > 0 && userProfile?.maintain_lead_access) {
            // merge two array uniquely
            var ids = new Set(statusShareUsers.map(d => d.user_id));
            var merged = [...statusShareUsers, ...leadsShareUsers.filter(d => !ids.has(d.ID))];
            setUsers(merged);
        }
    }, [statusShareUsers, leadsShareUsers])

    useEffect(() => {
        const list = statuses.filter(s => (s.label === 'Not Connected' || s.label === 'In Progress' || s.label === 'SV Scheduled'))
        setStatusList(list)
    }, [statuses]);

    useEffect(() => {
        if (leadId) {
            // getActiveFollowup(leadId)
            setSelectedLeadId(leadId)
            if (userProfile?.allowed_modules?.includes('lead_sharing')) {
                getShareLeadAccess()
            }
        }
        if (statusId && userProfile?.allowed_modules?.includes('lead_sharing')) {
            getShareLeadAccessUsingStatusId();
            getShareLeadAccessAssignedToByStatus();
        }
        getAllUsers();
    }, [leadId, statusId]);

    useEffect(() => {
        if (companyConfig) {
            setSourceList(companyConfig?.Source)
            setIntentStatuses(companyConfig?.Intent)
        }
    }, [companyConfig])

    useEffect(() => {
        getProjects();
        document.addEventListener('mousedown', handleMouseClick)
        return (() => document.removeEventListener('mousedown', handleMouseClick))
    }, []);

    const isDisable = useMemo(() => {
        if (ROLE_NAME === 'Super Admin') {
            return false;
        }

        return !allowedPermissions.includes('edit_lead_sharing')

    }, [allowedPermissions, ROLE_NAME]);

    const handleSubmit = async () => {
        // (projects.length === 0 && (!followupDate || !intentId))
        if (!name || !mobile || !assignedTo || !source || !projects?.length === 0) {
            toast.error('Please fill required fields');
            return;
        }
        // if (projects.length > 0) {
        //     let empty = projects.some(p => (!p.followupScheduleDate || !p.intentId))
        //     if (empty) {
        //         toast.error('Please fill required fields');
        //         return;
        //     }
        // }

        let mappedProjects = []
        if (projects.length === 0) {
            const obj = {
                projectId: null,
                followupScheduleDate: followupDate || null,
                intentId: intentId || null,
                siteVisitScheduleDate: svScheduleDate || null,
                siteVisitType: svScheduleType || null,
                is_sv_confirmed: svConfirm === 'yes' ? true : false,
                meetLink: meetLink || null,
            }
            mappedProjects.push(obj)
        }
        if (projects.length > 0) {
            const obj = projects.map(i => ({
                projectId: i.value,
                followupScheduleDate: sameTime ? followupDate : i.followupScheduleDate,
                intentId: sameTime ? intentId : i.intentId,
                siteVisitScheduleDate: sameTime ? svScheduleDate : i.siteVisitScheduleDate,
                siteVisitType: sameTime ? svScheduleType : i.svScheduleType,
                is_sv_confirmed: sameTime ? svConfirm === 'yes' ? true : false : i.svConfirm === 'yes' ? true : false,
                meetLink: sameTime ? meetLink : i.meetLink,
            }))
            mappedProjects = [...obj]
        }
        const payload = {
            customer_uuid: customer?.value !== 'new' ? customer?.value : '',
            name: name,
            mobile: mobile,
            sourceId: source,
            preSaleUserId: assignedTo || null,
            sourcingUserId: null,
            closingUserId: closingManager || null,
            remarks: remarks,
            referredBy: referredName || null,
            referredByNumber: referredMobile || null,
            referredBySociety: referredBySociety || null,
            referredByFlatNo: referredFlatNo || null,
            statusId: selectedStatus?.value,
            info: statusReason?.value,
            projects: mappedProjects
        }
        try {
            setLoading(true);
            const res = await insertOpportunity(payload);
            setLoading(false);
            if (res.data.status === 200) {
                toast.success(res.data.message)
                getOpportunities();
                closeModal();
            }
        } catch (err) {
            toast.success(err.response.data.message)
            setLoading(false);
        }
    }

    const loadOptions = async (name, field) => {
        if (field === 'name') {
            setName(name)
        } else {
            setReferredName(name)
        }
        const payload = {
            "pageNumber": 1,
            "perPage": 100,
            "searchText": name,
            filters: {},
            tab: 'all'
        }
        const res = await customerDashboard(payload)
        if (res.data.status === 200) {
            setShowList(res.data.data.length > 0 ? true : false)
            setUsersList(res.data.data)
            // const users = res.data?.data.map((item) => {
            //     return {
            //         label: <div className='flex flex-col text-sm !border-b !pb-2'> <span className='font-semibold text-black-t'>{item.name}</span> <span className='text-grey-500 text-xs'>{item.mobile}</span> </div>,
            //         value: item.uuid
            //     }
            // });
            // return [{
            //     label: <div className='flex flex-col text-sm !border-b font-semibold text-[#1570EF] !pb-4 !pt-2'> New Customer</div>,
            //     value: 'new'
            // }, ...users];
        }
    }

    return (
        <RightModal closeModal={closeModal} show={show} modalClass={'!w-[400px]'}>
            <div className="modal-header sticky top-0 left-0 bg-white !px-5  !py-4 flex">
                <h1 className='text-black text-2xl inter m-0'>New Opportunity</h1>
                <button onClick={closeModal} className='modal-close'>
                    <CloseIcon />
                </button>
            </div>
            <div className="modal-body !px-5 h-5/6 overflow-y-auto !space-y-4">
                <div className='relative'>
                    {/* <label htmlFor="">Customer</label> */}
                    {/* <AsyncSelect
                        cacheOptions
                        loadOptions={loadOptions}
                        defaultOptions
                        styles={customStyles}
                        placeholder='Search and Select'
                        value={customer}
                        onChange={(e) => {
                            setCustomer(e)
                        }}
                        isClearable={true}
                        menuPosition={'fixed'}
                    /> */}
                    <InputText
                        label={'Customer Name'}
                        placeholder={'Search Customer Name'}
                        value={name}
                        onChange={(e) => loadOptions(e.target.value, 'name')}
                        required={true}
                    />
                    {name && showList && <div ref={nameRef} className='absolute !top-22 !rounded-lg max-h-[200px] overflow-auto bg-white w-100 z-10 shadow !px-4'>
                        {
                            usersList?.map((item, index) => (
                                <div className='flex flex-col text-sm !border-b !py-2 cursor-pointer' key={item.uuid}
                                    onClick={() => {
                                        console.log(item, 'item')
                                        setCustomer(item)
                                        setMobile(item.display_number)
                                        setShowList(false)
                                    }}>
                                    <span className='font-semibold text-black-t'>{item.name}</span>
                                    <span className='text-grey-500 text-xs'>{item.display_number}</span>
                                </div>
                            ))
                        }
                        <div>

                        </div>

                    </div>}
                </div>
                {/* <InputSelect
                    label={'Customer'}
                    required={true}
                    options={[]}
                    isMulti={false}
                    value={customer}
                    onChange={(e) => setCustomer(e)}
                /> */}

                <MobileInput
                    label={'Mobile'}
                    value={mobile}
                    onChange={(e) => setMobile(e.phone)}
                    required={true}
                />
                <InputSelect
                    label={'Project'}
                    required={true}
                    options={projectList}
                    isMulti={true}
                    value={projects}
                    onChange={(e) => setProjects(e)}
                />
                <InputSelect
                    name="status"
                    maxMenuHeight="150px"
                    options={projects.length === 0 ? statusList.filter(i => i.label != 'SV Scheduled') : statusList}
                    placeholder="Select Status"
                    value={selectedStatus}
                    onChange={(e) => {
                        setSelectedStatus(e)
                        setStatusReason('')
                    }}
                    label='Status'
                    required={false}
                />
                {(selectedStatus.label === 'Not Connected' || selectedStatus.label === 'In Progress') && <InputSelect
                    name="reason"
                    options={selectedStatus.label === 'Not Connected' ? NotConnectedReasonsList : InProgessReasonsList}
                    placeholder="Select Reason"
                    value={statusReason}
                    onChange={(e) => {
                        setStatusReason(e);
                    }}
                    label='Reason'
                    required={false}
                />}
                <InputSelect
                    label={'Source'}
                    value={source ? sourceList.find(i => i.value === source) : ''}
                    onChange={(e) => setSource(e.value)}
                    options={sourceList}
                    required={false}
                />
                <div className='flex flex-col !mt-4 !mb-2'>
                    <label className='m-0'>Comments (optional)</label>
                    <textarea
                        className="form-control mb-16"
                        name="remarks"
                        value={remarks}
                        placeholder="Add notes here"
                        onChange={(e) => setRemarks(e.target.value)}
                    >

                    </textarea>
                </div>
                {userProfile?.allowed_modules?.includes('lead_sharing') &&
                    checkIsSomeItemAvailableInList(allowedPermissions, ['view_lead_share_modal', 'edit_lead_sharing']) &&
                    <div className='pt-3'>
                        <div className='!mt-4'>
                            <InputSelect
                                label={'Pre-sale'}
                                placeholder={'Select User'}
                                options={usersForAssigned}
                                value={assignedTo ? usersForAssigned.find(user => user.value === assignedTo) : ''}
                                onChange={(value) => {
                                    if (value) {
                                        setAssignedTo(value.value);
                                    } else {
                                        setAssignedTo(value);
                                    }
                                }}
                                disable={isDisable}
                                required={false}
                                isClearable={true}
                            />
                            <InputSelect
                                label={'Closing'}
                                placeholder={'Select User'}
                                inputclass={'!mt-4'}
                                options={usersForAssigned}
                                value={closingManager ? usersForAssigned.find(user => user.value === closingManager) : ''}
                                onChange={(value) => {
                                    if (value) {
                                        setClosingManager(value.value);
                                    } else {
                                        setClosingManager(value);
                                    }
                                }}
                                disable={isDisable}
                                isClearable={true}
                            />
                        </div>
                    </div>}
                {
                    source === 852 && <>
                        <div className='relative'>
                            <InputText
                                label={'Referred Name'}
                                placeholder={'Search Referred Name'}
                                value={referredName}
                                onChange={(e) => loadOptions(e.target.value, 'referred')}
                            />
                            {referredName && showList && <div className='absolute !top-20 !rounded-lg max-h-[200px] overflow-auto bg-white w-100 z-10 shadow !px-4'>
                                {
                                    usersList?.map((item, index) => (
                                        <div className='flex flex-col text-sm !border-b !py-2 cursor-pointer' key={item.uuid} onClick={() => {
                                            setReferredMobile(item.mobile)
                                            setShowList(false)
                                        }}>
                                            <span className='font-semibold text-black-t'>{item.name}</span>
                                            <span className='text-grey-500 text-xs'>{item.mobile}</span>
                                        </div>
                                    ))
                                }
                            </div>}

                        </div>

                        {/* <InputText
                            label={'Referred Name'}
                            placeholder={'Enter Referred Name'}
                            value={referredName}
                            onChange={(e) => setReferredName(e.target.value)}
                        /> */}
                        <MobileInput
                            label={'Referred Mobile'}
                            value={referredMobile}
                            onChange={(e) => setReferredMobile(e.phone)}
                        />
                        <InputText
                            label={'Referred Society'}
                            placeholder={'Enter Referred Society'}
                            value={referredBySociety}
                            onChange={(e) => setReferredBySociety(e.target.value)}
                        />
                        <InputText
                            label={'Referred Flat No'}
                            placeholder={'Enter Referred Flat No'}
                            value={referredFlatNo}
                            onChange={(e) => setReferredFlatNo(e.target.value)}
                        />
                    </>
                }

                {projects?.length > 1 && <div className='flex justify-between items-center'>
                    <label htmlFor="" className='mb-0 text-sm text-black-t font-medium'>Schedule all Followups at the same time</label>
                    <button
                        type="button"
                        className={`btn btn-lg btn-toggle ${sameTime ? 'active' : ''} mr-2`}
                        value={sameTime}
                        onClick={() => {
                            setSameTime(!sameTime)
                        }}
                    >
                        <div className="handle"></div>
                        <span className={`${sameTime ? 'on' : 'off'}`}></span>
                    </button>
                </div>}
                {
                    !sameTime && <>
                        {projects?.length > 0 && projects.map((item, index) => (
                            <div className='!space-y-4'>
                                {projects?.length > 1 && <p className='m-0 text-black-t text-sm font-medium flex items-center'>
                                    {
                                        item.label.length > 15 ? <TooltipText title={item.label}>
                                            <span className='!max-w-[150px] !min-w-[50px] text-nowrap truncate'>{item.label}</span>
                                        </TooltipText>
                                            :
                                            <span className='!max-w-[150px] !min-w-[50px] text-nowrap'>{item.label}</span>
                                    }
                                    <span className='!border-t w-[75%] !border-black-t inline-block !ml-3'></span>
                                </p>}
                                {selectedStatus.label === 'SV Scheduled' &&
                                    <>
                                        <DateTimePicker
                                            onValueChange={(date) => {
                                                let proj = [...projects]
                                                proj[index]['siteVisitScheduleDate'] = date
                                                setProjects(proj)
                                            }}
                                            label="Sv Schedule Date & Time"
                                            value={projects[index]?.siteVisitScheduleDate}
                                            name="scheduleDate"
                                            required={false}
                                        />
                                        <div className='form-group mt-3'>
                                            <label>Type of Site Visit</label>
                                            <div>
                                                <label className='mr-4'>
                                                    <input
                                                        type='radio'
                                                        name='sitevisit'
                                                        value={'offline'}
                                                        checked={projects[index]?.svScheduleType === 'offline'}
                                                        onChange={() => {
                                                            let proj = [...projects]
                                                            proj[index]['svScheduleType'] = 'offline'
                                                            setProjects(proj)
                                                        }}
                                                    /> Onsite
                                                </label>
                                                <label>
                                                    <input
                                                        type='radio'
                                                        name='sitevisit'
                                                        value='online'
                                                        checked={projects[index]?.svScheduleType === 'online'}
                                                        onChange={() => {
                                                            let proj = [...projects]
                                                            proj[index]['svScheduleType'] = 'online'
                                                            setProjects(proj)
                                                        }}
                                                    /> Video Call
                                                </label>
                                            </div>
                                        </div>
                                        <div className='form-group mt-3'>
                                            <label>Is this site visit confirmed?</label>
                                            <div>
                                                <label className='mr-4'>
                                                    <input
                                                        type='radio'
                                                        name='confirm'
                                                        value='yes'
                                                        checked={projects[index]?.svConfirm === 'yes'}
                                                        onChange={() => {
                                                            let proj = [...projects]
                                                            proj[index]['svConfirm'] = 'yes'
                                                            setProjects(proj)
                                                        }}
                                                    /> Yes
                                                </label>
                                                <label className='mr-4'>
                                                    <input
                                                        type='radio'
                                                        name='confirm'
                                                        value='no'
                                                        checked={projects[index]?.svConfirm === 'no'}
                                                        onChange={() => {
                                                            let proj = [...projects]
                                                            proj[index]['svConfirm'] = 'no'
                                                            setProjects(proj)
                                                        }}
                                                    /> No
                                                </label>
                                            </div>
                                        </div>
                                        {svScheduleType === 'online' && <InputText
                                            index={8}
                                            placeholder="Paste Online Meet Link (Optional)"
                                            value={projects[index]?.meetLink}
                                            onChange={(e) => {
                                                let proj = [...projects]
                                                proj[index]['svConfirm'] = e.target.value
                                                setProjects(proj)
                                            }}
                                        />}
                                    </>
                                }
                                <DateTimePicker
                                    onValueChange={(date) => {
                                        let proj = [...projects]
                                        proj[index]['followupScheduleDate'] = date
                                        setProjects(proj)
                                    }}
                                    label="Select Followup Date & Time"
                                    value={projects[index]?.followupScheduleDate}
                                    name="scheduleDate"
                                    required={false}
                                />
                                <InputSelect
                                    name="intent"
                                    maxMenuHeight="150px"
                                    options={intentStatuses}
                                    placeholder="Select Intent"
                                    value={projects[index]?.intentId ? intentStatuses.find(i => i.value === projects[index]?.intentId) : ''}
                                    onChange={(e) => {
                                        let proj = [...projects]
                                        proj[index]['intentId'] = e.value
                                        setProjects(proj)
                                    }}
                                    label='Intent'
                                    required={false}
                                />

                            </div>
                        ))}
                        {/* {
                            projects?.length <= 1 && <>
                                <DateTimePicker
                                    label="Select Followup Date & Time"
                                    value={projects[0]?.followupScheduleDate}
                                    onValueChange={(date) => {
                                        let proj = [...projects]
                                        proj[0]['followupScheduleDate'] = date
                                        setProjects(proj)
                                    }}
                                    name="scheduleDate"
                                    required={true}
                                />
                                <InputSelect
                                    name="intent"
                                    maxMenuHeight="150px"
                                    options={intentStatuses}
                                    placeholder="Select Intent"
                                    value={projects[0]?.intentId ? intentStatuses?.find(i => i.value === projects[0]?.intentId) : ''}
                                    onChange={(e) => {
                                        let proj = [...projects]
                                        proj[0]['intentId'] = e.value
                                        setProjects(proj)
                                    }}
                                    label='Intent'
                                    required={true}
                                /> </>} */}
                    </>
                }
                {/* { */}
                {/* // sameTime && */}
                {projects?.length === 0 || sameTime && <>
                    {selectedStatus.label === 'SV Scheduled' &&
                        <>
                            <DateTimePicker
                                onValueChange={(date) => {
                                    setSVScheduleDate(date)
                                }}
                                label="Sv Schedule Date & Time"
                                value={svScheduleDate}
                                name="scheduleDate"
                                required={false}
                            />
                            <div className='form-group mt-3'>
                                <label>Type of Site Visit</label>
                                <div>
                                    <label className='mr-4'>
                                        <input
                                            type='radio'
                                            name='sitevisit'
                                            value='offline'
                                            checked={svScheduleType === 'offline'}
                                            onChange={() => {
                                                setSVScheduleType('offline')
                                            }}
                                        /> Onsite
                                    </label>
                                    <label>
                                        <input
                                            type='radio'
                                            name='sitevisit'
                                            value='online'
                                            checked={svScheduleType === 'online'}
                                            onChange={() => {
                                                setSVScheduleType('online')
                                            }}
                                        /> Video Call
                                    </label>
                                </div>
                            </div>
                            <div className='form-group mt-3'>
                                <label>Is this site visit confirmed?</label>
                                <div>
                                    <label className='mr-4'>
                                        <input
                                            type='radio'
                                            name='confirm'
                                            value='yes'
                                            checked={svConfirm === 'yes'}
                                            onChange={() => {
                                                setSVConfirm('yes');
                                            }}
                                        /> Yes
                                    </label>
                                    <label className='mr-4'>
                                        <input
                                            type='radio'
                                            name='confirm'
                                            value='no'
                                            checked={svConfirm === 'no'}
                                            onChange={() => {
                                                setSVConfirm('no');
                                            }}
                                        /> No
                                    </label>
                                </div>
                            </div>
                            {svScheduleType === 'online' && <InputText
                                index={8}
                                placeholder="Paste Online Meet Link (Optional)"
                                value={meetLink}
                                onChange={(e) => {
                                    setMeetLink(e.target.value)
                                }}
                            />}
                        </>
                    }
                    <DateTimePicker
                        label="Select Followup Date & Time"
                        value={followupDate}
                        onValueChange={(date) => {
                            setFollowupDate(date)
                        }}
                        name="scheduleDate"
                        required={false}
                    />
                    <InputSelect
                        name="intent"
                        maxMenuHeight="150px"
                        options={intentStatuses}
                        placeholder="Select Intent"
                        value={intentId ? intentStatuses?.find(i => i.value === intentId) : ''}
                        onChange={(e) => {
                            setIntentId(e.value)
                        }}
                        label='Intent'
                        required={false}
                    />
                </>}
                {/* // } */}
            </div>


            <div className="d-flex flex-row align-items-center justify-content-between !px-5 gap-x-4 !py-4 righmodalfooter shadow-topshadow">

                <button onClick={closeModal} className='border rounded-lg py-2.5 w-28 basis-28 grow-0 shrink-0 border-primary !text-primary font-semibold text-16 text-center'>Cancel</button>
                {!loading && <button onClick={handleSubmit} className='border rounded-lg py-2.5 w-full ml-4 !bg-primary !border-primary text-white font-semibold text-16 text-center hover:opacity-90'> Create Opportunity</button>}
                {loading && <button className='border rounded-lg py-2.5 w-full ml-4 !bg-primary !border-primary text-white font-semibold text-16  flex items-center justify-center'>
                    <Oval
                        height={24}
                        width={24}
                        color="#FFFFFF"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#FFFFFF"
                        strokeWidth={4}
                        strokeWidthSecondary={4}
                    />
                </button>}

            </div >
        </RightModal>
    )
}

export default CreateOpportunities