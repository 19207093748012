import React, { useContext, useEffect, useMemo, useState } from 'react';
import SliderModal from '../../../../components/Modals/SliderModal';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import moment from 'moment';
import AsyncSelect from 'react-select/async';
import DateTimePicker from '../../../../components/InputGroup/DateTime';
import InputSelect from '../../../../components/InputGroup/InputSelect'
import { updateSiteVisitSchema, updateSiteVisitSchemaMultiProject } from '../../../../validators/leads.validator';

// sevices
import { insertSiteVisit, updateSiteVisit } from '../../../../services/private/sitevisit.service';
import { getLatestActivityByUuid, getLeadByUuid, getProjectsByLead, reassignLeads, searchLeadByName } from '../../../../services/private/leads.service';
import { getAllProjects, getLeadAccess, getLeadStatusAssignedToConfigByStatusId, getLeadStatusShareConfigByStatusId, getUsersForSettings, saveLeadAccess } from '../../../../services/private/company.service';

import Skeleton from 'react-loading-skeleton';
import InputText from '../../../../components/InputGroup/InputText';
import { validityUnit } from '../../../../helpers/enums';
import { ReactComponent as PlusPrimaryIcon } from '../../../../assets/icons/PlusPrimary.svg';
import Consumer from '../../../../helpers/context';
import { checkIsArrayEmpty, checkIsSomeItemAvailableInList } from '../../../../helpers/helpers';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { Oval } from 'react-loader-spinner';
import { changeStatus, getOpportunityDetails, rescheduleSiteVisit } from '../../services/dashboard.service';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },
        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};

const durationUnit = [
    {
        label: 'Minutes',
        value: 'mins'
    },
    {
        label: 'Hours',
        value: 'hrs'
    }
]

export default function SVScheduleModal({
    show,
    setShow,
    leadId,
    onSuccess,
    intentOptions,
    selectedIntent,
    isEdit,
    statusId,
    reassindedUserUuid,
    leadName,
    svUUID,
    isNew,
    data
}) {

    const [siteVisitData, setsiteVisitData] = useState({});
    const [followupdata, setfollowupdata] = useState({});

    const [leadDetails, setLeadDetails] = useState();
    const [projects, setProjects] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [statusShareUsers, setStatusShareUsers] = useState([]);
    const [leadsShareUsers, setLeadsShareUsers] = useState([]);

    // this is a hack update it when you have time :) @ankit
    const [selectedLeadId, setSelectedLeadId] = useState();
    const [activityLoader, setActivityLoder] = useState(false);
    const [projectLoader, setProjectLoader] = useState(false);
    const [closingManager, setClosingManager] = useState('');

    const [allUsers, setAllUsers] = useState([]);
    const [users, setUsers] = useState([
        {
            access: 'edit',
            validity: 30,
            validity_unit: 'days'
        }
    ]);
    const [assignedTo, setAssignedTo] = useState();
    const [usersForAssigned, setUsersForAssigned] = useState([]);
    const [duration, setDuration] = useState('');
    const [duration_unit, setDuration_unit] = useState('');

    const { userProfile, allowedPermissions } = useContext(Consumer);
    const ROLE_NAME = localStorage.getItem('role');

    const getAllUsers = () => {
        getUsersForSettings().then(res => {
            if (res.status === 200) {
                const userData = res.data.data.map((data) => {
                    return {
                        label: data.name + " - " + data.email,
                        // label: data.name,
                        value: data.id
                    }
                })
                const usersForAssign = res.data.data.map((data) => {
                    return {
                        label: data.name + " - " + data.email,
                        // label: data.name,
                        value: data.id,
                        uuid: data.uuid
                    }
                });
                setUsersForAssigned(usersForAssign);
                setAllUsers(userData)
            }
        })
    }

    const getShareLeadAccess = async () => {
        const res = await getLeadAccess(leadId);
        if (res.data.status === 200) {
            if (res.data.data.length > 0) {
                const mapped = res.data.data.map(item => ({
                    user_id: item.user_id,
                    validity: item.validity,
                    validity_unit: item?.validity_unit,
                    access: item.access
                }))
                // const results = allUsers.filter((item) => !mapped.some((mapVal) => item.value === mapVal.user_id));
                // setAllUsers(results)
                setLeadsShareUsers(mapped);
            }
        }
    }

    const getShareLeadAccessAssignedToByStatus = async () => {
        const res = await getLeadStatusAssignedToConfigByStatusId({ status_id: statusId });
        if (res.data.status === 200) {
            if (res.data.data?.user_uuid) {
                // const user = usersForAssigned.find(i => i.uuid === res.data.data?.user_uuid)
                setAssignedTo(res.data.data?.user_id)
            } else {
                const user = usersForAssigned.find(i => i.uuid === reassindedUserUuid)
                setAssignedTo(user?.value)
            }
        }
    }

    const getShareLeadAccessUsingStatusId = async () => {
        const res = await getLeadStatusShareConfigByStatusId({ status_id: statusId });
        if (res.data.status === 200) {
            if (res.data.data.length > 0) {
                const mapped = res.data.data.map(item => ({
                    user_id: item.user_id,
                    validity: item.validity,
                    validity_unit: item?.validity_unit,
                    access: item.access
                }))
                // const results = allUsers.filter((item) => !mapped.some((mapVal) => item.value === mapVal.user_id));
                // setAllUsers(results)
                setStatusShareUsers(mapped);
            }
        }
    }

    useEffect(() => {
        if (statusShareUsers.length > 0 && (leadsShareUsers.length === 0 || !userProfile?.maintain_lead_access)) {
            setUsers(statusShareUsers);
        } else if (leadsShareUsers.length > 0 && statusShareUsers.length === 0) {
            setUsers(leadsShareUsers);
        } else if (statusShareUsers.length > 0 && leadsShareUsers.length > 0 && userProfile?.maintain_lead_access) {
            // merge two array uniquely
            var ids = new Set(statusShareUsers.map(d => d.user_id));
            var merged = [...statusShareUsers, ...leadsShareUsers.filter(d => !ids.has(d.ID))];
            setUsers(merged);
        }
    }, [statusShareUsers, leadsShareUsers])


    useEffect(() => {
        if (leadId) {
            // if (!isNew) {
            //     getActivity(leadId)
            // }
            getLeadDetails()
            setSelectedLeadId(leadId)
            // getLeadProjects(leadId);
            if (userProfile?.allowed_modules?.includes('lead_sharing')) {
                getShareLeadAccess();
            }
        }
        if (statusId && userProfile?.allowed_modules?.includes('lead_sharing')) {
            // getShareLeadAccessAssignedToByStatus();
            getShareLeadAccessUsingStatusId();
        }
        getAllUsers();
    }, [leadId, statusId, isNew]);


    const getAllLeadsForInput = async (leadName) => {
        return searchLeadByName(leadName).then(res => {
            if (res.status === 200) {
                const leadOptionsReq = res.data.data.map((data) => {
                    return {
                        label: <>
                            <span>
                                {data.name}
                            </span>
                            <span>
                                {" (" + data.mobile + ") "}
                            </span>
                        </>,
                        value: data.uuid
                    }
                })
                return (leadOptionsReq)
            }
        })
    }

    const getAllProjectsByCompanyId = async () => {
        await getAllProjects().then((res) => {
            if (res.status === 200) {
                const projects = res.data.data.map((project) => ({ label: project.name, value: project.id }));
                setProjectList(projects);
            }
        })
    }

    useEffect(() => {
        getAllProjectsByCompanyId()
    }, [])

    const onUpdateSiteVist = async (values) => {
        // const projectIds = values.projectId.map(proj => proj.value);
        let projectIds = []
        setIsSubmitting(true);

        const leadshare = leadsShareUsers.map(item => ({
            ...item,
            validity: item.validity || '30',
            access: item.access || 'edit',
            validity_unit: item.validity_unit || 'days',
            expires_at: new Date(moment().add(parseInt(item.validity), item.validity_unit)).toISOString()
        }))

        const payload = {
            statusId: statusId,
            statusValue: 'sv scheduled',
            intentId: values.intent[0]?.value,
            siteVisitScheduleDate: values.scheduleDate,
            followupScheduleDate: values.followupScheduleDate,
            siteVisitType: values?.siteVisitType || 'offline',
            is_sv_confirmed: values?.confirm === 'yes',
            meetLink: values?.meetLink,
            project_id: values.projectId.value,
            remarks: values.remarks,
            preSaleUserId: assignedTo || null,
            sourcingUserId: null,
            closingUserId: closingManager || null,
            lead_Access_users: leadshare,
        }
        try {
            let res = {}
            if (isEdit) {
                res = await rescheduleSiteVisit(svUUID, payload)
            } else {
                res = await changeStatus(selectedLeadId, payload)
            }
            setIsSubmitting(false);
            if (res.status === 200) {
                toast.success("SV scheduled Updated")
                onSuccess();
                setShow(false);
            }
        } catch (err) {
            setIsSubmitting(false);
            toast.error(err.response?.data?.message)
        }
    }

    // const getActivity = async (leadId) => {
    //     setActivityLoder(true);
    //     const activity = await getLatestActivityByUuid(leadId)
    //     setActivityLoder(false);
    //     if (activity.siteVisit) {
    //         setsiteVisitData(activity.siteVisit)
    //     }
    //     if (activity.followup) {
    //         setfollowupdata(activity.followup)
    //     }
    // }

    const getLeadDetails = async () => {
        getOpportunityDetails(leadId).then(res => {
            if (res.status === 200) {
                setLeadDetails(res.data.data);
                setClosingManager(res.data.data?.closing_user_id)
                if (!res.data.data?.pre_sale_user_id) {
                    getShareLeadAccessAssignedToByStatus()
                } else {
                    setAssignedTo(res.data.data?.pre_sale_user_id)
                }
            }
        })
    }
    // const getLeadProjects = async (leadId) => {
    //     setProjectLoader(true);
    //     await getProjectsByLead(leadId).then(res => {
    //         if (res.status === 200) {
    //             const projectData = res.data.data.map((data) => {
    //                 return {
    //                     value: data.id,
    //                     label: data.name
    //                 }
    //             })
    //             setProjects(projectData);
    //             setProjectLoader(false)
    //         }
    //     }).catch(err => {
    //         console.log({ err })
    //         setProjectLoader(false)
    //     })
    // }

    const isDisable = useMemo(() => {
        if (ROLE_NAME === 'Super Admin') {
            return false;
        }

        return !allowedPermissions.includes('edit_lead_sharing')

    }, [allowedPermissions, ROLE_NAME])

    console.log(leadDetails, 'leadDetailsleadDetails')
    return (
        <SliderModal
            show={show}
            setShow={setShow}
            title={
                "SV Schedule & Followup"
            }
            body={
                <>
                    <Formik
                        onSubmit={(values, actions) => {
                            if (userProfile?.company_id !== 581 && values.followupScheduleDate > values.scheduleDate) {
                                actions.setFieldError('followupScheduleDate', 'Followup Date should be before SV Scheduled Date')
                                return
                            }
                            if (values.projectId.length === 0) {
                                actions.setFieldError('projectId', 'Project is required');
                                return
                            }
                            onUpdateSiteVist(values)
                        }}
                        validationSchema={userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID ? updateSiteVisitSchema : updateSiteVisitSchemaMultiProject}
                        initialValues={{
                            scheduleDate: leadDetails?.latest_site_visit?.schedule_date ? new Date(leadDetails?.latest_site_visit?.schedule_date) : '',
                            remarks: leadDetails?.latest_site_visit.remarks ? leadDetails?.latest_site_visit.remarks : '',
                            followupScheduleDate: leadDetails?.latest_followup.schedule_date ? new Date(leadDetails?.latest_followup.schedule_date) : '',
                            projectId: leadDetails?.project_name ? projectList?.find(i => i.label === leadDetails?.project_name) : '',
                            siteVisitType: leadDetails?.latest_site_visit?.type || 'offline',
                            meetLink: leadDetails?.latest_site_visit?.meet_link || '',
                            leadId: leadDetails?.uuid,
                            intent: leadDetails?.intent_display_name ? intentOptions.filter((intent) => intent.label === leadDetails?.intent_display_name) : '',
                            confirm: leadDetails?.latest_site_visit?.is_confirmed ? 'yes' : 'no'
                        }}
                        validateOnBlur={false}
                        validateOnChange={false}
                        enableReinitialize
                    >
                        {
                            (props) => {
                                const { values, touched, errors, handleChange, handleBlur, handleReset, handleSubmit } = props;
                                return (
                                    <form onSubmit={handleSubmit} className='flex flex-col h-full overflow-hidden'>
                                        <div className='h-full overflow-auto !px-5'>

                                            <div className={`form-group ${errors['leadId'] ? 'invalid' : ''} `}>
                                                <label className='mt-3'>Lead Name</label>

                                                {!leadId ?
                                                    <>
                                                        <AsyncSelect
                                                            cacheOptions
                                                            loadOptions={getAllLeadsForInput}
                                                            defaultOptions
                                                            value={values['leadId']}
                                                            onChange={(e) => {
                                                                const value = { target: { name: "leadId", value: e } }
                                                                handleChange(value)
                                                                setSelectedLeadId(e.value)
                                                                // getLeadProjects(e.value)
                                                                // getActivity(e.value)
                                                            }}
                                                        // onInputChange={getAllLeadsForInput}
                                                        />
                                                        {errors['leadId'] && (
                                                            <>
                                                                <div className="input-feedback mt-8">{errors['leadId']}
                                                                </div>
                                                            </>

                                                        )}
                                                    </>
                                                    : <div className='text-capitalize fz16 fw-po-medium'>
                                                        {leadDetails?.customer?.data?.name || leadName || data?.name}
                                                    </div>
                                                }
                                            </div>

                                            <label className='mt-3'>Select Site Visit Schedule Date & Time <sup>*</sup></label>
                                            <DateTimePicker
                                                onValueChange={(e) => {
                                                    const value = {
                                                        target: {
                                                            name: "scheduleDate",
                                                            value: e
                                                        }
                                                    }
                                                    handleChange(value)
                                                }}
                                                name="scheduleDate"
                                                error={errors['scheduleDate']}
                                                handelBlur={handleBlur}
                                                value={values['scheduleDate']}
                                            />
                                            {/* {activityLoader && <Skeleton count={1} height="30px" width="100%" />} */}

                                            <div className='form-group mt-3'>
                                                <label>Type of Site Visit</label>
                                                <div>
                                                    <label className='mr-4'>
                                                        <input
                                                            type='radio'
                                                            name='sitevisit'
                                                            value='offline'
                                                            checked={values['siteVisitType'] === 'offline'}
                                                            onChange={() => {
                                                                const value = { target: { name: "siteVisitType", value: 'offline' } }
                                                                handleChange(value)
                                                            }}
                                                        /> Onsite
                                                    </label>
                                                    <label>
                                                        <input
                                                            type='radio'
                                                            name='sitevisit'
                                                            value='online'
                                                            checked={values['siteVisitType'] === 'online'}
                                                            onChange={() => {
                                                                const value = { target: { name: "siteVisitType", value: 'online' } }
                                                                handleChange(value)
                                                            }}
                                                        /> Video Call
                                                    </label>
                                                </div>
                                                {userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && <div className='form-group mt-3'>
                                                    <label>Is this site visit confirmed?</label>
                                                    <div>
                                                        <label className='mr-4'>
                                                            <input
                                                                type='radio'
                                                                name='confirm'
                                                                value='yes'
                                                                checked={values['confirm'] === 'yes'}
                                                                onChange={() => {
                                                                    const value = { target: { name: "confirm", value: 'yes' } }
                                                                    handleChange(value)
                                                                }}
                                                            /> Yes
                                                        </label>
                                                        <label className='mr-4'>
                                                            <input
                                                                type='radio'
                                                                name='confirm'
                                                                value='no'
                                                                checked={values['confirm'] === 'no'}
                                                                onChange={() => {
                                                                    const value = { target: { name: "confirm", value: 'no' } }
                                                                    handleChange(value)
                                                                }}
                                                            /> No
                                                        </label>
                                                    </div>
                                                </div>}
                                                {values['siteVisitType'] === 'online' && <InputText
                                                    label={'Vide Call Link'}
                                                    index={8}
                                                    placeholder="Paste Online Meet Link (Optional)"
                                                    value={values['meetLink']}
                                                    onChange={(e) => {
                                                        const value = {
                                                            target: {
                                                                name: "meetLink",
                                                                value: e.target.value
                                                            }
                                                        }
                                                        handleChange(value)
                                                    }}
                                                />}
                                            </div>
                                            {/* <div className='form-group position-relative mt-3'>
                                                <label >Duration</label>
                                                <Dropdown
                                                    list={durationUnit}
                                                    onChange={(value) => {
                                                        console.log(value, 'dropdown')
                                                        setDuration_unit(value.value)
                                                    }}
                                                    value={duration_unit ? durationUnit.find(item => item.value === duration_unit) : ''}
                                                    style={style}
                                                />
                                                <input
                                                    type='text'
                                                    className='form-control text-input'
                                                    // style={{ borderColor: (error && !milestones.duration) ? '#FC5A5A' : '' }}
                                                    placeholder='e.g. 3'
                                                    value={duration}
                                                    onChange={(e) => {
                                                        let val = e.target.value
                                                            .replace(/^0+/g, '')
                                                            .replace(/[^0-9]/g, '')

                                                        setDuration(val)
                                                    }}
                                                />                                                
                                            </div> */}

                                            <label className='mt-3'>Projects</label>
                                            {!projectLoader && <InputSelect
                                                index={4}
                                                name="projectId"
                                                error={errors['projectId']}
                                                touched={touched['projectId']}
                                                isMulti={userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID ? false : true}
                                                options={projectList}
                                                placeholder="e.g. Piramal"
                                                value={values.projectId}
                                                disable={!!(values.projectId)}
                                                onChange={(e) => {
                                                    const value = {
                                                        target: {
                                                            name: "projectId",
                                                            value: e
                                                        }
                                                    }
                                                    handleChange(value)
                                                }}

                                            />}
                                            {projectLoader && < Skeleton count={1} height="30px" width="100%" />}

                                            <label className='mt-3'>Intent <sup>*</sup></label>
                                            <InputSelect
                                                index={4}
                                                name="intent"
                                                error={errors['intent']}
                                                touched={touched['intent']}
                                                options={intentOptions}
                                                placeholder="Select Intent"
                                                value={values.intent}
                                                onChange={(e) => {
                                                    const value = {
                                                        target: {
                                                            name: "intent",
                                                            value: [e]
                                                        }
                                                    }
                                                    handleChange(value)
                                                }}
                                            />
                                            {/* {isEdit && !selectedIntent && < Skeleton count={1} height="30px" width="100%" />} */}

                                            <div className='flex items-center justify-between !mt-4 !mb-2'>
                                                <label className='m-0'>Site Visit Comments (optional)</label>
                                                <button
                                                    type='button'
                                                    className='!border !border-grayLight rounded text-sm !text-black700 inter px-2.5 !gap-x-1 py-1 flex items-center flex-nowrap !bg-white hover:!bg-black200'
                                                    onClick={() => {
                                                        const value = {
                                                            target: {
                                                                name: "remarks",
                                                                value: ''
                                                            }
                                                        }
                                                        handleChange(value)
                                                    }}
                                                >Clear <CloseIcon className='h-3 w-3' /></button>
                                            </div>
                                            <textarea
                                                className="form-control mb-16 "
                                                placeholder="Enter any Site Visit Comments..."
                                                name="remarks"
                                                onChange={handleChange}
                                                value={values['remarks']}
                                            >
                                            </textarea>

                                            <label className='mt-3'>Select Followup Date & Time <sup>*</sup></label>
                                            <DateTimePicker
                                                onValueChange={(e) => {
                                                    const value = {
                                                        target: {
                                                            name: "followupScheduleDate",
                                                            value: e
                                                        }
                                                    }
                                                    handleChange(value)
                                                }}
                                                name="followupScheduleDate"
                                                handelBlur={handleBlur}
                                                error={errors['followupScheduleDate']}
                                                value={values['followupScheduleDate']}

                                            />
                                            {/* {activityLoader && <Skeleton count={1} height="30px" width="100%" />} */}

                                            {values && values.followupScheduleDate ?
                                                <div className="w-100 text-center mt-8 mb-24 px-24" >
                                                    <p className='text-center px-16 fz14 mt-16 black-dark-700 mb-0'>We will remind you <span className='black fw-po-medium'>15 mins</span> prior to <span className='black fw-po-medium'>{moment(values.followupScheduleDate).format("hh:mm a, Do MMM YYYY")}</span></p>
                                                </div>
                                                : " "}

                                            {userProfile?.allowed_modules?.includes('lead_sharing') && checkIsSomeItemAvailableInList(allowedPermissions, ['view_lead_share_modal', 'edit_lead_sharing']) &&
                                                <div className='pt-3'>
                                                    <div className='mt-3'>
                                                        <InputSelect
                                                            label={'Pre-sale'}
                                                            placeholder={'Select User'}
                                                            options={usersForAssigned}
                                                            value={assignedTo ? usersForAssigned.find(user => user.value === assignedTo) : ''}
                                                            onChange={(value) => {
                                                                if (value) {
                                                                    setAssignedTo(value.value);
                                                                } else {
                                                                    setAssignedTo(value);
                                                                }
                                                            }}
                                                            disable={isDisable}
                                                            isClearable={true}
                                                        />
                                                    </div>
                                                    <div className='mt-3'>
                                                        <InputSelect
                                                            label={'Closing'}
                                                            placeholder={'Select User'}
                                                            options={usersForAssigned}
                                                            value={closingManager ? usersForAssigned.find(user => user.value === closingManager) : ''}
                                                            onChange={(value) => {
                                                                if (value) {
                                                                    setClosingManager(value.value);
                                                                } else {
                                                                    setClosingManager(value);
                                                                }
                                                            }}
                                                            disable={isDisable}
                                                            isClearable={true}
                                                        />
                                                    </div>
                                                    <label className=' !mt-4'>Shared With</label>
                                                    {
                                                        users.map((item, index) => (
                                                            <div className={`${index > 0 ? 'mt-3' : ''}`} key={`${index}`}>
                                                                <div className='flex justify-between items-center'>
                                                                    <h3 className='text-base font-semibold'>User {index + 1}</h3>
                                                                    {allowedPermissions.includes('edit_lead_sharing') && <button
                                                                        type='button'
                                                                        className='text-primary text-base font-medium'
                                                                        onClick={() => {
                                                                            let newUsers = [...users];
                                                                            newUsers.splice(index, 1);
                                                                            if (users.length === 1) {
                                                                                setUsers([{}])
                                                                            } else {
                                                                                setUsers([...newUsers])
                                                                            }

                                                                        }}
                                                                    >Remove</button>}
                                                                </div>
                                                                <div>
                                                                    <InputSelect
                                                                        placeholder={'Select User'}
                                                                        options={allUsers}
                                                                        value={item.user_id ? allUsers.find(user => user.value === item.user_id) : ''}
                                                                        onChange={(value) => {
                                                                            let newUsers = [...users];
                                                                            newUsers[index].user_id = value.value;
                                                                            // let filteredUser = allUsers.filter(aluser => aluser.value !== value.value)
                                                                            // setAllUsers(filteredUser)
                                                                            setUsers([...newUsers]);
                                                                        }}
                                                                        disable={isDisable}
                                                                    />
                                                                    <div className='mt-3'>
                                                                        <label htmlFor={`edit_${index}`} className='mr-4'>
                                                                            <input
                                                                                type="radio"
                                                                                className='mr-2'
                                                                                name={`permission_${index}`}
                                                                                id={`edit_${index}`}
                                                                                value='edit'
                                                                                checked={item.access === 'edit'}
                                                                                onChange={() => {
                                                                                    let newUsers = [...users];
                                                                                    newUsers[index].access = 'edit'
                                                                                    setUsers(newUsers);
                                                                                }}
                                                                                disabled={!allowedPermissions.includes('edit_lead_sharing')}
                                                                            />
                                                                            <span>Edit Access</span>
                                                                        </label>
                                                                        <label htmlFor={`view_${index}`}>
                                                                            <input
                                                                                type="radio"
                                                                                className='mr-2'
                                                                                name={`permission_${index}`}
                                                                                id={`view_${index}`}
                                                                                value='view'
                                                                                checked={item.access === 'view'}
                                                                                onChange={() => {
                                                                                    let newUsers = [...users];
                                                                                    newUsers[index].access = 'view'
                                                                                    setUsers(newUsers);
                                                                                }}
                                                                                disabled={!allowedPermissions.includes('edit_lead_sharing')}
                                                                            />
                                                                            <span>View Only</span>
                                                                        </label>
                                                                    </div>
                                                                    <div>
                                                                        <label htmlFor="">Validity</label>
                                                                        <div className='grid grid-cols-2 gap-2'>
                                                                            <InputText
                                                                                placeholder={'e.g. 30'}
                                                                                value={item.validity}
                                                                                onChange={(e) => {
                                                                                    let newUsers = [...users];
                                                                                    newUsers[index].validity = e.target.value
                                                                                    setUsers(newUsers);
                                                                                }}
                                                                                disable={isDisable}
                                                                            />
                                                                            <InputSelect
                                                                                inputclass={'m-0'}
                                                                                styles={customStyles}
                                                                                placeholder={'Select Time'}
                                                                                value={item.validity_unit ? validityUnit.find(validity => validity.value === item.validity_unit) : ''}
                                                                                options={validityUnit}
                                                                                onChange={(value) => {
                                                                                    let newUsers = [...users];
                                                                                    newUsers[index].validity_unit = value.value;
                                                                                    setUsers(newUsers);
                                                                                }}
                                                                                disable={isDisable}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                    <button
                                                        type='button'
                                                        className='inline-flex !mt-3 items-center gap-x-2 !text-primary inter text-sm font-semibold disabled:bg-transparent disabled:hover:bg-transparent'
                                                        onClick={() => setUsers([...users, {
                                                            access: 'edit',
                                                            validity: 30,
                                                            validity_unit: 'days'
                                                        }])}
                                                        disabled={!allowedPermissions.includes('edit_lead_sharing')}
                                                    >
                                                        <PlusPrimaryIcon />Add another
                                                    </button>
                                                </div>}
                                        </div>
                                        <div className="form-inline justify-content-between px-20 filter-ok-cancel">
                                            <button
                                                className="cancel outline-btn"
                                                onClick={() => setShow(false)}
                                            >
                                                Cancel
                                            </button>
                                            {!isSubmitting && <button
                                                className="pr-btn"
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                Save Site Visit
                                            </button>}
                                            {isSubmitting && <button className="pr-btn flex items-center justify-center" type='button'>
                                                <Oval
                                                    height={24}
                                                    width={24}
                                                    color="#ffffff"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                    ariaLabel='oval-loading'
                                                    secondaryColor="#ffffff"
                                                    strokeWidth={4}
                                                    strokeWidthSecondary={4}
                                                />
                                            </button>}
                                        </div>

                                    </form>
                                )
                            }}
                    </Formik>

                </>
            }
        />
    )
}