import { API_URLS, API_METHOD } from '../../config/apiUrl';
import request from '../../helpers/requests';

export async function insertNews(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.customerApp.insertNews}`, null, payload, null);
}

export async function updateNews(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.customerApp.updateNews}/${uuid}`, null, payload, null);
}

export async function getAllNewsCRM() {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.customerApp.getAllNewsCRM}`, null, null, null);
}

export async function getNewsCRM(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.customerApp.getNewsCRM}/${uuid}`, null, null, null);
}

export async function deleteNews(uuid) {
    return await request(API_METHOD.DELETE, `${API_URLS.baseurl}${API_URLS.customerApp.deleteNews}/${uuid}`, null, null, null);
}

export async function getAppCustomers(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.customerApp.getAppCustomers}`, null, payload, null);
}

export async function getNewsTags() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.customerApp.getNewsTags}`, null, null, null);
}

export async function addReferralIncentiveCreative(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.customerApp.addReferralIncentiveCreative}`, null, payload, null);
}

export async function updateReferralIncentiveCreativeByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.customerApp.updateReferralIncentiveCreativeByUUID}/${uuid}`, null, payload, null);
}

export async function getAllCustomerReferrals(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.customerApp.getAllCustomerReferrals}`, null, payload, null);
}
export async function getReferralIncentiveCreative(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.customerApp.getReferralIncentiveCreative}`, null, payload, null);
}
export async function toggleReferralIncentiveCreativeStatusByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.customerApp.toggleReferralIncentiveCreativeStatusByUUID}/${uuid}`, null, payload, null);
}

export async function getResaleRequests(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.customerApp.getResaleRequests}`, null, payload, null);
}

export async function updateResaleRequestVisibilityByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.customerApp.updateResaleRequestVisibilityByUUID}/${uuid}`, null, payload, null);
}

export async function submitResaleRequestByPropacity(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.customerApp.submitResaleRequestByPropacity}`, null, payload, null);
}

export async function insertNoteByResaleRequestUUID(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.customerApp.insertNoteByResaleRequestUUID}/${uuid}`, null, payload, null);
}

export async function getNotesByResaleRequestUUID(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.customerApp.getNotesByResaleRequestUUID}/${uuid}`, null, null, null);
}

export async function insertNoteByReferralUUID(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.customerApp.insertNoteByReferralUUID}/${uuid}`, null, payload, null);
}

export async function getNotesByReferralUUID(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.customerApp.getNotesByReferralUUID}/${uuid}`, null, null, null);
}

export async function insertCustomerAppFaq(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.customerApp.insertCustomerAppFaq}`, null, payload, null);
}

export async function updateFaqById(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.customerApp.updateFaqById}/${uuid}`, null, payload, null);
}

export async function getCustomerAppFaqCrm() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.customerApp.getCustomerAppFaqCrm}`, null, null, null);
}

export async function deleteFaqById(uuid) {
    return await request(API_METHOD.DELETE, `${API_URLS.baseurl}${API_URLS.customerApp.deleteFaqById}/${uuid}`, null, null, null);
}

export async function getAllCustomerCallRequested(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.customerApp.getCustomerCallRequested}`, null, payload, null);
}

export async function insertCallbackRemarks(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.customerApp.insertCallbackRemarks}/${uuid}`, null, payload, null);
}

export async function updateCustomerCallRequested(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.customerApp.updateCustomerCallRequested}/${uuid}`, null, payload, null);
}