import React, { useEffect, useRef, useState } from 'react'
import { customerDashboard } from '../../../services/dashboard.service';
import InputText from '../../../../../components/InputGroup/InputText';
import MobileInput from '../../../../../components/InputGroup/MobileInput';
import { Oval } from 'react-loader-spinner';

const ReferralDetails = ({
    referredName,
    setReferredName,
    referredMobile,
    setReferredMobile,
    referredBySociety,
    setReferredBySociety,
    referredFlatNo,
    setReferredFlatNo,
    loading,
    handleSubmit,
    closeModal,
    setSteps
}) => {
    const [showList, setShowList] = useState(false);
    const [usersList, setUsersList] = useState([]);

    const referredRef = useRef();

    const handleMouseClick = (e) => {
        if (referredRef.current && !referredRef.current.contains(e.target)) {
            setShowList(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleMouseClick)
        return (() => document.removeEventListener('mousedown', handleMouseClick))
    }, []);

    const loadOptions = async (value) => {
        setReferredName(value);
        const payload = {
            "pageNumber": 1,
            "perPage": 100,
            "searchText": value,
            filters: {},
            tab: 'all'
        }
        const res = await customerDashboard(payload)
        if (res.data.status === 200) {
            setShowList(res.data.data.length > 0 ? true : false)
            setUsersList(res.data.data)
        }
    }

    return (
        <>
            <div className='h-[422px]'>

                <div className="!py-5 !px-6 grid grid-cols-2 !gap-x-9 !gap-y-5">
                    <div className=''>
                        <InputText
                            label={'Referred Name'}
                            placeholder={'Search Referred Name'}
                            value={referredName}
                            inputclass={'mt-0'}
                            required={true}
                            onChange={(e) => loadOptions(e.target.value, 'referred')}
                        />
                        {referredName && showList && <div ref={referredRef} className='absolute !top-48.5 !rounded-lg max-h-[200px] w-[350px] overflow-auto bg-white z-10 shadow !px-4'>
                            {
                                usersList?.map((item, index) => (
                                    <div className='flex flex-col text-sm !border-b !py-2 cursor-pointer' key={`${item.uuid}_${index}`} onClick={() => {
                                        setReferredMobile(item?.display_number)
                                        setReferredName(item?.name)
                                        setShowList(false)
                                    }}>
                                        <span className='font-semibold text-black-t'>{item.name}</span>
                                        <span className='text-grey-500 text-xs'>{item.display_number}</span>
                                    </div>
                                ))
                            }
                        </div>}

                    </div>

                    <MobileInput
                        label={'Referred Mobile'}
                        value={referredMobile}
                        inputclass={'mt-0'}
                        required={true}
                        onChange={(e) => setReferredMobile(e.phone)}
                    />
                    <InputText
                        label={'Referred Society'}
                        placeholder={'Enter Referred Society'}
                        value={referredBySociety}
                        inputclass={'mt-0'}
                        // required={true}
                        onChange={(e) => setReferredBySociety(e.target.value)}
                    />
                    <InputText
                        label={'Referred Flat No'}
                        placeholder={'Enter Referred Flat No'}
                        value={referredFlatNo}
                        inputclass={'mt-0'}
                        // required={true}
                        onChange={(e) => setReferredFlatNo(e.target.value)}
                    />
                </div>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-end !px-5 !py-4 shadow-topshadow">

                <button onClick={() => setSteps('basic')} className='border !rounded-lg py-2.5 w-[128px] shrink-0 !text-black-t font-medium text-base text-center'>Back</button>
                {!loading && <button onClick={handleSubmit} className='border !rounded-lg w-[128px] !py-2.5 !ml-4 !bg-primary !border-primary text-white font-medium text-base text-center hover:opacity-90'>
                    Create
                </button>}

                {loading && <button className='border !rounded-lg w-[128px] py-2.5 !ml-4 !bg-primary !border-primary font-semibold text-16  flex items-center justify-center'>
                    <Oval
                        height={24}
                        width={24}
                        color="#FFFFFF"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#FFFFFF"
                        strokeWidth={4}
                        strokeWidthSecondary={4}
                    />
                </button>}

            </div >

        </>
    )
}

export default ReferralDetails