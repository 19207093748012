import React, { useEffect, useState } from 'react'

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
// import { ReactComponent as SuccessIcon } from '../../../../../assets/icons/success.svg';

import { toast } from 'react-toastify';
import { Oval } from 'react-loader-spinner';

import { ReactComponent as BackIcon } from '../../../assets/icons/back.svg';
import InputText from '../../../components/InputGroup/InputText';
import MobileInput from '../../../components/InputGroup/MobileInput';
import { addBrokerCompany, getMicromarket, getUsersForSettings, updateBrokerCompany } from '../../../services/private/company.service';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { getCities } from '../../../services/private/csv.service';
import RightModal from '../../../components/Modals/RightModal';
import InputSelect from '../../../components/InputGroup/InputSelect';
import { ALL_REGEX } from '../../../config/static';
import { ReactComponent as LocationIcon } from '../../../assets/icons/location.svg'
import ShareLocationModal from './ShareLocationModal';

const customStyles = {
    option: (styles, state) => ({
        ...styles,
        zIndex: 99,
    }),
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: (base, state) => ({
        ...base,
        zIndex: 99,
        // You can also use state.isFocused to conditionally style based on the focus state
    }),
};

const AddNewCPFirm = ({ show, closeModal, getCompanyData, selectedCompany }) => {

    const [isUserInvited, setisUserInvited] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [ownerName, setOwnerName] = useState('');
    const [ownerEmail, setOwnerEmail] = useState('');
    const [ownerMobile, setOwnerMobile] = useState('');
    const [firmName, setCPFirmName] = useState('');
    const [city, setCity] = useState([{ label: 'Pune', value: 'Pune' }]);
    const [microMarket, setMicroMarket] = useState('')
    const [sourcingManager, setSourcingManager] = useState('')
    const [error, setError] = useState(false);
    const [usersList, setUsersList] = useState([]);
    const [mobileError, setMobileError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [showShareLocationModal, setShowShareLocationModal] = useState(false);
    const [firmLocation, setFirmLocation] = useState('');
    const [longitude, setLongitude] = useState('');
    const [latitude, setLatitude] = useState('');
    const [isAppInstalling, setIsAppInstalling] = useState(false);

    const getUsers = async () => {
        const res = await getUsersForSettings();
        if (res.data.status === 200) {
            setUsersList(res.data.data.map(i => ({ ...i, label: i.name, value: i.id })))
        }
    }

    console.log(selectedCompany, 'selected company')
    useEffect(() => {
        // console.log(Object.keys(selectedCompany))
        if (selectedCompany && Object.keys(selectedCompany?.length > 0)) {
            setOwnerEmail(selectedCompany?.owner_email || '');
            setOwnerMobile(selectedCompany?.owner_mobile || '');
            setOwnerName(selectedCompany?.owner_name || '');
            setMicroMarket(selectedCompany?.micromarket ? { label: selectedCompany?.micromarket, value: selectedCompany?.micromarket_id } : '');
            setSourcingManager(selectedCompany?.rm_id || '');
            setCPFirmName(selectedCompany?.name || '');
            setCity(selectedCompany?.operational_cities ? selectedCompany?.operational_cities.map(i => ({ label: i, value: i })) : '');
            // setLatitude()
            // setLongitude();
            // setFirmLocation()
        }
    }, [selectedCompany]);

    useEffect(() => {
        getUsers();
    }, []);

    const promiseOptions = (inputValue) => {
        return getMicromarket(inputValue)
            .then((res) => {
                if (res.data.status === 200) {
                    const market = res.data?.data
                        .filter((m) => m.value)
                        .map(m => {
                            return {
                                label: m.value,
                                value: m.id
                            }
                        })
                    return market;
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const loadOptions = (city) => {
        return getCities(city)
            .then((res) => {
                if (res.data.status === 200) {
                    const cities = res.data?.data.map((city) => {
                        return {
                            label: city.name,
                            value: city.name
                        }
                    });
                    return cities;
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleSubmit = async () => {

        if (!ownerName || !ownerMobile || !city || !sourcingManager) {
            toast.error('Please fill all required field')
            return;
        } else {
            setError(false);
        }

        if (ownerMobile && ownerMobile.length < 12) {
            setMobileError(true);
            return;
        } else {
            setMobileError(false)
        }
        if (ownerEmail && !ALL_REGEX.EMAIL.test(ownerEmail)) {
            setEmailError(true);
            return;
        } else {
            setEmailError(false)
        }

        const payload = {
            name: firmName.trim() || null,
            owner_mobile: ownerMobile || null,
            owner_email: ownerEmail || null,
            owner_name: ownerName || null,
            operational_cities: city.map(i => i.value),
            micromarket: microMarket?.value,
            rm_id: sourcingManager,
            location: {
                latitude: latitude,
                longitude: longitude,
                googleAddress: firmLocation
            },
            is_app_signup: isAppInstalling
        }
        try {
            setIsSubmitting(true);
            if (selectedCompany?.uuid) {
                const res = await updateBrokerCompany(selectedCompany?.uuid, payload)
                if (res.data.status === 200) {
                    toast.success(res.data.message);
                    setIsSubmitting(false);

                    getCompanyData();
                    closeModal();
                } else {
                    setIsSubmitting(false);

                    console.log(res.data.message);
                }
            } else {
                const res = await addBrokerCompany(payload)
                if (res.data.status === 200) {
                    toast.success(res.data.message);
                    setIsSubmitting(false);
                    getCompanyData();
                    closeModal();
                } else {
                    setIsSubmitting(false);
                    console.log(res.data.message);
                }
            }
        } catch (err) {
            setIsSubmitting(false);
            toast.error(err.response.data.message)
        }
    }

    return (
        <RightModal show={show} closeModal={closeModal} modalClass={`configurationModal`}>
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <div className='flex items-center'>
                    {/* <button onClick={closeModal}>
                        <BackIcon />
                    </button> */}
                    <h1 className='mb-0 black inter fz20 fw-po-medium ml-2'>
                        {selectedCompany?.uuid ? 'Edit' : 'Add'} CP Firm
                    </h1>
                </div>
                <button className='border-0 bg-tr ' onClick={closeModal}><CloseIcon /></button>
            </div>

            <div onSubmit={handleSubmit} className='flex flex-col h-full  overflow-hidden'>
                <div className='h-full overflow-auto !px-5 !space-y-4 !mb-3'>
                    <InputText
                        value={ownerName}
                        onChange={(e) => setOwnerName(e.target.value)}
                        index={2}
                        inputclass={'!mt-4'}
                        type="text"
                        placeholder="Enter Owner Name"
                        label="Owner Name"
                        required={true}
                    />
                    <MobileInput
                        index={1}
                        // countryCode="+91"
                        type='text'
                        value={ownerMobile}
                        onChange={(e) => {
                            setOwnerMobile(e.phone)
                        }}
                        placeholder="e.g. 9810139281"
                        label="Owner Mobile No."
                        required={true}
                        error={
                            (mobileError && ownerMobile) ? 'Please enter valid mobile no.' : ''
                        }
                    />
                    <InputText
                        value={ownerEmail}
                        onChange={(e) => setOwnerEmail(e.target.value)}
                        index={2}
                        type="email"
                        placeholder="Enter Owner Email"
                        label="Owner Email"
                        // required={true}
                        error={
                            (emailError && ownerEmail) ? 'Please enter valid email' : ''
                        }
                    />
                    <InputText
                        value={firmName}
                        onChange={(e) => setCPFirmName(e.target.value)}
                        type="text"
                        placeholder="Enter CP Firm Name"
                        name="name"
                        label="CP Firm Name"
                        required={true}
                    />
                    <InputSelect
                        value={sourcingManager ? usersList.find(i => i.id === sourcingManager) : ''}
                        label={'Select Sourcing Manager'}
                        placeholder={'Select Sourcing Manager'}
                        options={usersList}
                        onChange={(e) => {
                            setSourcingManager(e.value)
                        }}
                        required={true}
                    // onBlur={() => setTouchedMentorId(true)}
                    />
                    {/* <InputText
                        value={microMarket}
                        onChange={(e) => setMicroMarket(e.target.value)}
                        index={2}
                        type="email"
                        placeholder="Enter Micromarket"
                        label="Micromarket"
                    /> */}
                    <div>
                        <label htmlFor="">Micromarket</label>
                        <AsyncCreatableSelect
                            cacheOptions
                            loadOptions={promiseOptions}
                            defaultOptions
                            value={microMarket}
                            placeholder='Search and Select Micromarket'
                            styles={customStyles}
                            onChange={(e) => {
                                // if (e)
                                //     setCity(e)
                                setMicroMarket(e)
                            }}
                            menuPosition={'fixed'}
                            isClearable
                        />
                    </div>
                    <div>
                        <label htmlFor=""> Operational City</label>
                        <AsyncSelect
                            cacheOptions
                            loadOptions={loadOptions}
                            defaultOptions
                            value={city}
                            placeholder='Search and Select City'
                            styles={customStyles}
                            onChange={(e) => {
                                if (e)
                                    setCity(e)
                            }}
                            menuPosition={'fixed'}
                            isMulti={true}
                        />
                    </div>
                    <div className=''>
                        <label htmlFor="">
                            App Signup
                        </label>
                        <div className='grid grid-cols-2 gap-2'>
                            <div className='w-full'>
                                <label className='mb-0 flex items-center justify-between !border !py-4 !px-3 !rounded-lg !border-primary bg-[#2064FA0A]'>
                                    <span className='text-sm text-black-t font-medium'>Yes</span>
                                    <input type="radio" name="app" id="" value={true} checked={isAppInstalling} onChange={() => setIsAppInstalling(true)} />
                                </label>
                            </div>
                            <div className='w-full'>
                                <label className='mb-0 flex items-center justify-between !border !py-4 !px-3 !rounded-lg'>
                                    <span className='text-sm text-black-t font-medium'>No</span>
                                    <input type="radio" name="app" id="" value={false} checked={!isAppInstalling} onChange={() => setIsAppInstalling(false)} />
                                </label>
                            </div>
                        </div>
                    </div>
                    {!firmLocation && <div>
                        <label htmlFor="">Office Location
                            <sup>*</sup>
                        </label>
                        <div
                            className='bg-black100 text-[#647187] !py-3 !px-3 !border !rounded-lg flex items-center justify-center cursor-pointer'
                            onClick={() => {
                                setShowShareLocationModal(true)
                            }}
                        >
                            <LocationIcon className='!mr-2' />
                            Add Location
                        </div>
                    </div>}
                    {firmLocation && <div className='!mt-4'>
                        <label htmlFor="">Office Location
                            <sup>*</sup>
                        </label>
                        <div className='!border !rounded-lg !p-4'>

                            <iframe src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDRuni-c1oJBFaF8ahTxlxPmYyCmRRtclw&q=${firmLocation}`} width="100%" height="300" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" className='border-0'></iframe>
                            <div className='flex !mt-3'>
                                {/* <LocationIcon className='!mr-2 h-4 w-4' /> */}
                                <span className=' mb-0'>
                                    {firmLocation}
                                </span>
                            </div>
                        </div>
                    </div>}
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between !px-5 gap-x-4 !py-4 righmodalfooter shadow-topshadow">
                    <button onClick={closeModal}
                        className='border rounded-lg py-2.5 w-28 basis-28 grow-0 shrink-0 border-primary !text-primary font-semibold text-16 text-center'>Cancel</button>
                    {!isSubmitting && <button onClick={() => {
                        handleSubmit()
                    }}
                        className='border rounded-lg py-2.5 w-full ml-4 !bg-primary !border-primary text-white font-semibold text-16 text-center hover:opacity-90'>
                        {selectedCompany?.uuid ? 'Update' : 'Save'}
                    </button>
                    }
                    {isSubmitting && <button className='border rounded-lg py-2.5 w-full ml-4 !bg-primary flex items-center justify-center  hover:opacity-90' type='button'>
                        <Oval
                            height={24}
                            width={24}
                            color="#ffffff"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#ffffff"
                            strokeWidth={4}
                            strokeWidthSecondary={4}
                        />
                    </button>}
                </div >
            </div>
            {
                showShareLocationModal && <ShareLocationModal
                    show={showShareLocationModal}
                    closeModal={() => setShowShareLocationModal(false)}
                    setFirmLocation={setFirmLocation}
                    setFirmLongitude={setLongitude}
                    setFirmLatitude={setLatitude}
                />
            }
        </RightModal>
    )
}

export default AddNewCPFirm