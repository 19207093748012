import React from "react";


const InputModal = (props) => {
  const { show, setShow, name, editSave, type, placeholder, value, onChange, editClassRequired = true } = props;
  return (
    <div className="w-100">
      <div className={`${editClassRequired ? 'edit-input-modal' : '!w-100'} active`}>
        <div className="form-group mb-0">
          <div className="row">
            <div className="col-8 pr-0">
              <h3 className="fz16 mb-6">{name}</h3>
            </div>
            <div className="col-4">
              <div className="d-flex align-itmes-cener justify-content-end">
                <button onClick={() => editSave()} className="border-0 ml4 bg-tr px-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <rect width="20" height="20" rx="4" fill="#0062FF" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.58613 9.97668C5.25907 9.65288 4.73144 9.65552 4.40764 9.98259C4.08383 10.3097 4.08648 10.8373 4.41354 11.1611L7.60133 14.3005C7.94405 14.6398 8.50236 14.6182 8.81787 14.2535L15.6301 6.37854C15.9312 6.03047 15.8931 5.5042 15.545 5.2031C15.197 4.902 14.6707 4.94008 14.3696 5.28815L8.14051 12.489L5.58613 9.97668Z"
                      fill="white"
                    />
                  </svg>
                </button>
                <button onClick={() => setShow(false)} className="border-0 ml4 bg-tr">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4Z"
                      fill="#B5B5BE"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.1761 10.0006L15.5737 14.4021C15.899 14.7277 15.8987 15.2553 15.5731 15.5806C15.2476 15.9059 14.7199 15.9057 14.3946 15.5801L9.99674 11.1782L5.58865 15.5798C5.26298 15.905 4.73534 15.9046 4.41014 15.5789C4.08495 15.2532 4.08534 14.7256 4.41102 14.4004L8.81876 9.99919L4.41288 5.58931C4.08759 5.26373 4.08783 4.73609 4.41341 4.4108C4.739 4.08551 5.26663 4.08575 5.59192 4.41134L9.99815 8.82155L14.3948 4.43145C14.7205 4.10625 15.2481 4.10664 15.5733 4.43232C15.8985 4.758 15.8981 5.28564 15.5724 5.61083L11.1761 10.0006Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <input
                type={type}
                min="0"
                className="pl4 form-control"
                placeholder={placeholder}
                value={value}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InputModal;
