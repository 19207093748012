import React, { useState } from 'react'
import RightModal from '../../../components/Modals/RightModal';
import { ReactComponent as BackIcon } from '../../../assets/icons/BackIcon.svg';
import Location from '../../../components/Projects/Location';
import LocationPicker from './LocationPicker';

const ShareLocationModal = ({ show, closeModal, setFirmLocation, setFirmLongitude, setFirmLatitude }) => {
    const [googleAddress, setGoogleAddress] = useState('');
    const [city, setCity] = useState('');
    const [longitude, setLongitude] = useState('');
    const [latitude, setLatitude] = useState('');
    const [state, setState] = useState('');

    const handleUpdateLocation = (city, address, state, lat, long) => {
        setCity(city);
        setGoogleAddress(address);
        setState(state);
        setLatitude(lat);
        setLongitude(long)
    }

    const handleSubmit = () => {
        setFirmLocation(googleAddress)
        setFirmLongitude(longitude);
        setFirmLatitude(latitude);
        closeModal()
    }

    return (
        <RightModal show={show} closeModal={closeModal} modalClass={`configurationModal relative`}>
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <div className='flex items-center'>
                    <button onClick={closeModal}>
                        <BackIcon />
                    </button>
                    <h1 className='mb-0 black inter fz20 fw-po-medium ml-2'>
                        Share Location
                    </h1>
                </div>
            </div>
            <div className='flex flex-col h-[80vh]  overflow-hidden '>
                <LocationPicker
                    googleAddress={googleAddress}
                    previousCity={city}
                    latitude={latitude}
                    longitude={longitude}
                    previousState={state}
                    handleUpdateLocation={handleUpdateLocation}
                />
            </div>
            <div className="absolute bottom-0 w-full d-flex flex-row align-items-center justify-content-between !px-5 gap-x-4 !py-4 righmodalfooter shadow-topshadow">

                <button onClick={() => {
                    handleSubmit()
                }}
                    className='border rounded-lg py-2.5 w-full ml-4 !bg-primary !border-primary text-white font-semibold text-16 text-center hover:opacity-90'>
                    Share your current location
                </button>
            </div >
        </RightModal>
    )
}

export default ShareLocationModal